export const Constants = {
  token: 'TOKEN',
  companyGuid: 'c',
  companyName: "cN",
  language: "lang"
}

export enum CreateMode  {
  read,
  create_as_parent,
  create_as_child,
  create_as_user
}

export enum FrontVehicleType {
  Pull,
  Load
}

export enum BackVehicleType{
  Book,
  Curtain,
  Mega,
  Block,
  Refrigerated,
  Tarpaulin,
  Hanger,
  FlatBed,
  Tilting,
  PullOut,
  Crane,
  Jumbo
}

export enum TransportEquipmentType{
  Stabler,
  Truck,
  ElectricLift
}

export enum TourType{
  Export = 'Export',
  Inland = 'Inland'
}

export enum JobType{
  Wash = "Wash",
  PersonalService = "PersonalService"
}


export enum StatusType {
  InProgress = 'InProgress',
  Completed = 'Completed',
  Scheduled = 'Scheduled',
  Overdue = 'Overdue',
  Unknown = 'Unknown',
  Taken = 'Taken',
  Available = 'Available',
  Booked = 'Booked',
  Scheduled24Hours = 'Scheduled24Hours',
  Paused = 'Paused',
  BusinessRelated = 'BusinessRelated',
  Holidays = 'Holidays',
  Personal = 'Personal',
  Broken = "Broken",
  Wash = "Wash",
  PersonalService = "PersonalService",
  Alarm = "Alarm",
  Sick = "Sick",
  Off = "Off",
  OffPaid = "OffPaid",
  Education = "Education",
  Vacation = "Vacation",
  OnHold = "OnHold",
  Rest = "Rest",
  Intern = "Intern",
  Wait = "Wait",
  Garage = "Garage",
  Zero = "Zero",
  Task = "Task",
  WrongVehicle = "WrongVehicle"
}

export enum CalenderView  {
  none,
  resource,
  event,
}

export enum PermissionType {
  Full,
  Read,
  Limited,
}



export const StatusColors = {
  completed: '#337A94',
  inProgress: '#709A66',
  scheduled: '#D4D4D4',
  scheduled24Hours: '#D4D4D4',
  overdue:  '#B54B5D',
  available: '#709A66',
  booked: '#777777',
  taken:  '#FFDD82',
  paused:  '#FFDD82',
  unknown: '#B4DD8D',
  businessRelated: '#97608E',
  holidays: '#97608E',
  personal: '#97608E',
  broken: '#97608E',
  wash: '#04397D',
  personalService: '#04397D',
  alarm:  '#B54B5D',
  sick: "#97608E",
  off: "#97608E",
  offPaid: "#97608E",
  education: "#97608E",
  vacation: "#97608E",
  onHold: "#FF9966",
  zero: "#B54B5D",
  rest: "#777777",
  wait: "#FF9966",
  wrongVehicle: "#B54B5D"

}

/// NEW BACKGROUND COLORS
export const BackgroundStatusColors = {
  completed: '#77ADC3',
  inProgress: '#B8CDB3',
  scheduled: '#D4D4D4',
  scheduled24Hours: '#D4D4D4',
  overdue:  '#B54B5D',
  available: '#709A66',
  booked: '#777777',
  taken:  '#FFE398',
  paused:  '#FFE398',
  unknown: '#B54B5D',
  businessRelated: '#97608E',
  holidays: '#97608E',
  personal: '#97608E',
  broken: '#97608E',
  wash: '#04397D',
  personalService: '#04397D',
  alarm:  '#B54B5D',
  sick: "#97608E",
  off: "#97608E",
  offPaid: "#97608E",
  education: "#97608E",
  vacation: "#97608E",
  onHold: '#FF9966',
}

export const MapStatusColors = {
  rest: '#cccccc',
  work: '#FFDD82',
  driving: '#709A66',
  raadig: '#8e2b3c',
  default: '#eeeeee'
}
