import { Injectable } from '@angular/core';
import { environment } from "../../../environments/environment";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { NoteModel } from "../../shared/note-widget/shared/note.model";
import {
  CreateTransportEquipmentEventModel,
  CreateTransportEquipmentModel,
  TransportEquipmentModel,
  UpdateTransportEquipmentModel
} from "./transport-equipment.model";
import { UpdateBackVehicleEventModel } from 'src/app/back-vehicles/shared/back-vehicle.model';
import { DataExchangeModel } from 'src/app/shared/fileuploader/shared/data-exchange.model';

const url = environment.baseUrl + '/V1/TransportEquipments';

@Injectable({
  providedIn: 'root'
})

export class TransportEquipmentService {
  constructor(private http: HttpClient) { }

  getAll(): Observable<TransportEquipmentModel[]>{
    return this.http.get<TransportEquipmentModel[]>(`${url}/All`);
  }

  getSingle(id: number): Observable<TransportEquipmentModel> {
    return this.http.get<TransportEquipmentModel>(`${url}/${id}`);
  }

  create(model: CreateTransportEquipmentModel): Observable<TransportEquipmentModel> {
    return this.http.post<TransportEquipmentModel>(url, model);
  }

  delete(id: number): Observable<any> {
    return this.http.delete(`${url}/${id}`);
  }

  update(id: number, model: UpdateTransportEquipmentModel): Observable<TransportEquipmentModel> {
    return this.http.put<TransportEquipmentModel>(`${url}/${id}`, model);
  }

  getAttachments(transportEquipmentId: number): Observable<TransportEquipmentModel> {
    return this.http.get<TransportEquipmentModel>(`${url}/${transportEquipmentId}/Attachments`)
  }

  createAttachment(transportEquipmentId: number, file: File): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('file', file);
    return this.http.post<TransportEquipmentModel>(`${url}/${transportEquipmentId}/Attachment`, formData);
  }

  deleteAttachment(transportEquipmentId: number, attachmentId: number): Observable<TransportEquipmentModel> {
    return this.http.delete<TransportEquipmentModel>(`${url}/${transportEquipmentId}/Attachment/${attachmentId}`);
  }

  getNotes(transportEquipmentId: number): Observable<TransportEquipmentModel> {
    return this.http.get<TransportEquipmentModel>(`${url}/${transportEquipmentId}/Notes`);
  }

  deleteNote(transportEquipmentId: number, noteId: number): Observable<TransportEquipmentModel> {
    return this.http.delete<TransportEquipmentModel>(`${url}/${transportEquipmentId}/Note/${noteId}`);
  }

  createNote(transportEquipmentId: number, model: NoteModel): Observable<TransportEquipmentModel> {
    return this.http.post<TransportEquipmentModel>(`${url}/${transportEquipmentId}/Note`, model);
  }

  createTransportEquipmentEvent(transportEquipmentId: number, model: CreateTransportEquipmentEventModel): Observable<any> {
    return this.http.post<any>(`${url}/${transportEquipmentId}/Events`, model);
  }

  updateTransportEquipmentEvent(transportEquipmentId: number, eventId: number, model: UpdateBackVehicleEventModel): Observable<any> {
    return this.http.put<any>(`${url}/${transportEquipmentId}/Events/${eventId}`, model);
  }

  getSingleEvent(id: number): Observable<any> {
    return this.http.get<any>(`${url}/${id}/Events`);
  }

  getImportSchemas() {
    return this.http.get<DataExchangeModel[]>(`${url}/GetDataExchangeImportSchemas`);
  }

  getBox(boxId: number): Observable<any> {
    return this.http.get<any>(`${environment.baseUrl}/V1/Box/${boxId}`);
  }

  // MISSING IN API
  updatedBox(boxId: number, transportEquipmentId: number) {
    return this.http.put<any>(`${url}/${transportEquipmentId}/Box/`, boxId);
  }

  processImportFile(file: File, schemaid: number, customerid: number): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('file', file);
    formData.append('dataExchangeSchemaId', schemaid.toString());
    return this.http.post(`${url}/ImportDataExchange`, formData);
  }
}
