<div *ngIf="frontVehicles">
  <app-table
    *ngIf="frontVehicles.length > 0"
    [data]="tableData"
    [showImport]="true"
    [showCalenderView]="true"
    [multipleSelection]="true"
    [groupKey]="'FrontVehicles'"
    [groupsOptions]="this.groups"
    [serviceToUse]="'frontVehicle'"
    [calendarViewOption]="calenderView.resource"
    [showGroupButton]="true"
    (requestData)="getAll()"
    (addItem)="addNew = true"
    (selectedItem)="showItemDetails($event)"
    (createTourForItem)="createTour($event)"
    (selectedSubItem)="selectedSubItemId = $event.id"
    (addSelectedToGroupEmit)="addSelectedToGroup($event, 'FrontVehicles')"
    (bubbleSaveEvent)="saveMainGroup($event)"
  ></app-table>

  <app-detail
    *ngIf="showDetails && selectedItemId > 0"
    class="overlay"
    (close)="closeItemDetails()"
  >
    <app-front-vehicle-detail [id]="selectedItemId" #widget [active]="true" (updateList)="getAll()" (close)="showDetails = false"></app-front-vehicle-detail>
  </app-detail>

  <app-detail
    *ngIf="showTourDetails"
    class="overlay"
    (close)="closeTourItemDetails()"
  >
    <app-tour-detail [id]="tourId" #widget (updateList)="getAll()" (close)="showTourDetails = false"></app-tour-detail>
  </app-detail>

  <app-detail
    *ngIf="addNew"
    class="overlay"
    (close)="addNew = false"
    [closeByBackdrop]="false"
  >
    <app-front-vehicle-create #widget (cancel)="addNew = false" (created)="addNew = false; getAll()" (updateList)="getAll()"></app-front-vehicle-create>
  </app-detail>
  <div class="center" *ngIf="frontVehicles.length === 0">
    <button class="btn btn-default" style="width: 100%" (click)="addNew = true">{{'btn.createFrontVehicle' | translate}}</button>
  </div>
</div>

<app-loading *ngIf="!frontVehicles"></app-loading>

<app-detail
  *ngIf="selectedSubItemId > 0"
  class="overlay"
  (close)="selectedSubItemId = 0"
>
  <app-tour-detail [id]="selectedSubItemId" #widget [active]="true" (close)="selectedSubItemId = 0"></app-tour-detail>
</app-detail>

<app-detail
  *ngIf="createNewTour"
  class="overlay"
  (close)="createNewTour = undefined"
  [closeByBackdrop]="false"
>
  <app-tour-create
    #widget
    (close)="createNewTour = undefined"
    (cancel)="createNewTour = undefined"
    (created)="createNewTour = undefined; getAll()"
    [startDate]="createNewTour.event.startDate"
    [selectedFrontVehicle]="createNewTour.frontVehicle"
  ></app-tour-create>
</app-detail>

