import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FrontVehiclesRoutingModule } from './front-vehicles-routing.module';
import { FrontVehiclesComponent } from './front-vehicle-list/front-vehicles.component';
import {SharedModule} from "../shared/shared.module";
import { FrontVehicleDetailComponent } from './front-vehicle-detail/front-vehicle-detail.component';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {NgbModule} from "@ng-bootstrap/ng-bootstrap";
import {FontAwesomeModule} from "@fortawesome/angular-fontawesome";
import {AutosizeModule} from 'ngx-autosize';
import { FrontVehicleCreateComponent } from './front-vehicle-create/front-vehicle-create.component';
import {ToursModule} from "../tours/tours.module";



@NgModule({
  declarations: [
    FrontVehiclesComponent,
    FrontVehicleDetailComponent,
    FrontVehicleCreateComponent
  ],
    imports: [
        CommonModule,
        FrontVehiclesRoutingModule,
        SharedModule,
        NgbModule,
        ReactiveFormsModule,
        FontAwesomeModule,
        AutosizeModule,
        ToursModule
    ],
    exports: [
      FrontVehicleDetailComponent,
      FrontVehicleCreateComponent
    ]
})
export class FrontVehiclesModule { }
