import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrl: './checkbox.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi:true,
      useExisting: CheckboxComponent
    }
  ]
})
export class CheckboxComponent implements ControlValueAccessor {
  @Input()
  state: boolean = false

  @Input()
  disabled = false;

  @Output()
  emittedState: EventEmitter<any> = new EventEmitter()

  // Internal properties
  isChecked = false;
  onChange = ((_: any) => { }) // unkown type
  onBlur = ((_: any) => { }) // unkown type

  constructor() { }


  ngOnInit(): void {

  }


  writeValue(obj: boolean): void {
    this.isChecked = obj;
  }


  // unknown type
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }


  // unknown type
  registerOnTouched(fn: any): void {
    this.onBlur = fn;
  }


  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }


  // unknown type
  onChanged(event: any) {
    this.isChecked = event && event.target && event.target.checked;
    this.onChange(this.isChecked);
    this.emittedState.emit(this.isChecked)
  }
}
