<div
  class="modal fade"
  [ngClass]="{ 'show': isVisible }"
  id="exampleModal"
  tabindex="-1"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog">
    <div class="modal-content container-fluid">
      <div class="modal-header">
        <h1 class="modal-title fs-5" id="exampleModalLabel">
          {{ this.title }}
        </h1>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="this.cancel()"></button>
      </div>
      <div class="modal-body">
        <ng-content></ng-content>
      </div>
      <div class="modal-footer">
        @if (this.cancelButton) {
          <button type="button" class="btn btn-link" data-bs-dismiss="modal" (click)="this.cancel()">
            {{ 'modal.footer.cancel' | translate}}
          </button>
        }

        @if (this.confirmButton) {
          <button type="button" class="btn btn-secondary" (click)="this.confirm()">
            {{ 'modal.footer.confirm' | translate }}
          </button>
        }
      </div>
    </div>
  </div>
</div>
