import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { faCheck, faPencilAlt, faTimes, faTrash, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { DateValidator } from 'src/app/form-validators/date-validator';
import { TimeValidator } from 'src/app/form-validators/time-validator';
import { TachoModel, CreateBoxModel } from 'src/app/front-vehicles/shared/front-vehicle.model';
import { SharedService } from '../services/shared.service';

@Component({
  selector: 'app-tacho-info-widget',
  templateUrl: './tacho-info-widget.component.html',
  styleUrls: ['./tacho-info-widget.component.css']
})

export class TachoInfoWidgetComponent implements OnInit {

  @Input()
  tacho: TachoModel | undefined;

  @Input()
  createMode = false;

  @Output()
  cancelCreate = new EventEmitter();

  @Output()
  createEvent = new EventEmitter();

  @Output()
  updateEvent = new EventEmitter();

  @Output()
  removeEvent = new EventEmitter();

  @Output()
  cancelEvent = new EventEmitter();

  formGroup: UntypedFormGroup;

  @Input()
  includeAccept = true;

  types: string[] = [];

  faEdit = faPencilAlt;
  faTrash = faTrashAlt;
  faAccept = faCheck;
  faCancel = faTimes;

  readOnlyBox = true;

  toogleTachoDl: boolean = true;

  vehicleDDD: string = "";
  driverDDD: string = "";
  analyticComp: string = "";
  comment: string = "";

  language: any;

  constructor(private formBuilder: UntypedFormBuilder, private sharedService: SharedService, private datePipe: DatePipe) {
    this.formGroup = this.formBuilder.group({
      vehicleDDD: [this.vehicleDDD],
      driverDDD: [this.driverDDD],
      analyticComp: [this.analyticComp],
      comment: [this.comment],
    });


  }

  async getLanguage() {
    this.sharedService.getLanguageJson().subscribe(response => this.language = response)
  }

  async ngOnInit(): Promise<void> {
    await this.getLanguage()
    this.setTachoData()
  }

  setTachoData(): void {
    if (this.tacho) {

      this.vehicleDDD = this.language.tacho.every + " " + this.tacho.vehicleDDDDownloadRate + ". " + this.language.tacho.day
      this.driverDDD = this.language.tacho.every + " " + this.tacho.driverDDDDownloadRate + ". " + this.language.tacho.day

      if (this.tacho.analyticCompanyId == 1) this.analyticComp = "DAKO"

      this.comment = this.tacho.comment
    }
  }

  setTachoDl(): void {
    this.toogleTachoDl = !this.toogleTachoDl
  }
}
