<div class="col-md-12" style="padding: 20px 10px;" *ngIf="!uploaderActive">
    <div class="row">
        <div class="col-md-12" style="margin-top: 10px; white-space: pre-line;">
            {{'fileuploader.introText' | translate}}
            <br>
            <hr>
            <p><strong>{{'fileuploader.title' | translate}}</strong></p>

            <form [formGroup]="formGroup">
                <div class="form-group">
                    <label>{{'fileuploader.schema' | translate}}</label>
                    <select class="form-select" formControlName="schema" (change)="onChange($event.target)" style="margin-top: 10px;">
                      <option [value]='null' >{{'fileuploader.default' | translate}}</option>
                      <option *ngFor="let schema of dataSchemas" [value]="schema.id">{{schema.description}}</option>
                    </select>
                  </div>

                  <div *ngIf="serviceToUse == 'tour'" class="form-group">
                    <label>{{'fileuploader.customer' | translate}}</label>
                    <select class="form-select" formControlName="customer" (change)="onCustomerChange($event.target)" style="margin-top: 10px;">
                      <option [value]='null' >{{'fileuploader.defaultCustomer' | translate}}</option>
                      <option *ngFor="let customer of customers" [value]="customer.id">{{customer.name}}</option>
                    </select>
                  </div>
            </form>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12" style="margin-top: 10px;">
            <ngx-dropzone (change)="onSelect($event)" [multiple]="false" [disabled]="validation()">
            <ngx-dropzone-label><fa-icon [icon]="faUpload" size="6x"></fa-icon><br><br>{{'fileuploader.fieldText' | translate}}</ngx-dropzone-label>
	            <ngx-dropzone-preview *ngFor="let f of files" [removable]="true" (removed)="onRemove(f)">
		            <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
	            </ngx-dropzone-preview>
            </ngx-dropzone>
        </div>
        <div class="col-md-12" style="margin-top: 10px;" *ngIf="message">
            {{message}}
        </div>

    </div>
</div>

<div class="col-md-12" style="padding-left: 0;" *ngIf="uploaderActive">
    <app-loading></app-loading>
</div>
