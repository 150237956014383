<div class="position-relative">
  <form class="form-group" [formGroup]="formGroup">
    <h6 class="mt-3">{{'toursBook.searchTitle' | translate}}</h6>
    <div class="row align-items-end me-0">
      <div class="col">
        <label for="fromUtc">{{'logBook.general.fromUtc' | translate}}
          <span *ngIf="formGroup.controls['fromUtc'].hasError('required')" class="required-text">
            {{'message.required' | translate}}
          </span>
        </label>
        <input type="date" id="fromUtc" name="fromUtc" formControlName="fromUtc" class="form-control" />
      </div>
      <div class="col">
        <label for="toUtc">{{'logBook.general.toUtc' | translate}}
          <span *ngIf="formGroup.controls['toUtc'].hasError('required')" class="required-text">
            {{'message.required' | translate}}
          </span>
        </label>
        <input type="date" id="toUtc" name="toUtc" formControlName="toUtc" class="form-control" />
      </div>
      <button type="button" class="btn search" (click)="searchForTours()">
        <fa-icon class="fa-lg" [icon]="faMagnifyingGlass"></fa-icon>
      </button>
    </div>
    <hr class="my-4" />
  </form>
  <app-loading *ngIf="loading"></app-loading>
</div>

<div ngbAccordion #accordion="ngbAccordion" id="nestedAccordion" [closeOthers]="true" (click)="toggleBorderColor()">
  @for(tour of tours; track $index) {
    <div ngbAccordionItem="{{$index}}" class="mb-2 mx-2">
      <h2 ngbAccordionHeader>
        <button ngbAccordionButton class="row py-2 px-1">
          <div class="col-5 ps-1">
            <p class="tour-name mb-0 p-1 d-inline-block" (click)="openTourDetail(tour.id); $event.stopPropagation()">{{tour.description}}</p>
          </div>
          <div class="col-3">
            <p class="mb-0">• {{formatDateTime(tour.actualStartDate)}}</p>
          </div>
          <div class="col-3">
            <p class="mb-0">• {{formatDateTime(tour.actualEndDate)}}</p>
          </div>
        </button>
      </h2>
      <div ngbAccordionCollapse>
        <div ngbAccordionBody>
          <ng-template>
            <hr class="mx-2 mt-0" />
            <form class="form-group">
              <div class="row row-cols-1 row-cols-md-2 mb-2">
                <div class="col" *ngIf="resourceType != 'Customer'">
                  <label for="customer">{{'toursBook.labels.customer' | translate}}</label>
                  <input type="text" id="customer" name="customer" class="form-control" readonly [value]="tour.customer != null ? tour.customer.name : ''" />
                </div>
                <div class="col" *ngIf="resourceType != 'FrontVehicle'">
                  <label for="frontVehicle">{{'toursBook.labels.frontVehicle' | translate}}</label>
                  <input type="text" id="frontVehicle" name="frontVehicle" class="form-control" readonly [value]="tour.frontVehicle != null ? tour.frontVehicle.unitIdentification + ' ' + tour.frontVehicle.registrationNumber : ''" />
                </div>
                <div class="col" *ngIf="resourceType != 'BackVehicle'">
                  <label for="backVehicle">{{'toursBook.labels.backVehicle' | translate}}</label>
                  <input type="text" id="backVehicle" name="backVehicle" class="form-control" readonly [value]="tour.backVehicles.length > 0 ? backVehicleString(tour.backVehicles) : ''" />
                </div>
                <div class="col" *ngIf="resourceType != 'TransportEquipment'">
                  <label for="transportEquipment">{{'toursBook.labels.transportEquipment' | translate}}</label>
                  <input type="text" id="transportEquipment" name="transportEquipment" class="form-control" readonly [value]="tour.transportEquipment != null ? tour.transportEquipment.manufacturer + ' ' + tour.transportEquipment.type + ' ' + tour.transportEquipment.unitIdentification : ''"/>
                </div>
                <div class="col" *ngIf="resourceType != 'User'">
                  <label for="user">{{'toursBook.labels.user' | translate}}</label>
                  <input type="text" id="user" name="user" class="form-control" readonly [value]="tour.assignedUser != null ? tour.assignedUser.firstname + ' ' + tour.assignedUser.lastname : ''" />
                </div>
              </div>
            </form>
          </ng-template>
        </div>
      </div>
    </div>
  }
</div>
