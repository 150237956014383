<div *ngIf="error" class="col-md-12 center">
  <div class="col-md-12 text-center">
  <fa-icon style="font-size: 100px" class="text-danger" [icon]="faError"></fa-icon>
  </div>
  <div style="width: 100%">
    <h5 style="margin-bottom: 15px">{{'error.error' | translate}}</h5>
    <h6>{{'error.title' | translate}}</h6>
<p>{{error?.error}}</p>
    <h6>{{'error.message' | translate}}</h6>
    <p>{{error?.message.split('(')[0]}}</p>
  </div>
  <div>
    <button class="btn btn-outline-secondary" (click)="sendError()">{{'error.informSupport' | translate}}</button>
    <p class="none-text">{{'error.supportMailMessageInfo' | translate}}</p>
  </div>
  <button class="btn btn-secondary" (click)="goBack()">{{'error.takeMeBack' | translate}}</button>
</div>
