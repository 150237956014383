import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { StartComponent } from './homepage/start/start.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from "@angular/common/http";
import { Interceptor } from "./interceptors/interceptor.service";
import { RouterModule } from "@angular/router";
import { SharedModule } from './shared/shared.module';
import { CommonModule } from "@angular/common";
import { ToastrModule } from "ngx-toastr";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { ErrorComponent } from './error/error.component';
//import { Guard } from "./interceptors/guard.service";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { HomepageComponent } from './homepage/homepage/homepage.component';
import { IMqttServiceOptions, MqttModule } from 'ngx-mqtt';
import { environment as env } from '../environments/environment';
import { NgxGaugeModule } from 'ngx-gauge';
import { ScrollZoomCtlDirective } from './scroll-zoom-ctl.directive';
import { GlecViewComponent } from './glec-view-m/glec-view/glec-view.component';
import { MssComponent } from './mss/mss/mss.component';
import { UsersModule } from './users/users.module';
import { FrontVehiclesModule } from './front-vehicles/front-vehicles.module';
import { QRCodeModule } from 'angularx-qrcode';
import { AuthModule, LogLevel } from 'angular-auth-oidc-client';
import { AuthorizationGuard } from './auth/AuthorizationGuard';
import { SimpleLocalizeTranslateLoader } from './loaders/SimpleLocalizeTranslateLoader';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { LOAD_WASM, NgxScannerQrcodeModule } from 'ngx-scanner-qrcode';


LOAD_WASM().subscribe()

export function createSimpleLocalizeTranslateLoader(http: HttpClient) {
  return new SimpleLocalizeTranslateLoader(http);
}

export const mqttServiceOptions: IMqttServiceOptions =
{
  hostname: env.mqtt.server,
  port: env.mqtt.port,
  path: env.mqtt.path,
  username: env.mqtt.username,
  password: env.mqtt.password,
  protocol: (env.mqtt.protocol === 'wss') ? 'wss' : 'ws',
  clean: true,
  connectOnCreate: true,
  resubscribe: true,
  keepalive: 60,
  connectTimeout: 30 * 1000
};

@NgModule({
  declarations: [
    AppComponent,
    StartComponent,
    ErrorComponent,
    HomepageComponent,
    ScrollZoomCtlDirective,
    GlecViewComponent,
    MssComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    FontAwesomeModule,
    HttpClientModule,
    RouterModule,
    SharedModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    FormsModule,
    UsersModule,
    FrontVehiclesModule,
    ReactiveFormsModule,
    NgxGaugeModule,
    QRCodeModule,
    NgMultiSelectDropDownModule.forRoot(),
    NgxScannerQrcodeModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createSimpleLocalizeTranslateLoader,
        deps: [HttpClient]
      }
    }),
    MqttModule.forRoot(mqttServiceOptions),
    AuthModule.forRoot({
      config: {
        authority: env.idp.authority,
        redirectUrl: env.idp.redirectUrl,
        postLogoutRedirectUri: env.idp.postLogoutRedirectUri,
        clientId: env.idp.clientId,
        scope: env.idp.scope,
        responseType: env.idp.responseType,
        silentRenew: env.idp.silentRenew,
        useRefreshToken: env.idp.useRefreshToken,
        logLevel: LogLevel.Debug,
        triggerAuthorizationResultEvent: env.idp.triggerAuthorizationResultEvent,


        //postLoginRoute: 'auth',
        //unauthorizedRoute: 'auth/unauthorized',
      }
    })
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: Interceptor, multi: true }, AuthorizationGuard
  ],
  exports: [
    TranslateModule
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
