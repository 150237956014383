import { GroupingService } from './../../grouping/grouping.service';
import { Component, OnInit } from '@angular/core';
import { CustomerModel, TableCustomerModel } from "../../customers/shared/customer.model";
import { CustomerService } from "../../customers/shared/customer.service";
import { NotificationService } from "../../shared/services/notification.service";
import { TableUserModel, UserModel, UserTimeRegistrationModel, UpdateUserTimeRegistrationModel, SplitTimeRegModel } from "../shared/user.model";
import { UserService } from "../shared/user.service";
import { TourService } from 'src/app/tours/shared/tour.service';
import { SharedService } from "../../shared/services/shared.service";
import { CalenderView, JobType } from "../../constants";
import { DatePipe } from "@angular/common";
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import * as moment from 'moment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ITuple } from "../../shared/tuple";
import { LabelType, NgxSliderModule, Options } from '@angular-slider/ngx-slider';
import { lastValueFrom, Subscription, zip } from 'rxjs';
import { MainGroup, SubGroup } from 'src/app/grouping/grouping.models';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { EventProxyService, CustomProxyEvent, CreateGroup, DeleteGroup, UpdateGroup, SetTourDetailId, SetGroupId } from 'src/app/shared/services/event-proxy.service';


@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css'],
  providers: [DatePipe]
})
export class UsersComponent implements OnInit {
  private EventProxySubscription: Subscription | undefined

  users: UserModel[] | undefined;
  tableData: TableUserModel[] = [];
  showDetails: boolean = false;
  showTourDetails: boolean = false;
  selectedItemId: number = 0;
  selectedSubItemId: number = 0;
  addNewCustomer: boolean = false;
  createNewTour: { user?: UserModel, event: { startDate: Date, allDay: boolean } } | undefined;
  modalWindow: boolean = false;
  language: any;
  loading: boolean = false;
  groups: MainGroup[] = []
  tourId: number = 0;

  showAcceptBtn: boolean = true;

  editTimeRegistrationFormGroup: UntypedFormGroup;
  activitiesFormGroup: UntypedFormGroup;
  tour: any;

  selectedJobItemId: number = 0;
  showJobDetails: boolean = false;

  /* currentAssignment: any = ['test', 'test2', 'test3']; */
  availableAssignments: any = [];
  availableTimeregTypes: any = [];



  minValueSlide: number = 0;
  maxValueSlide: number = 100;
  dateRange: Date[] = [];


  currentTourModalTableColor: string = "#ffffff";
  newTourModalTableColor: string = "#709A66";

  currentTourModalTableColorLower: string = "#ffffff";
  newTourModalTableColorLower: string = "#ABC0A6";

  currentTourModalTableWidth: string = "90%";
  newTourModalTableWidth: string = "10%";

  tourModalTdTitle1: string = "";
  tourModalTdTitle2: string = "";

  sliderTableBackground: string = "";
  sliderTableBackgroundGradiant: string = "";

  selectedCurrentTour: any;
  selectedAvailTour: any;

  selectedCurrentTourTypeId: any;
  selectedAvailTourTypeId: any;

  selectedChassisNumber: any;

  splitTimeStart: any;
  splitTimeEnd: any;

  selectedTimeReg: any;
  selectedUserId: any;

  //updateTimeReg: UpdateUserTimeRegistrationModel;

  optionsSlide: Options = {
    floor: 0,
    ceil: 23 + 59 / 60,
    step: 1 / 60,
    noSwitching: true,
    animate: false,
  };

  showAGTempfix: boolean = true;

  constructor(
    private userService: UserService,
    private tourService: TourService,
    private notifyService: NotificationService,
    private sharedService: SharedService,
    private datePipe: DatePipe,
    private formBuilder: UntypedFormBuilder,
    private modalService: NgbModal,
    private groupingService: GroupingService,
    private translate: TranslateService,
    private toaster: ToastrService,
    private eventProxyService: EventProxyService
  ) {
    this.editTimeRegistrationFormGroup = this.formBuilder.group({
      datestart: [],
      timestart: [],
      dateend: [],
      timeend: [],
      currentTour: ['', Validators.required],
      availTour: ['', Validators.required],
      currentTourTypeId: ['', Validators.required],
      availTourTypeId: ['', Validators.required],
      note: ['', Validators.required],
      id: [],
      userid: [],
      isRunning: []
    }, { validator: this.validateDates })

    this.EventProxySubscription = this.eventProxyService.getEvent().subscribe((param: CustomProxyEvent) => {
      if (param instanceof CreateGroup || param instanceof UpdateGroup || param instanceof DeleteGroup) {
        this.getGroupsList()
      }

      if (param instanceof SetTourDetailId) {
        if (param.data && param.data > 0) {
          this.showTourItemDetails(param.data)
          this.tourId = param.data
        }
      }

      if (param instanceof SetGroupId) {
        if (param.data && param.data <= 0) {
          sessionStorage.removeItem('groupId')
        }

        return this.getAll()
      }
    })

    this.activitiesFormGroup = this.formBuilder.group({
      user: [],
      datestart: [],
      dateend: []
    }, { validator: this.validatecontentDates })
  }

  async getLanguage() {
    this.sharedService.getLanguageJson().subscribe(response => this.language = response)
  }

  async ngOnInit(): Promise<void> {
    await this.getLanguage()

    // Temp list of timeRegTypes:
    //setTimeout to make translations work.
    setTimeout(() => {
      this.availableTimeregTypes.push({ id: "1", name: this.translate.instant('toursAndUsers.timeRegTypes.task') });
      this.availableTimeregTypes.push({ id: "10", name: this.translate.instant('toursAndUsers.timeRegTypes.washNoTask') });
      this.availableTimeregTypes.push({ id: "11", name: this.translate.instant('toursAndUsers.timeRegTypes.washWithTask') });
      this.availableTimeregTypes.push({ id: "12", name: this.translate.instant('toursAndUsers.timeRegTypes.breakdown') });
      this.availableTimeregTypes.push({ id: "13", name: this.translate.instant('toursAndUsers.timeRegTypes.workshop') });
      this.availableTimeregTypes.push({ id: "4", name: this.translate.instant('toursAndUsers.timeRegTypes.breakRest') });
      this.availableTimeregTypes.push({ id: "14", name: this.translate.instant('toursAndUsers.timeRegTypes.breakPrivate') });
      this.availableTimeregTypes.push({ id: "17", name: this.translate.instant('toursAndUsers.timeRegTypes.timeOffPrivate') });
      this.availableTimeregTypes.push({ id: "16", name: this.translate.instant('toursAndUsers.timeRegTypes.waitingTime') });
      this.availableTimeregTypes.push({ id: "7", name: this.translate.instant('toursAndUsers.timeRegTypes.24HourRest') });
      this.availableTimeregTypes.push({ id: "8", name: this.translate.instant('toursAndUsers.timeRegTypes.weekRest') });
    }, 1)

    this.getAll()
  }


  createDateRange(startTime: Date, endTime: Date): Date[] {
    const dates: Date[] = [];
    let latestDate: Date = startTime;

    dates.push(startTime);

    while (latestDate < endTime) {
      dates.push(latestDate);
      latestDate = new Date(latestDate.getTime() + 1 * 60000);
    }

    dates.push(endTime);

    return dates;

  }

  onTemporaryAgToggle(toggle: boolean) {
    this.showAGTempfix = toggle;
    this.getAll();
  }


  getAll(): void {
    this.userService.getAll().subscribe(response => {
      this.users = response;
      this.tableData = this.users.map(x => (
        {
          id: x.id,
          name: x.firstname + ' ' + x.lastname,
          email: x.email,
          mobile: x.mobile,
          active: x.isActive,
          status: x.status,
          nested: x.tours !== null ? x.tours.map(t => ({
            tourDescription: t.description,
            expectedStart: this.datePipe.transform(t?.expectedStartDate, 'yyyy-MM-dd HH:mm')?.toString(),
            actualStart: this.datePipe.transform(t?.actualStartDate, 'yyyy-MM-dd HH:mm')?.toString(),
            expectedEnd: this.datePipe.transform(t?.expectedEndDate, 'yyyy-MM-dd HH:mm')?.toString(),
            actualEnd: this.datePipe.transform(t?.actualEndDate, 'yyyy-MM-dd HH:mm')?.toString(),
            status: t.status
          })).concat(x.events.map(t => ({
            tourDescription: t.title,
            expectedStart: this.datePipe.transform(t?.startDate, 'yyyy-MM-dd HH:mm')?.toString(),
            actualStart: ''.toString(),
            expectedEnd: this.datePipe.transform(t?.endDate, 'yyyy-MM-dd HH:mm')?.toString(),
            actualEnd: ''.toString(),
            status: t.typeKey.toString()
          })))
            .concat(x.jobs.map(t => ({
              tourDescription: t.type === JobType.Wash ? this.language.job.type.wash : this.language.job.type.personalService,
              expectedStart: this.datePipe.transform(t?.expectedStartDate, 'yyyy-MM-dd HH:mm')?.toString(),
              actualStart: this.datePipe.transform(t?.actualStartDate, 'yyyy-MM-dd HH:mm')?.toString(),
              expectedEnd: this.datePipe.transform(t?.expectedEndDate, 'yyyy-MM-dd HH:mm')?.toString(),
              actualEnd: this.datePipe.transform(t?.actualEndDate, 'yyyy-MM-dd HH:mm')?.toString(),
              status: t.status.toString()
            }))) : []
        }));

      //this.minValueSlide = this.users.tours.actualStart;
    });
  }



  getGroups() {
    this.groupingService.getGroupsList().subscribe((response) => {
      this.groups = response
      this.getAll()
    })
  }


  getGroupsList() {
    this.groupingService.getGroupsList().subscribe((response) => {
      this.groups = response
    })
  }

  saveMainGroup(_event: any) {
    try {
      this.groupingService.saveMainGroup({ name: _event.event.data.name, description: _event.event.data.description }).subscribe(response => {
        const event = { option: response }
        this.addSelectedToGroup({ event, selectedItems: _event.selected }, 'Users')
        this.eventProxyService.setEvent(new CreateGroup(null))
      })
    } catch (error: any) {
      console.error('Error: ', error)
      this.toaster.error(this.translate.instant('grouping.misc.groupNotCreated'))
    }
  }


  showJobItemDetails(event: any): void {
    this.selectedJobItemId = event.id;
    this.showJobDetails = true;
  }


  closeJobItemDetails(): void {
    this.selectedJobItemId = 0;
    this.showJobDetails = true;
  }

  showTourItemDetails(event: any): void {
    this.showTourDetails = true;
  }

  closeTourItemDetails(): void {
    this.showTourDetails = false;
  }

  showItemDetails(event: any): void {
    this.selectedItemId = event.id;
    this.showDetails = true;
  }


  closeItemDetails(): void {
    this.selectedItemId = 0;
    this.showDetails = false;
  }


  today(): string {
    const today = new Date();
    return <string>this.datePipe.transform(today, 'yyyy-MM-dd');
  }


  getFormattedTime(time: number): string {
    return ('0' + time).slice(-2);
  }


  showEditTimeRegistrationModal(content: any, e: any) {
    this.availableAssignments = [];

    if (e.event._def.publicId > 0) {
      if (e.event.extendedProps.userid) {

        this.selectedUserId = e.event.extendedProps.userid;

        this.userService.getUserTimeRegistrations(e.event.extendedProps.userid).subscribe((response) => {
          let result = response.find((t: any) => t.id == parseInt(e.event._def.publicId));
          if (result) {
            if (result.endTime == null) {
              this.modalService.dismissAll();
              this.notifyService.warningMessage(this.language.tour.splitNoEnd);
            }

            this.selectedTimeReg = parseInt(e.event._def.publicId);

            this.tourService.getSingle(e.event.extendedProps.tourId).subscribe((responseTour) => {


              this.tourModalTdTitle1 = responseTour.description;
              this.tourModalTdTitle2 = responseTour.description;

              this.availableAssignments.push({ id: responseTour.id, name: responseTour.description });

              this.selectedAvailTour = this.availableAssignments[0].id;
              this.selectedCurrentTour = this.availableAssignments[0].id;


              let startTimeDate = new Date(result.startTime);
              let endTimeDate = new Date(result.endTime);

              this.splitTimeStart = result.startTime;
              this.splitTimeEnd = result.endTime;

              this.selectedAvailTourTypeId = null;
              this.selectedCurrentTourTypeId = null;


              let startTimeDateBegining = new Date(result.startTime);
              let endTimeDateBegining = new Date(result.endTime);

              startTimeDateBegining.setHours(0, 0, 0, 0);
              endTimeDateBegining.setHours(23, 59, 59, 999);

              let dateStart = this.datePipe.transform(startTimeDateBegining, 'yyyy-MM-dd');
              let dateEnd = this.datePipe.transform(endTimeDateBegining, 'yyyy-MM-dd');

              this.userService.getUserWithToursExt(e.event.extendedProps.userid, dateStart, dateEnd).subscribe((responseTour) => {
                responseTour.tours.forEach((x: any) => {
                  if (x.id != this.selectedCurrentTour)
                    this.availableAssignments.push({ id: x.id, name: x.description });
                });
                //setTimeout to make translations work.
                setTimeout(() => {
                  this.availableAssignments.push({ id: 0, name: this.translate.instant('toursAndUsers.noTask') });
                }, 1);
              });


              this.minValueSlide = startTimeDate.getTime();

              this.dateRange = this.createDateRange(new Date(result.startTime), new Date(result.endTime));

              this.currentTourModalTableColor = e.event.backgroundColor;

              this.optionsSlide = {
                noSwitching: true,

                stepsArray: this.dateRange.map((date: Date) => {
                  return { value: date.getTime() };
                }),
                translate: (value: number, label: LabelType): string => {
                  let percentvalue: number = ((value - startTimeDate.getTime()) / (endTimeDate.getTime() - startTimeDate.getTime())) * 100;
                  percentvalue = +percentvalue.toFixed();

                  let reversePercentValue = 100 - percentvalue;

                  this.currentTourModalTableWidth = percentvalue + "%";
                  this.newTourModalTableWidth = reversePercentValue + "%";

                  this.sliderTableBackground = this.currentTourModalTableColorLower;
                  this.sliderTableBackgroundGradiant = "linear-gradient(90deg, " + this.currentTourModalTableColorLower + " " + this.currentTourModalTableWidth + ", " + this.newTourModalTableColorLower + " " + this.newTourModalTableWidth + ")";
                  let resultString = new Date(value).toLocaleString();

                  if ((startTimeDate.getFullYear() == endTimeDate.getFullYear()) && (startTimeDate.getMonth() == endTimeDate.getMonth()) && (startTimeDate.getDay() == endTimeDate.getDay())) {

                    let hoursString = ("0" + new Date(value).getHours()).slice(-2);
                    let minutesString = ("0" + new Date(value).getMinutes()).slice(-2);


                    resultString = hoursString + ":" + minutesString;
                  }

                  return resultString;
                },
                animate: false,
              };

              let indexMiddle: number = this.dateRange.length / 2;
              let indexMiddleFixed = +indexMiddle.toFixed();

              this.minValueSlide = this.dateRange[indexMiddleFixed].getTime();


              this.editTimeRegistrationFormGroup.patchValue({
                datestart: this.datePipe.transform(result.startTime, 'yyyy-MM-dd'),
                dateend: this.datePipe.transform(result.endTime, 'yyyy-MM-dd'),
                timestart: `${this.getFormattedTime(new Date(result.startTime).getHours())}:${this.getFormattedTime(new Date(result.startTime).getMinutes())}`,
                timeend: `${this.getFormattedTime(new Date(result.endTime).getHours())}:${this.getFormattedTime(new Date(result.endTime).getMinutes())}`
              });



            });
          }

        })
      }
    }

    if (this.selectedItemId === 0 && e.el.className.includes('timeregistration')) {
      const modalref = this.modalService.open(content);

      modalref.result.then((result) => {
        var start = moment(this.editTimeRegistrationFormGroup.value.datestart).add(this.editTimeRegistrationFormGroup.value.timestart);
        var end = moment(this.editTimeRegistrationFormGroup.value.dateend).add(this.editTimeRegistrationFormGroup.value.timeend);

        lastValueFrom<any>(this.userService.updateUserTimeRegistration(e.event.extendedProps.userid, e.event._def.publicId, { id: e.event._def.publicId, startTime: start.toDate(), endTime: end.toDate() })).then(_ => {
          this.getAll();
        })
        /* this.userService.updateUserTimeRegistration(e.event.extendedProps.userid, e.event._def.publicId,  { id: e.event._def.publicId, startTime: start.toDate(), endTime: end.toDate()} ).toPromise()
          .then(_ => {
            this.getAll();
          }) */
      }, () => {

      });
    }
  }


  splitTimeReg() {
    this.showAcceptBtn = false;
    this.loading = true;

    if (this.selectedCurrentTour != null && this.selectedCurrentTourTypeId != null && this.selectedAvailTour != null && this.selectedAvailTourTypeId != null && this.editTimeRegistrationFormGroup.controls['note'].value.length > 0) {
      var dateisoStartTime = new Date(this.splitTimeStart).toISOString();
      var dateisoSplitTime = new Date(this.minValueSlide).toISOString();
      var dateisoEndTime = new Date(this.splitTimeEnd).toISOString();

      if (dateisoStartTime == dateisoSplitTime) {
        this.minValueSlide = this.minValueSlide + 1000;
        dateisoSplitTime = new Date(this.minValueSlide).toISOString();
      }

      if (dateisoEndTime == dateisoSplitTime) {
        this.minValueSlide = this.minValueSlide - 1000;
        dateisoSplitTime = new Date(this.minValueSlide).toISOString();
      }

      const splitModel: SplitTimeRegModel = {
        StartTime: dateisoStartTime,
        EndTime: dateisoEndTime,
        SplitTime: dateisoSplitTime,
        FirstTourId: this.selectedCurrentTour,
        LastTourId: this.selectedAvailTour,
        FirstTimeRegistrationType_Id: this.selectedCurrentTourTypeId,
        LastTimeRegistrationType_Id: this.selectedAvailTourTypeId,
        Comment: this.editTimeRegistrationFormGroup.controls['note'].value
      }


      lastValueFrom<any>(this.userService.splitTimeReg(this.selectedUserId, this.selectedTimeReg, splitModel)).then(_ => {
        this.getAll();
        this.modalService.dismissAll();
        this.showAcceptBtn = true;
        this.loading = false;
      });
      // this.userService.splitTimeReg(this.selectedUserId, this.selectedTimeReg, splitModel).toPromise()
      //       .then(_ => {
      //         this.getAll();
      //      this.modalService.dismissAll();
      //         this.showAcceptBtn = true;
      //          this.loading = false;
      //       });
    } else {
      // Show error
    }
  }


  padTo2Digits(num: number) {
    return num.toString().padStart(2, '0');
  }


  formatDate(date: Date) {
    return (
      [
        date.getFullYear(),
        this.padTo2Digits(date.getMonth() + 1),
        this.padTo2Digits(date.getDate()),
      ].join('-') +
      'T' +
      [
        this.padTo2Digits(date.getHours()),
        this.padTo2Digits(date.getMinutes()),
        this.padTo2Digits(date.getSeconds()),
      ].join(':')
    );
  }


  onCurrentTourChange(value: any) {
    if (this.selectedCurrentTour != "0" && (this.selectedCurrentTourTypeId == "16" || this.selectedCurrentTourTypeId == "17" || this.selectedCurrentTourTypeId == "10")) this.selectedCurrentTourTypeId = 1;

    this.tourModalTdTitle1 = this.availableAssignments.find((y: any) => y.id == this.selectedCurrentTour).name;
    //currentTourModalTableColor

    switch (value.target.value) {
      case 'finished':
        this.currentTourModalTableColor = "#377A94";
        this.currentTourModalTableColorLower = "#70A7BD";
        break;
      case 'onHold':
        this.currentTourModalTableColor = "#FF9966";
        this.currentTourModalTableColorLower = "#F2BFA6";
        break;
      case 'startLater':
        this.currentTourModalTableColor = "#709A66";
        this.currentTourModalTableColorLower = "#ABC0A6";
        break;
      case 'startEarlier':
        this.currentTourModalTableColor = "#377A94";
        this.currentTourModalTableColorLower = "#70A7BD";
        break;
    }
  }


  onCurrentTourTypeIdChange(value: any) {
    if (value.target.value == "10" || value.target.value == "16" || value.target.value == "17") this.selectedCurrentTour = 0;

    this.tourModalTdTitle1 = this.availableAssignments.find((y: any) => y.id == this.selectedCurrentTour).name;
  }


  onNewTourChange(value: any) {
    this.tourModalTdTitle2 = this.availableAssignments.find((y: any) => y.id == this.selectedAvailTour).name;

    if (this.selectedAvailTour != "0" && (this.selectedAvailTourTypeId == "16" || this.selectedAvailTourTypeId == "17" || this.selectedAvailTourTypeId == "10")) this.selectedAvailTourTypeId = 1;
    //currentTourModalTableColor

    switch (value.target.value) {
      case 'finished':
        this.newTourModalTableColor = "#377A94";
        break;
      case 'onHold':
        this.newTourModalTableColor = "#FF9966";
        break;
      case 'startLater':
        this.newTourModalTableColor = "#709A66";
        break;
      case 'startEarlier':
        this.newTourModalTableColor = "#377A94";
        break;
    }
  }


  onNewTourTypeIdChange(value: any) {
    if (value.target.value == "10" || value.target.value == "16" || value.target.value == "17") this.selectedAvailTour = 0;

    this.tourModalTdTitle2 = this.availableAssignments.find((y: any) => y.id == this.selectedAvailTour).name;
  }


  showActivitiesModal(content: any) {
    const modalref = content

    var start = content.startDate;
    var end = content.dateEnd;
    let stringToConvert = ""

    for (let i = 2; i < content.user.length; i++) {
      stringToConvert += content.user[i];
    }
    let userId: number = +stringToConvert | 0;

    this.userService.getAllUsersWithActivities(userId, start, end).subscribe((response) => {
      this.users = response;
      this.tableData = this.users.map(x => (
        {
          id: x.id,
          name: x.firstname + ' ' + x.lastname,
          email: x.email,
          mobile: x.mobile,
          status: x.status,
          nested: x.tours.map(t => ({
            tourDescription: t.description,
            expectedStart: this.datePipe.transform(t?.expectedStartDate, 'yyyy-MM-dd HH:mm')?.toString(),
            actualStart: this.datePipe.transform(t?.actualStartDate, 'yyyy-MM-dd HH:mm')?.toString(),
            expectedEnd: this.datePipe.transform(t?.expectedEndDate, 'yyyy-MM-dd HH:mm')?.toString(),
            actualEnd: this.datePipe.transform(t?.actualEndDate, 'yyyy-MM-dd HH:mm')?.toString(),
            status: t.status
          })).concat(x.events.map(t => ({
            tourDescription: t.title,
            expectedStart: this.datePipe.transform(t?.startDate, 'yyyy-MM-dd HH:mm')?.toString(),
            actualStart: ''.toString(),
            expectedEnd: this.datePipe.transform(t?.endDate, 'yyyy-MM-dd HH:mm')?.toString(),
            actualEnd: ''.toString(),
            status: t.type.toString()
          })))
            .concat(x.jobs.map(t => ({
              tourDescription: t.type === JobType.Wash ? this.language.job.type.wash : this.language.job.type.personalService,
              expectedStart: this.datePipe.transform(t?.expectedStartDate, 'yyyy-MM-dd HH:mm')?.toString(),
              actualStart: this.datePipe.transform(t?.actualStartDate, 'yyyy-MM-dd HH:mm')?.toString(),
              expectedEnd: this.datePipe.transform(t?.expectedEndDate, 'yyyy-MM-dd HH:mm')?.toString(),
              actualEnd: this.datePipe.transform(t?.actualEndDate, 'yyyy-MM-dd HH:mm')?.toString(),
              status: t.status.toString()
            })))
        }));
      let counter = 20;
    })
  }


  validateDates(group: UntypedFormGroup) {
    const datestart = group.controls['datestart'].value
    const dateend = group.controls['dateend'].value
    const timestart = group.controls['timestart'].value;
    const timeend = group.controls['timeend'].value;

    return {
      mustBeLater: false
    };
  }


  validatecontentDates(group: UntypedFormGroup) {
    const datestart = group.controls['datestart'].value
    const dateend = group.controls['dateend'].value

    return {
      mustBeLater: false
    };
  }


  public get calenderView(): typeof CalenderView {
    return CalenderView;
  }


  createTour(e: { resourceId: string, event: { startDate: Date, allDay: boolean } }): void {
    this.createNewTour = {
      event: e.event,
      user: this.users?.find(x => x.id === Number.parseInt(e.resourceId))
    }
  }


  requestedRefreshedData(event: any) {
    if (event) {
      this.modalWindow = true;
      this.showActivitiesModal(event)
    } else {
      this.modalWindow = false;
      this.getAll();
    }
  }


  addSelectedToGroup(_event: any, str: string) {
    const { event, selectedItems } = _event

    let subgroup: SubGroup = event.option.subGroups.find((x: any) => x.resourceType === str) // select the subgroup in question
    const subgroupId = subgroup && subgroup.id ? subgroup.id : -1 // get the id or set to -1 if not found
    const itemIds: number[] = selectedItems.map((x: any) => x.id) // get the checked items from the table

    if (subgroupId < 0 && itemIds.length > 0) {
      this.privateSaveGroup(event, itemIds)
    } else {
      // Of the chosen items, remove the ones that already exist on the subgroup
      const adjustedMemberIds = itemIds.filter(id => !subgroup?.members?.some(member => member.id === id))

      if (adjustedMemberIds.length > 0) {
        this.privateSaveGroupMembers(subgroupId, itemIds, subgroup)
      }
    }
  }

  private privateSaveGroup(event: any, itemIds: number[]) {
    try {
      this.groupingService.saveSubGroup({
        masterGroupId: event.option.id,
        resourceType: "Users", // set the correct resourceType
        name: this.translate.instant('grouping.subgroups.users')
      }).subscribe((response) => {
        this.groupingService.saveSubGroupMembers({ id: response.id, memberIds: itemIds, resourceType: response.resourceType }).subscribe((res) => {
          this.toaster.success(this.translate.instant('grouping.misc.subgroupMembersAdded'))
        })
      })
    } catch (error: any) {
      console.error('Error: ', error)
      this.toaster.error(this.translate.instant('grouping.misc.subgroupMembersNotSaved'))
    }
  }

  private privateSaveGroupMembers(subgroupId: number, itemIds: number[], subgroup: SubGroup) {
    try {
      this.groupingService.saveSubGroupMembers({
        id: subgroupId,
        memberIds: itemIds,
        resourceType: subgroup.resourceType
      }).subscribe((res) => {
        this.toaster.success(this.translate.instant('grouping.misc.subgroupMembersAdded'))
      })
    } catch (error: any) {
      console.error('Error: ', error)
      this.toaster.error(this.translate.instant('grouping.misc.subgroupMembersNotSaved'))
    }
  }

  ngOnDestroy() {
    if (this.EventProxySubscription) {
      this.EventProxySubscription.unsubscribe();
    }
  }
}

