import { Component, OnInit } from '@angular/core';
import { WidgetComponent } from "../../shared/widget-component";
import { CreateUserMetaModel, UpdateUserMetaModel, UserEventModel, CreateUserEventModel, UserModel, UpdateUserEventModel } from "../shared/user.model";
import { UserService } from "../shared/user.service";
import { faPaperPlane, faPencilAlt, faPlus } from "@fortawesome/free-solid-svg-icons";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { DatePipe } from "@angular/common";
import { BackVehicleType, CreateMode, PermissionType } from "../../constants";
import { AddressModel } from "../../shared/address-widget/shared/address.model";
import { Router } from "@angular/router";
import { FrontVehicleModel } from "../../front-vehicles/shared/front-vehicle.model";
import { BackVehicleModel } from "../../back-vehicles/shared/back-vehicle.model";
import { TransportEquipmentModel } from "../../transport-equipments/shared/transport-equipment.model";
import { SharedService } from "../../shared/services/shared.service";
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-user-detail',
  templateUrl: './user-detail.component.html',
  styleUrls: ['./user-detail.component.css'],
  providers: [DatePipe]
})
export class UserDetailComponent extends WidgetComponent {
  get createModes() {
    return CreateMode;
  }


  get permissionTypes(): Array<string> {
    var keys = Object.keys(PermissionType);
    return keys.slice(keys.length / 2);
  }

  users: UserModel[] = [];

  readOnly = true;
  user: UserModel | undefined;
  address: AddressModel | undefined;

  name: any;
  mobile: any;
  email: any;

  loading = false;
  frontVehicleSelect = false;
  transportEquipmentSelect: boolean = false;
  backVehicleSelect: boolean = false;
  faAdd = faPlus;
  faMail = faPaperPlane;
  faEdit = faPencilAlt;
  frontVehicle: FrontVehicleModel | undefined;
  backVehicles: BackVehicleModel[] | undefined;
  transportEquipment: TransportEquipmentModel | undefined;

  overrideSkilled = false;
  onlyPull = false;

  metaFormGroup: UntypedFormGroup;

  addEvent = false;

  private dayValidators = [
    Validators.required,
    Validators.pattern(/^(?:\d{1,3}(?:,\d{3})*|\d+)(?:\,\d+)?$/)
  ];

  constructor(private userService: UserService, public sharedService: SharedService, private formBuilder: UntypedFormBuilder,
    private datePipe: DatePipe, private router: Router, private toaster: ToastrService, private translate: TranslateService) {
    super();

    this.metaFormGroup = this.formBuilder.group({
      salaryNumber: ['', Validators.compose([Validators.required, Validators.maxLength(20)])],
      salaryNumberHomeCountry: ['', Validators.maxLength(20)],
      title: ['', Validators.maxLength(50)],
      driversLicenseNumber: ['', Validators.pattern('[a-zA-Z]{2}[0-9]{14}')],
      driversLicenseNumberExpires: undefined,
      startDate: ['', Validators.required],
      skilledDate: undefined,
      frontVehicleId: undefined,
      transportEquipmentId: undefined,
      backVehicleIds: [],
      country: ['', Validators.compose([Validators.required, Validators.maxLength(255)])],
      state: ['', Validators.maxLength(255)],
      zipcode: ['', Validators.compose([Validators.required, Validators.maxLength(10)])],
      city: ['', Validators.compose([Validators.required, Validators.maxLength(255)])],
      street: ['', Validators.compose([Validators.required, Validators.maxLength(255)])],
      permission: [{ value: 'Read', disabled: true }, Validators.required],
      resignationDate: undefined,
      inlandAgreement: this.formBuilder.group({
        hours: this.formBuilder.group({
          mo: [undefined, this.dayValidators],
          tu: [undefined, this.dayValidators],
          we: [undefined, this.dayValidators],
          th: [undefined, this.dayValidators],
          fr: [undefined, this.dayValidators],
          sa: [undefined, this.dayValidators],
          su: [undefined, this.dayValidators],
        }),
        inlandAgreementType: [undefined]
      }),
      exportAgreementType: undefined,
      overrideSkilled: { value: undefined, disabled: true },
      onlyPull: { value: undefined, disabled: true }
    });
  }


  ngOnInit() {
    this.refresh();
  }

  drawCalendar(): void {
    window.dispatchEvent(new Event('resize'));
  }

  refresh(): void {
    this.loading = true;

    if (this.id != 0) {
      this.userService.getSingleWithMeta(this.id).subscribe(response => {
        this.user = response;
        this.name = `${this.user.firstname} ${this.user.lastname}`;
        this.mobile = this.user.mobile;
        this.email = this.user.email;
        this.patchMetaForm();
        this.loading = false;
      });
    } else {
      this.userService.getAll().subscribe(response => {
        this.users = response;

        this.users = this.users.sort((a, b) => {
          if (a.firstname < b.firstname) {
            return -1;
          }
          if (a.firstname > b.firstname) {
            return 1;
          }
          return 0;
        });

        this.loading = false;
      });
    }
  }


  patchMetaForm(): void {
    if (!this.user?.userMeta) {
      return;
    }

    this.frontVehicle = this.user.userMeta.frontVehicle;
    this.backVehicles = this.user.userMeta.backVehicles;
    this.transportEquipment = this.user.userMeta.transportEquipment;

    const model = this.user.userMeta;

    this.metaFormGroup.patchValue({
      salaryNumber: model.salaryNumber,
      salaryNumberHomeCountry: model.salaryNumberHomeCountry,
      title: model.title,
      startDate: this.datePipe.transform(model.startDate, 'yyyy-MM-dd'),
      skilledDate: this.datePipe.transform(model.skilledDate, 'yyyy-MM-dd'),
      country: model.country,
      state: model.state,
      zipcode: model.zipcode,
      city: model.city,
      street: model.street,
      frontVehicleId: model?.frontVehicle?.id,
      transportEquipmentId: model?.transportEquipment?.id,
      backVehicleIds: model.backVehicles.map(x => x.id),
      permission: this.user.permission,
      resignationDate: this.datePipe.transform(model.resignationDate, 'yyyy-MM-dd'),
      inlandAgreement: {
        hours: {
          mo: model.hoursAgreement?.split(';')[0],
          tu: model.hoursAgreement?.split(';')[1],
          we: model.hoursAgreement?.split(';')[2],
          th: model.hoursAgreement?.split(';')[3],
          fr: model.hoursAgreement?.split(';')[4],
          sa: model.hoursAgreement?.split(';')[5],
          su: model.hoursAgreement?.split(';')[6]
        },
        inlandAgreementType: model.inlandAgreement
      },
      exportAgreementType: model.exportAgreement,
      driversLicenseNumber: this.user.driversLicenseNumber,
      driversLicenseNumberExpires: this.datePipe.transform(this.user.driversLicenseNumberExpires, 'yyyy-MM-dd'),
      overrideSkilled: model.overrideSkilled,
      onlyPull: model.onlyPull
    });

    this.setAddress();

    if (model.inlandAgreement == 'CollectiveDefined') {
      this.setDaysValidators(true);
    }
    else {
      this.setDaysValidators(false);
    }
  }


  setAddress(): void {
    this.address = {
      id: 0,
      city: this.metaFormGroup.value.city,
      country: this.metaFormGroup.value.country,
      state: this.metaFormGroup.value.state,
      zipcode: this.metaFormGroup.value.zipcode,
      street: this.metaFormGroup.value.street,
      description: '',
      latitude: 0,
      longitude: 0,
      additionalInformation: '',
      priority: 0,
      hasArrived: false,
      arrivedDate: null,
      locationId: '',
      locationName: '',
      hasLeft: false,
      freight: '',
      freightAmount: 0,
      removedFreightAmount: 0,
      freightTypeId: 0,
      isDelivery: false,
      isPickup: false,
      arriveMileage: 0,
      leaveMileage: 0,
      orderId: '',
      arriveBackVehicle: '',
      leaveBackVehicle: '',
      arriveEquipment: '',
      leaveEquipment: '',
      type: '',
      customerId: 0,
      attachments: [],
      notes: []
    }
  }


  patchAddress(address: AddressModel): void {
    this.metaFormGroup.patchValue({
      country: address.country,
      state: address.state,
      zipcode: address.zipcode,
      city: address.city,
      street: address.street
    });
    this.setAddress();
  }


  sendMail(): void {
    window.location.href = "mailto:" + this.email;
  }


  update(): void {
    if(this.metaFormGroup.valid) {
      this.loading = true;
      this.readOnly = true;
      this.metaFormGroup.controls['permission'].enable();
      this.metaFormGroup.controls['overrideSkilled'].enable();
      this.metaFormGroup.controls['onlyPull'].enable();
      if (this.user?.userMeta) {
        this.userService.updateMeta(this.id, this.metaFormGroup.value as UpdateUserMetaModel).subscribe(() => this.refresh(), error => this.loading = false);
      } else {
        this.userService.createMeta(this.id, this.metaFormGroup.value as CreateUserMetaModel).subscribe(() => this.refresh(), error => this.loading = false);
      }
    }
    else {
      this.toaster.error(this.translate.instant('message.invalidForm'));
    }
  }


  goTo(route: string, params: any): void {
    this.router.navigate([route], { queryParams: { item: params } });
  }


  selectedFrontVehicleCallback(e: any): void {
    this.frontVehicleSelect = false;
    this.frontVehicle = e.frontVehicle;
    if (this.user?.userMeta)
      this.user.userMeta.frontVehicle = e.frontVehicle;
    this.metaFormGroup.patchValue({ frontVehicleId: e?.frontVehicle?.id });
  }


  selectedBackVehicleCallback(e: any): void {
    this.backVehicleSelect = false;
    this.backVehicles = e.backVehicles;
    if (this.user?.userMeta)
      this.user.userMeta.backVehicles = e.backVehicles;
    this.metaFormGroup.patchValue({ backVehicleIds: e?.backVehicles?.map((x: BackVehicleModel) => x.id) })
  }


  selectedTransportEquipmentCallback(e: any): void {
    this.transportEquipmentSelect = false;
    this.transportEquipment = e.transportEquipment;
    if (this.user?.userMeta)
      this.user.userMeta.transportEquipment = e.transportEquipment;
    this.metaFormGroup.patchValue({ transportEquipmentId: e?.transportEquipment?.id });
  }


  setDaysValidators(required?: boolean) {
    const hoursFormGroup = this.metaFormGroup.get('inlandAgreement')?.get('hours');
    if (required) {
      hoursFormGroup?.get('mo')?.setValidators(this.dayValidators);
      hoursFormGroup?.get('tu')?.setValidators(this.dayValidators);
      hoursFormGroup?.get('we')?.setValidators(this.dayValidators);
      hoursFormGroup?.get('th')?.setValidators(this.dayValidators);
      hoursFormGroup?.get('fr')?.setValidators(this.dayValidators);
      hoursFormGroup?.get('sa')?.setValidators(this.dayValidators);
      hoursFormGroup?.get('su')?.setValidators(this.dayValidators);
    } else {
      hoursFormGroup?.get('mo')?.removeValidators(Validators.required);
      hoursFormGroup?.get('tu')?.removeValidators(Validators.required);
      hoursFormGroup?.get('we')?.removeValidators(Validators.required);
      hoursFormGroup?.get('th')?.removeValidators(Validators.required);
      hoursFormGroup?.get('fr')?.removeValidators(Validators.required);
      hoursFormGroup?.get('sa')?.removeValidators(Validators.required);
      hoursFormGroup?.get('su')?.removeValidators(Validators.required);
    }
    hoursFormGroup?.get('mo')?.updateValueAndValidity();
    hoursFormGroup?.get('tu')?.updateValueAndValidity();
    hoursFormGroup?.get('we')?.updateValueAndValidity();
    hoursFormGroup?.get('th')?.updateValueAndValidity();
    hoursFormGroup?.get('fr')?.updateValueAndValidity();
    hoursFormGroup?.get('sa')?.updateValueAndValidity();
    hoursFormGroup?.get('su')?.updateValueAndValidity();
  }


  createEvent(model: any): void {
    this.loading = true;
    const m: CreateUserEventModel = {
      title: model.title,
      description: model.description,
      startDate: moment(this.datePipe.transform(`${model.startDate} ${model.startTime}`, 'yyyy-MM-dd HH:mm')).toDate(),
      endDate: moment(this.datePipe.transform(`${model.endDate} ${model.endTime}`, 'yyyy-MM-dd HH:mm')).toDate(),
      userEventTypeId: Number(model.type)
    }
    this.userService.createUserEvent(this.id, m).subscribe(() => this.refresh(), error => this.loading = false);
  }


  deleteEvent(model: any): void {
    this.loading = true;
    this.userService.deleteUserEvent(this.id, model.id).subscribe(() => this.refresh(), error => this.loading = false);
  }


  updateEvent(model: any): void {
    this.loading = true;
    const m: UpdateUserEventModel = {
      id: model.id,
      title: model.title,
      description: model.description,
      startDate: moment(this.datePipe.transform(`${model.startDate} ${model.startTime}`, 'yyyy-MM-dd HH:mm')).toDate(),
      endDate: moment(this.datePipe.transform(`${model.endDate} ${model.endTime}`, 'yyyy-MM-dd HH:mm')).toDate(),
      userEventTypeId: model.type,
    }
    this.userService.updateUserEvent(this.id, m).subscribe(() => this.refresh(), error => this.loading = false);
  }


  cancelEvent(): void {
    this.refresh();
  }


  lastEventIndex(): number {
    if (this.user === null || this.user === undefined || this.user.events === null || this.user.events === undefined) {
      return 0;
    }

    return this.user.events.length;
  }
}
