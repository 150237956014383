import { Injectable } from '@angular/core';
import {environment} from "../../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {AttachmentModel} from "./attachment.model";
import {map} from "rxjs/operators";

const url = environment.baseUrl + '/V1/Attachments'

@Injectable({
  providedIn: 'root'
})
export class AttachmentService {

  constructor(private http: HttpClient) { }

  getSingle(attachment: AttachmentModel): Observable<any> {
    return this.http.get(`${url}/${attachment.fileId}/${attachment.fileName}`, {responseType: 'blob'}).pipe(map(
      (res) => {
        return new Blob([res], {type: attachment.fileType});
      }));
  }
}
