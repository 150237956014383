import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { FrontVehiclesComponent } from './front-vehicle-list/front-vehicles.component';

const routes: Routes = [{ path: '', component: FrontVehiclesComponent }];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class FrontVehiclesRoutingModule { }
