<body [ngClass]="{ 'move-page': asideMenu, 'extra-margin-aside': showSideMenu, 'overflow-hidden': menuState == 'acr' }">
  <!-- aside nav start -->
  <aside *ngIf="showSideMenu" id="aside-hover" (mouseenter)="hoverAside()" (mouseleave)="hoverAside()" class="main-sidebar sidebar-dark-primary" [ngClass]="asideMenu ? '' : 'sidebar-icons-only'" [ngClass]="asideMenuHover ? '' : 'sidebar-icons-only'">
    <a [routerLink]="['tours']" class="brand-link" style="height:57px;">
      <img src="/assets/images/trasolu_logo_white.svg" alt="Trasolu Logo" class="brand-image">
    </a>
    <div class="sidebar">
      <div *ngIf="isAuthenticated" class="user-panel mt-3 pb-3 mb-3 d-flex">
        <div class="info">
          <span>{{'navigation.aside.greeting' | translate}} {{username}}</span>
        </div>
      </div>
      <nav class="mt-2">
        <ul class="nav nav-pills nav-sidebar flex-column">
          <li *ngIf="isSuperAdmin || isInRole && isCompanyAdmin" class="nav-item">
            <a [routerLinkActive]="['active']" [routerLink]="['reports/users']" class="nav-link">
              <fa-icon [icon]="faChartPie"></fa-icon>
              <p>{{'navigation.aside.reports' | translate}}</p>
            </a>
          </li>
          <li *ngIf="isSuperAdmin" class="nav-item">
            <a [routerLinkActive]="['active']" [routerLink]="['organisations']" class="nav-link">
              <fa-icon [icon]="faLayerGroup"></fa-icon>
              <p *ngIf="isSuperAdmin; else singularOrganisation">{{'navigation.aside.organisations' | translate}}</p>
              <ng-template #singularOrganisation>{{'navigation.aside.organisation' | translate}}</ng-template>
            </a>
          </li>
          <li *ngIf="isSuperAdmin || isCompanyAdmin" class="nav-item">
            <a [routerLinkActive]="['active']" [routerLink]="['companies']" class="nav-link">
              <img class="nav-icon filter-trasolu" src="/assets/images/RCA.svg">
              <p *ngIf="userCompanyGuidCountGreaterThanOne || isSuperAdmin || isCompanyAdmin; else singularCompany">{{'navigation.aside.companies' | translate}}</p>
              <ng-template #singularCompany>{{'navigation.aside.company' | translate}}</ng-template>
            </a>
          </li>
          <li *ngIf="isSuperAdmin || isCompanyAdmin" class="nav-item">
            <a [routerLinkActive]="['active']" [routerLink]="['company-users']" class="nav-link">
              <fa-icon [icon]="faUsers"></fa-icon>
              <p>{{'navigation.aside.users' | translate}}</p>
            </a>
          </li>
          <li *ngIf="isSuperAdmin || isCompanyAdmin" class="nav-item">
            <a [routerLinkActive]="['active']" [routerLink]="['grouping']" class="nav-link">
              <fa-icon [icon]="faDatabase"></fa-icon>
              <p>{{'navigation.aside.grouping' | translate}}</p>
            </a>
          </li>
          <li class="nav-item">
            <a [routerLinkActive]="['active']" [routerLink]="['acr']" class="nav-link">
              <img class="nav-icon filter-trasolu" src="/assets/images/Navigation.svg" />
              <p>{{'navigation.aside.tracker' | translate}}</p>
            </a>
          </li>
          <li class="nav-item">
            <a [routerLinkActive]="['active']" [routerLink]="['tours']" class="nav-link">
              <fa-icon [icon]="faGlobeEurope"></fa-icon>
              <p>{{'navigation.aside.planner' | translate}}</p>
            </a>
          </li>
          <li class="nav-item">
            <a [routerLinkActive]="['active']" [routerLink]="['help-center']" class="nav-link">
              <fa-icon [icon]="faQuestionCircle"></fa-icon>
              <p>{{'navigation.aside.helpCenter' | translate}}</p>
            </a>
          </li>
        </ul>
      </nav>
    </div>
  </aside>
  <!-- aside nav end -->

  <!-- white header start -->
  <nav *ngIf="showSideMenu" class="main-header navbar navbar-expand navbar-white navbar-light">
    <!-- toggle menu start -->
    <ul class="navbar-nav toggle-menu">
      <li class="nav-item" (click)="toggleAside()">
        <a class="nav-link pushmenu" title="Vis/Skjul">
          <fa-icon [icon]="faBars"></fa-icon>
        </a>
      </li>
    </ul>
    <!-- toggle menu end -->



    <!-- user menu start -->
    <ul *ngIf="showSideMenu" class="navbar-nav user-options">
      <li class="nav-item">
        <app-dropdown-button
          [buttonType]="'borderedPrimary'"
          [buttonText]="this.selectedButtonText()"
          [options]="this.mainGroups"
          [groupSelector]="true"
          (dropdownSelectionEvent)="selectGroup($event)"
        />
      </li>

      <!-- <li class="nav-item">
        <span class="navbar-text" style="padding-right: 30px;" [routerLink]="['start']">
          {{companyName}}
        </span>
         |
      </li> -->

      <li class="nav-item">
        <span class="nav-link">{{username}}</span>
      </li>
      <!-- TEMP REMOVED!
      <li class="nav-item dropdown" ngbDropdown>
        <a class="nav-link" data-toggle="dropdown" id="dropdownBasic1" ngbDropdownToggle>
          <fa-icon [icon]="faBell"></fa-icon>

        </a>

        <div class="dropdown-menu dropdown-menu-end" ngbDropdownMenu aria-labelledby="dropdownBasic1">
          <span class="dropdown-item dropdown-header" ngbDropdownItem>{{'navigation.header.notifications' | translate}}</span>
          <div class="dropdown-divider"></div>
          <a href="#" class="dropdown-item" ngbDropdownItem>
            <fa-icon [icon]="faEnvelope"></fa-icon> {{'navigation.header.messages' | translate}}
             <span class="float-end text-muted text-sm">3 mins</span>
          </a>
          <div class="dropdown-divider"></div>
          <a href="#" class="dropdown-item" ngbDropdownItem>
            <fa-icon [icon]="faUsers"></fa-icon> {{'navigation.header.requests' | translate}}
             <span class="float-end text-muted text-sm">12 hours</span>
          </a>
          <div class="dropdown-divider"></div>
          <a href="#" class="dropdown-item" ngbDropdownItem>
            <fa-icon [icon]="faFile"></fa-icon> {{'navigation.header.reports' | translate}}
             <span class="float-end text-muted text-sm">2 days</span>
          </a>
          <div class="dropdown-divider"></div>
          <a href="#" class="dropdown-item dropdown-footer" ngbDropdownItem>{{'navigation.header.allNotifications' | translate}}</a>
        </div>

      </li>-->
      <li class="nav-item dropdown" ngbDropdown>
        <a class="nav-link" data-toggle="dropdown" id="dropdownBasic2" ngbDropdownToggle>
          <fa-icon [icon]="faUser"></fa-icon>
          <!-- <fa-icon [icon]="faCaretDown"></fa-icon> -->
        </a>
        <div class="dropdown-menu dropdown-menu-end" ngbDropdownMenu aria-labelledby="dropdownBasic2">
        <!--  <a href="/Account/Profile" class="dropdown-item" ngbDropdownItem>
            <fa-icon [icon]="faUser"></fa-icon> {{'navigation.header.profile' | translate}}
          </a>
          <div class="dropdown-divider"></div>-->
          <a (click)="signout()" class="dropdown-item" ngbDropdownItem>
            <fa-icon [icon]="faSignOutAlt"></fa-icon> {{'navigation.header.logOut' | translate}}
          </a>
        </div>
      </li>
      <li class="nav-item dropdown" ngbDropdown>
        <a class="nav-link" data-toggle="dropdown" data-tooltip title="Sprog" id="dropdownBasic3" ngbDropdownToggle>
          <fa-icon [icon]="faFlag"></fa-icon>
        </a>
        <div class="dropdown-menu dropdown-menu-end" ngbDropdownMenu aria-labelledby="dropdownBasic3">
          @for (language of this.languageList; track $index) {
            <a href="/" (click)="setLanguage(language)" class="dropdown-item" ngbDropdownItem>
              <fa-icon [icon]="faGlobe"></fa-icon>
              {{ translateLanguage(language) }}
            </a>

            @if ($index < this.languageList.length - 1) {
              <div class="dropdown-divider"></div>
            }
          }
          <!-- <a href="/" class="dropdown-item" ngbDropdownItem>
            <fa-icon [icon]="faGlobe"></fa-icon>
            {{'navigation.header.danish' | translate}}
          </a>

          <div class="dropdown-divider"></div>

          <a href="/" class="dropdown-item" ngbDropdownItem>
            <fa-icon [icon]="faGlobe"></fa-icon>
            {{'navigation.header.english' | translate}}
          </a>

          <div class="dropdown-divider"></div>

          <a href="/" class="dropdown-item" ngbDropdownItem>
            <fa-icon [icon]="faGlobe"></fa-icon>
            {{'navigation.header.german' | translate}}
          </a> -->
        </div>
      </li>
    </ul>
    <!-- user menu end -->
  </nav>
  <!-- white header end -->

  <!-- planner top nav start -->
  <!-- *ngIf="menuState == 'global'" -->
  <nav *ngIf="menuState == 'global' || menuState == 'tours' && showSideMenu" [hidden]="!showSideMenu" class="navbar navbar-expand navbar-dark bg-default">
    <!-- <a class="navbar-brand" href="tours">{{'navigation.plannertitle' | translate}}</a> -->
    <div class="collapse navbar-collapse" id="navbarPlanner">
      <ul class="navbar-nav mr-auto">
        <li class="nav-item" [routerLinkActive]="['active']">
          <a class="nav-link" [routerLink]="['tours']">{{'navigation.tours' | translate}}</a>
        </li>
        <li class="nav-item" [routerLinkActive]="['active']">
          <a class="nav-link" [routerLink]="['customers']">{{'navigation.customers' | translate}}</a>
        </li>
        <li class="nav-item" [routerLinkActive]="['active']">
          <a class="nav-link" [routerLink]="['front-vehicles']">{{'navigation.frontVehicles' | translate}}</a>
        </li>
        <li class="nav-item" [routerLinkActive]="['active']">
          <a class="nav-link" [routerLink]="['back-vehicles']">{{'navigation.backVehicles' | translate}}</a>
        </li>
        <li class="nav-item" [routerLinkActive]="['active']">
          <a class="nav-link" [routerLink]="['transport-equipments']">{{'navigation.transportEquipments' | translate}}</a>
        </li>
        <li class="nav-item" [routerLinkActive]="['active']">
          <a class="nav-link" [routerLink]="['users']">{{'navigation.chauffeurs' | translate}}</a>
        </li>
        <li *ngIf="isSuperAdmin" class="nav-item" [routerLinkActive]="['active']">
          <a class="nav-link" [routerLink]="['boxes']">{{'navigation.boxes' | translate}}</a>
        </li>
      </ul>
      <span class="navbar-text" style="padding-right: 30px;" [routerLink]="['start']">
        {{companyName}}
      </span>
      <a class="navbar-text" title="{{'navigation.users.data' | translate}}" (click)="showDetailsUser = true"><fa-icon [icon]="faUser" style="color: white; padding-left: 10px;padding-right: 10px;cursor: pointer;"></fa-icon></a>
      <a class="navbar-text" title="{{'navigation.vehicles.data' | translate}}" (click)="showDetailsFrontVehicle = true"><fa-icon [icon]="faTruck" style="color: white; padding-left: 10px;margin-right: 10px;cursor: pointer;"></fa-icon></a>
    </div>
  </nav>
  <!-- planner top nav end -->

  <!-- tracker top nav start -->
  <!-- *ngIf="menuState == 'acr'" -->
  <nav class="navbar navbar-expand navbar-dark bg-default" *ngIf="menuState == 'acr'">
    <a class="navbar-brand" href="acr">{{'navigation.tracker' | translate}}</a>
    <div class="collapse navbar-collapse" id="navbarAcr">
      <ul class="navbar-nav mr-auto">
        <li class="nav-item" [routerLinkActive]="['active']">
          <!-- <a class="nav-link" [routerLink]="['acr']">{{'navigation.acr' | translate}}</a>-->
        </li>
        <li class="nav-item" [routerLinkActive]="['active']">
          <!-- <a class="nav-link" [routerLink]="['acr']">{{'navigation.acrTruckDetail' | translate}}</a>-->
        </li>
      </ul>
      <span class="navbar-text" style="padding-right: 30px; " [routerLink]="['start']">
        {{companyName}}
      </span>
      <a class="navbar-text" title="{{'navigation.users.data' | translate}}" (click)="showDetailsUser = true"><fa-icon [icon]="faUser" style="color: white; padding-left: 10px;padding-right: 10px;cursor: pointer;"></fa-icon></a>
      <a class="navbar-text" title="{{'navigation.vehicles.data' | translate}}" (click)="showDetailsFrontVehicle = true"><fa-icon [icon]="faTruck" style="color: white; padding-left: 10px;margin-right: 10px;cursor: pointer;"></fa-icon></a>
    </div>
  </nav>
  <!-- tracker top nav end -->

  <!-- reports top nav start -->
  <!-- *ngIf="menuState == 'reports'" -->
  <nav class="navbar navbar-expand navbar-dark bg-default" *ngIf="menuState == 'reports'">
    <!-- <a class="navbar-brand" href="reports">{{'navigation.reportstitle' | translate}}</a> -->
    <div class="collapse navbar-collapse" id="navbarReports">
      <ul class="navbar-nav mr-auto">
        <li class="nav-item" [routerLinkActive]="['active']">
          <a class="nav-link" [routerLink]="['reports/users']">{{'navigation.reports.users' | translate}}</a>
        </li>
        <li class="nav-item" [routerLinkActive]="['active']">
          <a class="nav-link" [routerLink]="['reports/tour']">{{'navigation.reports.tours' | translate}}</a>
        </li>
        <li class="nav-item" [routerLinkActive]="['active']">
          <a class="nav-link" [routerLink]="['reports/day']">{{'navigation.reports.day' | translate}}</a>
        </li>
        <li class="nav-item" [routerLinkActive]="['active']">
          <a class="nav-link" [routerLink]="['reports/glec']">{{'navigation.reports.glec' | translate}}</a>
        </li>
        <li class="nav-item" [routerLinkActive]="['active']">
          <a class="nav-link" [routerLink]="['reports/glecReportBasic']">{{'navigation.reports.glecBasicReport' | translate}}</a>
        </li>
        <li class="nav-item" [routerLinkActive]="['active']">
          <a class="nav-link" [routerLink]="['reports/glecReport']">{{'navigation.reports.glecReport' | translate}}</a>
        </li>
        <li class="nav-item" [routerLinkActive]="['active']">
          <a class="nav-link" [routerLink]="['reports/glecDistReport']">{{'navigation.reports.glecDistReport' | translate}}</a>
        </li>
      </ul>
      <span class="navbar-text" style="padding-right: 30px;" [routerLink]="['start']">
        {{companyName}}
      </span>
      <a class="navbar-text" title="{{'navigation.users.data' | translate}}" (click)="showDetailsUser = true"><fa-icon [icon]="faUser" style="color: white; padding-left: 10px;padding-right: 10px;cursor: pointer;"></fa-icon></a>
      <a class="navbar-text" title="{{'navigation.vehicles.data' | translate}}" (click)="showDetailsFrontVehicle = true"><fa-icon [icon]="faTruck" style="color: white; padding-left: 10px;margin-right: 10px;cursor: pointer;"></fa-icon></a>
    </div>
  </nav>
  <!-- reports top nav end -->

  <!-- ? start -->
  <app-detail *ngIf="showDetailsFrontVehicle" class="overlay" (close)="closeItemDetails()">
    <app-front-vehicle-detail #widget [active]="true" (addVehicle)="addNewVehicle = true" (close)="showDetailsFrontVehicle = false"></app-front-vehicle-detail>
  </app-detail>

  <app-detail *ngIf="showDetailsUser" class="overlay" (close)="closeItemDetails()">
    <app-user-detail #widget [active]="true" (close)="showDetailsUser = false"></app-user-detail>
  </app-detail>
  <app-detail *ngIf="addNewVehicle" class="overlay" (close)="addNewVehicle = false" [closeByBackdrop]="false">
    <app-front-vehicle-create #widget (cancel)="addNewVehicle = false" (created)="addNewVehicle = false;"></app-front-vehicle-create>
  </app-detail>

  <router-outlet></router-outlet>
  <!-- ? end -->
</body>
