import {Directive, EventEmitter, Input, OnInit, Output} from "@angular/core";

@Directive()
export abstract class WidgetComponent implements OnInit{

  @Input()
  public active: boolean = false;

  @Input()
  id: number = 0;

  @Output()
  updateList = new EventEmitter();

  @Output()
  close = new EventEmitter();

  @Output()
  addVehicle = new EventEmitter();

  public title: string | undefined;

  ngOnInit(): void {
  }
}
