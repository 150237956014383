import {Component, EventEmitter, HostListener, Input, OnInit, Output} from '@angular/core';
import {ContactModel} from "./shared/contact.model";
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {AddressModel} from "../address-widget/shared/address.model";
import {CreateMode} from "../../constants";
import { faCheck, faPencilAlt, faTimes, faTrash, faTrashAlt } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-contact-widget',
  templateUrl: './contact-widget.component.html'
})

export class ContactWidgetComponent implements OnInit {
  get createModes() {return CreateMode;}

  @Input()
  contact: ContactModel | undefined;

  @Input()
  createMode: any;

  @Output()
  cancelCreate = new EventEmitter();

  @Output()
  createContact = new EventEmitter();

  @Output()
  updateContact = new EventEmitter();
  
  @Output()
  cancelUpdate = new EventEmitter();

  @Output()
  deleteContact = new EventEmitter();

  formGroup: UntypedFormGroup;

  readOnly = true;

  faEdit = faPencilAlt;
  faAccept = faCheck;
  faCancel = faTimes;
  faTrash = faTrashAlt;

  constructor(private formBuilder: UntypedFormBuilder) {
    this.formGroup = formBuilder.group({
      id: undefined,
      name: [' ', [Validators.required, Validators.maxLength(255)]],
      title: [' ', Validators.maxLength(255)],
      phone: [' ', Validators.maxLength(12)],
      mobile: [' ', Validators.maxLength(12)],
      email: [' ', [Validators.email, Validators.maxLength(255)]]
    });
  }

  patchFormValues(): void {
    const model = this.contact;
    this.formGroup.patchValue({
      id: model?.id,
      name: model?.name,
      title: model?.title,
      phone: model?.phone,
      mobile: model?.mobile,
      email: model?.email
    });
  }

  ngOnInit(): void {
    this.patchFormValues();
  
    
    if(this.createMode){
      this.readOnly = false;
      this.formGroup.valueChanges.subscribe(() => {
        if ((this.createMode === this.createModes.create_as_user || this.createMode === this.createModes.create_as_parent)
            && this.formGroup.dirty) {
          
          this.createContact.emit(this.formGroup.value as ContactModel);
        }
      });
    }
  }

  create(): void {
   
    this.createContact.emit(this.formGroup.value as ContactModel);
  }

  update(): void {
    this.readOnly = !this.readOnly;
    this.updateContact.emit(this.formGroup.value as ContactModel);
    
  }

  cancel():void{
    this.readOnly = !this.readOnly;
  
    
    this.cancelUpdate.emit(this.contact?.id);
  }

  delete():void{
    this.deleteContact.emit(this.contact?.id);
  }

}
