<div class="combobox--component">
  <div ngbDropdown class="d-inline-block" #filterDropdown>
    <button
      type="button"
      class="btn btn-link px-0"
      ngbDropdownToggle
    >
      <fa-icon [icon]="faSliders"></fa-icon>
    </button>
    <div ngbDropdownMenu>
      @for (option of this.options; track $index) {
        <button ngbDropdownItem (click)="onSelect(option)">{{ 'grouping.columns.' + option.displayName | translate }}</button>
      }
    </div>
  </div>
</div>
