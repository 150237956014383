<div class="confirmation-action--component col-md-12">
  <div class="d-flex">
    <!--<button type="button" class="btn accept-btn btn-success" [disabled]=" !formGroup || formGroup.invalid" (click)="accept.emit()">-->
    <button type="button" class="btn btn-success" (click)="this.accept()">
      <fa-icon [icon]="faCheck"></fa-icon>
    </button>
    <!--<button class="btn accept-btn btn-outline-secondary" (click)="cancel.emit()">-->
    <button class="btn btn-secondary text-white mx-2" (click)="this.cancel()">
      <fa-icon [icon]="faCancel"></fa-icon>
    </button>
  </div>
</div>
