import { TranslateLoader } from '@ngx-translate/core'
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs'
import { environment } from 'src/environments/environment'

export class SimpleLocalizeTranslateLoader implements TranslateLoader {

  constructor(private http: HttpClient) {}

  getTranslation(lang: string): Observable<any> {
    let url = ''

    if (lang == 'danish') {
      url = environment.simpleLocalize.danish
    }

    if (lang == 'english') {
      url = environment.simpleLocalize.english
    }

    if (lang == 'german') {
      url = environment.simpleLocalize.german
    }
    if (lang == 'czech') {
      url = environment.simpleLocalize.czech
    }
    return this.http.get(url);
  }
}
