import {Component, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {CalendarOptions} from "@fullcalendar/core";
import {EventModel} from "./shared/event.model";
import {CalendarService} from "./shared/calendar.service";
import {DatePipe} from "@angular/common";
import interactionPlugin from '@fullcalendar/interaction';
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
import resourceTimeGridPlugin from '@fullcalendar/resource-timegrid';
import listPlugin from '@fullcalendar/list';
import bootstrapPlugin from '@fullcalendar/bootstrap';
import { lastValueFrom } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { FullCalendarComponent } from '@fullcalendar/angular';

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  providers: [DatePipe]
})

export class CalendarComponent implements OnInit{

  events: any[] = [];

  @Output()
  closeCalendar = new EventEmitter();

  @Output()
  openDetails = new EventEmitter();

  @Output()
  createNew = new EventEmitter();

  calendarOptions: CalendarOptions;

  calendar: any;

  refetchInterval: any;

  @ViewChild('calendar') calendarComponent: FullCalendarComponent | undefined;

  constructor(private service: CalendarService, private datePipe: DatePipe, private translate: TranslateService) {
    this.calendarOptions = {
      plugins: [
        resourceTimelinePlugin,
        resourceTimeGridPlugin,
        bootstrapPlugin,
        interactionPlugin,
        dayGridPlugin,
        timeGridPlugin,
        listPlugin
      ],
      schedulerLicenseKey: '0264157724-fcs-1705169645',
      initialView: 'dayGridMonth',
      datesAboveResources:true,
      buttonText: {
        today: this.translate.instant('calendar.shared.today'),
        month: this.translate.instant('calendar.shared.month'),
        week: this.translate.instant('calendar.shared.week'),
        day: this.translate.instant('calendar.shared.day'),
        listDay: this.translate.instant('calendar.calendar.listDay'),
        listWeek: this.translate.instant('calendar.calendar.listWeek')
      },
      headerToolbar: {
        left: 'prev,next today',
        center: 'title',
        right: 'tableBtn dayGridMonth,timeGridWeek,timeGridDay,listDay,listWeek'
      },
      locale: 'da-dk',
      height: '90vh',
      weekNumbers: true,
      weekText: this.translate.instant('calendar.shared.weekWithSpacing'),
      customButtons: {
        tableBtn: {
          text: this.translate.instant('calendar.shared.table'),
          click: () => this.closeCalendar.emit()
        }
      },
      views: {
        listDay: { buttonText: this.translate.instant('calendar.calendar.listDay') },
        listWeek: { buttonText: this.translate.instant('calendar.calendar.listWeek') },
        listMonth: { buttonText: this.translate.instant('calendar.calendar.listMonth') }
      },
      eventClick: (info: any) => this.eventClicked(info),
      events: (fetchInfo: any, successCallback: any, failureCallback: any) =>
      this.fetchData(fetchInfo),
      dateClick: (info: any) => this.dateClicked(info)
    }
  }

  ngOnInit(): void {
    this.refetchInterval = setInterval(() => {
      this.updateView();
    }, 60_000);
  }

  ngOnDestroy(): void {
    clearInterval(this.refetchInterval);
  }

  updateView(): void {

    if (this.calendarComponent != undefined ) {

      let calendarApi = this.calendarComponent.getApi();
        calendarApi.refetchEvents();
    }

  }

  async fetchData(e: any): Promise<EventModel[]> {
    const start = this.datePipe.transform(e.start, 'yyyy-MM-dd');
    const end = this.datePipe.transform(e.end, 'yyyy-MM-dd');
    return await lastValueFrom<any>(this.service.getTours(start, end))
    //const events = await this.service.getTours(start, end).toPromise();
    //return events;
  }

  eventClicked(e: any): void{
    const event = {id: e.event._def.publicId};
    this.openDetails.emit(event);
  }

  dateClicked(e: any): void {
    const event = {startDate: e.date, allDay: e.allDay};
    this.createNew.emit(event)
  }
}
