<ng-template #content let-modal>
  <div class="modal-header">
    <h6 class="modal-title" id="modal-basic-title">{{'tour.create.frontVehicle-select.title' | translate}}</h6>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
  </div>
  <div class="modal-body">
    <p>{{'tour.create.frontVehicle-select.description' | translate}}</p>
    <input class="form-control form-control-sm full-width" style="margin-bottom: 5px" id="search"
           placeholder="{{'tour.create.frontVehicle-select.search' | translate}}" [ngModel]="searchQuery" (ngModelChange)="search($event)" >
    <div *ngIf="formGroup">
      <form [formGroup]="formGroup" *ngIf="dataCopy">
        <ul id="assignedUserId" type="text" class="list-group">
          <button class="list-group-item list-group-item-action clickable" style="padding: 0.2rem 1rem!important;" [ngClass]="preselection?.id === frontVehicle.id ? 'bg-light' : ''"
                  *ngFor="let frontVehicle of dataCopy" (click)="formGroup.patchValue({frontVehicle: frontVehicle}); modal.close()">
            {{frontVehicle.unitIdentification}} / {{frontVehicle.registrationNumber}}
          </button>
        </ul>
      </form>
    </div>
  </div>
  <div class="modal-footer">
    <button [disabled]="!preselection" type="button" class="btn btn-outline-warning" (click)="modal.close()">{{'tour.create.frontVehicle-select.remove-btn' | translate}}</button>
    <button type="button" class="btn btn-outline-default" (click)="modal.dismiss()">{{'tour.create.frontVehicle-select.cancel-btn' | translate}}</button>
  </div>
</ng-template>

<button hidden (click)="openModal(content)" id="btn"></button>
