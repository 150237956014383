import { Component, Input, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { TranslateService } from "@ngx-translate/core";
import { TokenService } from './services/token.service';
import { IconDefinition, faUser, faTruck, faBars, faCaretDown, faSignOutAlt, faBell, faEnvelope, faUsers, faFile, faFlag, faGlobe, faUserCog, faUsersCog, faCaretLeft, faTools, faQuestionCircle, faGlobeEurope, faLayerGroup, faChartPie, faDatabase } from '@fortawesome/free-solid-svg-icons';
import { environment } from '../environments/environment';
import { LogoutAuthOptions, OidcSecurityService } from 'angular-auth-oidc-client';
import { MainGroup } from './grouping/grouping.models'
import { GroupingService } from './grouping/grouping.service'
import { CreateGroup, CustomProxyEvent, DeleteGroup, EventProxyService, SetGroupId, UpdateGroup } from './shared/services/event-proxy.service'
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent implements OnInit {
  private EventProxySubscription: Subscription | undefined

  menuState: string = 'global'
  urlTours: string = ''
  companyName: string = ''
  language: string = ''
  asideMenu: boolean = false
  asideMenuHover: boolean = false
  username: string = ''
  showSideMenu: boolean = false

  languageList: string[] = ['danish', 'english', 'german', 'czech']

  faUser: IconDefinition = faUser
  faTruck: IconDefinition = faTruck
  faBars: IconDefinition = faBars
  faCaretDown: IconDefinition = faCaretDown
  faSignOutAlt: IconDefinition = faSignOutAlt
  faBell: IconDefinition = faBell
  faEnvelope: IconDefinition = faEnvelope
  faUsers: IconDefinition = faUsers
  faFile: IconDefinition = faFile
  faFlag: IconDefinition = faFlag
  faGlobe: IconDefinition = faGlobe
  faUserCog: IconDefinition = faUserCog
  faUsersCog: IconDefinition = faUsersCog
  faCaretLeft: IconDefinition = faCaretLeft
  faTools: IconDefinition = faTools
  faQuestionCircle: IconDefinition = faQuestionCircle
  faGlobeEurope: IconDefinition = faGlobeEurope
  faLayerGroup: IconDefinition = faLayerGroup
  faChartPie: IconDefinition = faChartPie
  faDatabase: IconDefinition = faDatabase

  showDetailsFrontVehicle: boolean = false
  showDetailsUser: boolean = false
  addNewVehicle: boolean = false

  isSuperAdmin: boolean = false
  isCompanyAdmin: boolean = true
  isAuthenticated: boolean = true
  isInRole: boolean = true
  userCompanyGuidCountGreaterThanOne: boolean = true

  mainGroups: MainGroup[] = []

  constructor(public translate: TranslateService,
    private router: Router,
    private tokenService: TokenService,
    private oidc: OidcSecurityService,
    private groupingService: GroupingService,
    private eventProxyService: EventProxyService) {
    translate.addLangs(['danish'])
    translate.addLangs(['english'])
    translate.setDefaultLang('danish')

    this.language = tokenService.getUserLanguage()
    this.username = tokenService.getUserName()
    this.isSuperAdmin = tokenService.isSuperAdmin()

    switch (this.language) {
      case 'da-DK':
        this.language = 'danish'
        break
      case 'en-GB':
        this.language = 'english'
        break
      case 'de':
        this.language = 'german'
        break
      case 'cs':
        this.language = 'czech'
        break
      default:
        this.language = 'danish'
        break
    }

    translate.use(this.language)

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.menuState = this.getMenuState(router.url);
        this.urlTours = router.url;
      }
    });



    this.companyName = this.tokenService.getCompanyName();
    this.tokenService.companyName.subscribe(
      data => {
        this.companyName = data;
      }
    )


    this.EventProxySubscription = this.eventProxyService.getEvent().subscribe((param: CustomProxyEvent) => {
      if (param instanceof CreateGroup || param instanceof UpdateGroup || param instanceof DeleteGroup) {
        this.getGroups()
      }
    })
  }




  ngOnInit(): void { }



  getMenuState(url: string) {
    if (url.toLowerCase().indexOf('/acr') >= 0) {
      this.showSideMenu = true
      this.getGroups()
      return 'acr'
    }

    if (url.toLowerCase().indexOf('/reports') >= 0) {
      this.showSideMenu = true
      this.getGroups()
      return 'reports'
    }

    if (url.toLowerCase().indexOf('/glecview') >= 0) {
      this.showSideMenu = false
      return 'glecView'
    }

    if (url.toLowerCase().indexOf('/mss') >= 0) {
      this.showSideMenu = false
      return 'mss'
    }

    if (url.toLowerCase().indexOf('/start') >= 0) {
      this.showSideMenu = false
      return 'start'
    }

    if (url.toLowerCase().indexOf('/tours') >= 0) {
      this.showSideMenu = true
      this.getGroups()
      return 'tours'
    }

    if (url.toLowerCase().indexOf('/companies') >= 0 || url.toLowerCase().indexOf('/organisations') >= 0 || url.toLowerCase().indexOf('/company-users') >= 0 || url.toLowerCase().indexOf('/help-center') >= 0 || url.toLowerCase().indexOf('/grouping') >= 0) {
      this.showSideMenu = true
      this.getGroups()
      return ''
    }


    if (this.showSideMenu) this.getGroups()

    /* if (url.toLowerCase().indexOf('/company-reports') >= 0) {
      return 'company-reports';
    }
    if (url.toLowerCase().indexOf('/tracker') >= 0) {
      return 'tracker';
    }
    if (url.toLowerCase().indexOf('/planner') >= 0) {
      return 'planner';
    }
    if (url.toLowerCase().indexOf('/start') >= 0) {
      return 'planner';
    }
    return ''; */
    this.showSideMenu = true
    return 'global'
  }


  closeItemDetails(): void {
    this.showDetailsUser = false
    this.showDetailsFrontVehicle = false
  }


  toggleAside(): void {
    this.asideMenu = !this.asideMenu
  }


  hoverAside(): void {
    if (document.getElementsByClassName('move-page').length < 1) {
      this.asideMenuHover = !this.asideMenuHover
    }
  }



  getGroups() {
    this.groupingService.getGroupsList().subscribe(response => {
      this.mainGroups = response
    })
  }


  selectGroup(event: any) {

    if (event.maingroupId <= 0) {
      sessionStorage.removeItem('groupId')
    } else {
      sessionStorage.setItem('groupId', event.maingroupId.toString())
    }

    this.eventProxyService.setEvent(new SetGroupId(event.maingroupId))
  }

  selectedButtonText() {
    if (sessionStorage.getItem('groupId')) {
      return this.mainGroups.find(x => x.id == Number.parseInt(sessionStorage.getItem('groupId')!))?.name || this.translate.instant('grouping.buttons.chooseGroup')
    }
    return this.translate.instant('grouping.buttons.chooseGroup')
  }


  translateLanguage(language: string) {
    return this.translate.instant(`navigation.header.${language}`)
  }


  setLanguage(language: string) {
    if (language == 'danish') {
      this.language = 'danish'
      this.tokenService.setLanguage('da-DK')
    }

    if (language == 'english') {
      this.language = 'english'
      this.tokenService.setLanguage('en-GB')
    }

    if (language == 'german') {
      this.language = 'german'
      this.tokenService.setLanguage('de')
    }
    if (language == 'czech') {
      this.language = 'czhech'
      this.tokenService.setLanguage('cs')
    }

    this.translate.use(this.language)
  }


  signout(): void {
    //this.tokenService.SignoutLocal();
    //this.tokenService.Signout();
    const logoutAuthOptions: LogoutAuthOptions = {
      logoffMethod: 'GET',
    };
    this.oidc.logoffAndRevokeTokens('', logoutAuthOptions).subscribe(x => { });
    //window.location.href = 'https://app-staging-iframe.trasolu.com/connect/endsession?post_logout_redirect_uri=https%3A%2F%2Fplanner-staging-iframe.trasolu.com%2Fsignout-callback-oidc';

    //const url = environment.idp.authority + '/connect/endsession'
    //window.open(url, '_self');
    //this.router.navigateByUrl('https://app-staging-iframe.trasolu.com/connect/endsession');
  }

  ngOnDestroy() {
    if (this.EventProxySubscription) {
      this.EventProxySubscription.unsubscribe();
    }
  }
}
