<div class="row m-0 p-0 usageBox" id="usageBox">
  <div class="col-12 m-1"><span class="usageTitle"> {{'reports.glec.view.averageCO2eEmittedOnTask' | translate}} <br></span></div>
  <div class="col-6 text-left"><span class="infoText">
      {{consumption}} {{'reports.glec.view.literConsumption' | translate}} <br>
      {{co2e}} {{'reports.glec.view.kgCO2e' | translate}}<br>
      {{co2ePer}} {{'reports.glec.view.kgCO2eSlash' | translate}}{{unit}}</span>
  </div>
  <div class="col-6 text-center">
    <!-- <app-usage-gauge [centralLabel]="this.usage?.kmTotalString" [needleValue]="this.usage?.needleValue" #gauge></app-usage-gauge>-->
    <ngx-gauge [type]="gaugeType" [value]="needleValue" [min]="0" [max]="6" [duration]="1" [thick]="gaugeThickness ? gaugeThickness : 15" [size]="gaugeWidth ? gaugeWidth : 140" [markers]="markersConfig" [thresholds]="thresholdConfig">
      <ngx-gauge-append>
        <span class="gaugeTextStyle"> {{gaugeAppendText}} </span>
      </ngx-gauge-append>
      <ngx-gauge-label>
        <span class="labelStyle"> {{'reports.glec.view.kmColon' | translate}} {{centralLabel}} <br> {{extraInfo}} </span>
      </ngx-gauge-label>
    </ngx-gauge>
    <!--<span class="mt-0"  *ngIf="this.usage?.kmPerLiterString" >{{this.usage?.kmPerLiterString}}<br></span>
      <span *ngIf="this.usage?.kmTotal" >{{this.usage?.kmTotal}} km<br></span>-->
  </div>
  <!-- <div style="display: flex;justify-content: space-between;"><span>Co2 udledning for perioden</span><span>- 39,6 %</span></div> -->
</div>
<button type="button" (click)="generateImage()">{{'reports.glec.view.createImage' | translate}}</button>