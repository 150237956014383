import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { faPlus, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { ButtonComponent } from 'src/app/atoms/button/button.component';
import { MainGroup } from 'src/app/grouping/grouping.models';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-dropdown-button',
  standalone: true,
	imports: [NgbDropdownModule, FaIconComponent, TranslateModule],
  templateUrl: './dropdown-button.component.html',
  styleUrls: ['./dropdown-button.component.scss', '../../atoms/button/button.component.scss']
})
export class DropdownButtonComponent implements OnInit {
    @Input() buttonText: string = ''
    @Input() options: MainGroup[] | undefined
    @Input() buttonType: string = 'default'
    @Input() showCreateButton: boolean = false
    @Input() extraButton: boolean = false
    @Input() extraButtonText: string = ''
    @Input() groupSelector: boolean = false

    @Output() dropdownSelectionEvent = new EventEmitter()
    @Output() specialButtonEvent = new EventEmitter()


    faPlus: IconDefinition = faPlus

    constructor() { }


    ngOnInit(): void {}


    dropdownSelection(option: MainGroup | null, maingroupId: number | undefined) {
      this.dropdownSelectionEvent.emit({ option, maingroupId })

      if(option && this.groupSelector) this.buttonText = option.name
    }

    specialButtonEmit() {
      this.specialButtonEvent.emit()
    }
}
