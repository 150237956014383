import { ToastrService } from 'ngx-toastr';
import { UserService } from './../../users/shared/user.service';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { firstValueFrom } from 'rxjs';
import { FrontVehicleModel } from 'src/app/front-vehicles/shared/front-vehicle.model';
import { FrontVehicleService } from 'src/app/front-vehicles/shared/front-vehicle.service';
import { MessagingService } from 'src/app/molecules/message-list/messaging.service';
import { UserModel } from 'src/app/users/shared/user.model';
import { ModalComponent } from 'src/app/molecules/modal/modal.component';

export interface MessageForm {
  selectedFrontVehicles: any[],
  selectedUsers: any[],
  userId?: number | null,
  frontVehicleId?: number | null,
  message: string,
  type?: any
}


export interface MultiSelectList {
  item_id: number,
  item_text: string
}

export interface MessageType {
  value: number,
  label: string
}


@Component({
  selector: 'app-create-message',
  templateUrl: './create-message.component.html',
  styleUrl: './create-message.component.scss'
})
export class CreateMessageComponent implements OnInit {
  @ViewChild('messageCreateModal') messageCreateModal!: ModalComponent;


  @Input() disableSwitch: boolean = false
  @Input() showUser: boolean = false
  @Input() modalTitle: string = ''
  @Input() standalone: boolean = true

  @Output() messageCreateEmit = new EventEmitter()



  messageTypes: MessageType[] = [{
    value: 1,
    label: 'messaging.messageType.message'
  }, {
    value: 2,
    label: 'messaging.messageType.notification'
  }, {
    value: 0,
    label: 'messaging.messageType.alarm'
  }]
  usersList: MultiSelectList[] = []
  frontVehiclesList: MultiSelectList[] = []

  formGroup: UntypedFormGroup;
  messageForm: MessageForm = {
    selectedFrontVehicles: [],
    selectedUsers: [],
    userId: null,
    frontVehicleId: null,
    message: '',
    type: 1
  }



  dropdownSettings: IDropdownSettings = {
    singleSelection: true,
    idField: 'item_id',
    textField: 'item_text',
    allowSearchFilter: true,
    searchPlaceholderText: this.translate.instant('messaging.create.searchPlaceholder'),
    noDataAvailablePlaceholderText: this.translate.instant('messaging.create.noDataAvailable'),
    itemsShowLimit: 10,
    limitSelection: 1
  }


  constructor(
    private formBuilder: UntypedFormBuilder,
    public translate: TranslateService,
    private userService: UserService,
    private frontVehicleService: FrontVehicleService,
    private messagingService: MessagingService,
    private toastrService: ToastrService
  ) {
    this.formGroup = this.formBuilder.group({})
  }



  ngOnInit(): void {
    this.messageTypes = [{
      value: 1,
      label: this.translate.instant('messaging.messageType.message')
    }, {
      value: 2,
      label: this.translate.instant('messaging.messageType.notification')
    }, {
      value: 0,
      label: this.translate.instant('messaging.messageType.alarm')
    }]


    this.frontVehicleService.getAll().subscribe((response: FrontVehicleModel[]) => {
      this.frontVehiclesList = response.map((frontVehicle: FrontVehicleModel) => {
        return { item_id: frontVehicle.id, item_text: frontVehicle.registrationNumber }
      })
    })

    this.userService.getAll().subscribe((response: UserModel[]) => {
      this.usersList = response.map((user: UserModel) => {
        return { item_id: user.id, item_text: user.firstname + " " + user.lastname }
      })
    })


    this.formGroup = this.formBuilder.group({
      userId: null,
      frontVehicleId: null,
      message: ['', [Validators.maxLength(255), Validators.required]],
      type: 1
    })
  }

  /**
   * Using the switch
   * @param switchEvent
   */
  switchMessageMultiselect(switchEvent: boolean) {
    if (switchEvent) { this.messageForm.userId = null }
    if (!switchEvent) { this.messageForm.frontVehicleId = null }

    this.showUser = switchEvent
  }

  /**
   * Executes when the user multiselect is selected
   * @param selectEvent
   */
  onMessageUserMultiSelect(selectEvent: any) {
    this.messageForm.userId = selectEvent.item_id
  }

  /**
   * Executes when the front vehicle multiselect is selected
   * @param selectEvent
   */
  onMessageFrontVehicleMultiSelect(selectEvent: any) {
    this.messageForm.frontVehicleId = selectEvent.item_id
  }

  /**
   * Executes when the message modal closes
   */
  saveForm() {
    // you need to enter either a userId or a frontVehicleId to progress
    if (this.messageForm.userId || this.messageForm.frontVehicleId) {
      this.messageCreateEmit.emit(this.formGroup.value)

      // reset the multiselect
      this.messageForm.userId = null
      this.messageForm.selectedUsers = []

      this.messageForm.frontVehicleId = null
      this.messageForm.selectedFrontVehicles = []

      this.messageForm.type = 1
      this.messageForm.message = ''

      // reset form
      this.formGroup.patchValue({
        userId: null,
        frontVehicleId: null,
        message: '',
        type: 1
      })
    }
  }
}
