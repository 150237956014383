import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { GetLogBookEntriesModel, LogBookModel, LogBookTypeModel, SearchModel } from "./log-book.model";

const url = environment.baseUrl + '/V1/Logbook';

@Injectable({
  providedIn: 'root'
})

export class LogBookService {
  constructor(private http: HttpClient) { };

  private url(endpoint: string) {
    return `${url}/${endpoint}`;
  }

  //This is a post because it needs to send data as well as get data
  getEntryGroup(model: GetLogBookEntriesModel): Observable<LogBookModel> {
    return this.http.post<LogBookModel>(this.url('EntryType'), model);
  }

  getLogTypes(): Observable<LogBookTypeModel> {
    return this.http.get<LogBookTypeModel>(this.url('LogTypes'));
  }

  search(model: SearchModel): Observable<LogBookModel> {
    return this.http.post<LogBookModel>(this.url('Search'), model);
  }
}
