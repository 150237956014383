import { Component, EventEmitter, OnInit, Output } from '@angular/core'
import { faCheck, faCancel, IconDefinition } from '@fortawesome/free-solid-svg-icons'

@Component({
  selector: 'app-confirmation-action',
  templateUrl: './confirmation-action.component.html',
  styleUrl: './confirmation-action.component.scss'
})
export class ConfirmationActionComponent implements OnInit {


  faCheck: IconDefinition = faCheck
  faCancel: IconDefinition = faCancel

  @Output() acceptEvent = new EventEmitter()
  @Output() cancelEvent = new EventEmitter()


  constructor() { }

  ngOnInit(): void {
  }

  accept() {
    this.acceptEvent.emit()
  }

  cancel() {
    this.cancelEvent.emit()
  }
}
