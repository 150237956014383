import {Component, OnInit, Output, EventEmitter, Input, OnChanges, SimpleChanges} from '@angular/core';
import {TagService} from "./shared/tag.service";
import {TagModel} from "./shared/tag.model";

@Component({
  selector: 'app-tag',
  templateUrl: './tag.component.html'
})

export class TagComponent implements OnInit {

  @Input()
  preSelectedItems : number [] = [];

  @Output()
  selectedItem = new EventEmitter();

  tags: TagModel[] = [];

  constructor(private tagService: TagService) { }

  selected(): void {
    this.selectedItem.emit(this.tags);
  }

  ngOnInit(): void {
    this.tagService.getAll().subscribe(response => {
      this.tags = response;
      this.preSelectedItems?.forEach(pre => {
        const tag = this.tags.find(x => x.id === pre);
        if (tag) {
          tag.selected = true;
          this.selected();
        }

      })
    });
  }
}
