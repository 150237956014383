import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrl: './button.component.scss'
})
export class ButtonComponent implements OnInit {
  @Input() buttonText: string = ''
  @Input() buttonType: string = 'default'
  @Input() rounded: boolean = false

  @Output() buttonClickEvent = new EventEmitter()

  constructor() { }

  ngOnInit(): void { }

  buttonClick() {
    this.buttonClickEvent.emit()
  }
}
