import {
  Component,
  HostListener,
  EventEmitter,
  AfterContentInit,
  ContentChildren, QueryList, Output, Input
} from '@angular/core';
import {WidgetComponent} from "../widget-component";
import {faAngleDoubleLeft, faAngleDoubleRight, faPencilAlt} from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: 'app-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.css']
})
export class DetailComponent implements AfterContentInit {
  @ContentChildren('widget')
  widgets: QueryList<WidgetComponent> | undefined;

  @Output()
  close = new EventEmitter();

  @Input()
  closeByBackdrop = false;

  @HostListener('document:click', ['$event'])
  clickout(event: any) {
    if(event.target.className === 'detail-background' && this.closeByBackdrop )
      this.closeDetails();
  }

  title: string | undefined;
  faEdit = faPencilAlt;
  faResize = faAngleDoubleLeft;

  resize = false;

  constructor() {
  }

  ngAfterContentInit(): void {
    this.title = this.widgets?.get(0)?.title;
  }


  closeDetails(): void {
    this.close.emit();
  }

  resizeModal(): void {
    this.resize = ! this.resize;

    if (this.resize) this.faResize = faAngleDoubleRight;
    else this.faResize = faAngleDoubleLeft;
  }


}
