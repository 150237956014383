import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../../environments/environment";
import { Observable } from "rxjs";
import { EventModel } from "../../calendar/shared/event.model";
import { TourEventModel, TourSimpleModel } from "../../../tours/shared/tour.model";
import { map } from "rxjs/operators";
import { ResourceModel } from "./resource.model";
import { FrontVehicleEventModel, FrontVehicleModel } from "../../../front-vehicles/shared/front-vehicle.model";
import { UserEventModel, UserModel, UserResourceModel, UserTimeRegistrationModel } from "../../../users/shared/user.model";
import { BackVehicleEventModel, BackVehicleModel } from "../../../back-vehicles/shared/back-vehicle.model";
import { TransportEquipmentEventModel, TransportEquipmentModel } from "../../../transport-equipments/shared/transport-equipment.model";
import { SharedService } from "../../services/shared.service";
import { JobType, StatusColors, StatusType } from 'src/app/constants';
import { JobModel } from "../../../jobs/job.model";
import { useReducer } from 'preact/hooks';
import { userInfo } from 'os';
import * as moment from 'moment';
import { time } from 'console';
import { FrontVehicleService } from '../../../front-vehicles/shared/front-vehicle.service';

const url = environment.baseUrl + '/V1';

@Injectable({
  providedIn: 'root'
})
export class ResourceCalendarService {
  language: any;

  constructor(private http: HttpClient, public sharedService: SharedService, private frontVehicleService: FrontVehicleService) { }

  async getLanguage() {
    this.sharedService.getLanguageJson().subscribe(response => this.language = response)
  }

  async ngOnInit(): Promise<void> {
    await this.getLanguage()
  }

  getFrontVehicles(start: any, end: any): Observable<ResourceModel[]> {
    return this.http.get<FrontVehicleModel[]>(`${url}/FrontVehicles/With-Tours/${start}/${end}`).pipe(
      map((frontVehicles: FrontVehicleModel[]) => frontVehicles.filter(x => x.type != 'Internal').map((frontVehicle: FrontVehicleModel) => {
        const resource: ResourceModel = {
          id: frontVehicle.id.toString(),
          title: this.getVehicleDisplayText(frontVehicle),
          events: [...this.mapTours(frontVehicle.tours, frontVehicle.id.toString()), ...frontVehicle.events.map((x: FrontVehicleEventModel) => ({
            resourceId: frontVehicle.id.toString(),
            id: x.id.toString(),
            start: this.mapDateToLocal(x.actualStartDate),
            end: x.actualEndDate ? this.mapDateToLocal(x.actualEndDate) : new Date(),
            title: x.description,
            color: x.type.toString() === 'Broken' ? StatusColors.broken : StatusColors.wash,
            classNames: ['unclickable']
          }))]
        };
        return resource;
      }))
    );
  }


  getBackVehicles(start: any, end: any): Observable<ResourceModel[]> {
    return this.http.get<BackVehicleModel[]>(`${url}/BackVehicles/With-Tours/${start}/${end}`).pipe(
      map((backVehicles: BackVehicleModel[]) => backVehicles.map((backVehicle: BackVehicleModel) => {
        const resource: ResourceModel = {
          id: backVehicle.id.toString(),
          title: backVehicle.unitIdentification + ' / ' + backVehicle.registrationNumber,
          events: [...this.mapTours(backVehicle.tours, backVehicle.id.toString()), ...backVehicle.events.map((x: BackVehicleEventModel) => ({
            resourceId: backVehicle.id.toString(),
            id: x.id.toString(),
            start: this.mapDateToLocal(x.actualStartDate),
            end: x.actualEndDate ? this.mapDateToLocal(x.actualEndDate) : new Date(),
            title: x.description,
            color: x.type.toString() === 'Broken' ? StatusColors.broken : StatusColors.wash,
            classNames: ['unclickable']
          }))]
        };
        return resource;
      }))
    );
  }



  getTransportEquipments(start: any, end: any): Observable<ResourceModel[]> {
    return this.http.get<TransportEquipmentModel[]>(`${url}/TransportEquipments/With-Tours/${start}/${end}`).pipe(
      map((transportEquipments: TransportEquipmentModel[]) =>
        transportEquipments.map((transportEquipment: TransportEquipmentModel) => {
          const resource: ResourceModel = {
            id: transportEquipment.id.toString(),
            title: transportEquipment.unitIdentification + ' / ' + transportEquipment.manufacturer + ' ' + transportEquipment.type,
            events: [...this.mapTours(transportEquipment.tours, transportEquipment.id.toString()), ...transportEquipment.events.map((x: TransportEquipmentEventModel) => ({
              resourceId: transportEquipment.id.toString(),
              id: x.id.toString(),
              start: this.mapDateToLocal(x.actualStartDate),
              end: x.actualEndDate ? this.mapDateToLocal(x.actualEndDate) : new Date(),
              title: x.description,
              color: x.type.toString() === 'Broken' ? StatusColors.broken : StatusColors.wash,
              classNames: ['unclickable']
            }))]
          };
          return resource;
        }))
    )
  }




  // NEW DATAMODEL NOT DONE
  getAllUsersResources(start: any, end: any, filterAGfix: boolean): Observable<any[]> {
    return this.http.get<any[]>(`${url}/ResourceCalendar/Calendar/${start}/${end}`).pipe(
      map((users: any[]) => users
        .filter(x => {
          if (!filterAGfix) {
            return true;
          }
          if (x.isAutoGenerated) {
            if (x.timeRegistrations.length > 0) {

              return true;
            }
            else {
              return false;
            }
          }
          else {
            return true;
          }

        })
        .map((user: any) => {
          const resource: ResourceModel = {
            id: user.userId.toString(),
            title: user.name,
            tourHours: user.totalWorkTimeHours == 0 ? '0' : user.totalWorkTimeHours.toFixed(2).toString(),
            timeRegistrationHours: (user.totalTimeRegistrationHours - user.totalRestHours) == 0 ? '0' : (user.totalTimeRegistrationHours - user.totalRestHours).toFixed(2).toString(),
            restHours: user.totalRestHours == 0 ? '0' : user.totalRestHours.toFixed(2).toString(),
            events: [...user.timeRegistrations.filter((t: any) => t.typeId != 17)
              .map((timeRegistration: any) => ({
                id: timeRegistration.tourId,
                resourceId: user.userId.toString(),
                start: this.mapDateToLocal(timeRegistration.startTime),
                end: this.mapDateToLocal(timeRegistration.endTime),
                userid: user.userId.toString(),
                isRunning: timeRegistration.endLatitude == null && timeRegistration.endLongitude == null,
                title: timeRegistration.typeId == 1 ? this.getTourName(timeRegistration.tourName, timeRegistration.tourStatus) : timeRegistration.typeName, // tour related or rest
                color: timeRegistration.typeId == 1 && (timeRegistration.tourStatus == "InProgress" || timeRegistration.tourStatus == "Alarm" || timeRegistration.tourStatus == "OnHold" || timeRegistration.tourStatus == "Paused")
                  ? this.sharedService.getStatusColor(this.getLastFilteredNotUnknown(user.timeRegistrations).tourStatus)
                  : this.getTourColorFromType(timeRegistration.typeId, timeRegistration.tourStatus),
                // color: timeRegistration.typeId == 1 && (timeRegistration.tourStatus == "InProgress" || timeRegistration.tourStatus == "Alarm" || timeRegistration.tourStatus == "OnHold")
                //   ? timeRegistration.tourStatus == "OnHold" ? this.sharedService.getStatusColor("InProgress") : this.sharedService.getStatusColor(timeRegistration.tourStatus)
                //   : this.sharedService.getTypeColors(timeRegistration.typeId),
                classNames: ['clickable tour'],
                borderColor: timeRegistration.typeId == 1 && (timeRegistration.tourStatus == "InProgress" || timeRegistration.tourStatus == "Alarm" || timeRegistration.tourStatus == "OnHold" || timeRegistration.tourStatus == "Paused")
                  ? this.sharedService.getStatusColor(this.getLastFilteredNotUnknown(user.timeRegistrations).tourStatus)
                  : this.sharedService.getTypeColors(timeRegistration.typeId),
              })), ...user.userEvents.map((userEvent: any) => ({
                id: userEvent.id.toString(),
                resourceId: user.userId.toString(),
                start: this.mapDateToLocal(userEvent.startDate),
                end: this.mapDateToLocal(userEvent.endDate),
                title: this.language.user.event.types[userEvent.typeKey.toLowerCase()],
                color: this.sharedService.getStatusColor(userEvent.typeKey),
                classNames: ['unclickable']
              })), ...user.timeRegistrations.filter((t: any) => t.typeId != 17)
                .map((timeRegistration: any) => ({
                  id: timeRegistration.id.toString(),
                  resourceId: user.userId.toString(),
                  start: this.mapDateToLocal(timeRegistration.startTime),
                  end: this.mapDateToLocal(timeRegistration.endTime),
                  userid: user.userId.toString(),
                  isRunning: timeRegistration.endLatitude == null && timeRegistration.endLongitude == null,
                  title: '',
                  color: timeRegistration.typeId == 1 && (timeRegistration.tourStatus == "InProgress" || timeRegistration.tourStatus == "Alarm" || timeRegistration.tourStatus == "OnHold" || timeRegistration.tourStatus == "Paused")
                    ? this.sharedService.getStatusColor(this.getLastFilteredNotUnknown(user.timeRegistrations).tourStatus)
                    : this.sharedService.getTypeColors(timeRegistration.typeId),
                  //color: timeRegistration.tourStatus == "InProgress" ? this.sharedService.getBackgroundStatusColor(timeRegistration.tourStatus) : this.sharedService.getTypeColors(timeRegistration.typeId),
                  display: 'background',
                  tourId: timeRegistration.tourId,
                  classNames: ['unclickable timeregistration']
                })),
            ...this.mapTours(user.tours.filter((t: any) => !t.actualEndDate && !t.actualStartDate), user.userId.toString()),
            ...this.mapOpenTours(user.tours.filter((t: any) => !t.actualEndDate && t.actualStartDate && this.mapDateToLocal(t.expectedEndDate) > new Date()), user.userId.toString())
            ]
          };
          return resource;
        }))
    );
  }



  getTourName(tourName: string, tourStatus: string) {
    if (tourStatus != StatusType.WrongVehicle) return tourName;
    return "(" + this.language.status.wrongVehicle + ") " + tourName;
  }


  getTourColorFromType(typeId: number, tourStatus: string) {
    if (tourStatus != StatusType.WrongVehicle) return this.sharedService.getTypeColors(typeId);
    return this.sharedService.getStatusColor(tourStatus);
  }


  getLastFilteredNotUnknown(timeregs: any[]) {
    var regs = timeregs.filter((t: any) => t.typeId != 17)
    return regs[regs.length - 1];
  }


  getUsers(start: any, end: any): Observable<ResourceModel[]> {
    return this.http.get<UserModel[]>(`${url}/Users/With-Tours/${start}/${end}`).pipe(
      map((users: UserModel[]) => users.map((user: UserModel) => {
        const resource: ResourceModel = {
          id: user.id.toString(),
          title: user.firstname + ' ' + user.lastname,
          tourHours: user.sumOfWorkingHours.toString(),
          timeRegistrationHours: user.sumOfTimeRegistrations.toString(),
          restHours: user.restHours?.toString(),
          events: [...this.mapTours(user.tours, user.id.toString()), ...user.timeRegistrations
            .map((timeRegistration: UserTimeRegistrationModel) => ({
              id: timeRegistration.id.toString(),
              resourceId: user.id.toString(),
              start: timeRegistration.startTime,
              end: timeRegistration.endTime,
              userid: user.id.toString(),
              isRunning: timeRegistration.endLatitude == null && timeRegistration.endLongitude == null,
              title: '',
              color: '#49839c',
              display: 'background',
              classNames: ['unclickable timeregistration']
            })), ...user.jobs.map((job: JobModel) => ({
              id: job.id.toString(),
              resourceId: user.id.toString(),
              title: job.type === JobType.Wash ? this.language.job.type.wash : this.language.job.type.personalService,
              start: job.actualStartDate,
              end: job.actualEndDate ?? job.expectedEndDate,
              color: 'rgba(0, 0, 0, 0.5)',
              classNames: ['clickable jobregistration']
            })), ...user.events.map((userEvent: UserEventModel) => ({
              id: userEvent.id.toString(),
              resourceId: user.id.toString(),
              start: userEvent.startDate,
              end: userEvent.endDate,
              title: userEvent.title,
              color: this.sharedService.getStatusColor(userEvent.typeKey),
              classNames: ['unclickable']
            })), ...this.mapTourEvents(user.tours, user.id.toString())]
        };
        return resource;
      }))
    );
  }


  mapTourEvents(tours: TourSimpleModel[], resourceId: string): EventModel[] {
    let events: any[] = [];

    tours.forEach(tour => {
      events = [...events, ...tour.events.map((event: TourEventModel) => ({
        id: event.id.toString(),
        resourceId: resourceId,
        start: this.mapDateToLocal(event?.actualStartDate),
        end: this.mapDateToLocal(event?.actualEndDate),
        classNames: ['unclickable tourevent'],
        title: event?.description,
        color: 'rgba(0, 0, 0, 0.3)'
      }))];
    });
    return events;
  }


  // NOT STARTED TOURS
  mapTours(tours: TourSimpleModel[], resourceId: string): EventModel[] {
    var response = tours.map((tour: TourSimpleModel) => ({
      id: tour.id.toString(),
      resourceId: resourceId,
      title: tour?.description,
      start: tour?.actualStartDate != null ? this.mapDateToLocal(tour.actualStartDate) : this.mapDateToLocal(tour?.expectedStartDate),
      end: tour?.actualEndDate != null ? this.mapDateToLocal(tour.actualEndDate) : this.mapDateToLocal(tour?.expectedEndDate),
      //end: tour?.actualEndDate != null ? this.mapDateToLocal(tour.actualEndDate) : (this.mapDateToLocal(tour?.expectedEndDate) > new Date() ? this.mapDateToLocal(tour?.expectedEndDate) : new Date()),
      classNames: ['clickable tour'],
      color: this.sharedService.getStatusColor(tour.status)
    }));
    return response;
  }


  // OPEN TOURS WITH DRIVINGRECORDS
  mapOpenTours(tours: any[], resourceId: string): EventModel[] {
    return tours.map((tour: any) => ({
      id: tour.id.toString(),
      resourceId: resourceId,
      title: tour?.description,
      start: this.mapDateToLocal(new Date()),   //tour?.actualStartDate !== null ? this.mapDateToLocal(tour.actualStartDate) : this.mapDateToLocal(tour?.expectedStartDate),
      end: this.mapDateToLocal(tour?.expectedEndDate),
      classNames: ['clickable tour'],
      color: this.sharedService.getStatusColor(tour.status)
    }));
  }


  mapDateToLocal(date: Date): Date {
    return new Date(date);
    /// THIS NEEDS TO BE CORRECTED IN GLOBAL SETTING
    //return new Date(moment.utc(date).local().toString());
  }


  getVehicleDisplayText(vehicle: FrontVehicleModel): string {
    return this.frontVehicleService.GetDisplayText(vehicle);
  }
}
