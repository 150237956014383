import { Component, Input, OnInit, TemplateRef } from "@angular/core";
import * as moment from "moment";
import { UserService } from "../../users/shared/user.service";
import {
UserModel,
salaryWorkDaySumModel,
} from "../../users/shared/user.model";
import { TimeTableService } from "./shared/time-table.service";
import { TimeStatementModel } from "../time-statement/shared/time-statement.model";
import { faCheck, faSnowflake, faUserEdit } from "@fortawesome/free-solid-svg-icons";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { DateValidator } from "../../form-validators/date-validator";
import { TourType } from "../../constants";
import { SalaryFoundationRegistrationsModel } from "src/app/users/shared/salary-foundation-registrations.model";
import {
salaryWorkWeekSumModel,
UserEventModel
} from "src/app/users/shared/user.model";

import { NotificationService } from "../services/notification.service";
import { SharedService } from '../services/shared.service';
import { ColTotals, TableTotalsModel } from "../time-statement/shared/table-totals.model";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "app-time-table",
  templateUrl: "./time-table.component.html",
  styleUrls: ["./time-table.component.css"],
})
export class TimeTableComponent implements OnInit {
  @Input()
  user: UserModel | undefined;

  data: SalaryFoundationRegistrationsModel | undefined;
  dataExp: SalaryFoundationRegistrationsModel | undefined;
  salaryWorkWeeks: salaryWorkWeekSumModel[] = [];
  salaryWorkWeeksExp: salaryWorkWeekSumModel[] = [];
  activitiesData: UserEventModel[] = [];

  frozenWeeks: TableTotalsModel[] = [];

  language: any;

  //sumData: salaryWorkDaySumModel[] | undefined;

  sumData: [][] = [];

  inlandWeekNames: string[] = [];
  exportWeekNames: string[] = [];

  inlandWorkDaysMap = new Map<number, salaryWorkDaySumModel[]>();
  exportWorkDaysMap = new Map<number, salaryWorkDaySumModel[]>();

  columns: number[] = [];
  columnsExp: number[] = [];
  dataLoaded: boolean = false;

  notAbroadTableData: TimeStatementModel[] = [];
  abroadTableData: TimeStatementModel[] = [];

  loadingInland: boolean = false;
  loadingExport: boolean = false;
  faCheckIcon = faCheck;

  formGroup: UntypedFormGroup;

  frozen = faSnowflake;
  userPen = faUserEdit;

  csvFilename: string = "";
  modal?: NgbModalRef;
  constructor(
    private userService: UserService,
    private timetableService: TimeTableService,
    private formBuilder: UntypedFormBuilder,
    private notifyService: NotificationService,
    private sharedService: SharedService,
    private modalService: NgbModal
  ) {
    this.formGroup = this.formBuilder.group({
      fromDate: [
        moment().startOf("week").subtract(4, "weeks").format("YYYY-[W]W"),
        Validators.required,
      ],
      toDate: [
        moment().endOf("isoWeek").subtract(1, "week").format("YYYY-[W]W"),
        [Validators.required, DateValidator("fromDate")],
      ],
    })
  }


  async getLanguage() {
    this.sharedService.getLanguageJson().subscribe(response => this.language = response)
  }


  async ngOnInit(): Promise<void> {
    await this.getLanguage()

    this.initData();
  }

  get tourTypes(): typeof TourType {
    return TourType;
  }



  initData(): void {
    if (this.user) {
      this.getData()
      this.getFrozenData()

      let from = moment(this.formGroup.value.fromDate, "YYYY-[W]W").format("YYYY-MM-DD")
      let to = moment(this.formGroup.value.toDate, "YYYY-[W]W").endOf("isoWeek").format("YYYY-MM-DD")
      this.csvFilename = `export-${this.user?.firstname}${this.user?.lastname}-${from}-${to}.csv`
    }
  }


  getFrozenData(): void {
    this.frozenWeeks = []
    if (!this.user) return

    let fromDate = moment(this.formGroup.value.fromDate, "YYYY-[W]W").format("YYYY-MM-DD")
    let toDate = moment(this.formGroup.value.toDate, "YYYY-[W]W").endOf("isoWeek").format("YYYY-MM-DD")

    this.userService.GetFrozenWeekRange(this.user.id, fromDate, toDate).subscribe(x => {
      x.range.forEach(f => {
        let m = new TableTotalsModel()

        m.total = f.weekModel.total
        m.totalSubtractedBreaks = f.weekModel.totalSubtractedBreaks
        m.week = f.weekModel.week
        m.to = f.weekModel.toDate
        m.from = f.weekModel.fromDate
        m.year = f.weekModel.year
        m.revision = f.weekModel.revision
        m.isAbroad = f.weekModel.isAbroad
        m.mo = new ColTotals()
        m.tu = new ColTotals()
        m.we = new ColTotals()
        m.th = new ColTotals()
        m.fr = new ColTotals()
        m.sa = new ColTotals()
        m.su = new ColTotals()

        m.mo.ParseJson(f.weekModel.mondayJson)
        m.tu.ParseJson(f.weekModel.tuesdayJson)
        m.we.ParseJson(f.weekModel.wednesdayJson)
        m.th.ParseJson(f.weekModel.thursadayJson)
        m.fr.ParseJson(f.weekModel.fridayJson)
        m.sa.ParseJson(f.weekModel.saturdayJson)
        m.su.ParseJson(f.weekModel.sundayJson)


        f.transferedWeekModels.forEach(t => {
          let tmo = new ColTotals()
          let ttu = new ColTotals()
          let twe = new ColTotals()
          let tth = new ColTotals()
          let tfr = new ColTotals()
          let tsa = new ColTotals()
          let tsu = new ColTotals()

          tmo.ParseJson(t.mondayJson)
          ttu.ParseJson(t.tuesdayJson)
          twe.ParseJson(t.wednesdayJson)
          tth.ParseJson(t.thursadayJson)
          tfr.ParseJson(t.fridayJson)
          tsa.ParseJson(t.saturdayJson)
          tsu.ParseJson(t.sundayJson)

          m.mo.merge(tmo)
          m.tu.merge(ttu)
          m.we.merge(twe)
          m.th.merge(tth)
          m.fr.merge(tfr)
          m.sa.merge(tsa)
          m.su.merge(tsu)
        })

        this.frozenWeeks = [...this.frozenWeeks, m]
      })
    })
  }

  getData(): void {
    if (!this.user) return
    this.loadingExport = true
    this.loadingInland = true

    let inlandWeekNamesCurr: string[] = []
    let exportWeekNamesCurr: string[] = []


    let fromDate = moment(this.formGroup.value.fromDate, "YYYY-[W]W").startOf("isoWeek")
    let toDate = moment(this.formGroup.value.toDate, "YYYY-[W]W").endOf("isoWeek")

    this.salaryWorkWeeks = []
    this.salaryWorkWeeksExp = []
    this.activitiesData = []
    this.columns = []
    this.columnsExp = []
    //getUserWithTimeRegistrationsInWeeks
    this.userService
      .getUserWithTimeRegistrationsSalaryFoundation(
        this.user.id,
        fromDate.format("YYYY-MM-DD"),
        toDate.format("YYYY-MM-DD"),
        0
      )
      .subscribe(async (response) => {
        this.data = response;

        if (this.data.success) {
          if (this.data.userWorkWeeks.length > 0) {

            this.columns = [];
            this.data.userWorkWeeks.forEach(z => {
              z.salaryWorkWeeks.forEach(
                (x) => {
                  this.columns.push(x.week.weekNumber);
                }
              );
            });
          }
        }

        let weekSums: salaryWorkWeekSumModel[] = [];

        let _inlandWorkDaysMap = new Map<number, salaryWorkDaySumModel[]>();

        if (this.data.userWorkWeeks.length > 0) {
          this.data.userWorkWeeks.forEach(z => {
            z.salaryWorkWeeks.forEach(function (value) {
              let currWeekSum: salaryWorkDaySumModel[] = [];

              let inlandWorkDays: salaryWorkDaySumModel[] = [];
              let rest = 0;
              value.workDays.forEach(function (valueWD) {
                rest += valueWD.totalBreaktime;

                valueWD.workTimeTotals.map((v) => {
                  let currSum: salaryWorkDaySumModel = {
                    name: v.workTimeName,
                    sumHours: v.minutesSubtractedBreakTime,
                    colorCode: v.workTimeHexColor,
                    restTime: v.breakTime,
                    SumHoursOrig: v.minutes,
                    date: valueWD.date
                  };
                  inlandWorkDays.push(currSum);
                  const found = currWeekSum.find((obj) => {
                    return obj.name === v.workTimeName;
                  });

                  if (found) {
                    var foundIndex = currWeekSum.findIndex(
                      (x) => x.name == v.workTimeName
                    );
                    currWeekSum[foundIndex].sumHours =
                      currWeekSum[foundIndex].sumHours + v.minutesSubtractedBreakTime;
                    currWeekSum[foundIndex].restTime =
                      currWeekSum[foundIndex].restTime + v.breakTime;
                  } else {
                    if (!inlandWeekNamesCurr.includes(v.workTimeName))
                      inlandWeekNamesCurr.push(v.workTimeName);

                    currWeekSum.push(currSum);
                  }

                  return currSum;
                });
              });
              _inlandWorkDaysMap.set(value.week.weekNumber, inlandWorkDays);
              let currWeek: salaryWorkWeekSumModel = {
                weekNumber: value.week.weekNumber,
                workDaySum: currWeekSum.sort((a, b) => a.name.localeCompare(b.name)),
                weekKmDriven: value.kilometersDrivenThisWeek,
                weekKmDrivenExport: value.kilometerOnExportTours,
                weekKmDrivenInland: value.kilometerOnInlandTours,
                startDate: value.week.startDate,
                endDate: value.week.endDate
              };
              // ADD REST

              currWeek.workDaySum.push({
                name: 'OP',
                sumHours: rest,
                colorCode: '0xB3B3B3',
                restTime: 0,
                SumHoursOrig: 0,
                date: value.week.startDate
              })

              weekSums.push(currWeek);
            });

            z.transferedSalaryWorkWeeks.forEach(function (value) {
              let currWeekSum: salaryWorkDaySumModel[] = [];

              let inlandWorkDays: salaryWorkDaySumModel[] = [];
              let rest = 0;

              value.workDays.forEach(function (valueWD) {
                rest += valueWD.totalBreaktime;
                valueWD.workTimeTotals.map((v) => {
                  let currSum: salaryWorkDaySumModel = {
                    name: v.workTimeName,
                    sumHours: v.minutesSubtractedBreakTime,
                    colorCode: v.workTimeHexColor,
                    restTime: v.breakTime,
                    SumHoursOrig: v.minutes,
                    date: valueWD.date
                  };
                  inlandWorkDays.push(currSum);
                  const found = currWeekSum.find((obj) => {
                    return obj.name === v.workTimeName;
                  });

                  if (found) {
                    var foundIndex = currWeekSum.findIndex(
                      (x) => x.name == v.workTimeName
                    );
                    currWeekSum[foundIndex].sumHours =
                      currWeekSum[foundIndex].sumHours + v.minutesSubtractedBreakTime;
                    currWeekSum[foundIndex].restTime =
                      currWeekSum[foundIndex].restTime + v.breakTime;
                  } else {
                    if (!inlandWeekNamesCurr.includes(v.workTimeName))
                      inlandWeekNamesCurr.push(v.workTimeName);

                    currWeekSum.push(currSum);
                  }

                  return currSum;
                });
              });
              _inlandWorkDaysMap.set(value.week.weekNumber, inlandWorkDays);
              let currWeek: salaryWorkWeekSumModel = {
                weekNumber: value.week.weekNumber + 1,
                workDaySum: currWeekSum.sort((a, b) => a.name.localeCompare(b.name)),
                weekKmDriven: value.kilometersDrivenThisWeek,
                weekKmDrivenExport: value.kilometerOnExportTours,
                weekKmDrivenInland: value.kilometerOnInlandTours,
                startDate: value.week.startDate,
                endDate: value.week.endDate
              };
              // ADD REST
              currWeek.workDaySum.push({
                name: 'OP',
                sumHours: rest,
                colorCode: '0xB3B3B3',
                restTime: 0,
                SumHoursOrig: 0,
                date: value.week.startDate
              })

              weekSums.push(currWeek);
            });
          });
        }

        weekSums.sort((a, b) => a.weekNumber - b.weekNumber);
        this.inlandWeekNames = inlandWeekNamesCurr.sort((a, b) => a.localeCompare(b));
        this.inlandWeekNames.push('OP');
        this.salaryWorkWeeks = weekSums;
        this.inlandWorkDaysMap = _inlandWorkDaysMap;

        this.loadingInland = false;

        this.columns = this.mergeArray(this.columns, this.columnsExp);
        this.columns.sort();
      });

    ///////////////// Export
    this.userService
      .getUserWithTimeRegistrationsSalaryFoundation(
        this.user.id,
        fromDate.format("YYYY-MM-DD"),
        toDate.format("YYYY-MM-DD"),
        1
      )
      .subscribe(async (response) => {
        this.data = response;

        if (this.data.success) {
          if (this.data.userWorkWeeks.length > 0) {

            this.columnsExp = [];

            this.data.userWorkWeeks.forEach(z => {
              z.salaryWorkWeeks.forEach(
                (x) => {
                  this.columnsExp.push(x.week.weekNumber);
                }
              );
            });
          }
        }

        let weekSums: salaryWorkWeekSumModel[] = [];
        let _exportWorkDaysMap = new Map<number, salaryWorkDaySumModel[]>();

        if (this.data.userWorkWeeks.length > 0) {
          this.data.userWorkWeeks.forEach(z => {
            z.salaryWorkWeeks.forEach(function (value) {
              let currWeekSum: salaryWorkDaySumModel[] = [];

              let exportWorkDays: salaryWorkDaySumModel[] = [];
              value.workDays.forEach(function (valueWD) {
                valueWD.workTimeTotals.map((v) => {
                  let currSum: salaryWorkDaySumModel = {
                    name: v.workTimeName,
                    sumHours: v.minutesSubtractedBreakTime,
                    colorCode: v.workTimeHexColor,
                    restTime: v.breakTime,
                    SumHoursOrig: v.minutes,
                    date: valueWD.date
                  };
                  exportWorkDays.push(currSum);
                  const found = currWeekSum.find((obj) => {
                    return obj.name === v.workTimeName;
                  });

                  if (found) {
                    var foundIndex = currWeekSum.findIndex(
                      (x) => x.name == v.workTimeName
                    );
                    currWeekSum[foundIndex].sumHours =
                      currWeekSum[foundIndex].sumHours + v.minutesSubtractedBreakTime;

                  } else {
                    if (!exportWeekNamesCurr.includes(v.workTimeName))
                      exportWeekNamesCurr.push(v.workTimeName);

                    currWeekSum.push(currSum);
                  }

                  return currSum;
                });
              });

              _exportWorkDaysMap.set(value.week.weekNumber, exportWorkDays);
              let currWeek: salaryWorkWeekSumModel = {
                weekNumber: value.week.weekNumber,
                workDaySum: currWeekSum,
                weekKmDriven: value.kilometersDrivenThisWeek,
                weekKmDrivenExport: value.kilometerOnExportTours,
                weekKmDrivenInland: value.kilometerOnInlandTours,
                startDate: value.week.startDate,
                endDate: value.week.endDate
              };

              weekSums.push(currWeek);
            });


            z.transferedSalaryWorkWeeks.forEach(function (value) {
              let currWeekSum: salaryWorkDaySumModel[] = [];

              let exportWorkDays: salaryWorkDaySumModel[] = [];
              value.workDays.forEach(function (valueWD) {
                valueWD.workTimeTotals.map((v) => {
                  let currSum: salaryWorkDaySumModel = {
                    name: v.workTimeName,
                    sumHours: v.minutesSubtractedBreakTime,
                    colorCode: v.workTimeHexColor,
                    restTime: v.breakTime,
                    SumHoursOrig: v.minutes,
                    date: valueWD.date
                  };
                  exportWorkDays.push(currSum);
                  const found = currWeekSum.find((obj) => {
                    return obj.name === v.workTimeName;
                  });

                  if (found) {
                    var foundIndex = currWeekSum.findIndex(
                      (x) => x.name == v.workTimeName
                    );
                    currWeekSum[foundIndex].sumHours =
                      currWeekSum[foundIndex].sumHours + v.minutesSubtractedBreakTime;

                  } else {
                    if (!exportWeekNamesCurr.includes(v.workTimeName))
                      exportWeekNamesCurr.push(v.workTimeName);

                    currWeekSum.push(currSum);
                  }

                  return currSum;
                });
              });

              _exportWorkDaysMap.set(value.week.weekNumber, exportWorkDays);
              let currWeek: salaryWorkWeekSumModel = {
                weekNumber: value.week.weekNumber + 1,
                workDaySum: currWeekSum,
                weekKmDriven: value.kilometersDrivenThisWeek,
                weekKmDrivenExport: value.kilometerOnExportTours,
                weekKmDrivenInland: value.kilometerOnInlandTours,
                startDate: value.week.startDate,
                endDate: value.week.endDate
              };

              weekSums.push(currWeek);
            });

          });
        }




        this.exportWeekNames = exportWeekNamesCurr;
        this.salaryWorkWeeksExp = weekSums;
        this.exportWorkDaysMap = _exportWorkDaysMap;

        this.dataLoaded = true;
        this.loadingExport = false;

        this.columns = this.mergeArray(this.columns, this.columnsExp);

        this.columns.sort();
      });




    /// Activities!
    this.userService
      .getAllUsersWithActivities(
        this.user.id,
        fromDate.format("YYYY-MM-DD"),
        toDate.format("YYYY-MM-DD"),
      )
      .subscribe(async (response) => {
        response.forEach(value => {
          for (var i = 0; i < value.events.length; i++) {

            this.activitiesData.push(value.events[i]);

          }
        });
      });
  }

  mergeArray(arr1: number[], arr2: number[]) {
    const newArr: number[] = [...arr1];
    for (let i = 0; i < arr2.length; i++) {
      const item = arr2[i];
      if (newArr.includes(item)) continue;
      newArr.push(item);
    }
    return newArr;
  }


  exportWindow(editwindow: TemplateRef<any>) {
    this.modal = this.modalService.open(editwindow, { centered: true, size: 'lg' });
  }


  dismissExportWindow() {
    this.modal?.dismiss();
  }

  exportUserAgreedWeek(): void {
    if (!this.user) return;
    let from = moment(this.formGroup.value.fromDate, "YYYY-[W]W").format("YYYY-MM-DD");
    let to = moment(this.formGroup.value.toDate, "YYYY-[W]W").endOf("isoWeek").format("YYYY-MM-DD");

    this.userService.exportAgreedWeeks(this.user.id, from, to)
      .subscribe((event) => {
        var fileURL = URL.createObjectURL(event);
        var downloadLink = document.createElement("a");
        downloadLink.href = fileURL;
        if (!this.csvFilename.endsWith('.csv')) {
          this.csvFilename = this.csvFilename + '.csv';
        }
        downloadLink.download = this.csvFilename;

        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);


        this.modal?.dismiss();

      });
  }
  getRowColor(
    tableData: salaryWorkWeekSumModel[],
    workTimeName: string
  ): string {
    let colorCode = "#FFFFFF";

    tableData.forEach(function (value) {
      const list = value.workDaySum.filter((t) => t.name == workTimeName);

      list.forEach(function (valueL) {
        const colorCodeRead = valueL.colorCode.slice(-6);

        colorCode = "#" + colorCodeRead;
      }); // Refaktor
    });

    return colorCode;
  }


  getActHours(event: UserEventModel): number {
    let start = moment(event.startDate);
    let end = moment(event.endDate);
    let duration = moment.duration(end.diff(start));
    //
    return Math.round(duration.asHours());
  }

  roundTo(num: number, places: number) {
    const factor = 10 ** places;
    return Math.round(num * factor) / factor;
  };


  getWeekNumber(
    event: UserEventModel
  ): string {

    let startWeek = moment(event.startDate).isoWeek();
    let endWeek = moment(event.endDate).isoWeek();

    if (startWeek != endWeek) return startWeek + "-" + endWeek;
    return startWeek.toString();

  }

  weekIsFrozen(week: number, isAbroad: boolean): boolean {
    let frozenItem: TableTotalsModel | undefined = this.frozenWeeks.find(x => {
      return x.isAbroad === isAbroad && x.week === week;
    });

    if (frozenItem)
      return true;

    return false;
  }


  cellHasEdit(workTimeName: string, week: number, isAbroad: boolean): boolean {
    let frozenItem: TableTotalsModel | undefined = this.frozenWeeks.find(x => {
      return x.isAbroad === isAbroad && x.week === week;
    });
    if (frozenItem) {
      switch (workTimeName) {
        case "Normal":
          return frozenItem.sumNormalHasEdit();
        case "T1":
          return frozenItem.sumOvertime1HasEdit();
        case "T2":
          return frozenItem.sumOvertime2HasEdit();
        case "T3":
          return frozenItem.sumOvertime3HasEdit();
        case "T4":
          return frozenItem.sumOvertime4HasEdit();
      }
    }
    return false;
  }

  columnHasEdit(week: number, isAbroad: boolean): boolean {
    let frozenItem: TableTotalsModel | undefined = this.frozenWeeks.find(x => {
      return x.isAbroad === isAbroad && x.week === week;
    });

    if (!frozenItem)
      return false;

    return frozenItem.sumNormalHasEdit()
      || frozenItem.sumOvertime1HasEdit()
      || frozenItem.sumOvertime2HasEdit()
      || frozenItem.sumOvertime3HasEdit()
      || frozenItem.sumOvertime4HasEdit();
  }

  rowHasEdit(workTimeName: string, isAbroad: boolean): boolean {
    let result = false;
    this.frozenWeeks.filter(x => x.isAbroad === isAbroad).forEach(frozenItem => {
      if (workTimeName === "Normal" && frozenItem.sumNormalHasEdit()) {
        result = true;
      }
      if (workTimeName === "T1" && frozenItem.sumOvertime1HasEdit()) {
        result = true;
      }
      if (workTimeName === "T2" && frozenItem.sumOvertime2HasEdit()) {
        result = true;
      }
      if (workTimeName === "T3" && frozenItem.sumOvertime3HasEdit()) {
        result = true;
      }
      if (workTimeName === "T4" && frozenItem.sumOvertime4HasEdit()) {
        result = true;
      }
    });
    return result;
  }

  getRowValueSF(
    col: number,
    tableData: salaryWorkWeekSumModel[],
    workTimeName: string,
    isAbroad: boolean
  ): string {
    let frozenItem: TableTotalsModel | undefined = this.frozenWeeks.find(x => {
      return x.isAbroad === isAbroad && x.week === col;
    });

    if (frozenItem) {
      switch (workTimeName) {
        case "Normal":
          return frozenItem.sumNormalHours(true, true);
        case "T1":
          return frozenItem.sumOvertime1Hours(true, true);
        case "T2":
          return frozenItem.sumOvertime2Hours(true, true);
        case "T3":
          return frozenItem.sumOvertime3Hours(true, true);
        case "T4":
          return frozenItem.sumOvertime4Hours(true, true);
        case "OP":
          return frozenItem.sumTotalBreaks();
      }
    }

    const item: salaryWorkWeekSumModel | undefined = tableData.find(
      (t) => t.weekNumber === col && t.workDaySum.find(x => x.name === workTimeName)
    );

    if (!item) return "0";

    const itemWD: salaryWorkDaySumModel | undefined = item.workDaySum.find(
      (t) => t.name === workTimeName
    );

    if (!itemWD) return "0";

    return this.toHours(itemWD.sumHours);
  }

  getRowSumSF(
    tableData: salaryWorkWeekSumModel[],
    workTimeName: string,
    isAbroad: boolean
  ): string {

    let currSum = 0;
    let frozenWeeks = this.frozenWeeks.filter(f => f.isAbroad === isAbroad);
    let frozenWeekNumbers = frozenWeeks.map(c => c.week);

    if (workTimeName === "OP") {
      tableData.filter(x => !frozenWeekNumbers.includes(x.weekNumber)).forEach(function (value) {
        const list = value.workDaySum.filter((t) => t.name == workTimeName);
        list.forEach(function (valueL) {
          currSum = currSum + valueL.sumHours;
        }); // Refaktor
      });
    }
    else {
      tableData.filter(x => !frozenWeekNumbers.includes(x.weekNumber)).forEach(function (value) {
        const list = value.workDaySum.filter((t) => t.name == workTimeName);
        list.forEach(function (valueL) {
          currSum = currSum + valueL.sumHours;
        }); // Refaktor
      });
    }

    frozenWeeks.forEach(z => {
      switch (workTimeName) {
        case "Normal":
          currSum = currSum + z.calculateNormal(true, true);
          return;
        case "T1":
          currSum = currSum + z.calculateOvertime1(true, true);
          return;
        case "T2":
          currSum = currSum + z.calculateOvertime2(true, true);
          return;
        case "T3":
          currSum = currSum + z.calculateOvertime3(true, true);
          return;
        case "T4":
          currSum = currSum + z.calculateOvertime4(true, true);
          return;
        case "OP":
          currSum = currSum + z.calculateTotalBreaks();
          return;
      }
    });

    return this.toHours(currSum);
  }

  getColSumSF(tableData: salaryWorkWeekSumModel[], weekNumber: number, isAbroad: boolean): string {

    let currSum = 0;

    let frozenItem: TableTotalsModel | undefined = this.frozenWeeks.find(x => {
      return x.isAbroad === isAbroad && x.week === weekNumber;
    });

    if (frozenItem) {
      return frozenItem.sumTotalHours(true, true);
    }
    else {
      const list = tableData.filter((t) => t.weekNumber == weekNumber);

      list.forEach(function (value) {
        value.workDaySum.filter((e => e.name != 'OP')).forEach(function (valueL) {
          currSum = currSum + valueL.sumHours;
        }); // Refaktor
      }); // Refaktor
    }


    return this.toHours(currSum);
  }

  getWeeklyKm(
    week: number
  ): number {
    let currKm = 0;

    let frozenItemExp: TableTotalsModel | undefined = this.frozenWeeks.find(x => {
      return x.isAbroad === true && x.week === week;
    });
    if (frozenItemExp) {
      currKm = currKm + frozenItemExp.sumKmExport();
    }
    else {
      this.salaryWorkWeeksExp.filter((t) => t.weekNumber == week).forEach(function (value) {
        currKm = currKm + value.weekKmDrivenExport;
      });
    }

    let frozenItem: TableTotalsModel | undefined = this.frozenWeeks.find(x => {
      return x.isAbroad === false && x.week === week;
    });
    if (frozenItem) {
      currKm = currKm + frozenItem.sumKmInland();
    }
    else {
      this.salaryWorkWeeks.filter((t) => t.weekNumber == week).forEach(function (value) {
        currKm = currKm + value.weekKmDrivenInland;
      });
    }

    return this.roundTo(currKm / 1000, 2);
  }

  getWeeklyKmExport(week: number): number {
    let frozenItem: TableTotalsModel | undefined = this.frozenWeeks.find(x => {
      return x.isAbroad === true && x.week === week;
    });

    if (frozenItem) {
      return this.roundTo(frozenItem.sumKmExport() / 1000, 2);
    }


    let currKm = 0;
    const list = this.salaryWorkWeeksExp.filter((t) => t.weekNumber == week);
    list.forEach(function (value) {
      currKm = currKm + value.weekKmDrivenExport;
    });

    return this.roundTo(currKm / 1000, 2);
  }

  getWeeklyKmInland(week: number): number {
    let frozenItem: TableTotalsModel | undefined = this.frozenWeeks.find(x => {
      return x.isAbroad === false && x.week === week;
    });

    if (frozenItem) {
      return this.roundTo(frozenItem.sumKmInland() / 1000, 2);
    }

    let currKm = 0;
    const list = this.salaryWorkWeeks.filter((t) => t.weekNumber == week);
    list.forEach(function (value) {
      //currKm = currKm + value.weekKmDrivenExport;
      currKm = currKm + value.weekKmDrivenInland;
    });

    return this.roundTo(currKm / 1000, 2);
  }

  /*
  getSpecialDriven(col?: number): string {
    if (col)
    {
      const week : WeekTimeRegistrationsModel | undefined = this.data?.find(t => t.weekNumber == col);
      return week ? this.toHours(week.specialDrivenInMinutes) : '';
    }
    else{
      const sum = this.data?.map(t => t.specialDrivenInMinutes).reduce((partialSum, a) => partialSum + a, 0);
      return sum? this.toHours(sum) : '-';
    }
  }*/
  /*
  getKilometersDriven(col?: number): string {
    if (col)
    {
      const week : WeekTimeRegistrationsModel | undefined = this.data?.find(t => t.weekNumber == col);
      return week ? week.kilometerDriven?.toString() : '';
    }
    else{
      const sum = this.data?.map(t => t.kilometerDriven).reduce((partialSum, a) => partialSum + a, 0);
      return sum? sum.toString() : '-';
    }
  }
*/
  getTotalSums(includeBreaks: boolean, type: TourType, col?: number): string {
    if (col) {
      const list =
        type === TourType.Inland
          ? this.notAbroadTableData.filter((t) => t.week == col)
          : this.abroadTableData.filter((t) => t.week == col);
      return this.toHours(
        list
          .map((t) => t.totalIncludingBreaksInMinutes)
          .reduce((partialSum, a) => partialSum + a, 0)
      );
    } else {
      const list =
        type === TourType.Inland
          ? this.notAbroadTableData
          : this.abroadTableData;
      return includeBreaks
        ? this.toHours(
          list
            .map((t) => t.totalIncludingBreaksInMinutes)
            .reduce((partialSum, a) => partialSum + a, 0)
        )
        : this.toHours(
          list
            .map((t) => t.totalIncludingBreaksInMinutes)
            .reduce((partialSum, a) => partialSum + a, 0) -
          list
            .map((t) => t.totalBreakTimeInMinutes)
            .reduce((partialSum, a) => partialSum + a, 0)
        );
    }
  }

  get timeTypes(): typeof TimeType {
    return TimeType;
  }

  toHours(minutes: number | undefined): string {
    if (!minutes) return "0";

    const value = minutes / 60;
    //const formattedValue = this.roundTo(value * 10,2) / 10;
    const formattedValue = this.roundTo(value, 2);

    return formattedValue.toString().replace(".", ",");
  }
}

enum TimeType {
  NormalTime,
  OverTime1,
  Overtime2,
  Rest,
}
