import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UsersRoutingModule } from './users-routing.module';
import { UsersComponent } from './list/users.component';
import {SharedModule} from "../shared/shared.module";
import { UserDetailComponent } from './user-detail/user-detail.component';
import {NgbModule} from "@ng-bootstrap/ng-bootstrap";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {FontAwesomeModule} from "@fortawesome/angular-fontawesome";
import {ToursModule} from "../tours/tours.module";
import { JobDetailComponent } from '../jobs/job-detail/job-detail.component';
import { NgxSliderModule } from '@angular-slider/ngx-slider';



@NgModule({
  declarations: [
    UsersComponent,
    UserDetailComponent,
    JobDetailComponent
  ],
    imports: [
        CommonModule,
        UsersRoutingModule,
        SharedModule,
        NgbModule,
        FormsModule,
        ReactiveFormsModule,
        FontAwesomeModule,
        ToursModule,
        NgxSliderModule,

    ],
    exports: [
      UserDetailComponent

    ]
})
export class UsersModule { }
