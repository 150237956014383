<ng-template #content let-modal>
  <div class="modal-header">
    <h6 class="modal-title" id="modal-basic-title">{{'tour.create.user-select.title' | translate}}</h6>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
  </div>
  <div class="modal-body">
    <p>{{'tour.create.user-select.description' | translate}}</p>
      <input class="form-control form-control-sm full-width" style="margin-bottom: 5px" id="search"
           placeholder="{{'tour.create.user-select.search' | translate}}" [ngModel]="searchQuery" (ngModelChange)="search($event)" >
    <div *ngIf="formGroup">
    <form [formGroup]="formGroup" *ngIf="dataCopy">
        <ul id="assignedUserId" type="text" class="list-group">
          <button class="list-group-item list-group-item-action clickable" style="padding: 0.2rem 1rem!important;"
                  *ngFor="let user of dataCopy" (click)="submit(user, $event)">
            ID: {{user.id}} - {{'tour.create.user-select.name' | translate}}: {{user.firstname}} {{user.lastname}}
            <div *ngIf="user.tours.length > 0"><button class="btn text-warning btn-link" id="conflict-btn" (click)="openConflictsModal(user, content_conflict)">{{'tour.create.user-select.conflicts' | translate:{value: user.tours.length} }}</button></div>
            <div *ngIf="user.events.length > 0"><button class="btn text-warning btn-link" id="conflict-btn" (click)="openConflictsModal(user, content_event_conflict)">{{'tour.create.user-select.eventconflicts' | translate:{value: user.events.length} }}</button></div>
          </button>
        </ul>
    </form>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-default" (click)="modal.close()">{{'tour.create.user-select.cancel-btn' | translate}}</button>
  </div>
</ng-template>

<ng-template #content_event_conflict let-modal>
  <div class="modal-header">
    <h6 class="modal-title">{{'tour.create.user-select.eventconflicts' | translate:{value: eventConflicts.length} }}</h6>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
  </div>
  <div class="modal-body">
    <div *ngFor="let conflict of eventConflicts">
      <div class="alert alert-warning full-width">{{conflict.startDate | date: 'yyyy-MM-dd HH:mm'}} - {{conflict.endDate | date: 'yyyy-MM-dd HH:mm'}} • <span style="font-weight: bold">{{conflict.description}}</span></div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-default" (click)="modal.close()">{{'tour.create.user-select.okayBtn' | translate}}</button>
  </div>
</ng-template>

<ng-template #content_conflict let-modal>
  <div class="modal-header">
    <h6 class="modal-title">{{'tour.create.user-select.conflicts' | translate:{value: conflicts.length} }}</h6>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
  </div>
  <div class="modal-body">
    <div *ngFor="let conflict of conflicts">
      <div class="alert alert-warning full-width">{{conflict.expectedStartDate | date: 'yyyy-MM-dd HH:mm'}} - {{conflict.expectedEndDate | date: 'yyyy-MM-dd HH:mm'}} • <span style="font-weight: bold">{{conflict.description}}</span></div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-default" (click)="modal.close()">{{'tour.create.user-select.okayBtn' | translate}}</button>
  </div>
</ng-template>

<button hidden (click)="openModal(content)" id="btn"></button>
