import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrl: './modal.component.scss'
})
export class ModalComponent {
  @Input() title: string = 'Default Title'
  @Input() confirmButton: boolean = true
  @Input() cancelButton: boolean = true

  @Output() confirmed = new EventEmitter()
  @Output() cancelled = new EventEmitter()

  isVisible: boolean = false

  open() {
    this.isVisible = true
  }

  close() {
    this.isVisible = false
  }

  confirm() {
    this.confirmed.emit()
    this.isVisible = true
  }

  cancel() {
    this.cancelled.emit()
    this.isVisible = false
  }
}
