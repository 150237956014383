<ng-template #editcontent let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">{{'user.editTimeRegistration' | translate }}</h4>
    <div class="btn-group float-end" role="group">
      <div class="custom-loader" *ngIf="loading" style="margin-top: 11px;"></div>
      <button type="button" [disabled]="editTimeRegistrationFormGroup.controls['dateend'].invalid" *ngIf="showAcceptBtn == true" class="m-2 btnCheck" (click)="splitTimeReg()"></button>
      <button type="button" class=" btnClose m-2 " (click)="modal.dismiss('Cross click'); showAcceptBtn = true; loading = false;"></button>
    </div>
  </div>
  <div class="modal-body">
    <p>{{'user.endAndStartValuesTimeReg' | translate}}. {{'user.changesLogged' | translate}}.</p>
    <form [formGroup]="editTimeRegistrationFormGroup" class="col-md-12">

      <div class="form-group">
        <table style="width: 100%; " class="sliderTable">
          <tbody>
            <tr>
              <td [ngStyle]="{'background-color': currentTourModalTableColor, 'width': currentTourModalTableWidth}">{{tourModalTdTitle1}}</td>
              <td [ngStyle]="{'background-color': newTourModalTableColor, 'width': newTourModalTableWidth}">{{tourModalTdTitle2}}</td>
            </tr>
          </tbody>
        </table>

        <div class="custom-slider sliderDiv" [ngStyle]="{'background-color': sliderTableBackground, 'background': sliderTableBackgroundGradiant}">
          <ngx-slider class="custom-slider" [(value)]="minValueSlide" [options]="optionsSlide"></ngx-slider>
        </div>
      </div>

      <div class="row">
        <div class="form-group col-md-6">
          <label for="currentTour">
            {{'user.assignmentFill' | translate}}
            <span *ngIf="editTimeRegistrationFormGroup.controls['currentTour'].hasError('required')" class="required-text">
              {{'message.required' | translate}}
            </span>
          </label>
          <select id="currentTour" [(ngModel)]="selectedCurrentTour" type="text" formControlName="currentTour" class="form-select form-select-sm" (change)="onCurrentTourChange($event)">
            <option *ngFor="let tour of availableAssignments" [value]="tour.id"> {{tour.name}}</option>
          </select>

          <br>

          <label for="currentTourTypeId">{{'user.assignmentFillTypeId' | translate}}
            <span *ngIf="editTimeRegistrationFormGroup.controls['currentTourTypeId'].hasError('required')" class="required-text">
              {{'message.required' | translate}}
            </span>
          </label>
          <select id="currentTourTypeId" [(ngModel)]="selectedCurrentTourTypeId" type="text" formControlName="currentTourTypeId" class="form-select form-select-sm" (change)="onCurrentTourTypeIdChange($event)">
            <option *ngFor="let typeId of availableTimeregTypes" [value]="typeId.id"> {{typeId.name}}</option>
          </select>
        </div>

        <div class="form-group col-md-6">
          <label for="availTour">
            {{'user.assignmentFill' | translate}}
            <span *ngIf="editTimeRegistrationFormGroup.controls['availTour'].hasError('required')" class="required-text">
              {{'message.required' | translate}}
            </span>
          </label>
          <select id="availTour" [(ngModel)]="selectedAvailTour" type="text" formControlName="availTour" class="form-select form-select-sm" (change)="onNewTourChange($event)">
            <option *ngFor="let tour of availableAssignments" [value]="tour.id"> {{tour.name}}</option>
          </select>

          <br>

          <label for="availTourTypeId">{{'user.assignmentFillTypeId' | translate}}
            <span *ngIf="editTimeRegistrationFormGroup.controls['availTourTypeId'].hasError('required')" class="required-text">
              {{'message.required' | translate}}
            </span>
          </label>
          <select id="availTourTypeId" [(ngModel)]="selectedAvailTourTypeId" type="text" formControlName="availTourTypeId" class="form-select form-select-sm" (change)="onNewTourTypeIdChange($event)">
            <option *ngFor="let typeId of availableTimeregTypes" [value]="typeId.id"> {{typeId.name}}</option>
          </select>
        </div>
      </div>
      <div class="form-group">
        <label for="note">{{'user.note'| translate}}</label><span *ngIf="editTimeRegistrationFormGroup.controls['note'].hasError('required')" class="required-text">
          {{'message.required' | translate}}
        </span>
        <div class="input-group">
          <input id="note" type="text" formControlName="note" placeholder="{{'user.noteText'| translate}}" class="form-control form-control-sm">
        </div>
      </div>
    </form>
  </div>
</ng-template>

<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">{{'user.showTimeRegistration' | translate}}</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
  </div>

  <div class="modal-body">
    <p>{{'user.fromTooValueShowTimeRegistration' | translate}}</p>

    <form [formGroup]="activitiesFormGroup" class="col-md-12">
      <div class="form-group">
        <div>
          <label for="user">{{'user.pickUser' | translate }}</label>

          <select #User id="user" type="text" formControlName="user" class="form-select">
            <option id="selectedValue" *ngFor="let user of users;" [ngValue]="user.id">{{user.firstname + " " + user.lastname}}</option>
          </select>
        </div>

        <label for="date">{{'common.from' | translate}}</label>

        <div class="input-group">
          <input #DateStart id="datestart" class="form-control" formControlName="datestart" type="date">
        </div>
      </div>
      <div class="form-group">
        <label for="date">{{'common.too' | translate}}</label>

        <div class="input-group">
          <input #DateEnd id="dateend" class="form-control" formControlName="dateend" type="date" [max]="today()">
        </div>

        <span *ngIf="activitiesFormGroup.controls['dateend'].hasError('mustBeLater')" class="required-text">{{'user.tooDateSameOrLater' | translate}}</span>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" [disabled]="activitiesFormGroup.controls['dateend'].invalid" class="btn btn-outline-default" (click)="modal.close({startDate: DateStart.value, dateEnd: DateEnd.value, user: User.value})">{{'user.search' | translate}}</button>
    <button type="button" class="btn btn-outline-default" (click)="modal.dismiss('Cross click')">{{'common.close' | translate}}</button>
  </div>
</ng-template>

<div *ngIf="users">
  <app-table
    *ngIf="users.length > 0"
    [modal]="content"
    [data]="tableData"
    [addNewOption]="false"
    [bookmarkOption]="true"
    [showCalenderView]="true"
    [multipleSelection]="true"
    [completedViewOption]="true"
    [calendarViewOption]="calenderView.resource"
    [groupsOptions]="this.groups"
    [groupKey]="'Users'"
    [showGroupButton]="true"
    (selectedSubItem)="selectedSubItemId = $event.id"
    (createTourForItem)="createTour($event)"
    (eventClicked)="showEditTimeRegistrationModal(editcontent,$event)"
    (selectedJobItem)="showJobItemDetails($event)"
    (selectedItem)="showItemDetails($event)"
    (requestData)="requestedRefreshedData($event)"
    (addItem)="addNewCustomer = true"
    (addSelectedToGroupEmit)="addSelectedToGroup($event, 'Users')"
    [showTemporaryAgToggle]="true"
    (temporaryToggleChange)="onTemporaryAgToggle($event)"
    (bubbleSaveEvent)="saveMainGroup($event)"
  ></app-table>

  <app-detail *ngIf="showDetails && selectedItemId > 0" class="overlay" (close)="closeItemDetails()">
    <app-user-detail [id]="selectedItemId" #widget [active]="true" (updateList)="getAll()" (close)="showDetails = false"></app-user-detail>
  </app-detail>

  <app-detail
    *ngIf="showTourDetails"
    class="overlay"
    (close)="closeTourItemDetails()"
  >
    <app-tour-detail [id]="tourId" #widget (updateList)="getAll()" (close)="showTourDetails = false"></app-tour-detail>
  </app-detail>

  <div class="col-md-12 center" *ngIf="users.length === 0">
    <h6>{{'user.createFirstUser' | translate }}</h6>
  </div>
</div>

<app-loading *ngIf="!users"></app-loading>

<app-detail *ngIf="selectedSubItemId > 0" class="overlay" (close)="selectedSubItemId = 0">
  <app-tour-detail [id]="selectedSubItemId" #widget [active]="true" (close)="selectedSubItemId = 0"></app-tour-detail>
</app-detail>

<app-detail *ngIf="createNewTour" class="overlay" (close)="createNewTour = undefined" [closeByBackdrop]="false">
  <app-tour-create
    #widget
    (close)="createNewTour = undefined"
    (cancel)="createNewTour = undefined"
    (created)="createNewTour = undefined; getAll()"
    [startDate]="createNewTour.event.startDate"
    [selectedUser]="createNewTour.user"
  ></app-tour-create>
</app-detail>

<app-detail *ngIf="showJobDetails && selectedJobItemId > 0" class="overlay" (close)="closeJobItemDetails()">
  <app-job-detail [id]="selectedJobItemId" #widget [active]="true" (close)="showJobDetails = false"></app-job-detail>
</app-detail>
