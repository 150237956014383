import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from "../../environments/environment";
import {Observable} from "rxjs";
import {JobModel, UpdateJobModel} from "./job.model";

const url = environment.baseUrl + '/V1/Jobs'

@Injectable({
  providedIn: 'root'
})
export class JobService {

  constructor(private http: HttpClient) { }

  getSingle(id: number): Observable<JobModel> {
    return this.http.get<JobModel>(`${url}/${id}`);
  }

  getAll(): Observable<JobModel[]> {
    return this.http.get<JobModel[]>(`${url}/Active`);
  }

  update(id: number, model: UpdateJobModel): Observable<JobModel>{
    return this.http.put<JobModel>(`${url}/${id}`, model);
  }

  cancel(id: number, model: any): Observable<JobModel>
  {
    return this.http.patch<JobModel>(`${url}/${id}/Cancel`, model);
  }
}
