import { Injectable } from '@angular/core';
import {environment} from "../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {BackVehicleModel, CreateBackVehicleEventModel, CreateBackVehicleModel, UpdateBackVehicleEventModel, UpdateBackVehicleModel} from "./back-vehicle.model";
import {NoteModel} from "../../shared/note-widget/shared/note.model";
import * as internal from 'stream';
import { AttachmentCreateModel } from 'src/app/shared/attachment-widget/shared/attachment.model';
import { DataExchangeModel } from 'src/app/shared/fileuploader/shared/data-exchange.model';

const url = environment.baseUrl + '/V1/BackVehicles'

@Injectable({
  providedIn: 'root'
})

export class BackVehicleService {
  constructor(private http: HttpClient) { }

  getAll(): Observable<BackVehicleModel[]>{
    return this.http.get<BackVehicleModel[]>(`${url}/All`);
  }

  getSingle(id: number): Observable<BackVehicleModel>{
    return this.http.get<BackVehicleModel>(`${url}/${id}`);
  }

  create(model: CreateBackVehicleModel): Observable<BackVehicleModel>{
    return this.http.post<BackVehicleModel>(url, model);
  }

  delete(id: number): Observable<any> {
    return this.http.delete(`${url}/${id}`);
  }

  update(id: number, model: UpdateBackVehicleModel): Observable<BackVehicleModel>{
    return this.http.put<BackVehicleModel>(`${url}/${id}`, model);
  }

  getAttachments(backVehicleId: number): Observable<BackVehicleModel>{
    return this.http.get<BackVehicleModel>(`${url}/${backVehicleId}/Attachments`)
  }

  createAttachment(backVehicleId: number, model: AttachmentCreateModel):Observable<any>{
    const formData: FormData = new FormData();
    formData.append('file', model.file);
    formData.append('showInApp', model.showInApp.toString());
    return this.http.post<BackVehicleModel>(`${url}/${backVehicleId}/Attachment`, formData);
  }

  deleteAttachment(backVehicleId: number, attachmentId: number): Observable<BackVehicleModel>{
    return this.http.delete<BackVehicleModel>(`${url}/${backVehicleId}/Attachment/${attachmentId}`);
  }

  getNotes(backVehicleId: number): Observable<BackVehicleModel>{
    return this.http.get<BackVehicleModel>(`${url}/${backVehicleId}/Notes`);
  }

  deleteNote(backVehicleId: number, noteId: number): Observable<BackVehicleModel>{
    return this.http.delete<BackVehicleModel>(`${url}/${backVehicleId}/Note/${noteId}`);
  }

  createNote(backVehicleId: number, model: NoteModel): Observable<BackVehicleModel> {
    return this.http.post<BackVehicleModel>(`${url}/${backVehicleId}/Note`, model);
  }

  createBackVehicleEvent(backVehicleId: number, model: CreateBackVehicleEventModel): Observable<any> {
    return this.http.post<any>(`${url}/${backVehicleId}/Events`, model);
  }

  updateBackVehicleEvent(backVehicleId: number,model: UpdateBackVehicleEventModel): Observable<any> {
    return this.http.put<any>(`${url}/${backVehicleId}/Events/${model.id}`, model);
  }

  getSingleWithMeta(id: number): Observable<any>{
    return this.http.get<any>(`${url}/${id}/Events`);
  }

  getImportSchemas(){
    return this.http.get<DataExchangeModel[]>(`${url}/GetDataExchangeImportSchemas`);
  }

  getBox(boxId: number): Observable<any> {
    return this.http.get<any>(`${environment.baseUrl}/V1/Box/${boxId}`);
  }

  // MISSING IN API
  updatedBox(boxId: number, backVehicleId: number) {
    return this.http.put<any>(`${url}/${backVehicleId}/Box/`, boxId);
  }

  processImportFile(file: File, schemaid: number, customerid: number) :Observable<any> {
    const formData: FormData = new FormData();
    formData.append('file', file);
    formData.append('dataExchangeSchemaId', schemaid.toString());
    return this.http.post(`${url}/ImportDataExchange`, formData);
  }
}
