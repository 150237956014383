import { Component, EventEmitter, Input, OnChanges, OnInit, Output, PipeTransform, SimpleChanges } from '@angular/core'
import { FormControl } from '@angular/forms'
import { faSortUp, faSortDown, faSort, faTrash, faTruck, faTruckPickup, faCarSide, faQuestion, IconDefinition, faCheck } from '@fortawesome/free-solid-svg-icons'
import { Observable } from 'rxjs'
import { map, startWith, filter } from 'rxjs/operators'
import { MainGroup } from 'src/app/grouping/grouping.models'
import { DropdownButtonComponent } from '../dropdown-button/dropdown-button.component'
import { SharedService } from 'src/app/shared/services/shared.service'
import { TranslateService } from '@ngx-translate/core'

function compare(v1: string, v2: string) {
  return v1 < v2 ? -1 : v1 > v2 ? 1 : 0;
}

type SortDirection = 'desc' | 'asc' | '';
interface SortEvent {
  column: {
    key: string,
    displayName: string,
    displaySmallTable: boolean
  },
  direction: SortDirection
}

interface Option {
  key: string
  displayName: string
  displaySmallTable: boolean
  width?: string
}




@Component({
  selector: 'app-reusable-table',
  templateUrl: './reusable-table.component.html',
  styleUrl: './reusable-table.component.scss'
})
export class ReusableTableComponent implements OnInit, OnChanges {
  @Input() tableTitle: string | undefined = ''
  @Input() filterString: string = ''
  @Input() filterKey: Option[] = []
  @Input() columns: any[] | undefined = []
  @Input() data: any[] | undefined = [] // type is any because each item is a member om Subgroup, and each member could be anything
  @Input() dropdownOptions: MainGroup[] = []
  @Input() subgroupKey: string = ''
  @Input() displaySmallTable: boolean = false
  @Input() totalCount: number = 0

  @Output() removeMembersFromSubgroupEvent: EventEmitter<any> = new EventEmitter<any>()  // not sure what type it is
  @Output() addItemToEmitListEvent: EventEmitter<any> = new EventEmitter<any>() // not sure what type it is

  language: any;
  faSortUp: IconDefinition = faSortUp
  faSortDown: IconDefinition = faSortDown
  faSort: IconDefinition = faSort
  faTrash: IconDefinition = faTrash
  faTruck: IconDefinition = faTruck
  faTruckPickup: IconDefinition = faTruckPickup
  faCarSide: IconDefinition = faCarSide
  faQuestion: IconDefinition = faQuestion
  faCheck: IconDefinition = faCheck

  localData: any[] | undefined = []  // type is any because each item is a member om Subgroup, and each member could be anything
  localColumns: Option[] | undefined = []  // not sure what type it is
  selectedAll: boolean = false
  sortedColumn: SortEvent | undefined
  selectionLength: number | undefined = 0


  constructor(private sharedService: SharedService, private translate: TranslateService) {

  }


  async ngOnInit(): Promise<void> {

    this.assignData()
    this.setColumns(this.displaySmallTable)
  }

  // When the data to the component is updated i.e. after the user clicks a maingroup card
  ngOnChanges(changes: SimpleChanges) {

    this.assignData()
    this.setColumns(this.displaySmallTable)

    if (changes.filterString) {
      this.localData = this.returnSearchedItems(changes.filterString.currentValue, this.filterKey)
    }
  }


  /**
   * Set the columns to be displayed in the table, based on the displaySmallTable property in the confgi data
   * @param displaySmallTable - The input parameter of the component
   */
  setColumns(displaySmallTable: boolean) {
    if (displaySmallTable && this.columns) {
      this.localColumns = this.columns.filter(x => x.displaySmallTable === true)
    } else {
      this.localColumns = this.columns
    }
  }


  /**
   * Returns the items that are being searched for in the table
   * @param text - The user-inputted text
   * @param restrictSearch - The key(s) to search within
   * @returns
   */
  returnSearchedItems(text: string, restrictSearch: Option[]) {
    const term = text.toLowerCase();

    // Define the keys you want to include in the search
    let searchableKeys: string[] = [];

    if (restrictSearch.length >= 1) {
      restrictSearch.forEach((key) => {
        searchableKeys.push(key.key)
      })
    }

    if (restrictSearch.length <= 0 && this.columns) {
      Object.values(this.columns).forEach((column) => {
        searchableKeys.push(column.key)
      })
    }

    return this.localData?.filter((data) => {
      // Only search within the values of the specified keys
      return searchableKeys.some(key =>{
        if (key == 'fullname') {
          return this.returnFullname(data).toLowerCase().includes(term)
        }
        return data[key] && String(data[key]).toLowerCase().includes(term)
      });
    });
  }


  /**
   * Assign the data to the localData variable and adding a 'selected' property to each item
   */
  assignData() {
    this.localData = this.data?.map((x) => ({ ...x, selected: false, isAssignedGroup: x.isAssignedGroup }))
    this.selectionLength = this.localData?.filter(x => x.selected === true).length
  }


  /**
   * Selects all the items in the table
   */
  selectAll(): void {
    this.localData?.forEach(item => item.selected = this.selectedAll);
    this.selectionLength = this.localData?.filter(x => x.selected === true).length
  }


  /**
   * Sort the columns in the table
   * @param param
   * @returns
   */
  onSort({ column, direction }: any) {
    if (direction === '') {
      this.sortedColumn = undefined;
      this.localData = Object.assign([], this.localData?.filter(x => this.localData?.find(c => c.id === x.id)))
      return;
    }

    this.sortedColumn = { column: column, direction: direction };
    this.localData = this.localData?.sort((a, b) => {
      if (!a[column]) {
        a[column] = '';
      }
      if (!b[column]) {
        b[column] = '';
      }
      const res = isNaN(Number(a[column])) || isNaN(Number(b[column])) ? compare(a[column], b[column]) : a[column] - (b[column]);
      return direction === 'desc' ? res : -res;
    });
  }

  /**
   * Emit the selected members in the subgroup and reassign data to "update" the component data
   */
  emitRemoveMembersFromSubgroup() {
    const selectedItems = this.localData?.filter(x => x.selected === true)
    this.removeMembersFromSubgroupEvent.emit({ items: selectedItems, key: this.subgroupKey })

    this.assignData()
  }


  /**
   * Used for adding the selected item to an array, before emitting it to the parent
   * @param {any} items - Any because the type can be one of five different member types
   */
  addItemToListEmit(item: any) {
    const selectedItems = this.localData?.filter(x => x.selected === true)
    this.addItemToEmitListEvent.emit(selectedItems)
    this.selectionLength = this.localData?.filter(x => x.selected === true).length
  }


  /**
   * Return the icon based on the type of the item
   * @param type - The type of the item
   * @returns
   */
  returnTypeIcon(type: string) {
    if (type == 'Load') {
      return this.faTruck
    }

    if (type == 'Pull') {
      return this.faTruckPickup
    }

    if (type == 'Internal') {
      return this.faCarSide
    }

    return this.faQuestion
  }



  returnFullname(item: any) {
    return `${item.firstname} ${item.lastname}`
  }


  /**
   * Sort the icon based on the direction
   * @param direction - asc, desc
   * @returns
   */
  sortIconDirection(direction: string): IconDefinition {
    if (direction === 'asc') {
      return this.faSortUp;
    }

    return this.faSortDown;
  }


  /**
   * Translate the display name of the column
   * @param str - The string to translate, from the column config dataset
   * @returns { string } - The translated string
   */
  displayNameTranslation(str: string) {
    const translation = this.translate.instant(`grouping.columns.${str}`)
    return translation
  }





}
