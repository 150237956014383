import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup } from "@angular/forms";
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: 'app-btn-accept-widget',
  templateUrl: './btn-accept-widget.component.html'
})

export class BtnAcceptWidgetComponent implements OnInit {

  @Input()
  formGroup: UntypedFormGroup | undefined;

  @Output()
  accept = new EventEmitter();

  @Output()
  cancel = new EventEmitter();

  faAccept = faCheck;
  faCancel = faTimes;

  constructor() { }

  ngOnInit(): void { }
}
