import { Injectable } from '@angular/core';
import {ToastrService} from "ngx-toastr";

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(private toaster : ToastrService) { }

  UndoMessage(message: string, title?: string): void {
    this.toaster.info(message, title, {
      enableHtml: true,
    });
  }
    infoMessage(message: string): void {
      this.toaster.info(message);
    }

    warningMessage(message: string): void{
    this.toaster.warning(message);
    }

    successMessage(message: string): void{
    this.toaster.success(message);
    }

    errorMessage(message: string): void {
    this.toaster.error(message);
    }


}

