import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {AgreedUserModel} from "../../time-statement/shared/agreed-user.model";
import {AgreedTimeRegistrationModel} from "../../time-statement/shared/agreed-time-registration.model";
import {AgreedWeekScheduleModel} from "../../time-statement/shared/agreed-week-schedule.model";
import {Observable} from "rxjs";
import {WeekStatementModel} from "../../time-statement/shared/week-statement.model";
import {TimeStatementModel} from "../../time-statement/shared/time-statement.model";
import {environment} from "../../../../environments/environment";

const url = environment.agreedBaseUrl + '/v1';

@Injectable({
  providedIn: 'root'
})
export class TimeTableService {

  constructor(private http: HttpClient) { }

  calculateTimeRegistrations(agreementId: number, user: AgreedUserModel, timeRegistrations: AgreedTimeRegistrationModel[],
                             weekSchedule?: AgreedWeekScheduleModel): Observable<TimeStatementModel> {
    const model = {user, timeRegistrations, weekSchedule};
    return this.http.post<TimeStatementModel>(`${url}/agreements/${agreementId}/calculate`, model);
  }

}
