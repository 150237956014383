import { Injectable } from '@angular/core';
import {environment} from "../../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {
  FotaServiceTaskRequest,
  FotaServiceTaskResponse,
  FotaServiceTaskModel,
  FotaUnitModel
} from "./fota.model";


const url = environment.fotaUrl + '';

@Injectable({
  providedIn: 'root'
})
export class FOTAService {

  constructor(private http: HttpClient) { }

  checkIfImeiExist(imei: number): Observable<FotaUnitModel> {
    return this.http.get<FotaUnitModel>(url + '/devices/'+imei);
  }
  
  createTask(request: FotaServiceTaskRequest): Observable<FotaServiceTaskResponse> {
    return this.http.post<FotaServiceTaskResponse>(url + '/tasks', request)
  }

  testTask(taskId: number): Observable<FotaServiceTaskModel> {
    return this.http.get<FotaServiceTaskModel>(url + '/tasks/'+taskId);
  }
   
}
