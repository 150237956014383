import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CalculateModel } from './calculate.model';

const url = environment.baseUrl + '/V1/Glec'


@Injectable({
  providedIn: 'root'
})
export class GlecService {

  constructor(private http: HttpClient) { }

  calculateGlec(model: CalculateModel) : Observable<any>{
    return this.http.post(url+ '/Calculate', model);
  }
}
