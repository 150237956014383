import { Component, Input, OnInit } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { faMagnifyingGlass, IconDefinition } from "@fortawesome/free-solid-svg-icons";
import { TranslateService } from "@ngx-translate/core";
import * as moment from "moment";
import { ToastrService } from "ngx-toastr";
import { lastValueFrom } from "rxjs";
import { BackVehicleModel } from "src/app/back-vehicles/shared/back-vehicle.model";
import { TourModel } from "src/app/tours/shared/tour.model";
import { TourService } from "src/app/tours/shared/tour.service";
import { EventProxyService, SetTourDetailId } from "../services/event-proxy.service";

@Component({
  selector: 'app-tours-book',
  templateUrl: './tours-book.component.html',
  styleUrls: ['./tours-book.component.scss']
})

export class ToursBookComponent implements OnInit {
  @Input() selectedItemId: number = 0;
  @Input() resourceType: string = '';

  faMagnifyingGlass: IconDefinition = faMagnifyingGlass;
  formGroup: UntypedFormGroup;
  tours: TourModel[] = [];
  loading = false;

  constructor(private formBuilder: UntypedFormBuilder, private toaster: ToastrService, private translate: TranslateService, private tourService: TourService, private eventProxyService: EventProxyService) {
    this.formGroup = this.formBuilder.group({
      fromUtc: ['', Validators.required],
      toUtc: ['', Validators.required]
    });
  }

  ngOnInit(): void { }

  openTourDetail(id: number): void {
    this.eventProxyService.setEvent(new SetTourDetailId(id));
  }

  toggleBorderColor(): void {
    let accordionItems: NodeListOf<Element> = document.querySelectorAll('#nestedAccordion .accordion-item .accordion-header .accordion-button');
    accordionItems.forEach(accordionBtn => {
      let accordionItem: Element | null = accordionBtn.closest('.accordion-item');
      !accordionBtn?.classList.contains('collapsed') ? accordionItem?.classList.add('dark-border') : accordionItem?.classList.remove('dark-border');
    });
  }

  backVehicleString(backVehicles: BackVehicleModel[]): string {
    let str = '';

    backVehicles.forEach(backVehicle => {
      if(backVehicle.unitIdentification == '' || backVehicle.unitIdentification == null) {
        str = str + backVehicle.unitIdentification + '' + backVehicle.registrationNumber + ', ';
      }
      else {
        str = str + backVehicle.unitIdentification + ' ' + backVehicle.registrationNumber + ', ';
      }
    });

    str = str.slice(0, -2)
    return str;
  }

  formatDateTime(date: Date): string {
    if(date != null) {
      return moment(date).format('DD-MM-YYYY [• kl.] HH:mm');
    }
    else {
      return this.translate.instant('toursBook.noDate');
    }
  }

  async searchForTours(): Promise<void> {
    if (this.formGroup.valid) {
      this.loading = true;
      this.tours = [];

      const response = await lastValueFrom<TourModel[]>(this.tourService.getBetween(
        this.formGroup.controls['fromUtc'].value,
        this.formGroup.controls['toUtc'].value,
        this.resourceType,
        this.selectedItemId
      ));

      this.loading = false;

      if(response.length > 0) {
        this.tours = response;
      }
      else {
        this.toaster.info(this.translate.instant('toursBook.noToursSearch'));
      }
    }
    else {
      this.toaster.error(this.translate.instant('message.invalidForm'));
    }
  }
}
