import { NgModule } from '@angular/core';
import { RouterModule, Routes } from "@angular/router";
import { StartComponent } from "./homepage/start/start.component";
import { GlecViewComponent } from './glec-view-m/glec-view/glec-view.component';
import { ErrorComponent } from "./error/error.component";
//import { Guard } from "./interceptors/guard.service";
import { UnauthorizedComponent } from './auth/unauthorized/unauthorized.component';
import { SinginOidcComponent } from './auth/singin-oidc/singin-oidc.component';
import {  AutoLoginPartialRoutesGuard } from 'angular-auth-oidc-client';
import { AuthorizationGuard } from './auth/AuthorizationGuard';
import { SignoutCallbackOidcComponent } from './auth/signout-callback-oidc/signout-callback-oidc.component';

const routes: Routes = [
  { path: 'start', component: StartComponent, canActivate: [AuthorizationGuard ] },
  { path: 'glecView', loadChildren: () => import('./glec-view-m/glec-view-m.module').then(m => m.GlecViewMModule)},
  { path: 'mss', loadChildren: () => import('./mss/mss.module').then(m => m.MssModule) },
  { path: 'error', component: ErrorComponent },
  { path: 'customers', loadChildren: () => import('./customers/customers.module').then(m => m.CustomersModule), canActivate: [AuthorizationGuard ] },
  { path: 'users', loadChildren: () => import('./users/users.module').then(m => m.UsersModule), canActivate: [AuthorizationGuard ] },
  { path: 'front-vehicles', loadChildren: () => import('./front-vehicles/front-vehicles.module').then(m => m.FrontVehiclesModule), canActivate: [AuthorizationGuard ] },
  { path: 'back-vehicles', loadChildren: () => import('./back-vehicles/back-vehicles.module').then(m => m.BackVehiclesModule), canActivate: [AuthorizationGuard ] },
  { path: 'transport-equipments', loadChildren: () => import('./transport-equipments/transport-equipments.module').then(m => m.TransportEquipmentsModule), canActivate: [AuthorizationGuard ] },
  { path: 'tours', loadChildren: () => import('./tours/tours.module').then(m => m.ToursModule), canActivate: [AuthorizationGuard ] },
  { path: 'acr', loadChildren: () => import('./acr/acr.module').then(m => m.AcrModule), canActivate: [AuthorizationGuard ] },
  { path: 'boxes', loadChildren: () => import('./boxes/boxes.module').then(m => m.BoxesModule), canActivate: [AuthorizationGuard ] },
  { path: 'reports', loadChildren: () => import('./reports/reports.module').then(m => m.ReportsModule), canActivate: [AuthorizationGuard ] },
  { path: 'companies', loadChildren: () => import('./company/company.module').then(m => m.CompanyModule), canActivate: [AuthorizationGuard ] },
  { path: 'grouping', loadChildren: () => import('./grouping/grouping.module').then(m => m.GroupingModule), canActivate: [AuthorizationGuard ] },
  { path: 'organisations', loadChildren: () => import('./organisations/organisations.module').then(m => m.OrganisationsModule), canActivate: [AuthorizationGuard ] },
  { path: 'company-users', loadChildren: () => import('./company-users/company-users.module').then(m => m.CompanyUsersModule), canActivate: [AuthorizationGuard ] },
  { path: 'unauthorized', component: UnauthorizedComponent },
  { path: 'signin-oidc', component: SinginOidcComponent },
  { path: 'signout-callback-oidc', component: SignoutCallbackOidcComponent },
  { path: 'help-center', loadChildren: () => import('./help-center/help-center.module').then(m => m.HelpCenterModule), canActivate: [AuthorizationGuard ] },
  { path: '**', redirectTo: 'start' }
  //{ path: '**', redirectTo: 'start', canActivate: [AuthorizationGuard ] }
];

@NgModule({
  declarations: [],
  imports: [
    RouterModule.forRoot(routes,
    {
        paramsInheritanceStrategy: 'always'
    })
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule { }
