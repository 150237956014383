import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {faExclamationCircle} from "@fortawesome/free-solid-svg-icons";

export interface ErrorModel {
  status: number;
  error: string;
  message: any;
}

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.css']
})
export class ErrorComponent implements OnInit {

  error: ErrorModel | undefined;

  faError = faExclamationCircle;

  constructor(private route: ActivatedRoute ) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(x => {
      this.error = {status: x.status, error: x.error, message: x.message};
    });
  }

  sendError(): void {
    window.location.href = `mailto:support@trasolu.dk?subject=Fejlmelding&body=${this.error?.message}`;
  }

  goBack(): void {
    window.history.back();
  }

}
