import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../../environments/environment";
import { SharedService } from "../../services/shared.service";

import { TimeStatementModel } from './time-statement.model';
import { WeekStatementModel } from './week-statement.model';
import { WorkDay } from "./workday.model";
import { EventModel } from "../../calendar/shared/event.model";
import {  TableTotalsModel } from "./table-totals.model";

import {  salaryWorkDayModel, salaryWorkWeekModel} from 'src/app/users/shared/user.model';
import { UserService } from '../../../users/shared/user.service';
import { FreezeSalaryWeekModel, HasFrozenSalaryWeekModel } from '../../../users/shared/user-agreed-week.model';
import { SalaryFoundationRegistrationsModel } from '../../../users/shared/salary-foundation-registrations.model';
import * as moment from 'moment';

const url = environment.agreedBaseUrl + '/v1';

@Injectable({
  providedIn: 'root'
})
export class TimeStatementService
{

  private tableTotals = new TableTotalsModel();
  private currentTimeStatement: TimeStatementModel | undefined;
  frozenStatusVisual: HasFrozenSalaryWeekModel = { exportHasRevisions: false, inlandHasRevisions: false } as HasFrozenSalaryWeekModel;

  private transferedTotals: TableTotalsModel[] = [];
  constructor(private http: HttpClient, public sharedService: SharedService, private userService: UserService/*, private datePipe: DatePipe*/)
  {
  }

  getTableTotals(): TableTotalsModel
  {
    return this.tableTotals;
  };
  setTableTotals(tableTotals: TableTotalsModel) { this.tableTotals = tableTotals }

  getTansferedTotals(): TableTotalsModel[] { return this.transferedTotals };
  setTansferedTotals(transferedTotals: TableTotalsModel[]) { this.transferedTotals = transferedTotals }
  addTransfered(transferedTotal: TableTotalsModel)
  {
    this.transferedTotals = [...this.transferedTotals, transferedTotal];
  }

  getCurrentTimeStatement(): TimeStatementModel | undefined { return this.currentTimeStatement }




  FetchFrozenData(isAbroad: boolean, start: any, end: any, userId: number)
  {
    let endDay = new Date(end);
    endDay.setDate(endDay.getDate() - 1);
    let lastDay = endDay.toISOString().substring(0, 10);

    this.userService.hasFrozenWeek(userId, start.toString().substring(0, 10), lastDay).subscribe(x =>
    {
      this.frozenStatusVisual = x;
    })
    this.userService.getLatestFrozenWeek(userId, start.toString().substring(0, 10), lastDay, isAbroad).subscribe(data =>
    {
      this.setTableTotalsColDateFrozen(data);
    });

  }

  MapFromSalaryResponseToTableData(data: SalaryFoundationRegistrationsModel, isAbroad: boolean)
  {

    this.setTableTotals(new TableTotalsModel());
    this.setTansferedTotals([]);
    if (data.userWorkWeeks.length < 1 || data.userWorkWeeks[0].salaryWorkWeeks.length < 1)
    {
      return;
    }

    this.tableTotals.week = data.userWorkWeeks[0].salaryWorkWeeks[0].week.weekNumber;
    this.tableTotals.from = data.from.substring(0, 10);
    this.tableTotals.to = data.to.substring(0, 10);
    this.tableTotals.year = data.from.substring(0, 4)
    this.tableTotals.isAbroad = isAbroad;

    this.PopulateTableTotalsModel(this.tableTotals, data.userWorkWeeks[0].salaryWorkWeeks[0].workDays, data.userWorkWeeks[0].salaryWorkWeeks[0].week, isAbroad);


    data.userWorkWeeks[0].transferedSalaryWorkWeeks.forEach(t =>
    {
      let transfered = new TableTotalsModel();
      t.week
      this.PopulateTableTotalsModel(transfered, t.workDays, t.week, isAbroad);
      this.addTransfered(transfered);

    })

  }

  PopulateTableTotalsModel(model: TableTotalsModel, days: salaryWorkDayModel[], week: salaryWorkWeekModel, isAbroad: boolean)
  {
    model.year = week.startDate.toString().substring(0, 4)
    model.isAbroad = isAbroad;
    model.week = week.weekNumber;
    model.from = week.startDate.toString().substring(0, 10);
    model.to = week.endDate.toString().substring(0, 10);
    days.forEach(x =>
    {


      model.total += x.workTimeTotals.reduce((prev, curr) =>
      {
        return prev + curr.minutes;
      }, 0)

      let day = model.mo;

      switch (x.day)
      {
        case 1: {
          day = model.mo;
          break;
        }
        case 2: {
          day = model.tu;
          break;
        }
        case 3: {
          day = model.we;
          break;
        }
        case 4: {
          day = model.th;
          break;
        }
        case 5: {
          day = model.fr;
          break;
        }
        case 6: {
          day = model.sa;
          break;
        }
        case 7: {
          day = model.su;
          break;
        }
      }


      day.normal += x.workTimeTotals.filter(z => z.workTimeName == 'Normal').reduce((prev, curr) =>
      {
        return prev + curr.minutes;
      }, 0);

      day.overtime1 += x.workTimeTotals.filter(z => z.workTimeName == 'T1').reduce((prev, curr) =>
      {
        return prev + curr.minutes;
      }, 0);
      day.overtime2 += x.workTimeTotals.filter(z => z.workTimeName == 'T2').reduce((prev, curr) =>
      {
        return prev + curr.minutes;
      }, 0);
      day.overtime3 += x.workTimeTotals.filter(z => z.workTimeName == 'T3').reduce((prev, curr) =>
      {
        return prev + curr.minutes;
      }, 0);
      day.overtime4 += x.workTimeTotals.filter(z => z.workTimeName == 'T4').reduce((prev, curr) =>
      {
        return prev + curr.minutes;
      }, 0);



      day.normal_break += x.workTimeTotals.filter(z => z.workTimeName == 'Normal').reduce((prev, curr) =>
      {
        return prev + curr.breakTime;
      }, 0);

      day.overtime1_break += x.workTimeTotals.filter(z => z.workTimeName == 'T1').reduce((prev, curr) =>
      {
        return prev + curr.breakTime;
      }, 0);
      day.overtime2_break += x.workTimeTotals.filter(z => z.workTimeName == 'T2').reduce((prev, curr) =>
      {
        return prev + curr.breakTime;
      }, 0);
      day.overtime3_break += x.workTimeTotals.filter(z => z.workTimeName == 'T3').reduce((prev, curr) =>
      {
        return prev + curr.breakTime;
      }, 0);
      day.overtime4_break += x.workTimeTotals.filter(z => z.workTimeName == 'T4').reduce((prev, curr) =>
      {
        return prev + curr.breakTime;
      }, 0);

      //day.resttime += x.workTimeTotals.reduce((prev, curr) =>
      //{
      //  return prev + curr.breakTime;
      //}, 0);
      //day.breakMinutes = day.resttime;

      day.total += x.workTimeTotals.reduce((prev, curr) =>
      {
        return prev + curr.minutesSubtractedBreakTime;
      }, 0);
      //day.total = day.breakMinutes < 60 ? (day.total - day.breakMinutes) : day.total; // this logic is taken from the getSum function


      day.totalSubtractedBreaks += x.workTimeTotals.reduce((prev, curr) =>
      {
        return prev + curr.minutesSubtractedBreakTime;
      }, 0);

      day.resttime = x.totalBreaktime;
      day.breakMinutes = day.resttime;
      if (day.total > 0)
      {
        if (x.isHolliday)
        {
          day.isHoliday = true;
        }
        if (x.isSunday)
        {
          day.isSunday = true;
        }
      }

      day.kmExport = x.distanceTotalExport;
      day.kmInland = x.distanceTotal;
    })

  }

  MapFromSalaryResponseToCalenderEvents(data: SalaryFoundationRegistrationsModel): WeekStatementModel
  {
    let events: EventModel[] = [];

    if (data.userWorkWeeks.length < 1 || data.userWorkWeeks[0].salaryWorkWeeks.length < 1)
    {
      return {
        events: [],
        weekNumber: 0
      } as WeekStatementModel;
    }

    data.userWorkWeeks[0].salaryWorkWeeks[0].workDays.forEach(x =>
    {
      let date = x.date.toString().substring(0, 10);
      x.worktime.forEach(z =>
      {
        const coeff = 1000 * 60 * 1;
        let fdt = moment(date + " " + z.fromTime).toDate();//from date time
        let rfdt = new Date(Math.round(fdt.getTime() / coeff) * coeff);//rounded from date time (to nearest minute)
        let tdt = moment(date + " " + z.toTime).toDate(); //to date time
        let rtdt = new Date(Math.round(tdt.getTime() / coeff) * coeff);//rounded to date time (to nearest minute)

        let calenderEventModel: EventModel = {
          id: '0',
          resourceId: '',
          title: '',
          start: rfdt,
          end: rtdt,
          display: 'auto',
          classNames: ['unclickable'],
          color: z.colorHexCode.replace('0x', '#'),
          borderColor: z.colorHexCode.replace('0x', '#')

        }
        events.push(calenderEventModel);
      });
    });

    let lastIndex = 0;
    events.forEach((value, index) =>
    {
      if (index > 0)
      {
        if (events[lastIndex].color == events[index].color && (events[index].start.getTime()-events[lastIndex].end.getTime()) <= 60000 && events[index].start.getDay() == events[lastIndex].end.getDay())
        {
          events[lastIndex].end = events[index].end;
          events[index].display = 'none';
          if (events[lastIndex].end <= events[lastIndex].start)
          {
            events[lastIndex].end.setTime(events[lastIndex].end.getTime() + ((24 * 60 * 60 * 1000) - 1));
            //events[lastIndex].end.setTime(events[lastIndex].end.getTime() + ((24 * 60 * 60 * 1000)));
          }
        } else
        {
          lastIndex = index;

        }
      }
    });
    const newArr = events.filter((ele, ind) => ele.display !== 'none');

    newArr.forEach((value, index) =>
    {
      if (index > 0)
      {
        if (newArr[index - 1].end > newArr[index].start)
        {
          newArr[index - 1].end = newArr[index].start;
        }
      }

      if (index < newArr.length - 1)
      {
        //if end is less than start I am assuming it is because the time rolled over but not the actual day
        //so i assume the end is wrong and should be equal to the next events start
        //used for monday to saturday, and only for visual purposes
        if (newArr[index].end < newArr[index].start)
        {
          newArr[index].end = newArr[index + 1].start;
          //newArr[index].end.setTime(newArr[index].end.getTime() - 1);
        }
      }
      else
      {
        //if end is less than start I am assuming it is because the time rolled over but not the actual day
        //so i assume the end is wrong and should actually end 24 hours later
        //used for sunday, and only for visual purposes
        if (newArr[index].end <= newArr[index].start)
        {
          newArr[index].end.setTime(newArr[index].end.getTime() + ((24 * 60 * 60 * 1000)));
        }
      }
    });



    return {
      events: newArr,
      weekNumber: data.userWorkWeeks[0].salaryWorkWeeks[0].week.weekNumber
    } as WeekStatementModel;
  }




  //mapToWeekStatement(data: TimeStatementModel, deductables: UserTimeRegistrationModel[], tempAgreementId: number, userId: number, isAbroad: boolean): WeekStatementModel
  //{

  //  //this.currentTimeStatement = data;
  //  //this.tableTotals.week = data.weekNumber;
  //  //this.tableTotals.from = data.from.substring(0, 10);
  //  //this.tableTotals.to = data.to.substring(0, 10);
  //  //this.tableTotals.year = data.from.substring(0, 4)
  //  //this.tableTotals.isAbroad = isAbroad;


  //  //this.tableTotals.sum.normal = this.getTotalWorkWeekSum(data.workdays, 'Normal');
  //  //this.tableTotals.sum.overtime1 = this.getTotalWorkWeekSum(data.workdays, 'T1');
  //  //this.tableTotals.sum.overtime2 = this.getTotalWorkWeekSum(data.workdays, 'T2');
  //  //this.tableTotals.sum.overtime3 = this.getTotalWorkWeekSum(data.workdays, 'T3');
  //  //this.tableTotals.sum.overtime4 = this.getTotalWorkWeekSum(data.workdays, 'T4');


  //  //this.tableTotals.total += this.getTotalWorkWeekSum(data.workdays, 'Normal') +
  //  //  this.getTotalWorkWeekSum(data.workdays, 'T1') +
  //  //  this.getTotalWorkWeekSum(data.workdays, 'T2') +
  //  //  this.getTotalWorkWeekSum(data.workdays, 'T3') +
  //  //  this.getTotalWorkWeekSum(data.workdays, 'T4');


  //  let events: EventModel[] = [];


  //  data.workdays.forEach((workday: WorkDay, index: number) =>
  //  {

  //    //if (workday.timeRegistrations.length > 0)
  //    //{
  //    //  let arr = workday.worktime as Array<WorkTime>;
  //    //  let workdate = moment(workday.timeRegistrations[0].start);
  //    //  let date = new Date(workdate.toString()).toDateString();
  //    //  arr.forEach(t =>
  //    //  {
  //    //    events.push(
  //    //      {
  //    //        id: '0',
  //    //        resourceId: '',
  //    //        title: '',
  //    //        start: moment(date + " " + t.fromTime).toDate(),
  //    //        end: moment(date + " " + t.toTime).toDate(),
  //    //        display: 'auto',

  //    //        classNames: ['unclickable'],
  //    //        color: t.colorHexCode.replace('0x', '#'),
  //    //        borderColor: t.colorHexCode.replace('0x', '#')
  //    //      }
  //    //    );


  //    //    //});

  //    //    //workday.totalBreakTimeInMinutes = 0;

  //    //    //deductables.forEach((time: UserTimeRegistrationModel) =>
  //    //    //{

  //    //    //  let deworkdate = moment(time.startTime);
  //    //    //  let endworkdate = moment(time.endTime);

  //    //    //  if (workdate.day() == deworkdate.day())
  //    //    //  {
  //    //    //    // get break minutes from timereg
  //    //    //    var end = workdate.day() < endworkdate.day() ? moment(workdate.endOf('day')) : moment(time.endTime);
  //    //    //    var duration = moment.duration(moment(end).diff(moment(time.startTime)));
  //    //    //    var restMinutes = duration.asMinutes();
  //    //    //    // add rest for day
  //    //    //    workday.resttime.push({
  //    //    //      fromTime: time.startTime.toString(),
  //    //    //      toTime: end.toString(),
  //    //    //      minutes: restMinutes,
  //    //    //      worktimeTypeId: time.timeRegistrationTypeId,
  //    //    //      worktimeName: 'Rest',
  //    //    //      colorHexCode: '#ccc'
  //    //    //    } as WorkTime);

  //    //    //    workday.totalBreakTimeInMinutes += restMinutes;

  //    //    //  } else if (workdate.day() == endworkdate.day())
  //    //    //  {

  //    //    //    var start = deworkdate.day() != endworkdate.day() ? moment(endworkdate.startOf('day')) : moment(time.startTime);
  //    //    //    var duration = moment.duration(moment(time.endTime).diff(moment(start)));
  //    //    //    var restMinutes = duration.asMinutes();
  //    //    //    // add rest for day
  //    //    //    workday.resttime.push({
  //    //    //      fromTime: time.startTime.toString(),
  //    //    //      toTime: time.endTime.toString(),
  //    //    //      minutes: restMinutes,
  //    //    //      worktimeTypeId: time.timeRegistrationTypeId,
  //    //    //      worktimeName: 'Rest',
  //    //    //      colorHexCode: '#ccc'
  //    //    //    } as WorkTime);

  //    //    //    workday.totalBreakTimeInMinutes += restMinutes;

  //    //    //  }
  //    //    //});

  //    //    //if (data.subtractBreaktime)
  //    //    //{

  //    //    //  if (this.getSum(workday) > 270 && this.getSum(workday) < 360 && this.getRestSum(workday) < 60)
  //    //    //  {

  //    //    //    workday.totalBreakTimeInMinutes = 15;
  //    //    //    this.tableTotals.total -= 15;
  //    //    //  } else if (this.getSum(workday) > 360 && this.getRestSum(workday) < 60)
  //    //    //  {

  //    //    //    workday.totalBreakTimeInMinutes = 45;
  //    //    //    this.tableTotals.total -= 45;
  //    //    //  } else if (this.getRestSum(workday) > 60 && this.getRestSum(workday) < 300)
  //    //    //  {

  //    //    //    workday.totalBreakTimeInMinutes = this.getRestSum(workday);

  //    //    //  }
  //    //    //}

  //    //    //this.setTableTotalsColDate(index, workday);

  //    //    // resttimes that fit day
  //    //    //let restarr = deductables as Array<UserTimeRegistrationModel>;
  //    //    //restarr.forEach(t =>
  //    //    //{
  //    //    //  events.push(
  //    //    //    {
  //    //    //      id: '0',
  //    //    //      resourceId: '',
  //    //    //      title: '',
  //    //    //      start: moment(t.startTime).toDate(),
  //    //    //      end: moment(t.endTime).toDate(),
  //    //    //      display: 'background',
  //    //    //      classNames: ['unclickable'],
  //    //    //      color: '#ccc'
  //    //    //    }
  //    //    //  );
  //    //  });
  //    //}
  //  });



  //  let lastIndex = 0;


  //  //events.forEach((value, index) =>
  //  //{


  //  //  if (index > 0)
  //  //  {
  //  //    if (events[lastIndex].color == events[index].color && events[lastIndex].end.toDateString() === events[index].start.toDateString())
  //  //    {
  //  //      events[lastIndex].end = events[index].end;
  //  //      events[index].display = 'none';
  //  //      if (events[lastIndex].end <= events[lastIndex].start)
  //  //      {
  //  //        events[lastIndex].end.setTime(events[lastIndex].end.getTime() + ((24 * 60 * 60 * 1000) - 1));
  //  //        //events[lastIndex].end.setTime(events[lastIndex].end.getTime() + ((24 * 60 * 60 * 1000)));
  //  //      }
  //  //    } else
  //  //    {
  //  //      lastIndex = index;

  //  //    }
  //  //  }
  //  //});


  //  const newArr = events.filter((ele, ind) => ele.display !== 'none');

  //  newArr.forEach((value, index) =>
  //  {

  //    if (index > 0)
  //    {

  //      if (newArr[index - 1].end > newArr[index].start)
  //      {
  //        newArr[index - 1].end = newArr[index].start;
  //      }

  //    }

  //    if (index < newArr.length - 1)
  //    {
  //      //if end is less than start I am assuming it is because the time rolled over but not the actual day
  //      //so i assume the end is wrong and should be equal to the next events start
  //      //used for monday to saturday, and only for visual purposes
  //      if (newArr[index].end < newArr[index].start)
  //      {
  //        newArr[index].end = newArr[index + 1].start;
  //        //newArr[index].end.setTime(newArr[index].end.getTime() - 1);
  //      }
  //    }
  //    else
  //    {
  //      //if end is less than start I am assuming it is because the time rolled over but not the actual day
  //      //so i assume the end is wrong and should actually end 24 hours later
  //      //used for sunday, and only for visual purposes
  //      if (newArr[index].end <= newArr[index].start)
  //      {
  //        newArr[index].end.setTime(newArr[index].end.getTime() + ((24 * 60 * 60 * 1000)));
  //      }
  //    }

  //  });



  //  return {
  //    events: newArr,
  //    weekNumber: data.weekNumber
  //  };
  //}

  getMinutesIfExists(workday: WorkDay, key: any): number
  {
    return workday.workTimeTotals.findIndex(s => s.workTimeName.includes(key)) != -1 ? workday.workTimeTotals.filter(s => s.workTimeName.includes(key))[0].minutes : 0;
  }

  getTotalSum(workday: WorkDay, key: any): number
  {
    let sum = 0;
    workday.worktime.filter(s => s.worktimeName.includes(key)).forEach(worktime =>
    {
      sum += worktime.minutes;
    });
    return sum;
  }

  getTotalWorkWeekSum(workdays: WorkDay[], key: any): number
  {
    let sum = 0;
    workdays.forEach(workday =>
    {
      workday.worktime.filter(s => s.worktimeName.includes(key)).forEach(worktime =>
      {
        sum += worktime.minutes;
      });
    })
    return sum;
  }

  getSum(workday: WorkDay)
  {
    let sum = 0;

    workday.worktime.forEach(worktime =>
    {

      sum += worktime.minutes;
    });

    return workday.totalBreakTimeInMinutes < 60 ? sum - workday.totalBreakTimeInMinutes : sum;
  }

  getRestSum(workday: WorkDay)
  {
    let sum = 0;
    sum = workday.totalBreakTimeInMinutes;
    return sum;
  }

  setTableTotalsColDateFrozen(frozen: FreezeSalaryWeekModel)
  {
    if (frozen == null)
      return;
    this.tableTotals.total = frozen.weekModel.total;
    this.tableTotals.totalSubtractedBreaks = frozen.weekModel.totalSubtractedBreaks;
    this.tableTotals.week = frozen.weekModel.week;
    this.tableTotals.to = frozen.weekModel.toDate;
    this.tableTotals.from = frozen.weekModel.fromDate;
    this.tableTotals.year = frozen.weekModel.year;
    this.tableTotals.revision = frozen.weekModel.revision;
    this.tableTotals.isAbroad = frozen.weekModel.isAbroad;

    this.tableTotals.mo.ParseJson(frozen.weekModel.mondayJson);
    this.tableTotals.tu.ParseJson(frozen.weekModel.tuesdayJson);
    this.tableTotals.we.ParseJson(frozen.weekModel.wednesdayJson);
    this.tableTotals.th.ParseJson(frozen.weekModel.thursadayJson)
    this.tableTotals.fr.ParseJson(frozen.weekModel.fridayJson);
    this.tableTotals.sa.ParseJson(frozen.weekModel.saturdayJson);
    this.tableTotals.su.ParseJson(frozen.weekModel.sundayJson);

    frozen.transferedWeekModels.forEach(t =>
    {
      let f = this.transferedTotals.find(x => x.isAbroad === t.isAbroad && x.week === t.week);
      if (f)
      {
        f.total = t.total;
        f.totalSubtractedBreaks = t.totalSubtractedBreaks;
        f.week = t.week;
        f.to = t.toDate;
        f.from = t.fromDate;
        f.year = t.year;
        f.revision = t.revision;
        f.isAbroad = t.isAbroad;

        f.mo.ParseJson(t.mondayJson);
        f.tu.ParseJson(t.tuesdayJson);
        f.we.ParseJson(t.wednesdayJson);
        f.th.ParseJson(t.thursadayJson)
        f.fr.ParseJson(t.fridayJson);
        f.sa.ParseJson(t.saturdayJson);
        f.su.ParseJson(t.sundayJson);
      }
    });

  }

  setTableTotalsColDate(dayNumber: number, workday: WorkDay)
  {
    //switch (dayNumber)
    //{
    //  case 0: {
    //    this.tableTotals.mo.normal = + this.getMinutesIfExists(workday, 'Normal');
    //    this.tableTotals.mo.overtime1 = + this.getMinutesIfExists(workday, 'T1');
    //    this.tableTotals.mo.overtime2 = + this.getMinutesIfExists(workday, 'T2');
    //    this.tableTotals.mo.overtime3 = + this.getMinutesIfExists(workday, 'T3');
    //    this.tableTotals.mo.overtime4 = + this.getMinutesIfExists(workday, 'T4');
    //    this.tableTotals.mo.sun_holyday = + this.getMinutesIfExists(workday, 'SH');
    //    this.tableTotals.mo.resttime = + this.getRestSum(workday);
    //    this.tableTotals.mo.total = + this.getSum(workday);
    //    this.tableTotals.mo.breakMinutes = workday.totalBreakTimeInMinutes;
    //    this.tableTotals.mo.totalSubtractedBreaks = + (workday.totalNormalTimeSubtractedBreakTime
    //      + workday.totalOverTime1SubtractedBreakTime
    //      + workday.totalOverTime2SubtractedBreakTime);
    //    break;
    //  }
    //  case 1: {
    //    this.tableTotals.tu.normal = + this.getMinutesIfExists(workday, 'Normal');
    //    this.tableTotals.tu.overtime1 = + this.getMinutesIfExists(workday, 'T1');
    //    this.tableTotals.tu.overtime2 += this.getMinutesIfExists(workday, 'T2');
    //    this.tableTotals.tu.overtime3 = + this.getMinutesIfExists(workday, 'T3');
    //    this.tableTotals.tu.overtime4 = + this.getMinutesIfExists(workday, 'T4');
    //    this.tableTotals.tu.sun_holyday = + this.getMinutesIfExists(workday, 'SH');
    //    this.tableTotals.tu.resttime = + this.getRestSum(workday);
    //    this.tableTotals.tu.total = + this.getSum(workday);
    //    this.tableTotals.tu.breakMinutes = workday.totalBreakTimeInMinutes;
    //    this.tableTotals.tu.totalSubtractedBreaks = + (workday.totalNormalTimeSubtractedBreakTime
    //      + workday.totalOverTime1SubtractedBreakTime
    //      + workday.totalOverTime2SubtractedBreakTime);
    //    break;
    //  }
    //  case 2: {
    //    this.tableTotals.we.normal = + this.getMinutesIfExists(workday, 'Normal');
    //    this.tableTotals.we.overtime1 = + this.getMinutesIfExists(workday, 'T1');
    //    this.tableTotals.we.overtime2 += this.getMinutesIfExists(workday, 'T2');
    //    this.tableTotals.we.overtime3 = + this.getMinutesIfExists(workday, 'T3');
    //    this.tableTotals.we.overtime4 = + this.getMinutesIfExists(workday, 'T4');
    //    this.tableTotals.we.sun_holyday = + this.getMinutesIfExists(workday, 'SH');
    //    this.tableTotals.we.resttime = + this.getRestSum(workday);
    //    this.tableTotals.we.total = + this.getSum(workday);
    //    this.tableTotals.we.breakMinutes = workday.totalBreakTimeInMinutes;
    //    this.tableTotals.we.totalSubtractedBreaks = + (workday.totalNormalTimeSubtractedBreakTime
    //      + workday.totalOverTime1SubtractedBreakTime
    //      + workday.totalOverTime2SubtractedBreakTime);
    //    break;
    //  }
    //  case 3: {
    //    this.tableTotals.th.normal = + this.getMinutesIfExists(workday, 'Normal');
    //    this.tableTotals.th.overtime1 = + this.getMinutesIfExists(workday, 'T1');
    //    this.tableTotals.th.overtime2 += this.getMinutesIfExists(workday, 'T2');
    //    this.tableTotals.th.overtime3 = + this.getMinutesIfExists(workday, 'T3');
    //    this.tableTotals.th.overtime4 = + this.getMinutesIfExists(workday, 'T4');
    //    this.tableTotals.th.sun_holyday = + this.getMinutesIfExists(workday, 'SH');
    //    this.tableTotals.th.resttime = + this.getRestSum(workday);
    //    this.tableTotals.th.total = + this.getSum(workday);
    //    this.tableTotals.th.breakMinutes = workday.totalBreakTimeInMinutes;
    //    this.tableTotals.th.totalSubtractedBreaks = + (workday.totalNormalTimeSubtractedBreakTime
    //      + workday.totalOverTime1SubtractedBreakTime
    //      + workday.totalOverTime2SubtractedBreakTime);
    //    break;
    //  }
    //  case 4: {
    //    this.tableTotals.fr.normal = + this.getMinutesIfExists(workday, 'Normal');
    //    this.tableTotals.fr.overtime1 = + this.getMinutesIfExists(workday, 'T1');
    //    this.tableTotals.fr.overtime2 += this.getMinutesIfExists(workday, 'T2');
    //    this.tableTotals.fr.overtime3 = + this.getMinutesIfExists(workday, 'T3');
    //    this.tableTotals.fr.overtime4 = + this.getMinutesIfExists(workday, 'T4');
    //    this.tableTotals.fr.sun_holyday = + this.getMinutesIfExists(workday, 'SH');
    //    this.tableTotals.fr.resttime = + this.getRestSum(workday);
    //    this.tableTotals.fr.total = + this.getSum(workday);
    //    this.tableTotals.fr.breakMinutes = workday.totalBreakTimeInMinutes;
    //    this.tableTotals.fr.totalSubtractedBreaks = + (workday.totalNormalTimeSubtractedBreakTime
    //      + workday.totalOverTime1SubtractedBreakTime
    //      + workday.totalOverTime2SubtractedBreakTime)
    //    break;
    //  }
    //  case 5: {
    //    this.tableTotals.sa.normal = + this.getMinutesIfExists(workday, 'Normal');
    //    this.tableTotals.sa.overtime1 = + this.getMinutesIfExists(workday, 'T1');
    //    this.tableTotals.sa.overtime2 += this.getMinutesIfExists(workday, 'T2');
    //    this.tableTotals.sa.overtime3 = + this.getMinutesIfExists(workday, 'T3');
    //    this.tableTotals.sa.overtime4 = + this.getMinutesIfExists(workday, 'T4');
    //    this.tableTotals.sa.sun_holyday = + this.getMinutesIfExists(workday, 'SH');
    //    this.tableTotals.sa.resttime = + this.getRestSum(workday);
    //    this.tableTotals.sa.total = + this.getSum(workday);
    //    this.tableTotals.sa.breakMinutes = workday.totalBreakTimeInMinutes;
    //    this.tableTotals.sa.totalSubtractedBreaks = + (workday.totalNormalTimeSubtractedBreakTime
    //      + workday.totalOverTime1SubtractedBreakTime
    //      + workday.totalOverTime2SubtractedBreakTime)
    //    break;
    //  }
    //  case 6: {
    //    this.tableTotals.su.normal = + this.getMinutesIfExists(workday, 'Normal');
    //    this.tableTotals.su.overtime1 = + this.getMinutesIfExists(workday, 'T1');
    //    this.tableTotals.su.overtime2 += this.getMinutesIfExists(workday, 'T2');
    //    this.tableTotals.su.overtime3 = + this.getMinutesIfExists(workday, 'T3');
    //    this.tableTotals.su.overtime4 = + this.getMinutesIfExists(workday, 'T4');
    //    this.tableTotals.su.sun_holyday = + this.getMinutesIfExists(workday, 'SH');
    //    this.tableTotals.su.resttime = + this.getRestSum(workday);
    //    this.tableTotals.su.total = + this.getSum(workday);
    //    this.tableTotals.su.breakMinutes = workday.totalBreakTimeInMinutes;
    //    this.tableTotals.su.totalSubtractedBreaks = + (workday.totalNormalTimeSubtractedBreakTime
    //      + workday.totalOverTime1SubtractedBreakTime
    //      + workday.totalOverTime2SubtractedBreakTime)
    //    break;
    //  }
    //}
  }
}

