<form class="form-group" [formGroup]="formGroup">
  <h6 class="mt-3">{{'logBook.general.searchTitle' | translate}}</h6>
  <div class="row align-items-end me-0">
    <div class="col">
      <label for="logType">{{'logBook.general.searchContent' | translate}}
        <span *ngIf="formGroup.controls['logType'].hasError('required')" class="required-text">
          {{'message.required' | translate}}
        </span>
      </label>
      <select id="logType" name="logType" formControlName="logType" class="form-select">
        <option value="0">{{'logBook.general.all' | translate}}</option>
        @for(logType of logTypes; track $index) {
          <option [value]="logType.id">{{logType.name}}</option>
        }
      </select>
    </div>
    <div class="col">
      <label for="fromUtc">{{'logBook.general.fromUtc' | translate}}
        <span *ngIf="formGroup.controls['fromUtc'].hasError('required')" class="required-text">
          {{'message.required' | translate}}
        </span>
      </label>
      <input type="date" id="fromUtc" name="fromUtc" formControlName="fromUtc" class="form-control" />
    </div>
    <div class="col">
      <label for="toUtc">{{'logBook.general.toUtc' | translate}}
        <span *ngIf="formGroup.controls['toUtc'].hasError('required')" class="required-text">
          {{'message.required' | translate}}
        </span>
      </label>
      <input type="date" id="toUtc" name="toUtc" formControlName="toUtc" class="form-control" />
    </div>
    <button type="button" class="btn search" (click)="searchForLogs()">
      <fa-icon class="fa-lg" [icon]="faMagnifyingGlass"></fa-icon>
    </button>
  </div>
  <hr class="my-4" />
</form>

<div ngbAccordion #accordion="ngbAccordion" id="nestedAccordion" [closeOthers]="true" (click)="toggleBorderColor()">
  @for(log of logEntries; track $index) {
  <div ngbAccordionItem="{{$index}}" class="mb-2 mx-2">
    <h2 ngbAccordionHeader>
      <button ngbAccordionButton class="row py-2 px-1">
        <div class="col-3 ps-1">
          @for(logType of logTypes; track $index) {
            @if(log.logbookTypeId == logType.id){
            <p class="log-type mb-0">{{logType.name}}</p>
            }
          }
        </div>
        <div class="col-3">
          <p class="mb-0">• {{log.name}}</p>
        </div>
        <div class="col-5">
          <p class="mb-0">• {{formatDateTime(log.createdOn)}}</p>
        </div>
      </button>
    </h2>
    <div ngbAccordionCollapse>
      <div ngbAccordionBody>
        <ng-template>
          <hr class="mx-2 mt-0" />
          <form class="form-group">
            <div class="row row-cols-1 row-cols-md-2 mb-2">
              <div class="col">
                <label for="type">{{'logBook.general.type' | translate}}</label>
                @for(logType of logTypes; track $index) {
                  @if(log.logbookTypeId == logType.id){
                  <input type="text" id="type" name="type" class="form-control" readonly [value]="logType.name" />
                  }
                }
              </div>
              <div class="col">
                <label for="name">{{'logBook.general.name' | translate}}</label>
                <input type="text" id="name" name="name" class="form-control" readonly [value]="log.name" />
              </div>
            </div>
            <div class="row mb-2">
              <div class="col">
                <label for="createdBy">{{'logBook.general.createdBy' | translate}}</label>
                <input type="text" id="createdBy" name="createdBy" class="form-control" readonly [value]="log.createdBy.firstname + ' ' + log.createdBy.lastname" />
              </div>
            </div>
            <!-- row below needs class "pe-2" when the button below is commented back in. -->
            <div class="row align-items-end mb-2" id="location-map">
              <div class="col">
                <label for="location">{{'logBook.general.location' | translate}}</label>
                <input type="text" id="location" name="location" class="form-control" readonly [value]="log.location" />
              </div>
              <!-- commented out until needed. -->
              <!-- <button type="button" class="btn me-1">
                {{'logBook.general.showOnMap' | translate}} <fa-icon class="ms-2" [icon]="faLocationDot"></fa-icon>
              </button> -->
            </div>
            <div class="row">
              <div class="col">
                <label for="logDescription">{{'logBook.general.description' | translate}}</label>
                <textarea autosize rows="1" id="logDescription" name="logDescription" class="form-control" readonly>{{log.description}}</textarea>
              </div>
            </div>
            <hr class="my-4" />
            <h6 class="mt-3">{{'logBook.general.metaData' | translate}}</h6>
            <div class="row row-cols-1 row-cols-md-2">
              @for(meta of log.metadata; track $index) {
              <div class="col">
                <label [for]="'meta' + $index">{{meta.name}}</label>
                <input type="text" [id]="'meta' + $index" [name]="'meta' + $index" class="form-control" readonly [value]="meta.value" />
              </div>
              }
            </div>
            <hr class="my-4" />
          </form>
          <div class="px-3">
            <h6 class="mt-3">{{'logBook.general.notesAndAttachments' | translate}}</h6>
          </div>
          @if(log.attachments.length > 0 || log.notes.length > 0) {
            @for(attachment of log.attachments; track $index) {
              <div class="attachment-container p-2 mb-3 mx-3">
                <app-attachment-widget [logBook]="true" [attachment]="attachment"></app-attachment-widget>
              </div>
              }
              @for(note of log.notes; track $index) {
              <div class="note-container p-2 mb-3 mx-3">
                <app-note-widget [logBook]="true" [note]="note"></app-note-widget>
              </div>
              }
          }
          @else {
            <p class="ps-3 fst-italic text-black-50">{{'logBook.noData' | translate}}</p>
          }
        </ng-template>
      </div>
    </div>
  </div>
  }
</div>
