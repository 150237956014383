<div class="overlayLoader" *ngIf="dataLoading">
  <app-loading *ngIf="dataLoading"></app-loading>
</div>

<div class="row p-2">
  <div class="col-3">
    <img src="../../../assets/images/trasolu_logo.svg" width="200">
  </div>

  <div class="col-9 title">
    <h2>{{'mss.fitterServiceSite' | translate}}</h2>
    <span *ngIf="selectedVehicle != null" style="font-weight: bold;">{{selectedVehicle}}</span>
    <span *ngIf="mountedVehicle != null" style="font-weight: bold;">{{mountedVehicle.registrationNumber}}</span>
  </div>

  <div class="col-md-12">
    <hr>
  </div>

  <div class="errorDiv" *ngIf="error == true">{{errorText}}</div>

  <div *ngIf="step == 1 && error == false">
    <div class="col-md-12">
      {{'mss.welcome' | translate}} <br> {{'mss.unitIsMountedWithoutVoltage' | translate}}<br>
      {{'mss.enterCompanyCode' | translate}} <br>
      <input type="number" name="companyCode" id="companyCode" [(ngModel)]="companyCodeValue" required> <button class="btn" type="button" (click)="testCompanyCode()"> {{'mss.next' | translate}}</button>
    </div>
  </div>

  <div *ngIf="step == 2">
    <div class="col-md-12">
      <h3 *ngIf="selectedCompany">{{selectedCompany.name}}</h3>
      {{'mss.whichVehicleIsTheUnitIn' | translate}} <br>
      <select name="vehicleId" id="vehicleId" [(ngModel)]="selectedVehicle">
        <option *ngFor="let selectVehicle of selectVehicleList" [ngValue]="selectVehicle.id">{{selectVehicle.registrationNumber}}</option>
      </select>
      <button class="btn" type="button" (click)="selectVehicle()"> {{'mss.next' | translate}}</button>
    </div>
  </div>

  <div *ngIf="step == 3 && pendingFotaTask != true">
    <div class="col-md-12">
      <h3 *ngIf="selectedCompany">{{selectedCompany.name}}</h3>
      {{'mss.insertLicenseSwitchOnIgnition' | translate}} <br> {{'mss.5MinutesGreenStatus' | translate}} <br>
      {{'mss.alarmOnVehicleBaudRate' | translate}}<br>
      {{'mss.missingDataChangeSetup' | translate}}<br>
      <select name="configSelect" id="configSelect" [(ngModel)]="selectedConfig">
        <option *ngFor="let selectConfig of selectConfigList" [ngValue]="selectConfig.id">{{selectConfig.name}}</option>
      </select>
      <button class="btn" type="button" (click)="sendConfig();"> {{'mss.submit' | translate}}</button>
    </div>
  </div>

  <div *ngIf="step == 4 && pendingDataTask != true && pendingFotaTask != true">
    <div class="col-md-12">
      {{'mss.unitSetUpCorrectly' | translate}}<br>
    </div>
  </div>

  <div *ngIf="step == 5">
    <div class="col-md-12">
      {{'mss.unitSetUpCorrectly' | translate}}<br>
    </div>
  </div>

  <div *ngIf="pendingFotaTask == true">
    <div class="col-md-12 waitingDiv">
      {{'mss.applyVoltageToTheUnit' | translate}} {{'mss.waitingOnData' | translate}} <br>
      {{'mss.waitingTime' | translate}} {{pendingFotaTaskTime}} {{'mss.minutes' | translate}} <br>
    </div>
  </div>

  <div *ngIf="pendingDataTask == true">
    <div class="col-md-12 waitingDiv">
      {{'mss.insertLicenseAndStartVehicle' | translate}} {{'mss.waitingOnData' | translate}} <br>
      {{'mss.waitingTime' | translate}} {{pendingDataTaskTime}} {{'mss.minutes' | translate}}<br><br>
      {{'mss.canbusAlarmNoDataConfiguration' | translate}}
      <button class="btn" type="button" (click)="step = 3; pendingDataTask = false; pendingFotaTask = false;"> {{'mss.back' | translate}}</button>
    </div>
  </div>

  <div class="col-md-6">
    <div *ngIf="mqttActive">
      <h3>{{'mss.status' | translate}} </h3>
      {{'mss.contact' | translate}} <span *ngIf="selectedVehicleBoxStatus.timestamp != 0" style="color:green">{{'mss.okParenthesis' | translate}}{{getTimeFromUnix(selectedVehicleBoxStatus.timestamp)}} {{'mss.minutesAgo' | translate}}</span><span *ngIf="selectedVehicleBoxStatus.timestamp == 0" style="color:orange">{{'mss.awaiting' | translate}} </span> <br>
      {{'mss.tachograph' | translate}} <span *ngIf="selectedVehicleBoxStatus.driverCardStatus" style="color:green">{{'mss.ok' | translate}} </span><span *ngIf="!selectedVehicleBoxStatus.driverCardStatus && selectedVehicleBoxStatus.timestamp != 0" style="color:red">{{'mss.notOk' | translate}} </span><span
        *ngIf="!selectedVehicleBoxStatus.driverCardStatus && selectedVehicleBoxStatus.timestamp == 0" style="color:orange">{{'mss.awaiting' | translate}} </span> <br>
        {{'mss.fuel' | translate}} <span *ngIf="selectedVehicleBoxStatus.fuelDataStatus" style="color:green">{{'mss.ok' | translate}} </span><span *ngIf="!selectedVehicleBoxStatus.fuelDataStatus && selectedVehicleBoxStatus.timestamp != 0" style="color:red">{{'mss.notOk' | translate}} </span><span
        *ngIf="!selectedVehicleBoxStatus.fuelDataStatus && selectedVehicleBoxStatus.timestamp == 0" style="color:orange">{{'mss.awaiting' | translate}} </span> <br>
    </div>
  </div>
</div>