import { Injectable } from '@angular/core';
import {environment} from "../../../../environments/environment";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Observable} from "rxjs";
import {
  BoxServiceCompanyModel,
  BoxServiceVehicleModel,
  BoxServiceCreateBoxRequestModel
} from "./boxservice.model";
import {NoteModel} from "../../../shared/note-widget/shared/note.model";
import { AttachmentCreateModel } from 'src/app/shared/attachment-widget/shared/attachment.model';
import { DataExchangeModel } from '../../../shared/fileuploader/shared/data-exchange.model';

const url = environment.boxApiBaseUrl + '/tcp'

@Injectable({
  providedIn: 'root'
})
export class TcpServerService {

  constructor(private http: HttpClient) { }

 
  refreshTcp(): Observable<string> {

    return this.http.get(url + '/refresh', {responseType: 'text'});
  }

}
