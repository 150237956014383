import {AbstractControl, ValidationErrors, ValidatorFn} from "@angular/forms";

export function DateValidator(path: string): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null =>{
    const formGroup = control.parent;
    if (formGroup) {
      const startDate = formGroup.get(path);
      const d1 = new Date(startDate?.value);
      const d2 = new Date(control.value);
      return d1.getTime() > d2.getTime() ? {'wrong-date': {value: control.value}} : null;
    }
    return null;
  };
}
