<div class="col-md-12 px-3" *ngIf="box" >
  <div class="row">
    <div class="col-md-12 text-end" style="margin-top: 10px;">
      <button type="button" class="btn btn-default btn-sm" (click)="readOnlyBox = ! readOnlyBox" placement="left"
        ngbTooltip="{{'btn.edit' | translate}}">
        <fa-icon [icon]="faEdit"></fa-icon>
      </button>
    </div>
    <form [formGroup]="formGroup" class="col-md-12 row">
      <div class="col-md-6 padding-right">
        <label>{{'box.info.imei' | translate}}</label>
        <input class="form-control form-control-sm" formControlName="UID" [readOnly]="true">
      </div>
      
      <div class="col-md-6 padding-right">
        <label>{{'box.info.iccid' | translate}}</label>
        <input class="form-control form-control-sm" formControlName="CanBaud1" [readOnly]="true"
         >
      </div>
      <div class="col-md-12 padding-right">
        <label>{{'box.info.modelName' | translate}}</label>
        <input class="form-control form-control-sm" formControlName="ModelName" [readOnly]="true"
        >
      </div>
      <div class="col-md-6 padding-right">
        <label>{{'box.info.storageLocation' | translate}}</label>
        <input class="form-control form-control-sm" formControlName="StorageLocation" [readOnly]="true"
        >
      </div>
      <div class="col-md-6 padding-right">
        <label>{{'box.info.StorageBoxNumber' | translate}}</label>
        <input class="form-control form-control-sm" formControlName="StorageBoxNumber" [readOnly]="true"
          >
      </div>
      <!-- <div class="col-md-12">
        <label>{{'box.info.simPin' | translate}}</label>
        <input type="text" class="form-control form-control-sm" formControlName="simPin" [readOnly]="true"
         >
      </div> -->
      <div class="col-md-12" style="margin-bottom: 10px;">
        <label>{{'box.info.installationDate' | translate}}</label>
        <input type="text" class="form-control form-control-sm" formControlName="installationDate" [readOnly]="true">
      </div>
    </form>
  </div>
</div>
<div class="ng-container" *ngIf="!readOnlyBox" [ngClass]="{'bg-light': !readOnlyBox}">
  <div *ngIf="!readOnlyBox" class="col-md-12 btn-container ">
    <div class="d-flex justify-content-center">
      <button class="btn btn-danger" (click)="remove()" placement="top" ngbTooltip="{{'btn.removeBox' | translate}}">
        <fa-icon [icon]="faTrash"></fa-icon>
      </button>
    </div>
  </div>
</div>
 