import {Component, Inject, OnInit} from '@angular/core';
import {SelectComponent} from "../select-component";
import {FrontVehicleModel} from "../../../front-vehicles/shared/front-vehicle.model";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {UntypedFormBuilder, Validators} from "@angular/forms";
import {SharedService} from "../../../shared/services/shared.service";
import {FrontVehicleService} from "../../../front-vehicles/shared/front-vehicle.service";
import {BackVehicleModel} from "../../../back-vehicles/shared/back-vehicle.model";
import {BackVehicleService} from "../../../back-vehicles/shared/back-vehicle.service";

@Component({
  selector: 'app-back-vehicle-select',
  templateUrl: './back-vehicle-select.component.html',
  styleUrls: ['./back-vehicle-select.component.css']
})
export class BackVehicleSelectComponent extends SelectComponent<BackVehicleModel> implements OnInit {

  selectedItems: BackVehicleModel[] = [];

  constructor( @Inject(NgbModal) modalService: NgbModal, @Inject(UntypedFormBuilder) formBuilder: UntypedFormBuilder,
               @Inject(SharedService) public sharedService: SharedService,
               private backVehicleService: BackVehicleService) {
    super(modalService, formBuilder, sharedService);
    this.formGroup = this.formBuilder.group({
      backVehicles: [[], Validators.required]
    });
  }

  ngOnInit(): void {
    this.backVehicleService.getAll().subscribe(response => {
     this.data = this.getAllActivebackVehicles(response);
      this.assignCopy();
    });
    document.getElementById('btn')?.click();
    document.getElementById('search')?.focus();
  }


  addToList(b: BackVehicleModel): void {
    const index = this.selectedItems.indexOf(b);
    if (index > -1)
      this.selectedItems.splice(index, 1);
    else
    this.selectedItems.push(b);

    b.selected = ! b.selected;

    this.formGroup?.patchValue({backVehicles: this.selectedItems});
  }

  isPreselected(item: BackVehicleModel): boolean {
    let returnValue = false;
    this.preselection?.forEach((pre: BackVehicleModel) => {
      if (item.id === pre.id) returnValue = true;
    });
    return returnValue;
  }
  getAllActivebackVehicles(backVehicles: BackVehicleModel[]): BackVehicleModel[]
  {
    return backVehicles.filter(x=>  {
      let hasNoActiveEvent = true;

      if(x.events) {
        x.events.forEach(e => {
          if((new Date(e.actualEndDate) > new Date() || e.actualEndDate === null) && new Date(e.actualStartDate) < new Date())
            hasNoActiveEvent = false;
        });
      }

      return hasNoActiveEvent;
    });
  }
}
