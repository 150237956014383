<div class="button--component">

  @if (this.buttonType !== 'default') {
    <button
      class="btn elevated--low px-3 kanit fw-medium"
      [ngClass]="{
        'bordered--danger': this.buttonType === 'borderedDanger',
        'flat--danger': this.buttonType === 'flatDanger',
        'bordered-outline-primary': this.buttonType === 'borderedPrimary',
      }"
      (click)="buttonClick()"
    >
      <ng-content></ng-content>
    </button>
  }
  @else {
    <button class="btn btn-default px-3 kanit fw-medium" (click)="buttonClick()">
      {{ this.buttonText }}
    </button>
  }
</div>
