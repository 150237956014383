import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { faRedo } from '@fortawesome/free-solid-svg-icons';
import { DateValidator } from 'src/app/form-validators/date-validator';
import { PropellantModel } from 'src/app/front-vehicles/shared/propellant.model';
import { PropellantService } from 'src/app/front-vehicles/shared/propellant.service';
import { CalculateModel } from './shared/calculate.model';
import { GlecService } from './shared/glec.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-glec-calculator',
  templateUrl: './glec-calculator.component.html',
  styleUrls: ['./glec-calculator.component.css']
})
export class GlecCalculatorComponent implements OnInit {
  faReset = faRedo;

  pdf: any;
  co2ResultPerUnit: any;
  co2ResultTotal: any;
  co2ResultUnit: any;

  co2ResultPerUnitTTW: any;
  co2ResultTotalTTW: any;
  co2ResultUnitTTW: any;

  co2ResultPerUnitWTW: any;
  co2ResultTotalWTW: any;
  co2ResultUnitWTW: any;

  propellants: any[] = [];
  propellantLabel: any;

  volumeTypes: any[] = [
    { type: 'paller', id: 1, name: this.translate.instant('reports.glec.calc.pallets') },
    { type: 'kg', id: 2, name: this.translate.instant('reports.glec.calc.weightKg') },
    { type: 'ton', id: 3, name: this.translate.instant('reports.glec.calc.weightTon') },
    { type: 'm3', id: 4, name: this.translate.instant('reports.glec.calc.volume') }
  ];
  volumeLabel: any;
  formGroup: any;

  constructor(private propellantService: PropellantService, private formBuilder: UntypedFormBuilder, private glecService: GlecService, private translate: TranslateService) { }


  ngOnInit(): void {
    this.propellantService.getAll().subscribe((response) => {
      this.propellants = response;
      this.propellantLabel = this.propellants[0].name.match(/\((.*)\)/).pop();
    });

    this.volumeLabel = this.volumeTypes[0].type;

    this.formGroup = this.formBuilder.group({
      propellantId: ['1', [Validators.required]],
      distance: ['', [Validators.required, Validators.pattern("^[0-9]*$")]],
      volumeId: ['1', [Validators.required]],
      volumeAmount: ['', [Validators.required, Validators.pattern('[0-9]+(?:\\,[0-9]+)?')]],
      propellantAmount: ['', [Validators.required, Validators.pattern('[0-9]+(?:\\,[0-9]+)?')]]
    });
  }


  formValid() {
    return this.formGroup.valid;
  }


  reset(event: any) {
    this.pdf = undefined;
    this.formGroup.reset();
  }


  updatePropellant(event: any) {
    var result = this.propellants.find(o => o.id == event.target.value);
    this.propellantLabel = result.name.match(/\((.*)\)/).pop();
  }


  updateVolume(event: any) {
    var result = this.volumeTypes.find(o => o.id == event.target.value);
    this.volumeLabel = result.type;
  }


  calculate() {
    var values = this.formGroup.value;
    var model: CalculateModel = {
      propellantId: values.propellantId,
      rangeInKilometers: values.distance,
      kilometersPerLiterFuel: values.propellantAmount.replace(',', '.'),
      cargoAmount: values.volumeAmount.replace(',', '.'),
      cargoUnit: this.volumeLabel
    };
    this.glecService.calculateGlec(model).subscribe((response) => {
      this.co2ResultPerUnit = ' ' + response.gramCo2PerUnit + ' ' + response.unit;
      this.co2ResultTotal = response.kiloCo2Total;
      this.co2ResultPerUnitTTW = ' ' + response.gramCo2PerUnitTTW + ' ' + response.unit;
      this.co2ResultTotalTTW = response.kiloCo2TotalTTW;
      this.co2ResultPerUnitWTW = ' ' + response.gramCo2PerUnitWTW + ' ' + response.unit;
      this.co2ResultTotalWTW = response.kiloCo2TotalWTW;
      this.co2ResultUnit = this.translate.instant('reports.glec.calc.kg');
    });
  }
}
