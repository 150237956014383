import { TranslateService } from '@ngx-translate/core';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ReportItems, ReportState, ReportTypes } from '../../../reports/shared/report.model';
import { faCheck, faDownload, faSync, faTrash } from '@fortawesome/free-solid-svg-icons';
import { faEye } from '@fortawesome/free-regular-svg-icons';

@Component({
  selector: 'app-report-table',
  templateUrl: './report-table.component.html'
})

export class ReportTableComponent implements OnInit, OnChanges {
  @Input() data: ReportItems[] = [];

  @Output()
  downloadSelectedPdf = new EventEmitter();

  @Output()
  downloadSelectedCsv = new EventEmitter();

  @Output()
  downloadSelectedFiles = new EventEmitter();

  @Output()
  deleteSelectedReports = new EventEmitter();

  @Output()
  viewSelected = new EventEmitter();

  @Output()
  refreshSelected = new EventEmitter();

  page = 1;
  pageSize: number = 50;
  faDownload = faDownload;
  faEye = faEye;
  faSync = faSync;
  faCheck = faCheck;
  faTrash = faTrash
  selectedReports: number[] = [];
  selectedAll: boolean = false

  successData: ReportItems[] = [];
  failData: ReportItems[] = [];
  waitData: ReportItems[] = [];

  constructor(private translate: TranslateService) { }


  ngOnChanges(changes: SimpleChanges): void {
    this.successData = changes.data.currentValue.filter((x: { state: ReportState; }) => x.state == ReportState.Succeeded).map((x: any) => ({ ...x, selected: false }));
    this.failData = changes.data.currentValue.filter((x: { state: ReportState; }) => x.state == ReportState.Failed);
    this.waitData = changes.data.currentValue.filter((x: { state: ReportState; }) => x.state == ReportState.Waiting);
  }


  ngOnInit(): void {
    this.successData = this.data.filter(x => x.state == ReportState.Succeeded).map((x: any) => ({ ...x, selected: false }));
    this.failData = this.data.filter(x => x.state == ReportState.Failed);
    this.waitData = this.data.filter(x => x.state == ReportState.Waiting);
  }


  rowColor(i: number): string {
    if ((i + 1) % 2 == 0) {
      return 'bg-row'
    }
    else {
      return ''
    }
  }


  SelectDownload(reportId: any, ReportName: any, fileType: string) {
    if (fileType == 'pdf') {
      this.downloadSelectedPdf.emit({ id: reportId, name: ReportName });
    } else {
      this.downloadSelectedCsv.emit({ id: reportId, name: ReportName });
    }
  }


  SelectView(reportId: any, ReportName: any) {
    this.viewSelected.emit({ id: reportId, name: ReportName });
  }


  RefrehList() {
    this.refreshSelected.emit();
  }


  HasCsv(reportType: number): boolean {
    return (
      reportType == ReportTypes.CreateTourReportCommand ||
      reportType == ReportTypes.CreateSalaryReportCommand
    );
  }


  /**
   * Selects all the items in the table
   */
  selectAll(): void {
    this.successData?.forEach(item => item.selected = this.selectedAll)
    this.selectedReports = this.successData?.filter(x => x.selected === true).map(x => x.id)
  }


  /**
   * Select a single item in the table
   * @param item the singular item that is selected
   */
  selectItem(item: ReportItems) {
    this.selectedReports = this.successData.filter(x => x.selected).map(x => x.id)
  }

  /**
   * Emit the event and the list of ids to the parent component
   */
  downloadAllReports() {
    this.downloadSelectedFiles.emit(this.selectedReports)
  }

  deleteSelectedReportsEmit() {
    let txt = this.translate.instant('message.reports.delete')

    if (confirm(txt)) {
      this.deleteSelectedReports.emit(this.selectedReports)
    }
  }
}
