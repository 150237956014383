<p>{{signInTranslate}}</p>
<!--<div class="container">
    <div class="col-md-12 center">
      <h5 style="margin-left: 30px">title</h5>
      <ng-container class="row" *ngFor="let group of companies">
        <div class="col-md-12">
          <button class="btn btn-default" style="width: 100%; margin: 5px"
            (click)="setCompany(group)">{{group.name}}</button>
        </div>
      </ng-container>
    </div>
  </div>-->