import { Injectable } from '@angular/core';
import { IMqttMessage, MqttService } from "ngx-mqtt";
import { Observable } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class EventMqttService {
  endpoint: string;
  messageEndPoint: string;

  constructor(private _mqttService: MqttService) {
    this.endpoint = 'operator';
    this.messageEndPoint = 'message';

  }

  topic(deviceId: string): Observable<IMqttMessage> {
    let topicName = this.endpoint + '/' + deviceId + '/#';
    return this._mqttService.observe(topicName);
  }

  messageTopic(id: string, topic: string): Observable<IMqttMessage> {
    const topicName = `${this.messageEndPoint}/${topic}/${id}/#`
    return this._mqttService.observe(topicName);
  }


  sendMessageTopic(id: string, topic: string = 'user', message: string) {
    return this._mqttService.unsafePublish(`${this.messageEndPoint}/${topic}/${id}`, message, { qos: 1, retain: true })
  }
}
