import { Injectable, inject } from '@angular/core';
/* import jwt_decode, { JwtPayload } from 'jwt-decode'; */
import
{ ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { Observable, of } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { TokenService } from '../services/token.service';

@Injectable({ providedIn: 'root' })
export class AuthorizationGuard 
{
  private readonly oidc = inject(OidcSecurityService);
  private readonly router = inject(Router);
  private readonly tokenService = inject(TokenService);

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree>
  {
    this.oidc.checkAuth().subscribe(x =>
    {
      if (x.isAuthenticated !== true)
      {
        this.oidc.authorize();
      }
      else
      {
        this.tokenService.saveToken(x.accessToken);
      }
      return x;
    });

    return this.oidc.isAuthenticated();

  }
}
