import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { faFileImport, faUpload } from '@fortawesome/free-solid-svg-icons';
import { CustomerModel } from 'src/app/customers/shared/customer.model';
import { TourService } from 'src/app/tours/shared/tour.service';
import { CustomerService } from 'src/app/customers/shared/customer.service';
import { FrontVehicleService } from 'src/app/front-vehicles/shared/front-vehicle.service';
import { BackVehicleService } from 'src/app/back-vehicles/shared/back-vehicle.service';
import { TransportEquipmentService } from 'src/app/transport-equipments/shared/transport-equipment.service';
import { DataExchangeModel } from 'src/app/shared/fileuploader/shared/data-exchange.model';
import { NotificationService } from '../services/notification.service';
import { SharedService } from '../services/shared.service';

@Component({
  selector: 'app-fileuploader',
  templateUrl: './fileuploader.component.html'
})

export class FileuploaderComponent implements OnInit {
  @Input()
  serviceToUse: string | undefined;

  @Output()
  close = new EventEmitter();

  language: any;
  faUpload = faFileImport;
  files: File[] = [];

  importerChosen = false;
  customerChosen = false;
  uploaderActive = false;

  message: any | undefined;

  formGroup: UntypedFormGroup;
  dataSchemas: DataExchangeModel[] | undefined;
  customers: CustomerModel[] | undefined;

  selectedSchemaId: any;
  selectedSchema: DataExchangeModel | undefined;
  selectedCustomerId: any;
  selectedCustomer: CustomerModel | undefined;


  constructor(
    private tourService: TourService,
    private customerService: CustomerService,
    private frontVehicleService: FrontVehicleService,
    private backVehicleService: BackVehicleService,
    private transportEquipmentService: TransportEquipmentService,
    private formBuilder: UntypedFormBuilder,
    private sharedService: SharedService,
    private notifyService: NotificationService
  ) {
    this.formGroup = this.formBuilder.group({
      schema: [''],
      customer: ['']
    })
  }

  async getLanguage() {
    this.sharedService.getLanguageJson().subscribe(response => this.language = response)
  }


  async ngOnInit(): Promise<void> {
    await this.getLanguage()

    this.getData()
  }


  /**
   * Selects the correct service based on the usedService variable, set in the HTML as a prop
   * @returns TourService | FrontVehicleService | BackVehicleService | TransportEquipmentService | CustomerService
   */
  selectCorrectService() {
    let service: TourService | FrontVehicleService | BackVehicleService | TransportEquipmentService | CustomerService;

    switch (this.serviceToUse) {
      case "tour":
        service = this.tourService;
        break;
      case "customer":
        service = this.customerService;
        break;
      case "frontVehicle":
        service = this.frontVehicleService;
        break;
      case "backVehicle":
        service = this.backVehicleService;
        break;
      case "transportEquipment":
        service = this.transportEquipmentService;
        break;
      default:
        service = this.tourService;
        break;
    }

    return service
  }


  /**
   * Transforms the file to the correct MIME type
   * @param event File event
   * @returns
   */
  transformFile(event: File[]) {
    let files = event

    // Map over the files and clone the ones with incorrect MIME type
    files = files.map((file: any) => {
      if (file.type === 'application/vnd.ms-excel') {
        // Clone the file but change the MIME type to 'text/csv'
        const newFile = new File([file], file.name, { type: 'text/csv', lastModified: file.lastModified })
        return newFile
      }
      return file
    })

    return files
  }


  onSelect(event: any) {
    const files = this.transformFile(event.addedFiles)

    const service = this.selectCorrectService()

    this.files.push(...files)

    if (this.files.length > 0) {
      const fileType = this.files[0].type.toString()

      // Compare normalized file type with the selected schema
      if (fileType === this.selectedSchema?.fileType) {
        this.notifyService.infoMessage(event.addedFiles[0].name + " " + this.language?.message.processingFile)

        this.processData(
          this.files[0],
          this.selectedSchema?.id,
          this.selectedCustomerId ? this.selectedCustomerId : undefined,
          service
        )
      } else {
        this.notifyService.warningMessage(this.language?.message.fileMatchError)
        this.files.splice(0, 1)
      }
    }
  }



  onRemove(event: any) {
    this.files.splice(this.files.indexOf(event), 1);
  }


  // DataSchemas
  onChange(event: any) {
    if (event.value != 'null') {
      this.importerChosen = true;
      this.selectedSchemaId = event.value;
      this.selectedSchema = this.dataSchemas?.find(x => x.id == event.value);
    } else {
      this.importerChosen = false;
    }
  }


  onCustomerChange(event: any) {
    if (event.value != 'null') {
      this.customerChosen = true;
      this.selectedCustomerId = event.value;
      this.selectedCustomer = this.customers?.find(x => x.id == event.value);
    } else {
      this.customerChosen = false;
    }
  }


  getData() {
    let service: TourService | CustomerService | FrontVehicleService | BackVehicleService | TransportEquipmentService = this.selectCorrectService();

    service.getImportSchemas().subscribe((schemas) => {
      this.dataSchemas = schemas;
    });
    this.customerService.getAll().subscribe((customers) => {
      this.customers = customers;
    })
  }


  validation() {
    if (this.serviceToUse == 'tour') {
      return (!this.customerChosen || !this.importerChosen);
    }

    return !this.importerChosen;
  }

  reset() {
    this.customerChosen = false;
    this.selectedCustomerId = undefined;
    this.selectedCustomer = undefined;
    this.uploaderActive = false;
    this.importerChosen = false;
    this.selectedSchema = undefined;
    this.selectedSchemaId = undefined;
    this.files = [];
    this.close.emit(undefined);
  }


  processData(file: File, importSchema: number, customerId: number, service: TourService | FrontVehicleService | BackVehicleService | TransportEquipmentService | CustomerService) {
    this.uploaderActive = true;
    service.processImportFile(file, importSchema, customerId).subscribe((response) => {
      if (response.success) {
        response.errorLines.forEach((er: any) => {
          this.notifyService.warningMessage(er.message);
        });
      }
      this.notifyService.successMessage(this.language?.message.processFileCompleted);
      //this.reset();
      this.close.emit(undefined);
    }, error => {
      console.error('Error: ', error)
      this.uploaderActive = false;
      this.notifyService.warningMessage(error);
    });
  }
}
