import { Injectable } from "@angular/core";
import { CreateBoxModel } from "src/app/front-vehicles/shared/front-vehicle.model";
import { environment } from "src/environments/environment";
import {HttpClient} from "@angular/common/http";
import { BoxModel, UpdateBoxModel } from "./box.model";
import { Observable } from "rxjs";
const url = environment.baseUrl + '/V1/Box'
@Injectable({
    providedIn: 'root'
  })

  export class boxService{
    constructor(private http: HttpClient) { }

    createBoxService( model: CreateBoxModel) {
      return this.http.post<any>(`${url}`, model);
    }

    getAllBoxes() {
      return this.http.get<any>(`${url}`);
    }

    getAllAvailableBoxes() {
      return this.http.get<any>(`${url}/Available`);
    }

    getSingle(id: number): Observable<BoxModel>{
        return this.http.get<BoxModel>(`${url}/${id}`);
    }

    delete(id: number): Observable<any>{
        return this.http.delete(`${url}/${id}`);
    }

    update(id: number, model: UpdateBoxModel): Observable<any> {
      return this.http.put<UpdateBoxModel>(`${url}/${id}`, model);
    }
  }
