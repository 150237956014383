import { Component, EventEmitter, Input, OnInit, Output, ViewChild, ElementRef } from '@angular/core';
import { WidgetComponent } from "../../shared/widget-component";
import { AbstractControl, UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { TourService } from "../shared/tour.service";
import { CustomerService } from "../../customers/shared/customer.service";
import { FrontVehicleService } from "../../front-vehicles/shared/front-vehicle.service";
import { BackVehicleService } from "../../back-vehicles/shared/back-vehicle.service";
import { UserService } from "../../users/shared/user.service";
import { Observable } from "rxjs";
import { UserModel } from "../../users/shared/user.model";
import { FrontVehicleModel } from "../../front-vehicles/shared/front-vehicle.model";
import { BackVehicleModel } from "../../back-vehicles/shared/back-vehicle.model";
import { CreateCustomerModel, CustomerModel } from "../../customers/shared/customer.model";
import { CreateMode, StatusType } from "../../constants";
import { TransportEquipmentModel } from "../../transport-equipments/shared/transport-equipment.model";
import { TransportEquipmentService } from "../../transport-equipments/shared/transport-equipment.service";
import { AddressModel } from "../../shared/address-widget/shared/address.model";
import { TagModel } from "../../shared/tag/shared/tag.model";
import { CreateTourModel } from "../shared/tour.model";
import { NotificationService } from "../../shared/services/notification.service";
import { SharedService } from "../../shared/services/shared.service";
import { FreightService } from "../shared/freight.service";
import { FreightModel } from "../shared/freight.model";
import { DatePipe } from "@angular/common";
import { NoteModel } from "../../shared/note-widget/shared/note.model";
import { ignoreElements } from "rxjs/operators";
import * as moment from "moment";
import { DateValidator } from "../../form-validators/date-validator";
import { TimeValidator } from "../../form-validators/time-validator";
import DurationConstructor = moment.unitOfTime.DurationConstructor;
import { ITuple } from "../../shared/tuple";
import { faArrowDown, faArrowUp, faClone, faKeyboard, faLock, faLockOpen, faMinus, faPlus, faSync, faTrash } from "@fortawesome/free-solid-svg-icons";
import { GeocodeService } from "../../shared/services/geocode.service";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { IDropdownSettings } from 'ng-multiselect-dropdown';

@Component({
  selector: 'app-tour-create',
  templateUrl: './tour-create.component.html',
  styleUrls: ['./tour-create.component.css'],
  providers: [DatePipe]
})
export class TourCreateComponent extends WidgetComponent {
  @Input()
  startDate: Date | undefined;

  @Input()
  backVehicles: BackVehicleModel[] | undefined;

  @Input()
  transportEquipment: TransportEquipmentModel[] | undefined;

  @Input()
  arriveBackVehicle: string[] = [];

  selectArriveBackVehicle: string[] = [];

  @Input()
  leaveBackVehicle: string[] = [];

  selectLeaveBackVehicle: string[] = [];

  @Input()
  arriveEquipment: string[] = [];

  selectArriveEquipment: string[] = [];

  @Input()
  leaveEquipment: string[] = [];

  selectLeaveEquipment: string[] = [];

  @Output()
  created = new EventEmitter();

  @Output()
  cancel = new EventEmitter();

  formGroup: UntypedFormGroup;

  selectedAddress: any;
  @Input() selectedUser: UserModel | null | undefined; // used when selecting a user before adding an address
  @Input() selectedFrontVehicle: FrontVehicleModel | null | undefined; // used when selecting a front vehicle before adding an address
  @Input() selectedBackVehicles: BackVehicleModel[] = []; // used when selecting a back vehicle before adding an address
  @Input() selectedTransportEquipment: TransportEquipmentModel | null | undefined; // used when selecting a transport equipment before adding an address

  selectedSecondUser: UserModel | null | undefined;
  selectedCustomer: CustomerModel | null | undefined;
  loading = false;
  language: any;
  userSelect = false;
  secondUserSelect = false;
  showSecondUserField = false;
  customerSelect = false;
  frontVehicleSelect = false;
  backVehicleSelect = false;
  transportEquipmentSelect = false;
  createNewAddress = false;
  repetitionDateTimes: ITuple[] = [];
  faCloneIcon = faClone;
  faUpIcon = faArrowUp;
  faDownIcon = faArrowDown
  faAddIcon = faPlus
  faTrashIcon = faTrash
  faSyncIcon = faSync
  faMinusIcon = faMinus
  faKeyboard = faKeyboard
  isGeocoding: boolean = false
  isReverseGeocoding: boolean = false
  addressCount: number = 0

  currentLat: number = 55.850849
  currentLon: number = 9.845612

  currSearch: number = 0

  searchResults: any = []

  freightTypeUnitTitle: string = ''
  freightTypeDescValue: string = ''

  dropdownList: any[] = []
  arriveBackVehicles: any = {}
  leaveBackVehicles: any = {}
  showArriveMultiSelect: boolean[] = [false]
  showLeaveMultiSelect: boolean[] = [false]
  indexPointer: number = 0

  dropdownSettings: IDropdownSettings = {
    singleSelection: false,
    idField: 'item_id',
    textField: 'item_text',
    allowSearchFilter: true,
    searchPlaceholderText: this.translate.instant('reports.multiselect.search'),
    noDataAvailablePlaceholderText: this.translate.instant('reports.multiselect.noDataAvailable'),
    itemsShowLimit: 3,
    limitSelection: 3
  }

  @ViewChild('freightTypeDescSelect') freightTypeDescSelect!: ElementRef;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private http: HttpClient,
    private tourService: TourService,
    private customerService: CustomerService,
    private frontVehicleService: FrontVehicleService,
    private backVehicleService: BackVehicleService,
    private userService: UserService,
    private transportEquipmentService: TransportEquipmentService,
    private notifyService: NotificationService,
    protected sharedService: SharedService,
    private freightService: FreightService,
    private datePipe: DatePipe,
    private geocoder: GeocodeService,
    private translate: TranslateService
  ) {
    super()

    this.formGroup = this.formBuilder.group({
      orderId: ['', [Validators.maxLength(40)]],
      description: ['', [Validators.maxLength(40), Validators.required]],
      freight: ['', [Validators.maxLength(255)]],
      freightTotalAmount: 0,
      freightTypeId: ['', [Validators.required]],
      arriveBackVehicle: [this.arriveBackVehicle ? this.arriveBackVehicle : '', Validators.maxLength(255)],
      selectArriveBackVehicle: ['', Validators.maxLength(255)],
      arriveEquipment: [this.arriveEquipment ? this.arriveEquipment : '', Validators.maxLength(255)],
      selectArriveEquipment: ['', Validators.maxLength(255)],
      leaveBackVehicle: ['', Validators.maxLength(255)],
      selectLeaveBackVehicle: ['', Validators.maxLength(255)],
      leaveEquipment: ['', Validators.maxLength(255)],
      selectLeaveEquipment: ['', Validators.maxLength(255)],
      expectedStartDate: ['', Validators.required],
      expectedEndDate: ['', [DateValidator('expectedStartDate'), Validators.required]],
      expectedStartTime: '',
      expectedEndTime: ['', TimeValidator('expectedStartDate', 'expectedStartTime', 'expectedEndDate')],
      frontVehicleId: null,
      backVehicleIds: [],
      transportEquipmentId: null,
      assignedUserId: null,
      customerId: null,
      tagIds: [],
      attachment: null,
      note: null,
      repetition: 0,
      endRepetitionDate: [{ value: '', disabled: true }, DateValidator('expectedStartDate')],
      addresses: this.formBuilder.array([], Validators.required),
      type: [undefined, Validators.required],
      isSpecial: false,
      assignedSecondUserId: null,
      isLocked: true,
      calculatedTotalFreightAmount: 0
    })
  }

  async getLanguage() {
    this.sharedService.getLanguageJson().subscribe(response => this.language = response)
  }

  async ngOnInit(): Promise<void> {
    await this.getLanguage()

    this.selectedUserCallback({ user: this.selectedUser })
    this.selectedFrontVehicleCallback({ frontVehicle: this.selectedFrontVehicle })
    this.selectedBackVehicleCallback({ backVehicles: this.selectedBackVehicles })
    this.selectedTransportEquipmentCallback({ transportEquipment: this.selectedTransportEquipment })

    if (this.startDate) {
      this.formGroup.patchValue(({
        expectedStartDate: this.datePipe.transform(this.startDate, 'yyyy-MM-dd'),
        expectedStartTime: `${this.getFormattedTime(new Date(this.startDate).getHours())}:${this.getFormattedTime(new Date(this.startDate).getMinutes())}`,
      }))
    }

    this.backVehicles?.forEach(backVehicle => {
      this.dropdownList.push({ item_id: backVehicle.id, item_text: backVehicle.unitIdentification + ' / ' + backVehicle.registrationNumber });
    });

    this.onChanges()
  }

  // should update the associated input field as well as the leaveBackVehicle one
  selectedArriveBackVehicle(index: number) {
    this.arriveBackVehicle[index] = this.selectArriveBackVehicle[index]

    this.leaveBackVehicle[index] = this.selectArriveBackVehicle[index]
    this.selectLeaveBackVehicle[index] = this.selectArriveBackVehicle[index]
  }

  // should update the associated input field but not the arriveBackVehicle one since it's assumed that has been set and done
  selectedLeaveBackVehicle(index: number) {
    this.leaveBackVehicle[index] = this.selectLeaveBackVehicle[index]
  }

  // should update the associated input field as well as the leaveEquipment one
  selectedArriveEquipment(index: number) {
    this.arriveEquipment[index] = this.selectArriveEquipment[index]

    this.leaveEquipment[index] = this.selectArriveEquipment[index]
    this.selectLeaveEquipment[index] = this.selectArriveEquipment[index]
  }

  // should update the associated input field but not the arriveBackVehicle one since it's assumed that has been set and done
  selectedLeaveEquipment(index: number) {
    this.leaveEquipment[index] = this.selectLeaveEquipment[index]
  }

  // if the user manually updates the input field, clear the dropdowns and mirror the value to the other input
  manualArriveBackVehicleInput(index: number) {
    this.leaveBackVehicle[index] = this.arriveBackVehicle[index]

    // clear dropdowns
    this.selectArriveBackVehicle[index] = ""
    this.selectLeaveBackVehicle[index] = ""
  }

  // if the user manually updates the input field, clear the dropdowns and DON'T mirror the value to the other input, since it's assumed that has been set and done
  manualLeaveBackVehicleInput(index: number) {
    this.leaveBackVehicle[index] = this.leaveBackVehicle[index]

    // clear dropdowns
    this.selectLeaveBackVehicle[index] = ""
  }

  // if the user manually updates the input field, clear the dropdowns and mirror the value to the other input
  manualArriveEquipmentInput(index: number) {
    this.leaveEquipment = this.arriveEquipment

    // clear dropdowns
    this.selectArriveEquipment[index] = ""
    this.selectLeaveEquipment[index] = ""
  }

  // if the user manually updates the input field, clear the dropdowns and DON'T mirror the value to the other input, since it's assumed that has been set and done
  manualLeaveEquipmentInput(index: number) {
    this.leaveEquipment = this.leaveEquipment

    // clear dropdowns
    this.selectLeaveBackVehicle[index] = ""
  }

  get addresses() {
    return this.formGroup.controls['addresses'] as UntypedFormArray
  }

  addAddress(): void {
    const counter = this.addresses.value.length - 1

    let firstAddressBackVehicleAndEquipment = {
      arriveBackVehicle: '',
      selectArriveBackVehicle: '',
      leaveBackVehicle: '',
      selectLeaveBackVehicle: '',
      arriveEquipment: '',
      selectArriveEquipment: '',
      leaveEquipment: '',
      selectLeaveEquipment: ''
    }

    if (this.addresses.value[counter]) {
      const internalCounter = counter + 1
      const regEx = /^\d+(,\d+)*$/

      let localArriveTextJoin = ""
      let localArriveIdJoin = ""
      let localLeaveTextJoin = ""
      let localLeaveIdJoin = ""

      if (regEx.test(this.addresses.value[counter].arriveBackVehicle)) {
        localArriveTextJoin = this.arriveBackVehicles[counter].map((item: any) => item.item_text).join(', ')
        localArriveIdJoin = this.arriveBackVehicles[counter].map((item: any) => item.item_id).join(',')
      } else {
        localArriveTextJoin = this.addresses.value[counter].arriveBackVehicle
        localArriveIdJoin = this.addresses.value[counter].arriveBackVehicle
      }

      if (regEx.test(this.addresses.value[counter].leaveBackVehicle)) {
        localLeaveTextJoin = this.leaveBackVehicles[counter].map((item: any) => item.item_text).join(', ')
        localLeaveIdJoin = this.leaveBackVehicles[counter].map((item: any) => item.item_id).join(',')
      } else {
        localLeaveTextJoin = this.addresses.value[counter].leaveBackVehicle
        localLeaveIdJoin = this.addresses.value[counter].leaveBackVehicle
      }

      if (this.addresses.value[counter].arriveBackVehicle) this.arriveBackVehicle[internalCounter] = localArriveTextJoin
      if (this.addresses.value[counter].leaveBackVehicle) this.leaveBackVehicle[internalCounter] = localLeaveTextJoin
      if (this.addresses.value[counter].arriveEquipment) this.arriveEquipment[internalCounter] = this.addresses.value[counter].arriveEquipment
      if (this.addresses.value[counter].leaveEquipment) this.leaveEquipment[internalCounter] = this.addresses.value[counter].leaveEquipment
      if (this.addresses.value[counter].selectArriveEquipment) this.selectArriveEquipment[internalCounter] = this.addresses.value[counter].selectArriveEquipment
      if (this.addresses.value[counter].selectLeaveEquipment) this.selectLeaveEquipment[internalCounter] = this.addresses.value[counter].selectLeaveEquipment

      firstAddressBackVehicleAndEquipment = {
        arriveBackVehicle: localArriveIdJoin,
        selectArriveBackVehicle: localArriveIdJoin,
        leaveBackVehicle: localLeaveIdJoin,
        selectLeaveBackVehicle: localLeaveIdJoin,
        arriveEquipment: this.addresses.value[counter].arriveEquipment,
        selectArriveEquipment: this.addresses.value[counter].selectArriveEquipment,
        leaveEquipment: this.addresses.value[counter].leaveEquipment,
        selectLeaveEquipment: this.addresses.value[counter].selectLeaveEquipment
      }
    } else {
      const internalCounter = counter + 1

      this.arriveBackVehicle[internalCounter] = this.selectedBackVehicles.length > 0 ? `${this.selectedBackVehicles[0].unitIdentification} / ${this.selectedBackVehicles[0].registrationNumber}` : ''
      this.leaveBackVehicle[internalCounter] = this.selectedBackVehicles.length > 0 ? `${this.selectedBackVehicles[0].unitIdentification} / ${this.selectedBackVehicles[0].registrationNumber}` : ''
      this.arriveEquipment[internalCounter] = this.selectedTransportEquipment ? `${this.selectedTransportEquipment.unitIdentification} ${this.selectedTransportEquipment.type}` : ''
      this.leaveEquipment[internalCounter] = this.selectedTransportEquipment ? `${this.selectedTransportEquipment.unitIdentification} ${this.selectedTransportEquipment.type}` : ''
      this.selectArriveEquipment[internalCounter] = this.selectedTransportEquipment ? `${this.selectedTransportEquipment.unitIdentification} ${this.selectedTransportEquipment.type}` : ''
      this.selectLeaveEquipment[internalCounter] = this.selectedTransportEquipment ? `${this.selectedTransportEquipment.unitIdentification} ${this.selectedTransportEquipment.type}` : ''

      firstAddressBackVehicleAndEquipment = {
        arriveBackVehicle: this.selectedBackVehicles.length > 0 ? `${this.selectedBackVehicles[0].id}` : '',
        selectArriveBackVehicle: this.selectedBackVehicles.length > 0 ? `${this.selectedBackVehicles[0].id}` : '',
        leaveBackVehicle: this.selectedBackVehicles.length > 0 ? `${this.selectedBackVehicles[0].id}` : '',
        selectLeaveBackVehicle: this.selectedBackVehicles.length > 0 ? `${this.selectedBackVehicles[0].id}` : '',
        arriveEquipment: this.selectedTransportEquipment ? `${this.selectedTransportEquipment.unitIdentification} ${this.selectedTransportEquipment.type}` : '',
        selectArriveEquipment: this.selectedTransportEquipment ? `${this.selectedTransportEquipment.unitIdentification} ${this.selectedTransportEquipment.type}` : '',
        leaveEquipment: this.selectedTransportEquipment ? `${this.selectedTransportEquipment.unitIdentification} ${this.selectedTransportEquipment.type}` : '',
        selectLeaveEquipment: this.selectedTransportEquipment ? `${this.selectedTransportEquipment.unitIdentification} ${this.selectedTransportEquipment.type}` : ''
      }
    }

    const addressForm = this.formBuilder.group({
      country: ['', Validators.maxLength(255)],
      state: ['', Validators.maxLength(255)],
      zipcode: ['', [Validators.maxLength(10), Validators.required]],
      city: ['', [Validators.maxLength(255), Validators.required]],
      street: ['', Validators.maxLength(255)],
      latitude: 0,
      longitude: 0,
      priority: [this.formGroup.value.addresses.length + 1, Validators.required],
      description: '',
      additionalInformation: '',
      locationName: ['', Validators.maxLength(255)],
      locationId: ['', Validators.maxLength(255)],
      freight: ['', [Validators.maxLength(255)]],
      removedFreightAmount: 0,
      freightAmount: 0,
      freightTypeId: this.formGroup.value.freightTypeId > 0 ? this.formGroup.value.freightTypeId : 0,
      orderId: ['', [Validators.maxLength(40)]],
      arriveBackVehicle: [firstAddressBackVehicleAndEquipment.arriveBackVehicle, Validators.maxLength(255)],
      selectArriveBackVehicle: [firstAddressBackVehicleAndEquipment.selectArriveBackVehicle, Validators.maxLength(255)],
      arriveEquipment: [firstAddressBackVehicleAndEquipment.arriveEquipment, Validators.maxLength(255)],
      selectArriveEquipment: [firstAddressBackVehicleAndEquipment.selectArriveEquipment, Validators.maxLength(255)],
      leaveBackVehicle: [firstAddressBackVehicleAndEquipment.leaveBackVehicle, Validators.maxLength(255)],
      selectLeaveBackVehicle: [firstAddressBackVehicleAndEquipment.selectLeaveBackVehicle, Validators.maxLength(255)],
      leaveEquipment: [firstAddressBackVehicleAndEquipment.leaveEquipment, Validators.maxLength(255)],
      selectLeaveEquipment: [firstAddressBackVehicleAndEquipment.selectLeaveEquipment, Validators.maxLength(255)]
    })
    this.addresses.push(addressForm)
  }

  duplicate(address: any): void {
    const addressForm = this.formBuilder.group({
      country: [address.value.country, Validators.maxLength(255)],
      state: [address.value.state, Validators.maxLength(255)],
      zipcode: [address.value.zipcode, [Validators.maxLength(10), Validators.required]],
      city: [address.value.city, [Validators.maxLength(255), Validators.required]],
      street: [address.value.street, Validators.maxLength(255)],
      latitude: address.value.latitude,
      longitude: address.value.longitude,
      priority: [this.formGroup.value.addresses.length + 1, Validators.required],
      description: address.value.description,
      additionalInformation: '',
      locationName: address.value.locationName,
      locationId: address.value.locationId,
      freight: address.value.freight,
      freightTypeId: address.value.freightTypeId,
      removedFreightAmount: address.value.removedFreightAmount,
      freightAmount: address.value.freightAmount,
      orderId: ['', [Validators.maxLength(40)]],
      arriveBackVehicle: [this.arriveBackVehicle ? this.arriveBackVehicle : '', Validators.maxLength(255)],
      selectArriveBackVehicle: [this.selectArriveBackVehicle ? this.selectArriveBackVehicle : '', Validators.maxLength(255)],
      arriveEquipment: [this.arriveEquipment ? this.arriveEquipment : '', Validators.maxLength(255)],
      selectArriveEquipment: [this.selectArriveEquipment ? this.selectArriveEquipment : '', Validators.maxLength(255)],
      leaveBackVehicle: [this.leaveBackVehicle ? this.leaveBackVehicle : '', Validators.maxLength(255), Validators.maxLength(255)],
      selectLeaveBackVehicle: [this.selectLeaveBackVehicle ? this.selectLeaveBackVehicle : '', Validators.maxLength(255)],
      leaveEquipment: [this.leaveEquipment ? this.leaveEquipment : '', Validators.maxLength(255), Validators.maxLength(255)],
      selectLeaveEquipment: [this.selectLeaveEquipment ? this.selectLeaveEquipment : '', Validators.maxLength(255)]
    });

    this.addresses.push(addressForm);
  }

  removeAddressAtIndex(index: number): void {
    this.addresses.removeAt(index);
    for (let i = 0; i < this.addresses.value.length; i++) {
      this.setPriority(i);
    }
  }

  /**
   * When the user SELECTS a value from the multiselect dropdown for ARRIVE BACKVEHICLES
   * @param item
   * @param index
   */
  onItemSelectArriveBackVehicle(item: any, index: number) {
    if (this.arriveBackVehicles[index] == null) {
      this.arriveBackVehicles[index] = [item]
    } else {
      this.arriveBackVehicles[index].push(item)
    }

    let localIdArray = this.arriveBackVehicles[index].map((item: any) => item.item_id)
    let localTextArray = this.arriveBackVehicles[index].map((item: any) => item.item_text)

    this.arriveBackVehicle[index] = localTextArray.join(',')
    this.selectArriveBackVehicle[index] = localIdArray.join(',')

    this.addresses.at(index).patchValue({
      arriveBackVehicle: localIdArray.join(','),
      leaveBackVehicle: localTextArray.join(','),
      selectArriveBackVehicle: localIdArray.join(','),
      selectLeaveBackVehicle: localIdArray.join(',')
    })
  }

  /**
   * When the user DESELECTS a value from the multiselect dropdown for ARRIVE BACKVEHICLES
   * @param item
   * @param index
   */
  onItemDeSelectArriveBackVehicle(item: any, index: number) {
    if (this.arriveBackVehicles[index] != null) this.arriveBackVehicles[index].splice(this.arriveBackVehicles[index].findIndex((x: any) => x.item_id === item.item_id), 1)

    let localIdArray = this.arriveBackVehicles[index].map((item: any) => item.item_id)
    let localTextArray = this.arriveBackVehicles[index].map((item: any) => item.item_text)

    this.addresses.value[index].arriveBackVehicle = localTextArray.join(',')
    this.arriveBackVehicle[index] = this.arriveBackVehicles[index].join(',')

    this.addresses.at(index).patchValue({
      arriveBackVehicle: localIdArray.join(','),
      leaveBackVehicle: localTextArray.join(','),
      selectArriveBackVehicle: localIdArray.join(','),
      selectLeaveBackVehicle: localIdArray.join(',')
    })
  }

  /**
   * When the user selects a value from the multiselect dropdown for LEAVE BACKVEHICLES
   * @param item
   * @param index
  */
  onItemSelectLeaveBackVehicle(item: any, index: number) {
    if (this.leaveBackVehicles[index] == null) {
      this.leaveBackVehicles[index] = [item]
    } else {
      this.leaveBackVehicles[index].push(item)
    }

    let localIdArray = this.leaveBackVehicles[index].map((item: any) => item.item_id)
    let localTextArray = this.leaveBackVehicles[index].map((item: any) => item.item_text)

    this.leaveBackVehicle[index] = localTextArray.join(',')
    this.selectLeaveBackVehicle[index] = localIdArray.join(',')

    this.addresses.at(index).patchValue({
      leaveBackVehicle: localIdArray.join(','),
      selectLeaveBackVehicle: localIdArray.join(',')
    })
  }

  /**
   * When the user deselects a value from the multiselect dropdown for LEAVE BACKVEHICLES
   * @param item
   * @param index
   */
  onItemDeSelectLeaveBackVehicle(item: any, index: number) {
    if (this.leaveBackVehicles[index] != null) this.leaveBackVehicles[index].splice(this.leaveBackVehicles[index].findIndex((x: any) => x.item_id === item.item_id), 1)

    let localIdArray = this.leaveBackVehicles[index].map((item: any) => item.item_id)
    let localTextArray = this.leaveBackVehicles[index].map((item: any) => item.item_text)

    this.addresses.value[index].leaveBackVehicle = localTextArray.join(',')
    this.leaveBackVehicle[index] = this.leaveBackVehicles[index].join(',')

    this.addresses.at(index).patchValue({
      leaveBackVehicle: localIdArray.join(','),
      selectLeaveBackVehicle: localIdArray.join(',')
    })
  }

  /**
   * When the user toggles between multiselect and free-form writing
   */
  switchArriveField(index: number): void {
    this.showArriveMultiSelect[index] = !this.showArriveMultiSelect[index];
  }

  /**
   * When the user toggles between multiselect and free-form writing
   */
  switchLeaveField(index: number): void {
    this.showLeaveMultiSelect[index] = !this.showLeaveMultiSelect[index];
  }

  getFormattedTime(time: number): string {
    return ('0' + time).slice(-2);
  }

  inheritFromCustomer(): void {
    if (this.formGroup.value.customerId)
      this.getCustomer().subscribe(response => {
        this.formGroup.value.tagIds = [];
        this.addresses.clear();
        for (let i = 0; i < response.addresses?.length; i++) { this.addAddress() }
        this.formGroup.patchValue({ addresses: response.addresses });
      });
  }

  inheritFromChauffeur(): void {
    if (this.formGroup.value.assignedUserId)
      this.userService.getSingleWithMeta(this.formGroup.value.assignedUserId).subscribe(response => {
        this.selectedFrontVehicleCallback(response?.userMeta);
        this.selectedBackVehicleCallback(response?.userMeta);
        this.selectedBackVehicleCallback(response?.userMeta);
        this.selectedTransportEquipmentCallback(response?.userMeta);
      });
  }

  getCustomer(): Observable<CustomerModel> {
    return this.customerService.getSingle(this.formGroup.value.customerId);
  }

  create(): void {
    this.loading = true;
    const values = this.formGroup.value

    let creations: CreateTourModel[] = [];
    //if (this.repetitionDateTimes.length === 0) this.calculateRepetitionCount();
    this.calculateRepetitionCount();
    this.repetitionDateTimes.forEach(repetition => {
      const model: CreateTourModel = {
        orderId: values.orderId,
        assignedUserId: this.selectedUser
          && !this.selectedUser.tours?.some(x => moment(repetition.item1).isBetween(moment(x.expectedStartDate), moment(x.expectedEndDate)))
          && !this.selectedUser.tours?.some(x => moment(repetition.item2).isBetween(moment(x.expectedStartDate), moment(x.expectedEndDate)))
          ? values.assignedUserId : values.assignedUserId,
        description: values.description,
        freight: values.freight,
        freightTypeId: values.freightTypeId,
        freightTotalAmount: values.freightTotalAmount,
        backVehicleIds: values.backVehicleIds,
        tagIds: values.tagIds,
        customerId: values.customerId,
        frontVehicleId: values.frontVehicleId,
        transportEquipmentId: values.transportEquipmentId,
        expectedStartDate: repetition.item1,
        expectedEndDate: repetition.item2,
        note: values.note,
        addresses: values.addresses,
        TourType: values.type,
        isSpecial: values.isSpecial,
        assignedSecondUserId: this.selectedSecondUser
          && !this.selectedSecondUser.tours?.some(x => moment(repetition.item1).isBetween(moment(x.expectedStartDate), moment(x.expectedEndDate)))
          && !this.selectedSecondUser.tours?.some(x => moment(repetition.item2).isBetween(moment(x.expectedStartDate), moment(x.expectedEndDate)))
          ? values.assignedSecondUserId : values.assignedSecondUserId,
        isLocked: values.isLocked
      };
      creations.push(model)
    });

    this.tourService.createRange({ models: creations }, this.formGroup.value.attachment?.file as File,
      (this.formGroup.value.attachment ? this.formGroup.value.attachment.showInApp : false))
      .subscribe(() => {
        this.created.emit();
        this.notifyService.successMessage(this.language?.message?.createdTour);
      }, error => this.loading = false);
  }

  selectedUserCallback(e: any): void {
    this.userSelect = false;
    this.selectedUser = e.user;
    this.formGroup.patchValue({ assignedUserId: this.selectedUser?.id });
    if (this.selectedUser) {
      this.inheritFromChauffeur();
    }
  }

  selectedSecondUserCallback(e: any): void {
    if (this.selectedUser?.id == e.user.id) return;
    this.secondUserSelect = false;
    this.selectedSecondUser = e.user;
    this.formGroup.patchValue({ assignedSecondUserId: this.selectedSecondUser?.id });
  }

  toogleSecondUserField(): void {
    this.showSecondUserField = !this.showSecondUserField;
    if (!this.showSecondUserField) {
      this.secondUserSelect = false;
      this.selectedSecondUser = null;
      this.formGroup.patchValue({ assignedSecondUserId: null });
    }
  }

  selectedCustomerCallback(e: any): void {
    this.customerSelect = false;
    this.selectedCustomer = e.customer;
    this.formGroup.patchValue({ customerId: this.selectedCustomer?.id });
    if (this.selectedCustomer) {
      this.inheritFromCustomer();
    }
  }

  selectedFrontVehicleCallback(e: any): void {
    this.frontVehicleSelect = false;
    this.selectedFrontVehicle = e?.frontVehicle;
    this.formGroup.patchValue({ frontVehicleId: this.selectedFrontVehicle?.id });
  }

  selectedBackVehicleCallback(e: any): void {
    this.backVehicleSelect = false;
    this.selectedBackVehicles = e?.backVehicles;
    this.formGroup.patchValue({ backVehicleIds: this.selectedBackVehicles?.map(x => x.id) })
  }

  selectedTransportEquipmentCallback(e: any): void {
    this.transportEquipmentSelect = false;
    this.selectedTransportEquipment = e?.transportEquipment;
    this.formGroup.patchValue({ transportEquipmentId: this.selectedTransportEquipment?.id });
  }

  get createModes() {
    return CreateMode;
  }

  get backVehiclesString(): string {
    let str = '';

    this.selectedBackVehicles?.forEach(x => {
      str = str + ` ${x.unitIdentification} ${x.registrationNumber},`
    });

    str = str.slice(0, -1)
    return str;
  }

  patchNote(note: NoteModel): void {
    this.formGroup.patchValue({ note: note });
  }

  patchAttachment(attachment: File): void {
    this.formGroup.patchValue({ attachment: attachment });
  }

  onChanges(): void {
    this.formGroup.get('repetition')?.valueChanges.subscribe(x => {
      if (x === 0) {
        this.formGroup.patchValue({ endRepetitionDate: '' });
        this.formGroup.get('endRepetitionDate')?.disable();
      }
      else {
        this.formGroup.get('endRepetitionDate')?.addValidators(Validators.required);
        this.formGroup.get('endRepetitionDate')?.enable();
      }
    });

    this.formGroup.get('expectedStartDate')?.valueChanges.subscribe((x) => {
      if (this.formGroup.get('expectedEndDate')?.value == '') {
        this.formGroup.patchValue({ expectedEndDate: x });
      }
    });
  }

  onChangeFreightType(event: any): void {
    this.freightTypeDescValue = this.freightTypeDescSelect.nativeElement.value;
    this.freightTypeUnitTitle = this.language.tour.freightTypeUnit[this.freightTypeDescValue];
  }

  calculateRepetitionCount(): number {
    const startDate = moment(this.datePipe.transform(this.formGroup.value.expectedStartDate, 'yyyy-MM-dd'));
    let selectedStopDate = moment(this.formGroup.value.endRepetitionDate);
    switch (this.formGroup.value.repetition) {
      case 1: {
        const difference = selectedStopDate.diff(startDate, 'days', true);
        this.setRepetitionDateTimes('days', difference);
        return difference;
      }
      case 2: {
        const difference = selectedStopDate.diff(startDate, 'weeks');
        this.setRepetitionDateTimes('weeks', difference);
        return difference;
      }
      case 3: {
        const difference = selectedStopDate.diff(startDate, 'months');
        this.setRepetitionDateTimes('months', difference);
        return difference;
      }
      default: {
        this.setRepetitionDateTimes('days', 0);
        return 0;
      }
    }
  }

  setRepetitionDateTimes(unitOfTime: DurationConstructor, repetitions: number): void {
    this.repetitionDateTimes = [];
    for (let i = 0; i <= repetitions; i++) {
      const start = moment(this.datePipe.transform(`${this.formGroup.value.expectedStartDate} ${this.formGroup.value.expectedStartTime}`, 'yyyy-MM-dd HH:mm'));
      const end = moment(this.datePipe.transform(`${this.formGroup.value.expectedEndDate} ${this.formGroup.value.expectedEndTime}`, 'yyyy-MM-dd HH:mm'));
      this.repetitionDateTimes?.push({ item1: start.add(i, unitOfTime).toDate(), item2: end.add(i, unitOfTime).toDate() });
    }
  }

  swap(index1: number, index2: number) {
    const extras = [...this.addresses.value];
    if (index2 > 0 && index1 < extras.length - 1) {
      [extras[index1], extras[index2]] = [extras[index2], extras[index1]];
      this.addresses.setValue(extras);
    }
    this.addresses.value.forEach((address: any, index: number) => {
      this.setPriority(index)
    })
  }

  setPriority(i: number) {
    this.addresses.controls[i].patchValue({ priority: i + 1 })
    if (i == 0) {
      this.addresses.controls[i].patchValue({ type: 'start' });
    }
    else if (i == this.addresses.value.length - 1) {
      this.addresses.controls[i].patchValue({ type: 'stop' });
    }
    else {
      this.addresses.controls[i].patchValue({ type: 'waypoint' });
    }
  }

  geocode(address: AbstractControl): void {
    this.isGeocoding = true;
    const values = address.value;
    this.geocoder.geocodeAddress(values.street, values.zipcode, values.city, values.state, values.country).subscribe(response => {
      address.patchValue({ longitude: response.longitude, latitude: response.latitude });
      this.isGeocoding = false;
    }, error => this.isGeocoding = false);
  }

  reverseGeocode(address: AbstractControl): void {
    this.isReverseGeocoding = true;
    const values = address.value;
    this.geocoder.reverseGeocodeAddress(values.latitude, values.longitude).subscribe(response => {
      address.patchValue({
        country: response.country,
        state: response.state,
        zipcode: response.zipcode,
        city: response.city,
        street: response.street,
      });
      this.isReverseGeocoding = false;
    }, error => this.isReverseGeocoding = false);
  }

  getValue(value: string) {
    this.http
      .get(
        'https://api.tomtom.com/search/2/search/' +
        value + '.json?' +
        'lat=' + this.currentLat + '&' +
        'lon=' + this.currentLon + '&' +
        'minFuzzyLevel=1&' +
        'maxFuzzyLevel=2&' +
        'view=Unified&' +
        'relatedPois=off&' +
        'key=' + environment.tomtom.key)
      .subscribe((data: any) => (this.searchResults = data['results']));
  }

  generateAdressTitle(result: any) {
    var returnString = "";
    if (result.poi !== undefined) {
      if (result.poi.name !== undefined) returnString += result.poi.name + " ";
    }

    if (result.address !== undefined) {
      if (result.address.streetName !== undefined) returnString += result.address.streetName + " ";
      if (result.address.streetNumber !== undefined) returnString += result.address.streetNumber + " ";
      if (result.address.municipality !== undefined) returnString += result.address.municipality + " ";
      if (result.address.country !== undefined) returnString += ", " + result.address.country + " ";
    }

    return returnString;
  }

  fillAddressForm(result: any, address: AbstractControl) {
    if (result.address !== undefined) {
      if (result.address.streetName !== undefined) address.patchValue({ street: result.address.streetName });
      if (result.address.streetName !== undefined && result.address.streetNumber !== undefined) address.patchValue({ street: result.address.streetName + " " + result.address.streetNumber });
      if (result.address.municipality !== undefined) address.patchValue({ city: result.address.municipality });
      if (result.address.country !== undefined) address.patchValue({ country: result.address.country });
      if (result.address.postalCode !== undefined) address.patchValue({ zipcode: result.address.postalCode });
      if (result.address.countrySubdivision !== undefined) address.patchValue({ state: result.address.countrySubdivision });
    }

    if (result.position !== undefined) {
      address.patchValue({ longitude: result.position.lon, latitude: result.position.lat });
    }

    this.searchResults = null;
  }

  selectedFreightType: any = 1;

  //event handler for the select element's change event
  freightTypeIdSelectChangeHandler(event: any) {
    //update the ui
    this.selectedFreightType = event.target.value;
  }

  getVehicleDisplayText(vehicle: FrontVehicleModel): string {
    return this.frontVehicleService.GetDisplayText(vehicle);
  }
}
