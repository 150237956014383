import {Directive, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {UserModel} from "../../users/shared/user.model";
import {UntypedFormBuilder, FormControl, UntypedFormGroup} from "@angular/forms";
import {Observable} from "rxjs";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {SharedService} from "../../shared/services/shared.service";

@Directive()
export abstract class SelectComponent<T>{

  data: T[] | undefined;
  dataCopy: T[] | undefined;

  @Output()
  selected = new EventEmitter();

  formGroup: UntypedFormGroup | undefined;

  searchQuery: string | undefined;

  modalReference: any;

  @Input()
  preselection: any;

  protected constructor(protected modalService: NgbModal, protected formBuilder: UntypedFormBuilder,
                        public sharedService: SharedService) {
  }

  search(newValue: string): void{
    this.dataCopy = Object.assign([], this.data)
      .filter(item =>
        Object.keys(item).some(k => {
          const itemValue: string = (item[k]);
          return !itemValue ? '' : itemValue.toString().toUpperCase().includes(newValue.toUpperCase());
        }));
  }

  assignCopy()
  {
    this.dataCopy = Object.assign([], this.data?.map(x => ({...x, showConflicts: false})));
  }

  openModal(content: any) {
    this.modalReference = this.modalService.open(content);
    this.modalReference.result.then(() => {
      if (this.formGroup)
        this.selected.emit(this.formGroup.value);
    }, () => {
      this.selected.emit(null);
    });
  }
}
