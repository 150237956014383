<app-loading class="overlay" *ngIf="loading"></app-loading>

<div *ngIf="id == 0">
  <h4 style="padding: 5px; margin-left: 10px; margin-top: 10px;">{{'user.pickUser' | translate}}</h4>
  <ul class="list-group" style="margin-top: 10px;">
    <li class="list-group-item" *ngFor="let userR of users" (click)="this.id = userR.id; refresh();">
      {{userR.firstname}} {{userR.lastname}}
    </li>
  </ul>
</div>

<div *ngIf="id != 0" ngbAccordion #accordion="ngbAccordion" [closeOthers]="true">
  <div ngbAccordionItem="first" [collapsed]="false">
    <h2 ngbAccordionHeader>
      <button ngbAccordionButton>{{'common.masterData' | translate}}</button>
    </h2>
    <div ngbAccordionCollapse>
      <div ngbAccordionBody>
        <ng-template>
          <div class="col-md-12" [ngClass]="{'bg-light' : !readOnly}">
            <div class="form-group row w-100 ps-3">
              <div class="col-md-12 pe-1">
                <label for="name">{{'user.name' | translate}}</label>
                <input id="name" type="text" [(ngModel)]="name" class="form-control form-control-sm" readonly>
              </div>
              <div class="col-md-4">
                <label for="mobile">{{'user.mobile' | translate}} </label>
                <input id="mobile" type="text" class="form-control form-control-sm" [(ngModel)]="mobile" readonly>
              </div>
              <div class="col-md-8 pe-1">
                <label for="mobile">{{'user.email' | translate}} </label>
                <div class="input-group">
                  <input class="form-control form-control-sm" [(ngModel)]="email" readonly>
                  <span class="btn btn-default btn-sm" style="border-bottom-left-radius: 0; border-top-left-radius: 0 " (click)="sendMail()" placement="left" ngbTooltip="{{'btn.mail' | translate}}">
                    <fa-icon [icon]="faMail"></fa-icon>
                  </span>
                </div>
              </div>
            </div>
            <hr class="full-width">
            <div class="row w-100">
              <div class="d-flex pe-1">
                <div class="flex-grow-1 ms-3">
                  <h6>{{'common.user' | translate}} {{'common.masterData' | translate}}</h6>
                </div>
                <button type="button" class="btn btn-default btn-sm" (click)="readOnly = ! readOnly" placement="left" ngbTooltip="{{'btn.edit' | translate}}">
                  <fa-icon [icon]="faEdit"></fa-icon>
                </button>
              </div>
            </div>
            <form [formGroup]="metaFormGroup">
              <div class="row">
                <div class="col-md-4">
                  <label for="title">
                    {{'user.title' | translate}}
                    <span *ngIf="metaFormGroup.controls['title'].hasError('maxlength')" class="required-text">
                      {{'message.maxLength' | translate: {number: 50} }}
                    </span>
                  </label>
                  <input id="title" type="text" formControlName="title" class="form-control form-control-sm" [readOnly]="readOnly">
                </div>

                <div class="col-md-4">
                  <label for="driversLicenseNumber">{{'user.driversLicense' | translate}}
                    <span *ngIf="metaFormGroup.controls['driversLicenseNumber'].hasError('pattern')" class="required-text">
                      {{'message.driversLicenseNumberPattern' | translate }}
                    </span>
                  </label>
                  <input id="driversLicenseNumber" type="text" formControlName="driversLicenseNumber" class="form-control form-control-sm" [readOnly]="readOnly">
                </div>

                <div class="col-md-4">
                  <label for="driversLicenseNumberExpires">{{'user.driversLicenseExpiration' | translate}}</label>
                  <input id="driversLicenseNumberExpires" type="date" formControlName="driversLicenseNumberExpires" class="form-control form-control-sm" [readOnly]="readOnly">
                </div>
              </div>
              <div class="row">
                <div class="col-md-4">
                  <label for="salaryNumber">
                    {{'user.salaryNumber' | translate}}
                    <span *ngIf="metaFormGroup.controls['salaryNumber'].hasError('maxlength')" class="required-text">
                      {{'message.maxLength' | translate: {number: 20} }}
                    </span>
                    <span *ngIf="metaFormGroup.controls['salaryNumber'].hasError('required')" class="required-text">
                      {{'message.required' | translate }}
                    </span>
                  </label>
                  <input id="salaryNumber" type="text" formControlName="salaryNumber" class="form-control form-control-sm" [readOnly]="readOnly">
                </div>
                <div class="col-md-4 ">
                  <label for="salaryNumberHomeCountry">
                    {{'user.salaryNumberHomeCountry' | translate}}
                    <span *ngIf="metaFormGroup.controls['salaryNumberHomeCountry'].hasError('maxlength')" class="required-text">
                      {{'message.maxLength' | translate: {number: 20} }}
                    </span>
                  </label>
                  <input id="salaryNumberHomeCountry" type="text" formControlName="salaryNumberHomeCountry" class="form-control form-control-sm" [readOnly]="readOnly">
                </div>

                <div class="col-md-4">
                  <label for="permission">
                    {{'user.permission' | translate}}
                    <span *ngIf="metaFormGroup.controls['permission'].hasError('required')" class="required-text">
                      {{'message.required' | translate}}
                    </span>
                  </label>
                  <select id="permission" type="text" formControlName="permission" class="form-select form-select-sm" [attr.disabled]="readOnly ? '' : null">
                    @for(type of permissionTypes; track $index) {
                      @if(type == 'Read') {
                        <option [value]="type" [attr.selected]="!loading ? 'selected' : null">
                          {{'permission.' + type.toLowerCase() | translate}}
                        </option>
                      }
                      @else {
                        <option [value]="type">
                          {{'permission.' + type.toLowerCase() | translate}}
                        </option>
                      }
                    }
                  </select>
                </div>
              </div>

              <div class="row">
                <div class="col-md-12">
                  <app-address-widget class="full-width mt-3" *ngIf="!readOnly" [createMode]="createModes.create_as_user" (createAddress)="patchAddress($event)" [address]="address" [showDescription]="false" (editAddress)="patchAddress($event)" [showCoordinates]="false" [showEditButton]="false"
                    [showLocationNameAndId]="false" [showFreightSection]="false" [addressIsRequired]="false">
                  </app-address-widget>

                  <app-address-widget class="full-width" *ngIf="readOnly && !loading" [address]="address" [showCoordinates]="false" [showDescription]="false" [showEditButton]="false" [showLocationNameAndId]="false" [showFreightSection]="false" [addressIsRequired]="false"></app-address-widget>
                </div>
              </div>
              <div class="row">
                <div class="col-md-4">
                  <label for="startDate">
                    {{'user.accessionDate' | translate}}
                    <span *ngIf="metaFormGroup.controls['startDate'].hasError('required')" class="required-text">
                      {{'message.required' | translate}}
                    </span>
                  </label>
                  <input id="startDate" type="date" formControlName="startDate" class="form-control form-control-sm" [readOnly]="readOnly">
                </div>
                <div class="col-md-4">
                  <label for="skilledDate">{{'user.skilledDate' | translate}}</label>
                  <input id="skilledDate" type="date" formControlName="skilledDate" class="form-control form-control-sm" [readOnly]="readOnly">
                </div>
                <div class="col-md-4">
                  <label for="resignationDate">{{'user.resignationDate' | translate}}</label>
                  <input id="resignationDate" type="date" formControlName="resignationDate" class="form-control form-control-sm" [readOnly]="readOnly">
                </div>
              </div>
              <div class="row">
                <div class="col-md-12 pt-3">
                  <div class="form-check-inline">
                    <label>
                      <input type="checkbox" value="overrideSkilled" formControlName="overrideSkilled" style="height:15px; width:15px; margin-right: 5px" [attr.disabled]="readOnly ? readOnly : null">
                      <span>{{'user.overrideSkilled' | translate}}</span>
                    </label>
                  </div>
                  <div class="form-check-inline">
                    <label>
                      <input type="checkbox" value="onlyPull" formControlName="onlyPull" style="height:15px; width:15px; margin-right: 5px" [attr.disabled]="readOnly ? readOnly : null">
                      <span>{{'user.onlyPull' | translate}} {{readOnly}}</span>
                    </label>
                  </div>
                </div>
              </div>

              <hr class="full-width">
              <div class="row">
                <div class="col-md-12">
                  <h6>{{'common.attached' | translate}}</h6>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <button class="btn btn-sm btn-link" (click)="readOnly ? frontVehicleSelect = false : frontVehicleSelect = true" [ngClass]="readOnly ? 'text-dark' : 'text-success'">
                    {{'tour.frontVehicle' | translate}}{{readOnly ? '' : '...'}}
                  </button>
                  <div *ngIf="frontVehicle" class="col-md-12">
                    <button class="btn btn-secondary btn-sm" style=" margin: 0px 5px 0 0;" (click)="goTo('front-vehicles', user?.userMeta?.frontVehicle?.id)" [disabled]="!readOnly">
                      {{'vehicle.no' | translate}}: {{frontVehicle?.unitIdentification}} - Reg:
                      {{frontVehicle?.registrationNumber}} - {{'vehicle.manufacturer' | translate}}:
                      {{frontVehicle?.manufacturer}}
                    </button>
                  </div>
                  <app-front-vehicle-select *ngIf="frontVehicleSelect" id="frontVehicle-select" (selected)="selectedFrontVehicleCallback($event)" [preselection]="frontVehicle"></app-front-vehicle-select>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <button class="btn btn-sm btn-link" (click)="readOnly ? backVehicleSelect = false : backVehicleSelect = true" [ngClass]="readOnly ? 'text-dark' : 'text-success'">
                    {{'tour.backVehicle' | translate}}{{readOnly ? '' : '...'}}
                  </button>
                  <div *ngIf="backVehicles">
                    <div *ngIf="backVehicles.length > 0" class="col-md-12">
                      <button class="btn btn-sm btn-secondary" [disabled]="!readOnly" style=" margin: 0px 5px 0 0;" (click)="goTo('back-vehicles', backVehicle.id)" *ngFor="let backVehicle of backVehicles">
                        {{'vehicle.no' | translate}}: {{backVehicle.unitIdentification}} - Reg:
                        {{backVehicle.registrationNumber}} - {{'vehicle.manufacturer' | translate}}:
                        {{backVehicle.manufacturer}}
                      </button>
                    </div>
                  </div>
                  <app-back-vehicle-select *ngIf="backVehicleSelect" id="backVehicle-select" (selected)="selectedBackVehicleCallback($event)" [preselection]="backVehicles"></app-back-vehicle-select>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <button class="btn btn-sm btn-link" (click)="readOnly ? transportEquipmentSelect = false : transportEquipmentSelect = true" [ngClass]="readOnly ? 'text-dark' : 'text-success'">
                    {{'tour.transportEquipment' | translate}}{{readOnly ? '' : '...'}}
                  </button>
                  <div *ngIf="transportEquipment" class="col-md-12">
                    <button class="btn btn-sm btn-secondary" [disabled]="!readOnly" style=" margin: 0px 5px 0 0;" (click)="goTo('transport-equipments',user?.userMeta?.transportEquipment?.id)">
                      {{'vehicle.no' | translate}}: {{transportEquipment?.unitIdentification}} - {{
                      'vehicle.manufacturer' |
                      translate
                      }}: {{transportEquipment?.manufacturer}}
                    </button>
                  </div>
                  <app-transport-equipment-select *ngIf="transportEquipmentSelect" (selected)="selectedTransportEquipmentCallback($event)" [preselection]="transportEquipment"></app-transport-equipment-select>
                </div>
              </div>
              <hr class="full-width">
              <div class="row">
                <div class="col-md-12" formGroupName="inlandAgreement">
                  <h6>
                    {{'user.inlandDrive' | translate}} <span class="text-danger required-text" *ngIf="metaFormGroup.controls['inlandAgreement'].invalid">
                      {{'user.lackFill' | translate}}
                    </span>
                  </h6>
                  <div class="row">
                    <div class="col-md-6 form-check" style="line-height: 0.3; padding-left: 35px">
                      <div class="row">
                        <div class="col-md-12 form-check" style="line-height: 0.3; padding-left: 35px">
                          <input class="form-check-input" type="radio" id="CollectiveDefined" (click)="setDaysValidators(true)" value="CollectiveDefined" formControlName="inlandAgreementType" [attr.disabled]="readOnly ? '' : null">
                          <label class="form-check-label unselectable" style="margin-left: 5px" for="CollectiveDefined">
                            {{'user.reiterateWorkSchedule' | translate}}
                          </label>
                        </div>
                      </div>
                      <!--  Disabled until needed
                      <div class="row">
                        <div class="col-md-12 form-check" style="line-height: 0.3; padding-left: 35px">
                          <input class="form-check-input" type="radio" id="LocaleDefinedFixed"
                            (click)="setDaysValidators(true)" value="LocaleDefinedFixed"
                            formControlName="inlandAgreementType" [attr.disabled]="readOnly ? '' : null">
                          <label class="form-check-label unselectable" style="margin-left: 5px" for="LocaleDefinedFixed">
                            {{'user.localWorkSchedule' | translate}}
                          </label>
                        </div>
                      </div>-->
                    </div>
                    <div class="col-md-6 form-check" style="line-height: 0.3">
                      <input class="form-check-input" type="radio" id="CollectiveUnDefined" (click)="setDaysValidators()" value="CollectiveUnDefined" formControlName="inlandAgreementType" [attr.disabled]="readOnly ? '' : null">
                      <label class="form-check-label unselectable" style="margin-left: 5px" for="CollectiveUnDefined">
                        {{'user.noReiterateWorkSchedule' | translate}}
                      </label>
                    </div>
                    <div class="col-md-6">
                      <div class="row">
                        <div class="col-md-2 text-end"></div>
                        <div class="col-md-1 text-default day-name unselectable">M</div>
                        <div class="col-md-1 text-default day-name unselectable">T</div>
                        <div class="col-md-1 text-default day-name unselectable">O</div>
                        <div class="col-md-1 text-default day-name unselectable">T</div>
                        <div class="col-md-1 text-default day-name unselectable">F</div>
                        <div class="col-md-1 text-default day-name unselectable">L</div>
                        <div class="col-md-1 text-default day-name unselectable">S</div>
                      </div>
                    </div>

                    <div class="col-md-6 form-check" style="line-height: 0.3">
                      <input class="form-check-input" type="radio" id="LocaleDefined" (click)="setDaysValidators()" value="LocaleDefined" formControlName="inlandAgreementType" [attr.disabled]="readOnly ? '' : null">
                      <label class="form-check-label unselectable" style="margin-left: 5px" for="LocaleDefined">
                        {{'user.localAgreement' | translate}}
                      </label>
                    </div>

                    <div class="col-md-6">
                      <div class="row" style="padding-left: 8px!important;" formGroupName="hours">
                        <div class="col-md-2"></div>
                        <div class="col-md-1 day-input">
                          <input [readOnly]="readOnly" class="form-control form-control-sm full-width" formControlName="mo">
                        </div>
                        <div class="col-md-1 day-input">
                          <input [readOnly]="readOnly" class="form-control form-control-sm full-width" formControlName="tu">
                        </div>
                        <div class="col-md-1 day-input">
                          <input [readOnly]="readOnly" class="form-control form-control-sm full-width" formControlName="we">
                        </div>
                        <div class="col-md-1 day-input">
                          <input [readOnly]="readOnly" class="form-control form-control-sm full-width" formControlName="th">
                        </div>
                        <div class="col-md-1 day-input">
                          <input [readOnly]="readOnly" class="form-control form-control-sm full-width" formControlName="fr">
                        </div>
                        <div class="col-md-1 day-input">
                          <input [readOnly]="readOnly" class="form-control form-control-sm full-width" formControlName="sa">
                        </div>
                        <div class="col-md-1 day-input">
                          <input [readOnly]="readOnly" class="form-control form-control-sm full-width" formControlName="su">
                        </div>
                        <div class="col-md-2"></div>
                      </div>
                    </div>
                    <div class="col-md-6 form-check" style="line-height: 0.3">
                      <input class="form-check-input" type="radio" id="UserDefined" (click)="setDaysValidators()" value="UserDefined" formControlName="inlandAgreementType" [attr.disabled]="''">
                      <label class="form-check-label unselectable" style="margin-left: 5px" for="UserDefined">
                        {{'user.indivudualAgreement' | translate}}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <hr class="full-width">
              <div class="row">
                <div class="col-md-12">
                  <h6>{{'user.exportDrive' | translate}}</h6>
                  <fieldset id="group2">
                    <div class="row">
                      <div class="col-md-6 form-check" style="line-height: 0.3; padding-left: 35px">
                        <input class="form-check-input" type="radio" id="exportAgreementTypeCollective" value="CollectiveDefined" formControlName="exportAgreementType" [attr.disabled]="readOnly ? '' : null">
                        <label class="form-check-label unselectable" style="margin-left: 5px" for="exportAgreementTypeCollective">
                          {{'user.exportDrive3F' | translate}}
                        </label>
                      </div>
                      <div class="col-md-6 form-check" style="line-height: 0.3">
                        <input class="form-check-input" type="radio" id="exportAgreementTypeUserDefined" value="UserDefined" formControlName="exportAgreementType" [attr.disabled]="''">
                        <label class="form-check-label unselectable" style="margin-left: 5px" for="exportAgreementTypeUserDefined">
                          {{'user.indivudualAgreement' | translate}}
                        </label>
                      </div>
                    </div>
                  </fieldset>
                </div>
              </div>
              <div *ngIf="!readOnly" class="col-md-12 btn-container">
                <app-btn-accept-widget (cancel)="readOnly = true; patchMetaForm()" [formGroup]="metaFormGroup" (accept)="update()"></app-btn-accept-widget>
              </div>
            </form>
          </div>
        </ng-template>
      </div>
    </div>
  </div>
  <div ngbAccordionItem="second">
    <h2 ngbAccordionHeader>
      <button ngbAccordionButton>{{'toursBook.accordionTitle' | translate}}</button>
    </h2>
    <div ngbAccordionCollapse>
      <div ngbAccordionBody>
        <ng-template>
          <app-tours-book [selectedItemId]="id" [resourceType]="'User'"></app-tours-book>
        </ng-template>
      </div>
    </div>
  </div>
  <div ngbAccordionItem="third">
    <h2 ngbAccordionHeader>
      <button ngbAccordionButton>{{'user.event.absence' | translate}}
        <div class="plus-btn-right">
          <button class="btn btn-light btn-sm rounded-circle" placement="left" ngbTooltip="{{'user.event.addAbsence' | translate}}"
                  (click)="addEvent = true; $event.stopPropagation()">
            <fa-icon [icon]="faAdd"></fa-icon>
          </button>
        </div>
      </button>
    </h2>
    <div ngbAccordionCollapse>
      <div ngbAccordionBody>
        <ng-template>
          <app-user-event-widget *ngIf="addEvent" [createMode]="true" (cancelCreate)="addEvent = false" (createEvent)="createEvent($event)"></app-user-event-widget>
          <p *ngIf="lastEventIndex() === 0" class="none-text" style="margin-left: 15px">{{'message.none' | translate}}</p>
          <ng-container *ngFor="let event of user?.events; let i = index">
            <app-user-event-widget [event]="event" (deleteEvent)="deleteEvent($event)" (cancelEvent)="cancelEvent()" (updateEvent)="updateEvent($event)"></app-user-event-widget>
            <hr *ngIf="i + 1 < lastEventIndex()">
          </ng-container>
        </ng-template>
      </div>
    </div>
  </div>
  <div ngbAccordionItem="fourth" (shown)="drawCalendar()" [destroyOnHide]="false">
    <h2 ngbAccordionHeader>
      <button ngbAccordionButton>{{'user.timeHistory' | translate}}</button>
    </h2>
    <div ngbAccordionCollapse>
      <div ngbAccordionBody>
        <ng-template>
          <app-time-statement [user]="user"></app-time-statement>
        </ng-template>
      </div>
    </div>
  </div>
  <div ngbAccordionItem="fifth">
    <h2 ngbAccordionHeader>
      <button ngbAccordionButton>{{'user.timeSalary' | translate}}</button>
    </h2>
    <div ngbAccordionCollapse>
      <div ngbAccordionBody>
        <ng-template>
          <app-time-table [user]="user"></app-time-table>
        </ng-template>
      </div>
    </div>
  </div>
</div>
