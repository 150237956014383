import { Directive, HostListener, Output, EventEmitter } from '@angular/core';

@Directive({
  selector: '[appScrollZoomCtl]'
})
export class ScrollZoomCtlDirective {

  @Output() disableAutoZoomDir = new EventEmitter<boolean>();

  constructor() {}

  @HostListener("wheel", ["$event"])
  public onScroll(event: WheelEvent) {
    //this.element.nativeElement.scrollLeft += event.deltaY;

    this.disableAutoZoomDir.emit(true);
  }
}
