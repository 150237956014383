<div class="col-md-12 table-responsive" style="padding-top: 15px">
    <table class="table table-bordered">
      <thead class="unselectable">
        <tr>
          <th style="padding-left: 2px!important;">{{'timeRegistrationTable.start' | translate}}</th>
          <th class="text-center">{{'timeRegistrationTable.end' | translate}}</th>
        </tr>
      </thead>
      <tbody class="text-center">
        <tr *ngFor="let tr of timeregistrations">
          <td>{{tr.start}}</td>
          <td>{{tr.end}}</td>
        </tr>
      </tbody>
    </table>
  </div>