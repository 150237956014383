import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { FormsModule } from "@angular/forms";
import { SharedService } from "src/app/shared/services/shared.service";
import { IMqttMessage } from "ngx-mqtt";
import { EventMqttService } from "src/app/acr/shared/event-mqtt.service";
import { Observable, Subscription, timer } from "rxjs";
import { BoxService } from "./shared/boxservice.service";
import { FOTAService } from "./shared/fota.service";
import { forEach } from "vis-util";
import {
  BoxServiceCompanyModel,
  BoxServiceCreateBoxRequestModel,
  BoxServiceVehicleSelect,
} from "./shared/boxservice.model";
import {
  FotaServiceTaskRequest,
  FotaServiceConfigModel,
} from "./shared/fota.model";
import { TokenService } from "src/app/services/token.service";
import { MqttStatusModel } from "./shared/mqttStatus.model";
import { HttpErrorResponse } from "@angular/common/http";
import { FotaServiceTaskResponse } from "./shared/fota.model";
import { map, switchMap } from "rxjs/operators";
import { TcpServerService } from "./shared/tcpServer.service";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-mss",
  templateUrl: "./mss.component.html",
  styleUrls: ["./mss.component.css"],
})
export class MssComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private boxService: BoxService,
    private fotaService: FOTAService,
    private tcpService: TcpServerService,
    private tokenService: TokenService,
    private SharedService: SharedService,
    private mqttService: EventMqttService,
    private translate: TranslateService
  ) { }

  subscription: Subscription | undefined;

  imeiRead: any | undefined;
  iccidRead: any | undefined;
  step: number = 1;
  dataLoading: boolean = false;

  error: boolean = false;
  errorText: string = "";

  companyCodeValue: any | undefined;

  selectedCompany: BoxServiceCompanyModel | undefined;
  selectedCompanyGuid: string = "";

  selectVehicleList: BoxServiceVehicleSelect[] = [];
  selectConfigList: FotaServiceConfigModel[] = [];

  mountedVehicle: any;

  selectedVehicleBoxStatus: MqttStatusModel = {
    timestamp: 0,
    driverCardStatus: false,
    fuelDataStatus: false,
    latestStatus: 0,

  };

  selectedVehicle: any;
  selectedConfig: any;
  mqttActive: boolean = false;

  currentUnitModel: string = "";

  pendingFotaTask: boolean = false;
  pendingFotaTaskId: number = 0;
  pendingFotaTaskTime: number = 0;
  pendingFotaTaskTimestamp: Date = new Date();

  pendingDataTask: boolean = false;
  pendingDataTaskTime: number = 0;
  pendingDataTaskTimestamp: Date = new Date();

  timerSubscription: Subscription | undefined;

  /// Todoliste:
  /// - Tilføj firma information til fota kaldene
  /// - Automatisk tjek af fms (kræver 1nce integration)
  /// - 1nce integration
  /// - Bedre gui
  /// - Mulighed for at komme "midt ind i et step"
  /// - CreateBox skal knytte en enhed til bilen, også selvom den allerede er oprettet
  /// - Bedre status, med info om sidste data fra bil o.l. (ligger klar i payload)


  ngOnInit(): void {
    const queries = this.route.snapshot.queryParamMap;

    this.imeiRead = this.route.snapshot.queryParamMap.get("imei");
    this.iccidRead = this.route.snapshot.queryParamMap.get("iccid");

    this.step = 1;

    if (this.imeiRead == null || this.iccidRead == null) {
      this.error = true;
      this.errorText = this.translate.instant('mss.qrCodeError');
    }

    this.selectConfigList = [
      { id: 1, name: this.translate.instant('mss.fms.activeBaud250') },
      { id: 2, name: this.translate.instant('mss.fms.activeBaud500') },
      { id: 3, name: this.translate.instant('mss.fms.inactiveBaud250WithoutResistance') },
      { id: 4, name: this.translate.instant('mss.fms.inactiveBaud500WithoutResistance') },
      { id: 5, name: this.translate.instant('mss.fms.inactiveBaud250WithResistance') },
      { id: 6, name: this.translate.instant('mss.fms.inactiveBaud500WithResistance') }
    ];

    this.timerSubscription = timer(0, 30000)
      .pipe(
        map(() => {
          this.timedTest(); // load data contains the http request
        })
      )
      .subscribe();
  }


  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }


  nextStep(): void {
    this.step = this.step + 1;
    this.error = false;
    this.errorText = "";
  }


  testCompanyCode(): void {
    this.dataLoading = true;
    this.selectVehicleList = [];

    this.boxService.getAllCompanies(this.companyCodeValue).subscribe((companies) => {
      companies.forEach((company) => {
        if (
          this.companyCodeValue != null &&
          this.companyCodeValue == company.installmentCode
        ) {

          this.selectedCompany = company;
          this.selectedCompanyGuid = company.guid;

          this.tokenService.setCompanyGuid(company.guid);

          this.subscribeToTopic(this.selectedCompanyGuid);

        }
      });

      if (this.selectedCompany == null) {
        this.error = true;
        this.errorText = this.translate.instant('mss.companyCodeDoesNotExist');
      } else {
        this.boxService
          .getAllFrontVehicles(this.companyCodeValue)
          .subscribe((frontVehicles) => {
            this.mountedVehicle = frontVehicles.find((vehicle) => vehicle.box != null && vehicle.box.uid == this.imeiRead);

            if (this.mountedVehicle != undefined) {
              this.nextStep();
              this.nextStep();
            } else {
              frontVehicles.forEach((frontVehicle) => {
                let curFrontVehicle: BoxServiceVehicleSelect = {
                  chassisNumber: frontVehicle.chassisNumber,
                  id: frontVehicle.id,
                  registrationNumber: frontVehicle.registrationNumber,
                };

                if (frontVehicle.box == null)
                  this.selectVehicleList.push(curFrontVehicle);
              });

              if (this.selectVehicleList.length == 0) {
                this.error = true;
                this.errorText = this.translate.instant('mss.noVehiclesWithoutUnitFitted');
              } else {
                this.nextStep();
              }
            }
          });
      }

      this.dataLoading = false;
    });



  }


  selectVehicle(): void {
    let createBoxRequest: BoxServiceCreateBoxRequestModel = {
      imei: this.imeiRead,
      iccid: this.iccidRead,
      canBaud1: "0",
      vehicleId: this.selectedVehicle,
      boxType: "frontvehicle"
    };

    this.boxService.createBoxOnVehicle(createBoxRequest).subscribe((result) => {
      this.tcpService.refreshTcp().subscribe(
        (result) => {
          if (result[0].includes("ERROR")) {
            this.error = true;
            this.errorText = this.translate.instant('mss.errorOnRefreshTCP');
          } else {
            this.nextStep();
          }
        },
        (error: HttpErrorResponse) => {
          console.error("error", error);
          this.error = true;
          this.errorText = this.translate.instant('mss.errorOnRefreshTCPCall');
        }
      );
    });
  }

  sendConfig(): void {
    alert(this.translate.instant('mss.unplugUnitAndWait2Minutes'));

    this.fotaService.checkIfImeiExist(this.imeiRead).subscribe(
      (resultUnit) => {

        this.currentUnitModel = resultUnit.model;

        let configArray = [];
        // Todo. FLere configs og fleksibilitet

        if (resultUnit.model == "FMB640") {
          configArray[1] = 2247518;
          configArray[2] = 0;
          configArray[3] = 2241413;
          configArray[4] = 2325113;
          configArray[5] = 0;
          configArray[6] = 0;
        }

        if (resultUnit.model == "FMC650") {
          configArray[1] = 3406993;
          configArray[2] = 3406994;
          configArray[3] = 3406996;
          configArray[4] = 3406998;
          configArray[5] = 3406995;
          configArray[6] = 3406997;
        }

        if (resultUnit.model == "FMC130") {
          configArray[1] = 2181885;
          configArray[2] = 2181886;
          configArray[3] = 2181885;
          configArray[4] = 2181886;
          configArray[5] = 0;
          configArray[6] = 0;
        }

        let request: FotaServiceTaskRequest = {
          device_imei: this.imeiRead,
          type: "TxConfiguration",
          file_id: configArray[this.selectedConfig],
        };

        this.fotaService.createTask(request).subscribe(
          (result) => {

            this.pendingFotaTask = true;
            this.pendingFotaTaskId = result.data.id;
            this.pendingFotaTaskTime = 0;
            this.pendingFotaTaskTimestamp = new Date();
          },
          (error: HttpErrorResponse) => {
            console.error("error", error);
            this.error = true;
            this.errorText = this.translate.instant('mss.errorXYZContactSupport');
          }
        );
      },
      (error: HttpErrorResponse) => {
        console.error("error", error);
        this.error = true;
        this.errorText = this.translate.instant('mss.unitNotFoundContactSupport');
      }
    );
  }

  private subscribeToTopic(deviceId: string) {
 
    this.subscription = this.mqttService
      .topic(deviceId)
      .subscribe((data: IMqttMessage) => {
 
        this.mqttActive = true;
        let payload = {
          vehicleId: data.topic.split("/")[2],
          payload: JSON.parse(data.payload.toString()),
        };
        if (payload.payload.imei == this.imeiRead) {
          this.selectedVehicleBoxStatus.timestamp = payload.payload.timestamp;

          if (payload.payload.fuelUsage != null)
            this.selectedVehicleBoxStatus.fuelDataStatus = true;
          if (
            this.getObject(payload.payload.iOElements, 184) != null ||
            this.getObject(payload.payload.iOElements, 185) != null ||
            ( this.getObject(payload.payload.iOElements, 48) != null && this.getObject(payload.payload.iOElements, 48) != 0)
          )
            this.selectedVehicleBoxStatus.driverCardStatus = true;
        }
      });
  }


  timedTest(): any {
    if (this.pendingFotaTask) {
      this.pendingFotaTaskTime = this.minutesDiff(
        this.pendingFotaTaskTimestamp,
        new Date()
      );

      this.fotaService.testTask(this.pendingFotaTaskId).subscribe(
        (result) => {
          if ((result.status_id == 2)) {

            this.nextStep();
            this.pendingDataTask = true;
            this.pendingDataTaskTimestamp = new Date();
          }
        },
        (error: HttpErrorResponse) => {
          console.error("error", error);
          this.error = true;
          this.errorText = this.translate.instant('mss.errorXYZ2ContactSupport');
        }
      );
    }

    if (this.pendingDataTask) {
      this.pendingDataTaskTime = this.minutesDiff(
        this.pendingDataTaskTimestamp,
        new Date()
      );

      if (
        this.selectedVehicleBoxStatus.driverCardStatus == true &&
        this.selectedVehicleBoxStatus.fuelDataStatus == true
      ) {
        this.nextStep();
      }
    }

    return 1;
  }


  getObject(array: any[], key: any, prop: any = "key") {
    prop = typeof prop === "undefined" ? "key" : prop;
    for (var i = 0; i < array.length; i++) {
      if (array[i][prop] === key) {
        return array[i]["value"];
      }
    }
  }


  getTimeFromUnix(unix: number) {
    var d = new Date(unix);
    return this.minutesDiff(d, new Date());
  }


  minutesDiff(dateTimeValue2: Date, dateTimeValue1: Date) {
    var differenceValue =
      (dateTimeValue2.getTime() - dateTimeValue1.getTime()) / 1000;
    differenceValue /= 60;
    return Math.abs(Math.round(differenceValue));
  }
}
