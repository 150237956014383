import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core'
import { AbstractControl, FormControl, FormGroup } from '@angular/forms'
import { IconDefinition, faStar, faEllipsisVertical, faTruck, faTruckPickup, faBriefcase, faCircleUser, faBoxesStacked, faDolly, faCheckCircle, faCircleXmark, faPencil } from '@fortawesome/free-solid-svg-icons'
import { TranslateService } from '@ngx-translate/core'
import { ToastrService } from 'ngx-toastr'
import { MainGroup, SubGroup } from 'src/app/grouping/grouping.models'

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrl: './card.component.scss'
})
export class CardComponent implements OnInit {
  @Input() data: MainGroup | any
  @Input() image: string = ""
  @Input() link: string = ""
  @Input() linkText: string = ""
  @Input() showImage: boolean = true

  @Output() isEditing = new EventEmitter()
  @Output() selectCard = new EventEmitter()
  @Output() saveEvent = new EventEmitter()
  @Output() cancelEvent = new EventEmitter()
  @Output() formSubmit = new EventEmitter()

  @ViewChild('favouriteButton', { static: false }) favouriteButton: ElementRef = new ElementRef(null)

  cardForm: FormGroup = new FormGroup({})

  selected: boolean = false
  edit: boolean = false
  copyToCancel: MainGroup | undefined

  faStar: IconDefinition = faStar
  faTruck: IconDefinition = faTruck
  faCircleUser: IconDefinition = faCircleUser
  faDolly: IconDefinition = faDolly
  faBoxesStacked: IconDefinition = faBoxesStacked
  faBriefcase: IconDefinition = faBriefcase
  faTruckPickup: IconDefinition = faTruckPickup
  faDots: IconDefinition = faEllipsisVertical
  faCheckCircle: IconDefinition = faCheckCircle
  faCircleXmark: IconDefinition = faCircleXmark
  faPencil: IconDefinition = faPencil



  constructor(private toaster: ToastrService, private translate: TranslateService) { }

  ngOnInit(): void {
    this.edit = this.data.edit

    if (this.edit == true) {
      this.isEditing.emit(true)
    }
  }

  get f(): { [key: string]: AbstractControl } {
    return this.cardForm.controls;
  }


  favourite() {
    this.favouriteButton.nativeElement.classList.toggle('active')
    this.data.favourite = !this.data.favourite
  }


  save() {
    if (this.data.name == "" || this.data.description == "") {
      this.toaster.warning(this.translate.instant('grouping.validation.missingFields'))
      return
    }

    this.edit = false
    this.isEditing.emit(false)
    this.saveEvent.emit({ data: this.data, copy: this.copyToCancel })
  }


  cancel() {
    this.edit = false
    this.isEditing.emit(false)
    this.cancelEvent.emit({ data: this.data, copy: this.copyToCancel })
  }


  toggleEdit(obj: MainGroup) {
    this.edit = !this.edit
    this.isEditing.emit(true)
    this.copyToCancel = { ...obj }
  }


  cardSelection() {
    this.selectCard.emit(this.data)
  }


  onSubmit(event: Event) {
    // event.preventDefault()

    this.formSubmit.emit({ data: this.data, copy: this.copyToCancel })
  }


  returnCount(group: any, resourceType: string) {
    const exists = group.subGroups?.find((x: any) => x.resourceType == resourceType)

    return exists?.members?.length > 0 ? exists.members.length : 0
  }
}
