import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NoteDateModel} from "./shared/noteDate.model";
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {AddressModel} from "../address-widget/shared/address.model";
import {formatDate} from "@angular/common";
import { DateValidator } from 'src/app/form-validators/date-validator';

@Component({
  selector: 'app-note-date-widget',
  templateUrl: './note-date-widget.component.html'
})

export class NoteDateWidgetComponent implements OnInit {

  @Input()
  note: NoteDateModel | undefined;

  @Input()
  createMode = false;

  @Input()
  text: string = "note.text";

  @Output()
  cancelCreate = new EventEmitter();

  @Output()
  createNote = new EventEmitter();

  formGroup: UntypedFormGroup;

  @Input()
  includeAccept = true;


  constructor(private formBuilder: UntypedFormBuilder) {
    this.formGroup = this.formBuilder.group({
      value: ['', [Validators.required, Validators.maxLength(255)]],
      endDate : [''],
      endTime : ['']

    });
  }

  ngOnInit(): void {
    this.formGroup.valueChanges.subscribe(() => {
      if (this.createMode && !this.includeAccept) {
        this.createNote.emit(this.formGroup.value as NoteDateModel);
      }
    });
  }

  create(): void {
    this.createNote.emit(this.formGroup.value as NoteDateModel);
    this.formGroup.reset();
    this.cancelCreate.emit();
  }

}
