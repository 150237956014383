<div class="col-md-12" *ngIf="tacho" >
  <div class="row">
    <form [formGroup]="formGroup" class=" row col-md-12 px-0">
      <div class="autoZoomTgl align-middle col-md-12" style="padding-right: 0; padding-top: 0; margin-top: -4px; padding-left: 25px;" >
        <label>{{'tacho.sliderTitle' | translate}}</label> <br>
        <label class="switch">
         <input type="checkbox" disabled [checked]="toogleTachoDl" (click)="setTachoDl()"> >
         <span class="slider round"> </span>
        </label>
      </div>

      <div class="col-6" style="padding-left: 25px;">
        <label>{{'tacho.info.vehicleDDD' | translate}}</label>
        <input class="form-control form-control-sm" formControlName="vehicleDDD" [readOnly]="true" value="{{'tacho.every' | translate}} {{tacho.vehicleDDDDownloadRate}} . {{'tacho.day' | translate}}"
          [(ngModel)]="vehicleDDD">
      </div>

      <div class="col-6" style="padding-left: 25px;">
        <label>{{'tacho.info.driverDDD' | translate}}</label>
        <input type="text" class="form-control form-control-sm" formControlName="driverDDD" [readOnly]="true"
        [(ngModel)]="driverDDD">
      </div>
      <div class="col-12" style="padding-left: 25px;">
        <label>{{'tacho.info.analyticComp' | translate}}</label>
        <input type="text" class="form-control form-control-sm" formControlName="analyticComp" [readOnly]="true"
        [(ngModel)]="analyticComp">
      </div>

      <div class="col-md-12" style="padding-left: 25px;">
        <label>{{'tacho.info.comment' | translate}}</label>
        <input type="text" class="form-control form-control-sm" formControlName="comment" [readOnly]="true"
        [(ngModel)]="comment">
      </div>
    </form>
  </div>
</div>