import { Component, Input, OnInit } from '@angular/core';
import { UserModel } from 'src/app/users/shared/user.model';
import { UserService } from 'src/app/users/shared/user.service';
import { TimeRegistrationModel } from './shared/timeregistration';

@Component({
  selector: 'app-time-registrations-table',
  templateUrl: './time-registrations-table.component.html'
})

export class TimeRegistrationsTableComponent implements OnInit {

  @Input()
  user : UserModel | undefined;

  timeregistrations: TimeRegistrationModel[] = [];

  constructor(private userService: UserService) { }

  ngOnInit(): void {
  }
}