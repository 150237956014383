<div *ngIf="createMode" class="bg-light">
  <form [formGroup]="formGroup" class="col-md-12">
    <div class="row">
      <div class="col-md-12" >
        <label >{{text | translate}}
          <span *ngIf="formGroup.controls['value'].hasError('maxlength')" class="required-text">
          {{'message.maxLength' | translate: {number: 255} }}
        </span>
          <span *ngIf="formGroup.controls['value'].hasError('required') && includeAccept" class="required-text">
          {{'message.required' | translate}}
        </span></label>
        <textarea autosize rows="1" id="value" type="text" formControlName="value" class="form-control form-control-sm w-100"></textarea>
      </div>
      <div class="col-md-4">
        <label for="endDate">{{'tour.noteDate' | translate}}
          <span *ngIf="formGroup.controls['endDate'].hasError('required')" class="required-text">
            {{'message.required' | translate}}
          </span>
          <span *ngIf="formGroup.controls['endDate'].hasError('wrong-date')" class="required-text">
            {{'message.wrong-date' | translate}}
          </span></label>
        <input id="endDate" type="date" formControlName="endDate" class="form-control form-control-sm">
      </div>
      <div class="col-md-2">
        <label for="endTime">{{'tour.noteTime' | translate}}
          <span *ngIf="formGroup.controls['endTime'].hasError('wrong-time')" class="required-text">
            {{'message.wrong-date' | translate}}
          </span></label>
        <input id="endTime" type="time" formControlName="endTime" class="form-control form-control-sm">
      </div>
    </div>
  </form>
  <div *ngIf="createMode">
    <app-btn-accept-widget *ngIf="includeAccept" (cancel)="cancelCreate.emit()" (accept)="create()" [formGroup]="formGroup"></app-btn-accept-widget>
  </div>
</div>

<div class="col-md-12" *ngIf="note" style="padding: 15px">
  <div class="row ">
    <div class="col-md-12" style="white-space: pre-line">
      {{note.value}}
    </div>
    <div class="col-md-12" style="margin-top: 10px; font-size: 12px; font-style: italic; opacity: 0.8">
      {{'note.written' | translate}} {{note.timestamp | date: 'yyyy-MM-dd HH:mm:ss'}} {{'note.by' | translate}} {{note.createdBy?.firstname}} {{note.createdBy?.lastname}}
    </div>
  </div>
</div>



