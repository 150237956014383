import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {NoteModel} from "../note-widget/shared/note.model";
import {AttachmentCreateModel, AttachmentModel} from "./shared/attachment.model";
import {AttachmentService} from "./shared/attachment.service";
import {AddressModel} from "../address-widget/shared/address.model";
import { faDesktop, faMobileAlt, faPencilAlt, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { faEyeSlash } from '@fortawesome/free-regular-svg-icons';

@Component({
  selector: 'app-attachment-widget',
  templateUrl: './attachment-widget.component.html',
  styleUrls: ['./attachment-widget.component.css']
})
export class AttachmentWidgetComponent implements OnInit {

  @Input()
  createMode: boolean = false;

  @Input()
  attachment: AttachmentModel | undefined;

  @Output()
  cancelCreate = new EventEmitter();

  @Output()
  create = new EventEmitter();

  @Output()
  delete = new EventEmitter();
  formGroup: UntypedFormGroup;

  @Input()
  includeAccept = true;

  @Input()
  logBook: boolean = false;

  faEdit = faPencilAlt;
  readOnly = true;
  faTrash = faTrashAlt;
  faMobileAlt = faMobileAlt;
  faDesktop = faDesktop;

  constructor(private formBuilder: UntypedFormBuilder, private attachmentService: AttachmentService) {
    this.formGroup = this.formBuilder.group({
      name: ['Choose file', Validators.required],
      type: ['', Validators.required],
      file: undefined,
      showInApp: [true]
    });
  }

  ngOnInit(): void {
    this.formGroup.valueChanges.subscribe(() => {
      if (this.createMode && !this.includeAccept) {
        //this.create.emit(this.formGroup.value.file as File);
        this.create.emit(this.formGroup.value as AttachmentCreateModel);
      }
    });
  }

  onFileChange(event: any):void {
    const files = event.files;
    this.formGroup.patchValue({
      name: files[0].name,
      type: files[0].type,
      file: files[0]
    });
  }

  createAttachment(): void {
    //this.create.emit(this.formGroup.value.file as File);
    this.create.emit(this.formGroup.value as AttachmentCreateModel);
    this.formGroup.reset();
  }

  showFile(): void {
    if (this.attachment){
      this.attachmentService.getSingle(this.attachment).subscribe(response => {
        var fileURL = URL.createObjectURL(response);
        window.open(fileURL);
      });
    }
  }

  checkClick(event: any): void{
    let buttonPressed = false;
    var path = event.composedPath() ? event.composedPath() : event.path;
    path.forEach((element: { id: string; }) => {
      if(element.id==="editButton" || element.id==="deleteButton")
      {
        this.readOnly = !this.readOnly;
        buttonPressed = true;
      }

    });
    if(!buttonPressed)
      this.showFile();



  }


}
