<div [ngClass]="{'bg-light' : (createMode && createMode !== createModes.create_as_user) || !readOnly}" class="transition-widget">
  <div class="row pt-3 w-100">
    <div *ngIf="showEditButton" class="col-md-1 text-end full-width pe-1">
      <button *ngIf="readOnly && !address?.hasArrived" type="button" class="btn btn-default btn-sm"
              (click)="readOnly = !readOnly" placement="left" ngbTooltip="{{'btn.edit' | translate}}">
        <fa-icon [icon]="faEdit"></fa-icon>
      </button>
    </div>
  </div>
  <form [formGroup]="formGroup" class="col-md-12">
    <!--<div class="col-md-12 justify-content-end">
    <div class="row">
      <div class="col-8"></div>
      <div class="col-4">-->
    <app-btn-accept-widget *ngIf="!readOnly && !createMode" [formGroup]="formGroup" (accept)="update()"
                           (cancel)="cancelUpdate()"></app-btn-accept-widget>

    <app-btn-accept-widget *ngIf="createMode === createModes.create_as_child" [formGroup]="formGroup"
                           (accept)="create()" (cancel)="cancelCreate.emit()"></app-btn-accept-widget>
    <!--</div>
    </div>
  </div>-->
    <h6 *ngIf="address?.hasArrived">{{'address.driverArrivedAtTheAddress' | translate}} {{address?.arrivedDate | date:'dd-MM-yyyy HH:mm'}}</h6>
    <div class="form-group row">
      <!--------------------------------------------------->
      <div class="col-md-6 pe-0" *ngIf="showLocationNameAndId">
        <label for="locationName">{{'address.locationName' | translate}}</label>
        <input id="locationName" type="text" class="form-control form-control-sm" formControlName="locationName" [readonly]="readOnly" />
      </div>
      <div class="col-md-3 pe-0" *ngIf="showLocationNameAndId">
        <label for="locationId">{{'address.locationId' | translate}}</label>
        <input id="locationId" type="text" class="form-control form-control-sm" formControlName="locationId" [readonly]="readOnly" />
      </div>
      <div class="col-md-3" *ngIf="showLocationNameAndId">
        <label for="type">{{'address.type' | translate}}</label>
        <input *ngIf="address?.type == 'start'" value="{{'address.typeDesc.start' | translate}}" id="type" type="text" class="form-control form-control-sm" [readonly]="true" />
        <input *ngIf="address?.type == 'waypoint'" value="{{'address.typeDesc.waypoint' | translate}}" id="type" type="text" class="form-control form-control-sm" [readonly]="true" />
        <input *ngIf="address?.type == 'stop'" value="{{'address.typeDesc.stop' | translate}}" id="type" type="text" class="form-control form-control-sm" [readonly]="true" />
        <input *ngIf="address?.type != 'waypoint' && address?.type != 'start' && address?.type != 'stop'" value="{{'address.typeDesc.unknown' | translate}}" id="type" type="text" class="form-control form-control-sm" [readonly]="true" />
      </div>
      <!--------------------------------------------------->
      <div class="col-md-12" *ngIf="!readOnly">
        <label for="search">{{'address.searchAddress' | translate}}   </label>
        <input type="text" class="form-control" (keyup)="getValue(search.value)" #search name="search" placeholder="{{'address.searchAddressDesc' | translate}} " autocomplete="off" [readonly]="readOnly" />
        <ul class="resultList" *ngFor="let result of searchResults" class="list-group">
          <li role="button" (click)="fillAddressForm(result, formGroup)" class="list-group-item cursor">{{generateAdressTitle(result)}}</li>
        </ul>
      </div>
      <!--------------------------------------------------->
      <div class="col-md-6 pe-0">
        <label for="street">
          {{'address.street' | translate}}
          <span *ngIf="formGroup.controls['street'].hasError('maxlength')" class="required-text">
            {{'message.maxLength' | translate: {number: 255} }}
          </span>
          <span *ngIf="formGroup.controls['street'].hasError('required')" class="required-text">
            {{'message.required' | translate}}
          </span>
        </label>
        <input id="street" type="text" formControlName="street" class="form-control form-control-sm" [readonly]="readOnly">
      </div>
      <div class="col-md-3 pe-0">
        <label for="city">
          {{'address.city' | translate}}
          <span *ngIf="formGroup.controls['city'].hasError('maxlength')" class="required-text">
            {{'message.maxLength' | translate: {number: 255} }}
          </span>
          <span *ngIf="formGroup.controls['city'].hasError('required') && !readOnly" class="required-text">
            {{'message.required' | translate}}
          </span>
        </label>
        <input id="city" type="text" formControlName="city" class="form-control form-control-sm" [readonly]="readOnly">
      </div>
      <div class="col-md-3">
        <label for="zipcode">
          {{'address.zipcode' | translate}}
          <span *ngIf="formGroup.controls['zipcode'].hasError('maxlength')" class="required-text">
            {{'message.maxLength' | translate: {number: 10} }}
          </span>
          <span *ngIf="formGroup.controls['zipcode'].hasError('required') && !readOnly" class="required-text">
            {{'message.required' | translate}}
          </span>
        </label>
        <input id="zipcode" type="text" formControlName="zipcode" class="form-control form-control-sm" [readonly]="readOnly">
      </div>
      <!--------------------------------------------------->
      <div class="col-md-6 pe-0">
        <label for="state">
          {{'address.state' | translate}}
          <span *ngIf="formGroup.controls['state'].hasError('maxlength')" class="required-text">
            {{'message.maxLength' | translate: {number: 255} }}
          </span>
        </label>
        <input id="state" type="text" formControlName="state" class="form-control form-control-sm" [readonly]="readOnly">
      </div>
      <div class="col-md-6 ">
        <label for="country">
          {{'address.country' | translate}}
          <span *ngIf="formGroup.controls['country'].hasError('maxlength')" class="required-text">
            {{'message.maxLength' | translate: {number: 255} }}
          </span>
          <span *ngIf="formGroup.controls['country'].hasError('required')" class="required-text">
            {{'message.required' | translate}}
          </span>
        </label>
        <input id="country" type="text" formControlName="country" class="form-control form-control-sm" [readonly]="readOnly">
      </div>
      <!--------------------------------------------------->
      <div class="col-md-1" *ngIf="showCoordinates && !readOnly">
      </div>
      <div class="col-md-10 justify-content-center d-flex py-3" *ngIf="showCoordinates && !readOnly">
        <button class="btn btn-sm btn-outline-default" (click)="geocode()">{{'btn.geocode' | translate}}</button>
      </div>
      <div class="col-md-1" *ngIf="showCoordinates && !readOnly">
        <div *ngIf="isGeocoding" class="spinner-border content text-dark to-bottom" role="status">
          <span class="sr-only">{{'common.loading' | translate}}</span>
        </div>
      </div>
      <hr class="full-width" *ngIf="!readOnly && showCoordinates">
      <div class="col-md-6 pe-0" *ngIf="showCoordinates">
        <label for="longitude">{{'address.longitude' | translate}}</label>
        <input id="longitude" type="text" formControlName="longitude" class="form-control form-control-sm" [readonly]="readOnly">
      </div>
      <div class="col-md-6" *ngIf="showCoordinates">
        <label for="latitude">{{'address.latitude' | translate}}</label>
        <input id="latitude" type="text" formControlName="latitude" class="form-control form-control-sm" [readonly]="readOnly">
      </div>
      <div class="col-md-1" *ngIf="showCoordinates && !readOnly"></div>
      <div class="col-md-10 justify-content-center d-flex py-3" *ngIf="showCoordinates && !readOnly">
        <button class="btn btn-sm btn-outline-default" (click)="reverseGeocode()">{{'btn.reverseGeocode' | translate}}</button>
      </div>
      <div class="col-md-1" *ngIf="showCoordinates && !readOnly">
        <div *ngIf="isReverseGeocoding" class="spinner-border content text-dark to-bottom" role="status">
          <span class="sr-only">{{'common.loading' | translate}}</span>
        </div>
      </div>

      <!-- THIS IS FOR FREIGHT -->
      <!-- FREIGHT END -->

      <hr class="full-width" *ngIf="!readOnly && showDescription">
      <!-------------------------------------------------------------->

      <div *ngIf="showFreightSection" class="col-md-6 pe-0 mt-3 d-flex align-items-end position-relative">
        <ng-multiselect-dropdown
          [hidden]="!showArriveMultiSelect"
          class="w-100"
          [disabled]="readOnly"
          [placeholder]="'address.multiSelect.selectArriveBackVehicles' | translate"
          [settings]="dropdownSettings"
          [data]="dropdownList"
          (onSelect)="onItemSelectArriveBackVehicle($event)"
          (onDeSelect)="onItemDeSelectArriveBackVehicle($event)"
        ></ng-multiselect-dropdown>
        <div class="w-100" [hidden]="showArriveMultiSelect">
          <label for="arriveBackVehicle">
            {{'address.arriveBackVehicle' | translate}}
            <span *ngIf="formGroup.get('arriveBackVehicle')?.hasError('maxlength')" class="required-text">
              {{'message.maxLength' | translate: {number: 255} }}
            </span>
          </label>
          <input [hidden]="showArriveMultiSelect" id="arriveBackVehicle" [readonly]="readOnly" formControlName="arriveBackVehicle" type="text" class="form-control form-control-sm" [(ngModel)]="arriveBackVehicle" (change)="this.manualArriveBackVehicleInput()">
        </div>
        <button class="btn change-field ms-3" (click)="switchArriveField()" placement="top" #t="ngbTooltip" (mouseleave)="t.close()" [ngbTooltip]="(showArriveMultiSelect ? 'address.switchToFreeText' : 'address.switchToMultiSelect') | translate">
          <fa-icon [icon]="this.faKeyboard"></fa-icon>
        </button>
      </div>

      <div *ngIf="showFreightSection" class="col-md-6 mt-3 d-flex align-items-end position-relative">
        <ng-multiselect-dropdown
          class="w-100"
          [hidden]="!showLeaveMultiSelect"
          [disabled]="readOnly"
          [placeholder]="'address.multiSelect.selectLeaveBackVehicles' | translate"
          [settings]="dropdownSettings"
          [data]="dropdownList"
          (onSelect)="onItemSelectLeaveBackVehicle($event)"
          (onDeSelect)="onItemDeSelectLeaveBackVehicle($event)"
        ></ng-multiselect-dropdown>
        <div class="w-100" [hidden]="showLeaveMultiSelect">
          <label for="leaveBackVehicle">
            {{'address.leaveBackVehicle' | translate}}
            <span *ngIf="formGroup.get('leaveBackVehicle')?.hasError('maxlength')" class="required-text">
              {{'message.maxLength' | translate: {number: 255} }}
            </span>
          </label>
          <input [hidden]="showLeaveMultiSelect" id="leaveBackVehicle" formControlName="leaveBackVehicle" [readonly]="readOnly" type="text" class="form-control form-control-sm" [(ngModel)]="leaveBackVehicle" (change)="this.manualLeaveBackVehicleInput()">
        </div>
        <button class="btn change-field ms-3" (click)="switchLeaveField()" placement="top" #tt="ngbTooltip" (mouseleave)="tt.close()" [ngbTooltip]="(showLeaveMultiSelect ? 'address.switchToFreeText' : 'address.switchToMultiSelect') | translate">
          <fa-icon [icon]="faKeyboard"></fa-icon>
        </button>
      </div>

        <div class="pe-0" *ngIf="showFreightSection" [ngClass]="showFreightSection && !readOnly ? 'col-md-4' : 'col-md-6'">
          <label for="arriveEquipment">
            {{'address.arriveEquipment' | translate}}
            <span *ngIf="formGroup.controls['arriveEquipment'].hasError('maxlength')" class="required-text">
              {{'message.maxLength' | translate: {number: 255} }}
            </span>
          </label>
          <input
            id="arriveEquipment"
            type="text"
            formControlName="arriveEquipment"
            class="form-control form-control-sm"
            [readonly]="readOnly"
            [(ngModel)]="arriveEquipment"
            (change)="this.manualArriveEquipmentInput()"
          />
        </div>

        <div class="col-md-2 mt-4 pe-0" *ngIf="showFreightSection && !readOnly">
          <select
            id="selectArriveEquipment"
            class="form-select form-select-sm mt-half"
            formControlName="selectArriveEquipment"
            [(ngModel)]="selectArriveEquipment"
            (change)="selectedArriveEquipment()"
          >
            @for(transportEquipment of transportEquipment; track $index){
              <option [value]="transportEquipment.unitIdentification + ' ' + transportEquipment.type">{{transportEquipment.unitIdentification}} {{sharedService.getTranslateType(transportEquipment.type) | translate}}</option>
            }
          </select>
        </div>

        <div *ngIf="showFreightSection" [ngClass]="showFreightSection && !readOnly ? 'col-md-4 pe-0' : 'col-md-6'">
          <label for="leaveEquipment">
            {{'address.leaveEquipment' | translate}}
            <span *ngIf="formGroup.controls['leaveEquipment'].hasError('maxlength')" class="required-text">
              {{'message.maxLength' | translate: {number: 255} }}
            </span>
          </label>
          <input
            id="leaveEquipment"
            type="text"
            formControlName="leaveEquipment"
            class="form-control form-control-sm"
            [readonly]="readOnly"
            [(ngModel)]="leaveEquipment"
            (change)="this.manualLeaveEquipmentInput()"
          />
        </div>
        <div class="col-md-2 mt-4" *ngIf="showFreightSection && !readOnly">
          <select
            id="selectLeaveEquipment"
            class="form-select form-select-sm mt-half"
            formControlName="selectLeaveEquipment"
            [(ngModel)]="selectLeaveEquipment"
            (change)="selectedLeaveEquipment()"
          >
            @for(transportEquipment of transportEquipment; track $index){
              <option [value]="transportEquipment.unitIdentification + ' ' + transportEquipment.type">{{transportEquipment.unitIdentification}} {{sharedService.getTranslateType(transportEquipment.type) | translate}}</option>
            }
          </select>
        </div>

        <div class="col-md-12" *ngIf="showFreightSection">
          <label for="orderId">
            {{'tour.orderId' | translate}}
            <span *ngIf="formGroup.controls['orderId'].hasError('maxlength')" class="required-text">
              {{'message.maxLength' | translate: {number: 40} }}
            </span>
          </label>
          <input id="orderId" type="text" formControlName="orderId" class="form-control form-control-sm" [readOnly]="readOnly">
        </div>

      <div class="col-12 form-group" *ngIf="showFreightSection">
        <label for="freight">{{'address.freight' | translate}}</label>
        <input id="freight" type="text" formControlName="freight" class="form-control form-control-sm" [readonly]="readOnly" />
      </div>
      <div class="col-4 form-group pe-0" *ngIf="showFreightSection">
        <label for="freightTypeId">{{'address.freightType' | translate}}</label>
        <select (change)="freightTypeIdSelectChangeHandler($event)" id="freightTypeId" formControlName="freightTypeId" class="form-select form-select-sm" [attr.disabled]="readOnly || !showFreightType ? 'disabled' : null">
          <option value="1">{{'tour.freightTypeUnitDesc.weight' | translate}}</option>
          <option value="2">{{'tour.freightTypeUnitDesc.volume'| translate}}</option>
          <option value="3">{{'tour.freightTypeUnitDesc.pallets' | translate}}</option>
        </select>
      </div>
      <div class="col-4 form-group pe-0" *ngIf="showFreightSection">
        <label for="removedFreightAmount">{{'address.removedFreightAmount' | translate}}</label>
        <div class="input-group input-group-sm">
          <input id="removedFreightAmount" type="number" formControlName="removedFreightAmount" class="form-control form-control-sm" [readonly]="readOnly" />
          <div class="input-group-append">
            <span *ngIf="selectedFreightType == '1'" class="input-group-text span-styling">{{'tour.freightTypeUnitAbbreviation.weight' | translate}}</span>
            <span *ngIf="selectedFreightType == '2'" class="input-group-text span-styling">{{'tour.freightTypeUnitAbbreviation.volume'| translate}}</span>
            <span *ngIf="selectedFreightType == '3'" class="input-group-text span-styling">{{'tour.freightTypeUnitAbbreviation.pallets' | translate}}</span>
          </div>
        </div>
      </div>
      <div class="col-4 form-group " *ngIf="showFreightSection">
        <label for="freightAmount">{{'address.freightAmount' | translate}}</label>
        <div class="input-group input-group-sm">
          <input id="freightAmount" type="number" formControlName="freightAmount" class="form-control form-control-sm" [readonly]="readOnly" />
          <div class="input-group-append">
            <span *ngIf="selectedFreightType == '1'" class="input-group-text span-styling">{{'tour.freightTypeUnitAbbreviation.weight' | translate}}</span>
            <span *ngIf="selectedFreightType == '2'" class="input-group-text span-styling">{{'tour.freightTypeUnitAbbreviation.volume'| translate}}</span>
            <span *ngIf="selectedFreightType == '3'" class="input-group-text span-styling">{{'tour.freightTypeUnitAbbreviation.pallets' | translate}}</span>
          </div>
        </div>
      </div>
      <!-------------------------------------------------------------->
      <div class="col-md-12" *ngIf="showDescription">
        <label for="description">
          {{'address.description' | translate}}
          <span *ngIf="formGroup.controls['description'].hasError('maxlength')" class="required-text">{{'message.maxLength' | translate: {number: 255} }}</span>
        </label>
        <textarea autosize id="description" type="text" formControlName="description"
                  class="form-control form-control-sm" [readonly]="readOnly"></textarea>
      </div>


      <div class="col-md-12" *ngIf="showDescription">
        <ng-container *ngFor="let attachment of address?.attachments; let i = index">
          <div class="col-md-12 widget-margin">
            <app-attachment-widget  [attachment]="attachment"></app-attachment-widget>
          </div>
        </ng-container>
      </div>

      <div class="col-md-12" *ngIf="showDescription && address?.notes != undefined">
        <ng-container  *ngFor="let note of address?.notes; let i = index">
          <app-note-widget [note]="note"></app-note-widget>
        </ng-container>
      </div>
    </div>
    <!--<div class="col-12">
      <div class="row">
    <div class="col-10"></div>
    <div class="col-1" style="margin-top: 10px; margin-bottom: 10px;">
      <button *ngIf="!readOnly && !createMode" class="btn btn-danger" placement="top"
              (click)="delete.emit(this.address?.id)" ngbTooltip="{{'btn.deleteAddress' | translate}}">
        <fa-icon [icon]="faTrash"></fa-icon>
      </button>
    </div>
  </div>-->
  <div class="d-flex flex-row-reverse">
    <!--<div class="col-1" style="margin-top: 10px; margin-bottom: 10px;">-->
    <button *ngIf="!readOnly && !createMode" class="btn btn-danger" placement="top"
            (click)="delete.emit(this.address?.id)" ngbTooltip="{{'btn.deleteAddress' | translate}}" style="margin-bottom: 10px;">
      <fa-icon [icon]="faTrash"></fa-icon>
    </button>
    <!--</div>
  </div>-->
  </div>
  </form>
</div>
