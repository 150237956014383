<div class="dropdown-button--component">
  <div ngbDropdown class="d-inline-block">
    <!-- <button type="button" class="btn btn-outline-primary" ngbDropdownToggle>

    </button> -->
    <div class="button--component">
      <button
        class="btn elevated--low px-3 kanit fw-medium bordered-outline-primary"
        ngbDropdownToggle
      >
        {{ this.buttonText }}
      </button>
    </div>
    <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
      @if (this.groupSelector) {
        <app-button ngbDropdownItem (click)="dropdownSelection({ name: 'Alle' }, 0)">{{ 'grouping.misc.all' | translate }}</app-button>
      }

      @if (this.options && this.options.length <= 0 && !this.groupSelector) {
        <app-button ngbDropdownItem>{{ 'grouping.misc.nothingToShow' | translate }}</app-button>
      }
      @else {
        @for(option of this.options; track $index;) {
          <app-button ngbDropdownItem (click)="dropdownSelection(option, option.id)">{{ option.name }}</app-button>
        }
      }

      @if (this.extraButton) {
        <hr class="my-1">

        <app-button ngbDropdownItem (click)="specialButtonEmit()">
          <fa-icon [icon]="faPlus"></fa-icon>
          {{ this.extraButtonText }}
        </app-button>
      }
    </div>
  </div>

  <div class="create--modal">

  </div>
</div>
