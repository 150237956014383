import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { faCheck, faPencilAlt, faTimes, faTrash } from '@fortawesome/free-solid-svg-icons';
import { DateValidator } from 'src/app/form-validators/date-validator';
import { TimeValidator } from 'src/app/form-validators/time-validator';
import { SharedService } from '../services/shared.service';
import { DatePipe } from "@angular/common";
import { BackVehicleEventModel, CreateBackVehicleEventModel, UpdateBackVehicleEventModel } from 'src/app/back-vehicles/shared/back-vehicle.model';

@Component({
  selector: 'app-resource-event-widget',
  templateUrl: './resource-event-widget.component.html'
})

export class BackvehicleEventWidgetComponent implements OnInit {
  @Input()
  event: BackVehicleEventModel | undefined;

  @Input()
  createMode = false;

  @Output()
  cancelCreate = new EventEmitter();

  @Output()
  createEvent = new EventEmitter();

  @Output()
  updateEvent = new EventEmitter();

  @Output()
  deleteEvent = new EventEmitter();

  @Output()
  cancelEvent = new EventEmitter();

  formGroup: UntypedFormGroup;
  editFormGroup: UntypedFormGroup;

  @Input()
  includeAccept = true;

  types: string[] = [];

  faEdit = faPencilAlt;
  faTrash = faTrash;
  faAccept = faCheck;
  faCancel = faTimes;

  allDay: boolean = false;
  readOnly = true;

  constructor(private formBuilder: UntypedFormBuilder, private sharedService: SharedService, private datePipe: DatePipe) {
    this.formGroup = this.formBuilder.group({
      description: ['', [Validators.required, Validators.maxLength(255)]],
      startDate: ['', [Validators.required]],
      endDate: ['', [DateValidator('startDate')]],
      startTime: [''],
      endTime: [''],
      type: [undefined, [Validators.required]]
    });
    this.editFormGroup = this.formBuilder.group({
      id: '',
      description: ['', [Validators.required, Validators.maxLength(255)]],
      startDate: ['', [Validators.required]],
      endDate: ['', [DateValidator('startDate')]],
      startTime: [''],
      endTime: ['', TimeValidator('startDate', 'startTime', 'endDate')],
      type: [undefined, [Validators.required]]
    });
  }


  ngOnInit(): void {
    this.types = ['Broken'];
    if (this.event) {

      this.editFormGroup.patchValue({
        id: this.event.id,
        description: this.event?.description,
        startDate: this.datePipe.transform(this.event.actualStartDate, 'yyyy-MM-dd'),
        endDate: this.datePipe.transform(this.event.actualEndDate, 'yyyy-MM-dd'),
        startTime: `${this.getFormattedTime(new Date(this.event?.actualStartDate).getHours())}:${this.getFormattedTime(new Date(this.event?.actualStartDate).getMinutes())}`,
        endTime: `${this.getFormattedTime(new Date(this.event?.actualEndDate).getHours())}:${this.getFormattedTime(new Date(this.event?.actualEndDate).getMinutes())}`,
        type: this.event.type
      });
    }
  }


  getFormattedTime(time: number): string {
    return ('0' + time).slice(-2);
  }


  create(): void {
    this.createEvent.emit(this.formGroup.value as CreateBackVehicleEventModel)
    this.formGroup.reset();
    this.createMode = false;
  }


  update(): void {
    this.readOnly = !this.readOnly;
    this.updateEvent.emit(this.editFormGroup.value as UpdateBackVehicleEventModel);
  }


  delete(): void {
    this.deleteEvent.emit(this.event);
  }


  cancel(): void {
    this.readOnly = !this.readOnly;
    this.cancelEvent.emit();
  }


  getValue(type: string): string {
    return `backVehicle.event.${type.toLowerCase()}`;
  }


  changeAllDay(ev: any) {
    this.allDay = ev.target.checked;
    if (this.allDay) {
      if (this.formGroup.get('startDate')?.value !== '') {
        let date = new Date(this.formGroup.get('startDate')?.value);
        if (this.formGroup.get('endDate')?.value === '' || new Date(this.formGroup.get('endDate')?.value) <= date) {
          this.formGroup.patchValue({
            endDate: this.datePipe.transform(date.setDate(date.getDate() + 1), 'yyyy-MM-dd'),
            startTime: `${this.getFormattedTime(date.getUTCHours())}:${this.getFormattedTime(date.getUTCMinutes())}`,
            endTime: `${this.getFormattedTime(date.getUTCHours())}:${this.getFormattedTime(date.getUTCMinutes())}`,
          });
        } else {
          this.formGroup.patchValue({
            startTime: `${this.getFormattedTime(date.getUTCHours())}:${this.getFormattedTime(date.getUTCMinutes())}`,
            endTime: `${this.getFormattedTime(date.getUTCHours())}:${this.getFormattedTime(date.getUTCMinutes())}`,
          });
        }
      }
    }
  }


  changeAllDayEdit(ev: any) {
    this.allDay = ev.target.checked;
    if (this.allDay) {
      if (this.editFormGroup.get('endDate')?.value !== '') {
        let date = new Date(this.editFormGroup.get('startDate')?.value);

        if (this.editFormGroup.get('endDate')?.value === '' || new Date(this.editFormGroup.get('endDate')?.value) <= date) {
          this.editFormGroup.patchValue({
            endDate: this.datePipe.transform(date.setDate(date.getDate() + 1), 'yyyy-MM-dd'),
            endTime: `${this.getFormattedTime(date.getUTCHours())}:${this.getFormattedTime(date.getUTCMinutes())}`,
          });
        } else if (this.event?.actualStartDate) {
          let endDate = new Date(this.editFormGroup.get('endDate')?.value)
          this.editFormGroup.patchValue({
            endDate: this.datePipe.transform(date.setDate(endDate.getDate() + 1), 'yyyy-MM-dd'),
            endTime: `${this.getFormattedTime(date.getUTCHours())}:${this.getFormattedTime(date.getUTCMinutes())}`,
          });
        }
      }
    }
  }
}
