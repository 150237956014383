import {AbstractControl, ValidationErrors, ValidatorFn} from "@angular/forms";

export function TimeValidator(startDatePath: string, startTimePath: string, endDatePath: string): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null =>{
    const formGroup = control.parent;
    if (formGroup) {
      const d1 = new Date(`${formGroup.get(startDatePath)?.value} ${formGroup.get(startTimePath)?.value}`);
      const d2 = new Date(`${formGroup.get(endDatePath)?.value} ${control?.value}`);
      return d1.getTime() > d2.getTime() ? {'wrong-time': {value: control.value}} : null;
    }
    return null;
  };
}
