import { NumericLiteral } from "typescript";

export class TableTotalsModel
{
  constructor()
  {
    this.mo = new ColTotals();
    this.tu = new ColTotals();
    this.we = new ColTotals();
    this.th = new ColTotals();
    this.fr = new ColTotals();
    this.sa = new ColTotals();
    this.su = new ColTotals();
    this.total = 0;
    this.calTotal = 0;
    this.totalSubtractedBreaks = 0;
    this.week = 0;
  }
  mo: ColTotals;
  tu: ColTotals;
  we: ColTotals;
  th: ColTotals;
  fr: ColTotals;
  sa: ColTotals;
  su: ColTotals;
  total: number;
  calTotal: number;
  totalSubtractedBreaks: number;
  week: number;
  to: string = "";
  from: string = "";
  year: string = "";
  revision: number = 0;
  isAbroad: boolean = false;

  reset(): void
  {
    this.mo.reset();
    this.tu.reset();
    this.we.reset();
    this.th.reset();
    this.fr.reset();
    this.sa.reset();
    this.su.reset();
    this.calTotal = 0;
  }

  calculateTotalBreaks(): number
  {
    var value = this.mo.breakMinutes;
    value += this.tu.breakMinutes;
    value += this.we.breakMinutes;
    value += this.th.breakMinutes;
    value += this.fr.breakMinutes;
    value += this.sa.breakMinutes;
    value += this.su.breakMinutes;

    //var value = this.mo.normal_break;
    //value += this.mo.overtime1_break;
    //value += this.mo.overtime2_break;
    //value += this.mo.overtime3_break;
    //value += this.mo.overtime4_break;

    //value += this.tu.normal_break;
    //value += this.tu.overtime1_break;
    //value += this.tu.overtime2_break;
    //value += this.tu.overtime3_break;
    //value += this.tu.overtime4_break;

    //value += this.we.normal_break;
    //value += this.we.overtime1_break;
    //value += this.we.overtime2_break;
    //value += this.we.overtime3_break;
    //value += this.we.overtime4_break;

    //value += this.th.normal_break;
    //value += this.th.overtime1_break;
    //value += this.th.overtime2_break;
    //value += this.th.overtime3_break;
    //value += this.th.overtime4_break;

    //value += this.fr.normal_break;
    //value += this.fr.overtime1_break;
    //value += this.fr.overtime2_break;
    //value += this.fr.overtime3_break;
    //value += this.fr.overtime4_break;

    //value += this.sa.normal_break;
    //value += this.sa.overtime1_break;
    //value += this.sa.overtime2_break;
    //value += this.sa.overtime3_break;
    //value += this.sa.overtime4_break;

    //value += this.su.normal_break;
    //value += this.su.overtime1_break;
    //value += this.su.overtime2_break;
    //value += this.su.overtime3_break;
    //value += this.su.overtime4_break;

    return value;
  }
  sumTotalBreaks(): string
  {
    let value = this.calculateTotalBreaks();
    value = value / 60;
    const formattedValue = this.roundTo(value, 2);
    return formattedValue.toString().replace('.', ',');
  }

  calculateTotal(includeEdited: boolean, subtractBreaks: boolean): number
  {
    let value = this.mo.normal;
    value += this.mo.overtime1;
    value += this.mo.overtime2;
    value += this.mo.overtime3;
    value += this.mo.overtime4;

    value += this.tu.normal;
    value += this.tu.overtime1;
    value += this.tu.overtime2;
    value += this.tu.overtime3;
    value += this.tu.overtime4;

    value += this.we.normal;
    value += this.we.overtime1;
    value += this.we.overtime2;
    value += this.we.overtime3;
    value += this.we.overtime4;

    value += this.th.normal;
    value += this.th.overtime1;
    value += this.th.overtime2;
    value += this.th.overtime3;
    value += this.th.overtime4;

    value += this.fr.normal;
    value += this.fr.overtime1;
    value += this.fr.overtime2;
    value += this.fr.overtime3;
    value += this.fr.overtime4;

    value += this.sa.normal;
    value += this.sa.overtime1;
    value += this.sa.overtime2;
    value += this.sa.overtime3;
    value += this.sa.overtime4;

    value += this.su.normal;
    value += this.su.overtime1;
    value += this.su.overtime2;
    value += this.su.overtime3;
    value += this.su.overtime4;


    if (includeEdited)
    {
      value += this.mo.edited_normal;
      value += this.mo.edited_overtime1;
      value += this.mo.edited_overtime2;
      value += this.mo.edited_overtime3;
      value += this.mo.edited_overtime4;

      value += this.tu.edited_normal;
      value += this.tu.edited_overtime1;
      value += this.tu.edited_overtime2;
      value += this.tu.edited_overtime3;
      value += this.tu.edited_overtime4;

      value += this.we.edited_normal;
      value += this.we.edited_overtime1;
      value += this.we.edited_overtime2;
      value += this.we.edited_overtime3;
      value += this.we.edited_overtime4;

      value += this.th.edited_normal;
      value += this.th.edited_overtime1;
      value += this.th.edited_overtime2;
      value += this.th.edited_overtime3;
      value += this.th.edited_overtime4;

      value += this.fr.edited_normal;
      value += this.fr.edited_overtime1;
      value += this.fr.edited_overtime2;
      value += this.fr.edited_overtime3;
      value += this.fr.edited_overtime4;

      value += this.sa.edited_normal;
      value += this.sa.edited_overtime1;
      value += this.sa.edited_overtime2;
      value += this.sa.edited_overtime3;
      value += this.sa.edited_overtime4;

      value += this.su.edited_normal;
      value += this.su.edited_overtime1;
      value += this.su.edited_overtime2;
      value += this.su.edited_overtime3;
      value += this.su.edited_overtime4;

    }


    if (subtractBreaks)
    {
      value -= this.mo.normal_break;
      value -= this.mo.overtime1_break;
      value -= this.mo.overtime2_break;
      value -= this.mo.overtime3_break;
      value -= this.mo.overtime4_break;

      value -= this.tu.normal_break;
      value -= this.tu.overtime1_break;
      value -= this.tu.overtime2_break;
      value -= this.tu.overtime3_break;
      value -= this.tu.overtime4_break;

      value -= this.we.normal_break;
      value -= this.we.overtime1_break;
      value -= this.we.overtime2_break;
      value -= this.we.overtime3_break;
      value -= this.we.overtime4_break;

      value -= this.th.normal_break;
      value -= this.th.overtime1_break;
      value -= this.th.overtime2_break;
      value -= this.th.overtime3_break;
      value -= this.th.overtime4_break;

      value -= this.fr.normal_break;
      value -= this.fr.overtime1_break;
      value -= this.fr.overtime2_break;
      value -= this.fr.overtime3_break;
      value -= this.fr.overtime4_break;

      value -= this.sa.normal_break;
      value -= this.sa.overtime1_break;
      value -= this.sa.overtime2_break;
      value -= this.sa.overtime3_break;
      value -= this.sa.overtime4_break;

      value -= this.su.normal_break;
      value -= this.su.overtime1_break;
      value -= this.su.overtime2_break;
      value -= this.su.overtime3_break;
      value -= this.su.overtime4_break;
    }

    return value;
  }
  sumTotalHours(includeEdited: boolean, subtractBreaks: boolean): string
  {
    let value = this.calculateTotal(includeEdited, subtractBreaks);
    value = value / 60;
    const formattedValue = this.roundTo(value, 2);
    return formattedValue.toString().replace('.', ',');
  }

  calculateNormal(includeEdited: boolean, subtractBreaks: boolean): number
  {
    let value = this.mo.normal;
    value += this.tu.normal;
    value += this.we.normal;
    value += this.th.normal;
    value += this.fr.normal;
    value += this.sa.normal;
    value += this.su.normal;

    if (includeEdited)
    {
      value = value + this.mo.edited_normal;
      value = value + this.tu.edited_normal;
      value = value + this.we.edited_normal;
      value = value + this.th.edited_normal;
      value = value + this.fr.edited_normal;
      value = value + this.sa.edited_normal;
      value = value + this.su.edited_normal;
    }

    if (subtractBreaks)
    {
      value = value - this.mo.normal_break;
      value = value - this.tu.normal_break;
      value = value - this.we.normal_break;
      value = value - this.th.normal_break;
      value = value - this.fr.normal_break;
      value = value - this.sa.normal_break;
      value = value - this.su.normal_break;
    }

    return value;
  }
  sumNormalHours(includeEdited: boolean, subtractBreaks: boolean): string
  {
    let value = this.calculateNormal(includeEdited, subtractBreaks);
    value = value / 60;
    const formattedValue = this.roundTo(value, 2);
    return formattedValue.toString().replace('.', ',');
  }

  calculateOvertime1(includeEdited: boolean, subtractBreaks: boolean): number
  {
    let value = this.mo.overtime1;
    value += this.tu.overtime1;
    value += this.we.overtime1;
    value += this.th.overtime1;
    value += this.fr.overtime1;
    value += this.sa.overtime1;
    value += this.su.overtime1;


    if (includeEdited)
    {
      value = value + this.mo.edited_overtime1;
      value = value + this.tu.edited_overtime1;
      value = value + this.we.edited_overtime1;
      value = value + this.th.edited_overtime1;
      value = value + this.fr.edited_overtime1;
      value = value + this.sa.edited_overtime1;
      value = value + this.su.edited_overtime1;
    }

    if (subtractBreaks)
    {
      value = value - this.mo.overtime1_break;
      value = value - this.tu.overtime1_break;
      value = value - this.we.overtime1_break;
      value = value - this.th.overtime1_break;
      value = value - this.fr.overtime1_break;
      value = value - this.sa.overtime1_break;
      value = value - this.su.overtime1_break;
    }

    return value;
  }
  sumOvertime1Hours(includeEdited: boolean, subtractBreaks: boolean): string
  {
    let value = this.calculateOvertime1(includeEdited, subtractBreaks);

    value = value / 60;

    const formattedValue = this.roundTo(value, 2);

    return formattedValue.toString().replace('.', ',');
  }

  calculateOvertime2(includeEdited: boolean, subtractBreaks: boolean): number
  {
    let value = this.mo.overtime2;
    value += this.tu.overtime2;
    value += this.we.overtime2;
    value += this.th.overtime2;
    value += this.fr.overtime2;
    value += this.sa.overtime2;
    value += this.su.overtime2;

    if (includeEdited)
    {
      value = value + this.mo.edited_overtime2;
      value = value + this.tu.edited_overtime2;
      value = value + this.we.edited_overtime2;
      value = value + this.th.edited_overtime2;
      value = value + this.fr.edited_overtime2;
      value = value + this.sa.edited_overtime2;
      value = value + this.su.edited_overtime2;
    }
    if (subtractBreaks)
    {
      value = value - this.mo.overtime2_break;
      value = value - this.tu.overtime2_break;
      value = value - this.we.overtime2_break;
      value = value - this.th.overtime2_break;
      value = value - this.fr.overtime2_break;
      value = value - this.sa.overtime2_break;
      value = value - this.su.overtime2_break;
    }
    return value;
  }
  sumOvertime2Hours(includeEdited: boolean, subtractBreaks: boolean): string
  {
    let value = this.calculateOvertime2(includeEdited, subtractBreaks);
    value = value / 60;
    const formattedValue = this.roundTo(value, 2);
    return formattedValue.toString().replace('.', ',');
  }

  calculateOvertime3(includeEdited: boolean, subtractBreaks: boolean): number
  {
    let value = this.mo.overtime3;
    value += this.tu.overtime3;
    value += this.we.overtime3;
    value += this.th.overtime3;
    value += this.fr.overtime3;
    value += this.sa.overtime3;
    value += this.su.overtime3;

    if (includeEdited)
    {
      value = value + this.mo.edited_overtime3;
      value = value + this.tu.edited_overtime3;
      value = value + this.we.edited_overtime3;
      value = value + this.th.edited_overtime3;
      value = value + this.fr.edited_overtime3;
      value = value + this.sa.edited_overtime3;
      value = value + this.su.edited_overtime3;
    }
    if (subtractBreaks)
    {
      value = value - this.mo.overtime3_break;
      value = value - this.tu.overtime3_break;
      value = value - this.we.overtime3_break;
      value = value - this.th.overtime3_break;
      value = value - this.fr.overtime3_break;
      value = value - this.sa.overtime3_break;
      value = value - this.su.overtime3_break;
    }
    return value;
  }
  sumOvertime3Hours(includeEdited: boolean, subtractBreaks: boolean): string
  {
    let value = this.calculateOvertime3(includeEdited, subtractBreaks);
    value = value / 60;
    const formattedValue = this.roundTo(value, 2);
    return formattedValue.toString().replace('.', ',');
  }

  calculateOvertime4(includeEdited: boolean, subtractBreaks: boolean): number
  {
    let value = this.mo.overtime4;
    value += this.tu.overtime4;
    value += this.we.overtime4;
    value += this.th.overtime4;
    value += this.fr.overtime4;
    value += this.sa.overtime4;
    value += this.su.overtime4;

    if (includeEdited)
    {
      value = value + this.mo.edited_overtime4;
      value = value + this.tu.edited_overtime4;
      value = value + this.we.edited_overtime4;
      value = value + this.th.edited_overtime4;
      value = value + this.fr.edited_overtime4;
      value = value + this.sa.edited_overtime4;
      value = value + this.su.edited_overtime4;
    }
    if (subtractBreaks)
    {
      value = value - this.mo.overtime4_break;
      value = value - this.tu.overtime4_break;
      value = value - this.we.overtime4_break;
      value = value - this.th.overtime4_break;
      value = value - this.fr.overtime4_break;
      value = value - this.sa.overtime4_break;
      value = value - this.su.overtime4_break;
    }
    return value;
  }
  sumOvertime4Hours(includeEdited: boolean, subtractBreaks: boolean): string
  {
    let value = this.calculateOvertime4(includeEdited, subtractBreaks);
    value = value / 60;
    const formattedValue = this.roundTo(value, 2);
    return formattedValue.toString().replace('.', ',');
  }

  calculateResttime(includeEdited: boolean): number
  {
    let value = this.mo.resttime;
    value += this.tu.resttime;
    value += this.we.resttime;
    value += this.th.resttime;
    value += this.fr.resttime;
    value += this.sa.resttime;
    value += this.su.resttime;

    if (includeEdited)
    {
      value = value + this.mo.edited_resttime;
      value = value + this.tu.edited_resttime;
      value = value + this.we.edited_resttime;
      value = value + this.th.edited_resttime;
      value = value + this.fr.edited_resttime;
      value = value + this.sa.edited_resttime;
      value = value + this.su.edited_resttime;
    }
    return value;
  }
  sumResttimeHours(includeEdited: boolean): string
  {
    let value = this.calculateResttime(includeEdited);
    value = value / 60;
    const formattedValue = this.roundTo(value, 2);
    return formattedValue.toString().replace('.', ',');
  }

  sumBreakMinutes(): number
  {
    return this.mo.breakMinutes
      + this.tu.breakMinutes
      + this.we.breakMinutes
      + this.th.breakMinutes
      + this.fr.breakMinutes
      + this.sa.breakMinutes
      + this.su.breakMinutes;
  }

  roundTo(num: number, places: number)
  {
    const factor = 10 ** places;
    return Math.round(num * factor) / factor;
  };

  sumNormalHasEdit(): boolean
  {
    return this.mo.normalHasEdit()
      || this.tu.normalHasEdit()
      || this.we.normalHasEdit()
      || this.th.normalHasEdit()
      || this.fr.normalHasEdit()
      || this.sa.normalHasEdit()
      || this.su.normalHasEdit();
  }

  sumOvertime1HasEdit(): boolean
  {
    return this.mo.overtime1HasEdit()
      || this.tu.overtime1HasEdit()
      || this.we.overtime1HasEdit()
      || this.th.overtime1HasEdit()
      || this.fr.overtime1HasEdit()
      || this.sa.overtime1HasEdit()
      || this.su.overtime1HasEdit();
  }

  sumOvertime2HasEdit(): boolean
  {
    return this.mo.overtime2HasEdit()
      || this.tu.overtime2HasEdit()
      || this.we.overtime2HasEdit()
      || this.th.overtime2HasEdit()
      || this.fr.overtime2HasEdit()
      || this.sa.overtime2HasEdit()
      || this.su.overtime2HasEdit();
  }

  sumOvertime3HasEdit(): boolean
  {
    return this.mo.overtime3HasEdit()
      || this.tu.overtime3HasEdit()
      || this.we.overtime3HasEdit()
      || this.th.overtime3HasEdit()
      || this.fr.overtime3HasEdit()
      || this.sa.overtime3HasEdit()
      || this.su.overtime3HasEdit();
  }

  sumOvertime4HasEdit(): boolean
  {
    return this.mo.overtime4HasEdit()
      || this.tu.overtime4HasEdit()
      || this.we.overtime4HasEdit()
      || this.th.overtime4HasEdit()
      || this.fr.overtime4HasEdit()
      || this.sa.overtime4HasEdit()
      || this.su.overtime4HasEdit();
  }

  sumResttimeHasEdit(): boolean
  {
    return this.mo.resttimeHasEdit()
      || this.tu.resttimeHasEdit()
      || this.we.resttimeHasEdit()
      || this.th.resttimeHasEdit()
      || this.fr.resttimeHasEdit()
      || this.sa.resttimeHasEdit()
      || this.su.resttimeHasEdit();
  }

  sumTotalHasEdit(): boolean
  {
    return this.mo.totalHasEdit()
      || this.tu.totalHasEdit()
      || this.we.totalHasEdit()
      || this.th.totalHasEdit()
      || this.fr.totalHasEdit()
      || this.sa.totalHasEdit()
      || this.su.totalHasEdit();
  }

  sumKmExport()
  {
    return this.mo.kmExport
      + this.tu.kmExport
      + this.we.kmExport
      + this.th.kmExport
      + this.fr.kmExport
      + this.sa.kmExport
      + this.su.kmExport;

  }

  sumKmInland()
  {
    return this.mo.kmInland
      + this.tu.kmInland
      + this.we.kmInland
      + this.th.kmInland
      + this.fr.kmInland
      + this.sa.kmInland
      + this.su.kmInland;
  }

  sumKmTotal()
  {
    this.sumKmExport() + this.sumKmInland();
  }
  //hasRevision(): boolean
  //{
  //  return !(this.revision < 1);
  //}
  showSH(): boolean
  {
    let H = this.mo.isHoliday
      || this.tu.isHoliday
      || this.we.isHoliday
      || this.th.isHoliday
      || this.fr.isHoliday
      || this.sa.isHoliday
      || this.su.isHoliday;
    let S = this.mo.isSunday
      || this.tu.isSunday
      || this.we.isSunday
      || this.th.isSunday
      || this.fr.isSunday
      || this.sa.isSunday
      || this.su.isSunday;

    return S || H;
  }

  showSleep(): boolean
  {
    return this.mo.isSleepOver
      || this.tu.isSleepOver
      || this.we.isSleepOver
      || this.th.isSleepOver
      || this.fr.isSleepOver
      || this.sa.isSleepOver
      || this.su.isSleepOver;
  }

  sumTotalSpecialHours(): string
  {
    let value = this.mo.special
      || this.tu.special
      || this.we.special
      || this.th.special
      || this.fr.special
      || this.sa.special
      || this.su.special;

    value = value / 60;

    const formattedValue = this.roundTo(value, 2);

    return formattedValue.toString().replace('.', ',');
  }

  public distanceExport(onvertToKm: boolean, roundTo: number): number
  {
    let km = this.mo.distanceExport(false, 0)
      + this.tu.distanceExport(false, 0)
      + this.we.distanceExport(false, 0)
      + this.th.distanceExport(false, 0)
      + this.fr.distanceExport(false, 0)
      + this.sa.distanceExport(false, 0)
      + this.su.distanceExport(false, 0);


    if (onvertToKm)
    {
      km = km / 1000;
    }
    if (roundTo > 0)
    {
      km = this.roundTo(km, 2);
    }
    return km;
  }

}

export class ColTotals
{

  constructor()
  {
    this.normal = 0;
    this.overtime1 = 0;
    this.overtime2 = 0;
    this.overtime3 = 0;
    this.overtime4 = 0;

    this.normal_break = 0;
    this.overtime1_break = 0;
    this.overtime2_break = 0;
    this.overtime3_break = 0;
    this.overtime4_break = 0;

    this.resttime = 0;
    this.total = 0;
    this.includeBreaks = true;
    this.breakMinutes = 0;
    this.totalSubtractedBreaks = 0;
    this.kmExport = 0;
    this.kmInland = 0;

    this.edited_normal = 0;
    this.edited_overtime1 = 0;
    this.edited_overtime2 = 0;
    this.edited_overtime3 = 0;
    this.edited_overtime4 = 0;
    this.edited_resttime = 0;

    this.edited_comment_normal = "";;
    this.edited_comment_overtime1 = "";
    this.edited_comment_overtime2 = "";
    this.edited_comment_overtime3 = "";
    this.edited_comment_overtime4 = "";
    this.edited_comment_resttime = "";

    this.isHoliday = false;
    this.isSunday = false;
    this.isSleepOver = false;

    this.special = 0;
  }

  isHoliday: boolean;
  isSunday: boolean;
  isSleepOver: boolean;

  special: number;

  normal: number;
  overtime1: number;
  overtime2: number;
  overtime3: number;
  overtime4: number;

  normal_break: number;
  overtime1_break: number;
  overtime2_break: number;
  overtime3_break: number;
  overtime4_break: number;

  resttime: number;
  total: number;
  includeBreaks: boolean;
  breakMinutes: number;
  totalSubtractedBreaks: number;
  kmExport: number;
  kmInland: number;

  edited_normal: number;
  edited_overtime1: number;
  edited_overtime2: number;
  edited_overtime3: number;
  edited_overtime4: number;
  edited_resttime: number;

  edited_comment_normal: string;
  edited_comment_overtime1: string;
  edited_comment_overtime2: string;
  edited_comment_overtime3: string;
  edited_comment_overtime4: string;
  edited_comment_resttime: string;

  reset(): void
  {
    this.normal = 0;
    this.overtime1 = 0;
    this.overtime2 = 0;
    this.overtime3 = 0;
    this.overtime4 = 0;
    this.resttime = 0;
    this.total = 0;
    this.totalSubtractedBreaks = 0;
    this.kmExport = 0;
    this.kmInland = 0;

    this.edited_normal = 0;
    this.edited_overtime1 = 0;
    this.edited_overtime2 = 0;
    this.edited_overtime3 = 0;
    this.edited_overtime4 = 0;
    this.edited_resttime = 0;

    this.edited_comment_normal = "";;
    this.edited_comment_overtime1 = "";
    this.edited_comment_overtime2 = "";
    this.edited_comment_overtime3 = "";
    this.edited_comment_overtime4 = "";
    this.edited_comment_resttime = "";

    this.isHoliday = false;
    this.isSleepOver = false;
    this.isSunday = false;

    this.special = 0;
  }

  public distanceExport(onvertToKm: boolean, roundTo: number): number
  {
    let km = this.kmExport;
    if (onvertToKm)
    {
      km = km / 1000;
    }
    if (roundTo > 0)
    {
      km = this.roundTo(km, 2);
    }
    return km;
  }

  public specialHours(): string
  {
    return this.Hours(false, false, this.special, 0, 0);
  }

  public normalHours(includeEdited: boolean, subtractBreaks: boolean): string
  {
    return this.Hours(includeEdited, subtractBreaks, this.normal, this.edited_normal, this.normal_break);
  }

  public overtime1Hours(includeEdited: boolean, subtractBreaks: boolean): string
  {
    return this.Hours(includeEdited, subtractBreaks, this.overtime1, this.edited_overtime1, this.overtime1_break);
  }

  public overtime2Hours(includeEdited: boolean, subtractBreaks: boolean): string
  {
    return this.Hours(includeEdited, subtractBreaks, this.overtime2, this.edited_overtime2, this.overtime2_break);
  }

  public overtime3Hours(includeEdited: boolean, subtractBreaks: boolean): string
  {
    return this.Hours(includeEdited, subtractBreaks, this.overtime3, this.edited_overtime3, this.overtime3_break);
  }

  public overtime4Hours(includeEdited: boolean, subtractBreaks: boolean): string
  {
    return this.Hours(includeEdited, subtractBreaks, this.overtime4, this.edited_overtime4, this.overtime4_break);
  }

  public resttimeHours(includeEdited: boolean): string
  {
    return this.Hours(includeEdited, false, this.resttime, this.edited_resttime, 0);
  }

  public totalHours(includeEdited: boolean, subtractBreaks: boolean): string
  {
    let value = this.normal;
    value = value + this.overtime1;
    value = value + this.overtime2;
    value = value + this.overtime3;
    value = value + this.overtime4;

    if (includeEdited)
    {
      value = value + this.edited_normal;
      value = value + this.edited_overtime1;
      value = value + this.edited_overtime2;
      value = value + this.edited_overtime3;
      value = value + this.edited_overtime4;
    }

    if (subtractBreaks)
    {
      value = value - this.normal_break;
      value = value - this.overtime1_break;
      value = value - this.overtime2_break;
      value = value - this.overtime3_break;
      value = value - this.overtime4_break;
    }

    value = value / 60;

    const formattedValue = this.roundTo(value, 2);

    return formattedValue.toString().replace('.', ',');
  }


  Hours(includeEdited: boolean, subtractBreaks: boolean, time: number, time_edited: number, time_break: number): string
  {
    let value = time;
    if (includeEdited)
    {
      value = value + time_edited;
    }
    if (subtractBreaks)
    {
      value = value - time_break;
    }
    value = value / 60;

    const formattedValue = this.roundTo(value, 2);

    return formattedValue.toString().replace('.', ',');
  }

  roundTo(num: number, places: number)
  {
    const factor = 10 ** places;
    return Math.round(num * factor) / factor;
  };

  public normalHasEdit(): boolean
  {
    return !(this.edited_normal === 0);
  }

  public overtime1HasEdit(): boolean
  {
    return !(this.edited_overtime1 === 0);
  }
  public overtime2HasEdit(): boolean
  {
    return !(this.edited_overtime2 === 0);
  }
  public overtime3HasEdit(): boolean
  {
    return !(this.edited_overtime3 === 0);
  }
  public overtime4HasEdit(): boolean
  {
    return !(this.edited_overtime4 === 0);
  }
  public resttimeHasEdit(): boolean
  {
    return !(this.edited_resttime === 0);
  }



  public totalHasEdit(): boolean
  {
    return this.normalHasEdit()
      || this.overtime1HasEdit()
      || this.overtime2HasEdit()
      || this.overtime3HasEdit()
      || this.overtime4HasEdit()
      || this.resttimeHasEdit();

  }


  public ParseJson(json: string)
  {
    let obj = JSON.parse(json) as ColTotals;

    this.normal = obj.normal;
    this.overtime1 = obj.overtime1;
    this.overtime2 = obj.overtime2;
    this.overtime3 = obj.overtime3;
    this.overtime4 = obj.overtime4;


    this.normal_break = obj.normal_break;
    this.overtime1_break = obj.overtime1_break;
    this.overtime2_break = obj.overtime2_break;
    this.overtime3_break = obj.overtime3_break;
    this.overtime4_break = obj.overtime4_break;

    this.resttime = obj.resttime;
    this.total = obj.total;
    this.includeBreaks = obj.includeBreaks;
    this.breakMinutes = obj.breakMinutes;
    this.totalSubtractedBreaks = obj.totalSubtractedBreaks;
    this.kmExport = obj.kmExport;
    this.kmInland = obj.kmInland;

    this.edited_normal = obj.edited_normal;
    this.edited_overtime1 = obj.edited_overtime1;
    this.edited_overtime2 = obj.edited_overtime2;
    this.edited_overtime3 = obj.edited_overtime3;
    this.edited_overtime4 = obj.edited_overtime4;
    this.edited_resttime = obj.edited_resttime;

    this.edited_comment_normal = obj.edited_comment_normal;
    this.edited_comment_overtime1 = obj.edited_comment_overtime1;
    this.edited_comment_overtime2 = obj.edited_comment_overtime2;
    this.edited_comment_overtime3 = obj.edited_comment_overtime3;
    this.edited_comment_overtime4 = obj.edited_comment_overtime4;
    this.edited_comment_resttime = obj.edited_comment_resttime;

    this.isHoliday = obj.isHoliday;
    this.isSleepOver = obj.isSleepOver;
    this.isSunday = obj.isSunday;

    this.special = obj.special;
  }

  public merge(obj: ColTotals)
  {
    this.normal += obj.normal;
    this.overtime1 += obj.overtime1;
    this.overtime2 += obj.overtime2;
    this.overtime3 += obj.overtime3;
    this.overtime4 += obj.overtime4;


    this.normal_break += obj.normal_break;
    this.overtime1_break += obj.overtime1_break;
    this.overtime2_break += obj.overtime2_break;
    this.overtime3_break += obj.overtime3_break;
    this.overtime4_break += obj.overtime4_break;

    this.resttime += obj.resttime;
    this.total += obj.total;
    this.includeBreaks = obj.includeBreaks;
    this.breakMinutes += obj.breakMinutes;
    this.totalSubtractedBreaks += obj.totalSubtractedBreaks;
    this.kmExport += obj.kmExport;
    this.kmInland += obj.kmInland;

    this.edited_normal += obj.edited_normal;
    this.edited_overtime1 += obj.edited_overtime1;
    this.edited_overtime2 += obj.edited_overtime2;
    this.edited_overtime3 += obj.edited_overtime3;
    this.edited_overtime4 += obj.edited_overtime4;
    this.edited_resttime += obj.edited_resttime;

    this.edited_comment_normal = obj.edited_comment_normal;
    this.edited_comment_overtime1 = obj.edited_comment_overtime1;
    this.edited_comment_overtime2 = obj.edited_comment_overtime2;
    this.edited_comment_overtime3 = obj.edited_comment_overtime3;
    this.edited_comment_overtime4 = obj.edited_comment_overtime4;
    this.edited_comment_resttime = obj.edited_comment_resttime;


    this.isHoliday = obj.isHoliday || this.isHoliday;
    this.isSleepOver = obj.isSleepOver || this.isSleepOver;
    this.isSunday = obj.isSunday || this.isSunday;

    this.special += obj.special;

  }
}
