<div class="col-md-12">
<span *ngFor="let e of equipments" style="overflow: auto">
          <div class="chip bg-secondary unselectable">
            <span class="chip-text">{{e.value}}</span>
            <span *ngIf="createMode" class="chip-close-btn" (click)="deleteEquipment(e.value)">&times;</span>
          </div>
        </span>
</div>
<div *ngIf="createMode" class="col-md-12 input-group input-group-sm">
  <div class="input-group-append col-md-6" style="padding-left: 0">
    <input placeholder="{{'equipment.add' | translate}}" class="form-control form-control-sm"
           [(ngModel)]="equipmentInput" style="border-bottom-right-radius: 0; border-top-right-radius: 0 "
           (keyup.enter)="createEquipment()">
    <span class="btn btn-default btn-sm" (click)="createEquipment()"><fa-icon [icon]="faAdd"></fa-icon></span>
  </div>
</div>
