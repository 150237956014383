<div class="detail-background">
  <div class="detail-container detail-depth" [style.width]="!resize ? '45%' : '80%'">
    <div class="detail-content col-md-12">
      <div class="card">
        <div class="card-header">
          <div class="d-flex align-items-center">
            <fa-icon class="close clickable m-2" [icon]="faResize" (click)="resizeModal()"></fa-icon>
            <span class="m-2  flex-grow-1">
              {{'detail.title' | translate}}
            </span>
            <div class=" d-flex m-2">
              <button type="button" class="btn-close" aria-label="Close" (click)="closeDetails()">
                <!--<span aria-hidden="true">&times;</span>-->
              </button>
            </div>
          </div>
        </div>
        <div class="card-body">
          <ng-content></ng-content>
        </div>
      </div>
    </div>
  </div>
</div>
