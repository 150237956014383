import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-unauthorized',
  templateUrl: './unauthorized.component.html'
})

export class UnauthorizedComponent implements OnInit {
  unauthorizedTranslate: any;

  constructor(private translate: TranslateService) { }

  ngOnInit(): void {
    this.unauthorizedTranslate = this.translate.instant('auth.unauthorized');
  }
}