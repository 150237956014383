<div class="message-list-component">
  @if (this.messageList.length >= 1) {
    @for (message of this.messageList | slice:(this.page - 1) * this.pageSize:this.page * this.pageSize; track $index) {
      <div class="d-flex message-list-item flex-nowrap p-1" [ngClass]="{ 'unread': !message.isRead }" (click)="this.selectMessage(message)">
        <div class="col-6">
          {{ message.type }}
        </div>
        <div class="col-6">
          {{ this.readableDate(message.timestamp) }}
        </div>
      </div>
    }
  }

  <div class="message-list-pagination mt-2 mb-3">
    <ngb-pagination [collectionSize]="this.messageList.length" [(page)]="page" [pageSize]="pageSize"></ngb-pagination>
  </div>

  <div [ngClass]="{ 'd-none': !this.showMessageContent }" class="message-list-content bg-body-tertiary p-4">
    <span class="mb-5 italic">{{ this.readableDate(this.selectedMessageContent?.timestamp) }}</span>

    <br><br>

    {{ this.selectedMessageContent?.message }}
  </div>
</div>
