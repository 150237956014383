import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { EventModel } from "../calendar/shared/event.model";
import { CalendarOptions } from "@fullcalendar/core";
import { FullCalendarComponent } from "@fullcalendar/angular";
import { ResourceCalendarService } from "./shared/resource-calendar.service";
import { ResourceModel, ResourceView } from "./shared/resource.model";
import { Route, Router } from "@angular/router";
import { DatePipe } from "@angular/common";
import tippy from "tippy.js";
import { SharedService } from '../services/shared.service';
import { CalenderView } from 'src/app/constants';
import { filter } from 'rxjs/operators';
import interactionPlugin from '@fullcalendar/interaction';
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
import resourceTimeGridPlugin from '@fullcalendar/resource-timegrid';
import listPlugin from '@fullcalendar/list';
import bootstrapPlugin from '@fullcalendar/bootstrap';
import { Observable, lastValueFrom } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-resource-calendar',
  templateUrl: './resource-calendar.component.html',
  providers: [DatePipe]
})

export class ResourceCalendarComponent implements OnChanges {

  @Output()
  closeCalendar = new EventEmitter();

  @Output()
  openResourceDetails = new EventEmitter();

  @Output()
  openEventDetails = new EventEmitter();

  @Output()
  openJobDetails = new EventEmitter();

  @Output()
  eventClicked = new EventEmitter();

  @Output()
  createResourceTour = new EventEmitter();

  @Input()
  reloadDataCounter = 0;


  @Input()
  showTemporaryAgFix = false;

  @Output()
  toggleTemporaryFix = new EventEmitter<boolean>();

  filterAGFix: boolean = true;

  calendarOptions: CalendarOptions;

  fetchInfo: any;

  resource: any;

  language: any;

  refetchInterval: any;

  @ViewChild('calendar') calendarComponent: FullCalendarComponent | undefined;

  rightBtns: string = 'tableBtn resourceTimelineDay,resourceTimelineWeek,resourceTimelineMonth';

  constructor(
    private service: ResourceCalendarService,
    private router: Router,
    private datePipe: DatePipe,
    private sharedService: SharedService,
    private translate: TranslateService
  ) {
    if (this.showTemporaryAgFix) {
      this.rightBtns = 'tempBtn tableBtn resourceTimelineDay,resourceTimelineWeek,resourceTimelineMonth';
    }
    this.calendarOptions = {
      plugins: [
        resourceTimelinePlugin,
        resourceTimeGridPlugin,
        bootstrapPlugin,
        interactionPlugin,
        dayGridPlugin,
        timeGridPlugin,
        listPlugin
      ],
      schedulerLicenseKey: '0264157724-fcs-1705169645',
      initialView: 'resourceTimelineDay',
      eventMinWidth: 0,
      datesAboveResources: true,
      buttonText: {
        today: this.translate.instant('calendar.shared.today'),
        month: this.translate.instant('calendar.shared.month'),
        week: this.translate.instant('calendar.shared.week'),
        day: this.translate.instant('calendar.shared.day'),
        list: this.translate.instant('calendar.resourceCalendar.list')
      },
      headerToolbar: {
        left: 'prev,next today',
        center: 'title',
        right: this.rightBtns
      },
      locale: 'da-dk',
      aspectRatio: 1.5,
      height: 'auto',
      weekNumbers: true,
      weekText: this.translate.instant('calendar.shared.weekWithSpacing'),
      customButtons: {
        tableBtn: {
          text: this.translate.instant('calendar.shared.table'),
          click: () => this.closeCalendar.emit()
        },
        tempBtn: {
          text: 'AG +',
          click: () => this.toggleTemporaryData()
        },
      },
      firstDay: 1,
      resourceAreaWidth: '22%',
      resourceAreaColumns: this.router.url === '/users' || this.router.url === '/tours' ? [
        {
          field: 'title',
          headerContent: this.translate.instant('calendar.resourceCalendar.resources'),
          width: 60
        },
        {
          field: 'restHours',
          headerContent: { html: this.translate.instant('calendar.resourceCalendar.breaks') + '<br>' + this.translate.instant('calendar.resourceCalendar.sum') },
          width: 20
        },
        {
          field: 'timeRegistrationHours',
          headerContent: { html: this.translate.instant('calendar.resourceCalendar.hours') + '<br>' + this.translate.instant('calendar.resourceCalendar.sum') },
          width: 20
        },
        {
          field: 'tourHours',
          headerContent: { html: this.translate.instant('calendar.resourceCalendar.tasks') + '<br>' + this.translate.instant('calendar.resourceCalendar.sum') },
          width: 20
        }
      ] : [
        {
          field: 'title',
          headerContent: this.translate.instant('calendar.resourceCalendar.resources')
        }
      ],
      eventClick: (info: any) => this.clickedEvent(info),
      dateClick: (info: any) => this.dateClicked(info),
      refetchResourcesOnNavigate: true,
      resources: (fetchInfo: any, successCallback: any, failureCallback: any) => this.fetchData(fetchInfo),
      resourceLabelDidMount: (info: any) => this.didMountResource(info),
      nowIndicator: true,
      eventDidMount: (info: any) => this.onEventDidMount(info),
    }

  }

  async getLanguage() {
    this.sharedService.getLanguageJson().subscribe(response => this.language = response)
  }

  async ngOnInit(): Promise<void> {
    await this.getLanguage()

    this.refetchInterval = setInterval(() => {
      this.updateView()
    }, 60_000)
  }


  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    if (this.showTemporaryAgFix) {
      this.rightBtns = 'tempBtn tableBtn resourceTimelineDay,resourceTimelineWeek,resourceTimelineMonth';
      //this.calendarOption?.headerToolbar?.right = this.rightBtns;
    }
    else {
      this.rightBtns = 'tableBtn resourceTimelineDay,resourceTimelineWeek,resourceTimelineMonth';
    }

    this.calendarOptions.headerToolbar = {
      left: 'prev,next today',
      center: 'title',
      right: this.rightBtns
    };

    if (this.fetchInfo) {

      await this.fetchData(this.fetchInfo);
      this.calendarOptions.resources = (fetchInfo: any, successCallback: any, failureCallback: any) => this.fetchData(fetchInfo);
    }
  }

  toggleTemporaryData() {
    this.filterAGFix = !this.filterAGFix;
    if (this.filterAGFix) {
      this.calendarOptions.customButtons = {
        tableBtn: {
          text: 'Tabel',
          click: () => this.closeCalendar.emit()
        },
        tempBtn: {
          text: 'AG +',
          click: () => this.toggleTemporaryData()
        },
      }
    }
    else {
      this.calendarOptions.customButtons = {
        tableBtn: {
          text: 'Tabel',
          click: () => this.closeCalendar.emit()
        },
        tempBtn: {
          text: 'AG -',
          click: () => this.toggleTemporaryData()
        },
      }
    }
    this.toggleTemporaryFix.emit(this.filterAGFix);
  }


  ngOnDestroy(): void {
    clearInterval(this.refetchInterval);
  }

  async updateView(): Promise<void> {

    if (this.calendarComponent != undefined) {

      if (this.fetchInfo) {

        await this.fetchData(this.fetchInfo);
        this.calendarOptions.resources = (fetchInfo: any, successCallback: any, failureCallback: any) => this.fetchData(fetchInfo);
      }

    }

  }

  async fetchData(e: any): Promise<ResourceModel[]> {

    this.calendarOptions.events = [];

    this.fetchInfo = e;

    const resources = await this.getResources(e);
    const events: EventModel[] = [];


    resources.forEach(resource => {
      resource.events.forEach(event => events.push(event))
    });
    this.calendarOptions.events = events;



    return resources;
  }

  clickedEvent(e: any): void {
    this.eventClicked.emit(e);

    if (e.el.className.includes('unclickable')) return;

    const event = { id: e.event._def.publicId };

    if (e.el.className.includes('jobregistration')) {
      this.openJobDetails.emit(event);
    } else {
      this.openEventDetails.emit(event);
    }
  }

  dateClicked(e: any): void {

    const event = { startDate: e.date, allDay: e.allDay };

    if (event.startDate > new Date() && !e.jsEvent.path[0].className.includes('timeregistration'))
      this.createResourceTour.emit({ resourceId: e.resource._resource.id, event: event });
  }

  didMountResource(e: any): void {

    const event = { id: e.resource._resource.id, resource: this.resource };

    const target = e.el.querySelector('.fc-datagrid-cell-main');
    target.classList.add('clickable');
    target.addEventListener("click", () => this.openResourceDetails.emit(event));

  }

  onEventDidMount(e: any) {
    tippy(e.el, {
      allowHTML: true,
      content: '<strong>' + e.event.title + '</strong><br></span><span>' + `${this.datePipe.transform(e.event.start, 'yyyy-MM-dd HH:mm')} - ${this.datePipe.transform(e.event.end, 'yyyy-MM-dd HH:mm')}` + '</span>',
      placement: 'bottom'
    })
  }

  private async getResources(e: any): Promise<ResourceModel[]> {
    const start = this.datePipe.transform(e.start, 'yyyy-MM-dd');
    const end = this.datePipe.transform(e.end, 'yyyy-MM-dd');
    let groupId: string = ''

    if (sessionStorage.getItem('groupId')) {
      groupId = sessionStorage.getItem('groupId')!.toString()
    }


    switch (this.router.url) {
      case '/users':
        this.resource = ResourceView.users;
        return await lastValueFrom<any>(this.service.getAllUsersResources(start, end, this.filterAGFix));
      //return this.service.getAllUsersResources(start, end);
      //return this.service.getUsers(start, end).toPromise();
      case '/front-vehicles':
        this.resource = ResourceView.frontVehicles;
        return await lastValueFrom<any>(this.service.getFrontVehicles(start, end));
      //return this.service.getFrontVehicles(start, end).toPromise();
      case '/back-vehicles':
        this.resource = ResourceView.backVehicles;
        return await lastValueFrom<any>(this.service.getBackVehicles(start, end));
      //return this.service.getBackVehicles(start, end).toPromise();
      case '/transport-equipments':
        this.resource = ResourceView.equipments
        return await lastValueFrom<any>(this.service.getTransportEquipments(start, end));
      //return this.service.getTransportEquipments(start, end).toPromise();
      default:
        this.resource = ResourceView.users;
        //return this.service.getUsers(start, end).toPromise();
        return await lastValueFrom<any>(this.service.getAllUsersResources(start, end, this.filterAGFix));
      //return this.service.getAllUsersResources(start, end).toPromise();
    }
  }

}
