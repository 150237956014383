<div class="col-md-12">
  <div class="d-flex flex-row-reverse" *ngIf="formGroup">
    <!--<button type="button" class="btn accept-btn btn-success" [disabled]=" !formGroup || formGroup.invalid" (click)="accept.emit()">-->
    <button type="button" class="btn btn-success" [disabled]=" !formGroup || formGroup.invalid" (click)="accept.emit()">
      <fa-icon [icon]="faAccept"></fa-icon>
    </button>
    <!--<button class="btn accept-btn btn-outline-secondary" (click)="cancel.emit()">-->
    <button class="btn btn-outline-secondary mx-2" (click)="cancel.emit()">
      <fa-icon [icon]="faCancel"></fa-icon>
    </button>
  </div>
</div>
