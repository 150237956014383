import { Injectable } from '@angular/core';
import {environment} from "../../../../environments/environment";
import {Observable} from "rxjs";
import {TagModel} from "./tag.model";
import {HttpClient} from "@angular/common/http";

const url = environment.baseUrl + '/V1/Tags'

@Injectable({
  providedIn: 'root'
})
export class TagService {

  constructor(private http: HttpClient) { }

  getAll(): Observable<TagModel[]>{
    return this.http.get<TagModel[]>(url);
  }
}
