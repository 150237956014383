import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-signout-callback-oidc',
  templateUrl: './signout-callback-oidc.component.html'
})

export class SignoutCallbackOidcComponent implements OnInit {
  signOutTranslate: any;

  constructor(private router: Router, private translate: TranslateService) { }

  ngOnInit(): void {
    this.signOutTranslate = this.translate.instant('auth.signOut');
    this.router.navigate(['start']);
  }
}