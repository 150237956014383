import { LogBookService } from './shared/log-book.service';
import { LogBook, LogBookTypes, SearchModel, GetLogBookEntriesModel } from './shared/log-book.model';
import { Component, Input, OnInit } from '@angular/core';
import { IconDefinition, faLocationDot, faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import * as moment from 'moment';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { lastValueFrom } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-log-book',
  templateUrl: './log-book.component.html',
  styleUrls: ['./log-book.component.scss']
})

export class LogBookComponent implements OnInit {
  @Input() selectedItemId: number = 0;
  @Input() resourceType: string = '';

  logEntries: LogBook[] = [];
  logTypes: LogBookTypes[] = [];

  faLocationDot: IconDefinition = faLocationDot;
  faMagnifyingGlass: IconDefinition = faMagnifyingGlass;

  formGroup: UntypedFormGroup;

  constructor(private logBookService: LogBookService, private formBuilder: UntypedFormBuilder, private toaster: ToastrService, private translate: TranslateService) {
    this.formGroup = this.formBuilder.group({
      logType: [0, Validators.required],
      fromUtc: ['', Validators.required],
      toUtc: ['', Validators.required]
    });
  }

  ngOnInit(): void {
    this.getLogTypes();
    this.getLogEntries(this.resourceType);
  }

  getLogEntries(resourceType: string): void {
    let model: GetLogBookEntriesModel = { resourceId: this.selectedItemId, resourceType: resourceType }
    this.logBookService.getEntryGroup(model).subscribe(logEntry => {
      if (logEntry.entries) {
        logEntry.entries.forEach(log => {
          this.logEntries.push(log);
        });
      }
    });
  }

  getLogTypes(): void {
    this.logBookService.getLogTypes().subscribe(logType => {
      if (logType) {
        logType.logbookTypes.forEach(log => {
          this.logTypes.push(log)
        })
      }
    });
  }

  toggleBorderColor(): void {
    let accordionItems: NodeListOf<Element> = document.querySelectorAll('#nestedAccordion .accordion-item .accordion-header .accordion-button');
    accordionItems.forEach(accordionBtn => {
      let accordionItem: Element | null = accordionBtn.closest('.accordion-item');
      !accordionBtn?.classList.contains('collapsed') ? accordionItem?.classList.add('dark-border') : accordionItem?.classList.remove('dark-border');
    });
  }

  formatDateTime(date: Date): string {
    return moment(date).format('DD-MM-YYYY [• kl.] HH:mm');
  }

  async searchForLogs(): Promise<void> {
    if (this.formGroup.valid) {
      let model: SearchModel = this.formGroup.value as SearchModel;

      model.resourceId = this.selectedItemId;
      model.resourceType = this.resourceType;

      const response = await lastValueFrom<any>(this.logBookService.search(model));

      if (response.entries.length > 0) {
        this.logEntries = response.entries;
      }
      else {
        this.toaster.info(this.translate.instant('logBook.noLogsSearch'));
      }
    }
    else {
      this.toaster.error(this.translate.instant('message.invalidForm'));
    }
  }
}
