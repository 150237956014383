import { Injectable } from '@angular/core';
import { environment } from "../../../environments/environment";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { CreateBoxModel, CreateFrontVehicleEventModel, CreateFrontVehicleModel, FrontVehicleExistsModel, FrontVehicleModel, UpdateFrontVehicleBoxIdModel, UpdateFrontVehicleEventModel, UpdateFrontVehicleModel } from "./front-vehicle.model";
import { CustomerModel } from "../../customers/shared/customer.model";
import { NoteModel } from "../../shared/note-widget/shared/note.model";
import { boxService } from 'src/app/boxes/shared/box.service';
import { AttachmentCreateModel } from 'src/app/shared/attachment-widget/shared/attachment.model';
import { DataExchangeModel } from 'src/app/shared/fileuploader/shared/data-exchange.model';

const url = environment.baseUrl + '/V1/FrontVehicles'

@Injectable({
  providedIn: 'root'
})

export class FrontVehicleService {
  constructor(private http: HttpClient) { }


  getAll(): Observable<FrontVehicleModel[]>{
    return this.http.get<FrontVehicleModel[]>(`${url}/All`);
  }

  getSingle(id: number): Observable<FrontVehicleModel> {
    return this.http.get<FrontVehicleModel>(`${url}/${id}`);
  }

  create(model: CreateFrontVehicleModel): Observable<FrontVehicleModel> {
    return this.http.post<FrontVehicleModel>(url, model);
  }

  exists(chassisNumber: string, unitIdentification: string): Observable<FrontVehicleExistsModel> {
    return this.http.get<FrontVehicleExistsModel>(`${url}/exists?chassisNumber=${chassisNumber}&unitIdentification=${unitIdentification}`);
  }

  delete(id: number): Observable<any> {
    return this.http.delete(`${url}/${id}`);
  }

  update(id: number, model: UpdateFrontVehicleModel): Observable<FrontVehicleModel> {
    return this.http.put<FrontVehicleModel>(`${url}/${id}`, model);
  }

  getAttachments(frontVehicleId: number): Observable<FrontVehicleModel> {
    return this.http.get<FrontVehicleModel>(`${url}/${frontVehicleId}/Attachments`)
  }

  createAttachment(frontVehicleId: number, attachment: AttachmentCreateModel): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('file', attachment.file);
    formData.append('showInApp', attachment.showInApp.toString());
    return this.http.post<FrontVehicleModel>(`${url}/${frontVehicleId}/Attachment`, formData);
  }

  deleteAttachment(frontVehicleId: number, atttachmentId: number): Observable<FrontVehicleModel> {
    return this.http.delete<FrontVehicleModel>(`${url}/${frontVehicleId}/Attachment/${atttachmentId}`);
  }

  getNotes(frontVehicleId: number): Observable<FrontVehicleModel> {
    return this.http.get<FrontVehicleModel>(`${url}/${frontVehicleId}/Notes`);
  }

  deleteNote(frontVehicleId: number, noteId: number): Observable<FrontVehicleModel> {
    return this.http.delete<FrontVehicleModel>(`${url}/${frontVehicleId}/Note/${noteId}`);
  }

  createNote(frontVehicleId: number, model: NoteModel): Observable<FrontVehicleModel> {
    return this.http.post<FrontVehicleModel>(`${url}/${frontVehicleId}/Note`, model);
  }

  getTranslateType(type: string): string {
    return `type.${type.toLowerCase()}`
  }

  updateFrontVehicleEvent(frontVehicleId: number, eventId: number, model: UpdateFrontVehicleEventModel): Observable<any> {
    return this.http.put<any>(`${url}/${frontVehicleId}/Events/${eventId}`, model);
  }

  getSingleEvent(id: number): Observable<any> {
    return this.http.get<any>(`${url}/${id}/Events`);
  }

  createFrontVehicleEvent(frontVehicleId: number, model: CreateFrontVehicleEventModel): Observable<any> {
    return this.http.post<any>(`${url}/${frontVehicleId}/Events`, model);
  }

  getBox(boxId: number): Observable<any> {
    return this.http.get<any>(`${environment.baseUrl}/V1/Box/${boxId}`);
  }

  // MISSING IN API
  updatedBox(boxId: number, frontVehicleId: number) {
    return this.http.put<any>(`${url}/${frontVehicleId}/Box/`, boxId);
  }

  GetDisplayText(vehicle: FrontVehicleModel): string {
    if (vehicle?.unitIdentification != null && vehicle?.registrationNumber != null) {
      return vehicle?.unitIdentification + ' - ' + vehicle?.registrationNumber;
    }
    if (vehicle?.unitIdentification != null) {
      return vehicle.unitIdentification;
    }
    if (vehicle?.registrationNumber != null) {
      return vehicle.registrationNumber;
    }
    return '';
  }

  getImportSchemas() {
    return this.http.get<DataExchangeModel[]>(`${url}/GetDataExchangeImportSchemas`);
  }

  processImportFile(file: File, schemaid: number, customerid: number): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('file', file);
    formData.append('dataExchangeSchemaId', schemaid.toString());
    return this.http.post(`${url}/ImportDataExchange`, formData);
  }
}
