<div class="overlayLoader" *ngIf="dataLoading">
  <app-loading *ngIf="dataLoading"></app-loading>
</div>

<div class="row p-2">
  <div class="col-md-3">
    <img src="../../../assets/images/trasolu_logo.svg" width="200">
  </div>

  <div class="col-md-9 title">
    <h2>{{'mss.fitterServiceSite' | translate}}</h2>
    <span *ngIf="selectedVehicle != null" style="font-weight: bold;">{{selectedVehicle}}</span>
    <span *ngIf="mountedVehicle != null" style="font-weight: bold;">{{mountedVehicle.registrationNumber}}</span>
  </div>

  <div class="col-md-12">
    <hr>
  </div>

  <div class="errorDiv" *ngIf="error == true && missingData != true">{{errorText}}</div>

  <div class="" *ngIf="missingData == true">

    <div class="row">
      <div class="col-sm-6">
      <div class="card" >
      <img src="../../../assets/images/mssIconGps.png" class="card-img-center" height="256" width="256" alt="...">
      <div class="card-body">
        <h5 class="card-title">GPS Tracker</h5>
        <p class="card-text">Vælg denne opsætning hvis du skal installere en tracker i et køretøj eller på et påhæng.</p>
        <button class="btn btn-primary" type="button" (click)="scanTracker()">Opsæt tracker</button>
      </div>
    </div>
        
      </div>
      <div class="col-sm-6">
      <div class="card" >
      <img src="../../../assets/images/mssIconBeacon.png" class="card-img-center" height="256" width="256" alt="...">
      <div class="card-body">
        <h5 class="card-title">Trasolu EYE</h5>
        <p class="card-text">Vælg denne opsætning hvis du skal installere en trasolu EYE på et køretøj eller et påhæng.</p>
        <button class="btn btn-primary" type="button" (click)="scanBeacon()">Opsæt beacon</button>
      </div>
    </div>
        
      </div>
    </div>


    
    


  </div>

  <div *ngIf="(step == 1 || step == 11 || step == 21) && error == false">
    <div class="col-md-12">
      {{'mss.welcome' | translate}} <br> {{'mss.unitIsMountedWithoutVoltage' | translate}}<br>
      {{'mss.enterCompanyCode' | translate}} <br>
      <input type="number" name="companyCode" id="companyCode" [(ngModel)]="companyCodeValue" required> <button class="btn" type="button" (click)="testCompanyCode()"> {{'mss.next' | translate}}</button>
    </div>
  </div>

  <div *ngIf="step == 2 || step == 22 || step == 12">
    <div class="col-md-12">
      <h3 *ngIf="selectedCompany">{{selectedCompany.name}}</h3>

      <div class="btn-group tab-button-group" style="width:100%;" role="group">
        <input type="radio" [value]="'frontVehicles'" class="btn-check" id="btnradio1" [(ngModel)]="btnTap" autocomplete="off">
        <label class="btn btn-light tab-button" for="btnradio1">Køretøjer</label>

        <input type="radio" [value]="'backVehicles'" class="btn-check" id="btnradio2" [(ngModel)]="btnTap" autocomplete="off">
        <label class="btn btn-light tab-button" for="btnradio2">Påhæng</label>
 
      </div>

      <div *ngIf="btnTap === 'frontVehicles'">
        {{'mss.whichVehicleIsTheUnitIn' | translate}} <br>
        <select name="vehicleId" id="vehicleId" [(ngModel)]="selectedVehicle">
          <option *ngFor="let selectVehicle of selectVehicleList" [ngValue]="selectVehicle.id">{{selectVehicle.registrationNumber}}</option>
        </select>
        <button class="btn" type="button" (click)="selectVehicle()"> {{'mss.next' | translate}}</button>
      </div>

      
      <div *ngIf="btnTap === 'backVehicles'">
        {{'mss.whichBackVehicleIsTheUnitIn' | translate}} <br>
        <select name="vehicleId" id="vehicleId" [(ngModel)]="selectedVehicle">
          <option *ngFor="let selectVehicle of selectBackVehicleList" [ngValue]="selectVehicle.id">{{selectVehicle.registrationNumber}}</option>
        </select>
        <button class="btn" type="button" (click)="selectBackVehicle()"> {{'mss.next' | translate}}</button>
      </div>
      
    </div>
  </div>

  <div *ngIf="step == 3 && pendingFotaTask != true && btnTap == 'frontVehicles'">
    <div class="col-md-12">
      <h3 *ngIf="selectedCompany">{{selectedCompany.name}}</h3>
      {{'mss.insertLicenseSwitchOnIgnition' | translate}} <br> {{'mss.5MinutesGreenStatus' | translate}} <br>
      {{'mss.alarmOnVehicleBaudRate' | translate}}<br>
      {{'mss.missingDataChangeSetup' | translate}}<br>
      <select name="configSelect" id="configSelect" [(ngModel)]="selectedConfig">
        <option *ngFor="let selectConfig of selectConfigList" [ngValue]="selectConfig.id">{{selectConfig.name}}</option>
      </select>
      <button class="btn" type="button" (click)="sendConfig();"> {{'mss.submit' | translate}}</button>
    </div>
  </div>

  <div *ngIf="step == 4 && pendingDataTask != true && pendingFotaTask != true">
    <div class="col-md-12">
      {{'mss.unitSetUpCorrectly' | translate}}<br>
    </div>
  </div>

  <div *ngIf="step == 5 || step == 23  || step == 13 ">
    <div class="col-md-12">
    <h2>  {{'mss.unitSetUpCorrectly' | translate}}<br> </h2>
    </div>
  </div>

  <div *ngIf="step == 10">
    <div class="col-md-12">
      <h2>Scan QR koden på Trasolu Boxen</h2>

      <div class="col-xs-12 col-md-6 col-lg-4 col-xl-3 my-1">
        <select #select1 (change)="action.playDevice(select1.value)" class="form-select form-select-md">
          <option [value]="null" selected>Vælg kamera</option>
          <option *ngFor="let c of action.devices.value; let i = index" [value]="c.deviceId" [selected]="i == action.deviceIndexActive">{{c.label}}</option>
        </select>
      </div>

      <button class="btn" [class.btn-info]="!action.isStart" [class.btn-warning]="action.isStart" [disabled]="action.isLoading" (click)="handle(action, action.isStart ? 'stop' : 'start')">
        <img [src]="action.isStart ? '../../../assets/images/camera-off.svg' : '../../../assets/images/camera-on.svg'" width="30px"/> Start kamera
      </button>

      <ngx-scanner-qrcode #action="scanner"  [config]="config" (event)="onEvent($event, action)"></ngx-scanner-qrcode>
 
     


    </div>
  </div>

 

  <div *ngIf="step == 20">
    <div class="col-md-12">
      <h2>Scan QR koden på Trasolu EYE</h2>

      <div class="col-xs-12 col-md-6 col-lg-4 col-xl-3 my-1">
        <select #select1 (change)="action.playDevice(select1.value)" class="form-select form-select-md">
          <option [value]="null" selected>Vælg kamera</option>
          <option *ngFor="let c of action.devices.value; let i = index" [value]="c.deviceId" [selected]="i == action.deviceIndexActive">{{c.label}}</option>
        </select>
      </div>

      <button class="btn" [class.btn-info]="!action.isStart" [class.btn-warning]="action.isStart" [disabled]="action.isLoading" (click)="handle(action, action.isStart ? 'stop' : 'start')">
        <img [src]="action.isStart ? '../../../assets/images/camera-off.svg' : '../../../assets/images/camera-on.svg'" width="30px"/> Start kamera
      </button>

      <ngx-scanner-qrcode #action="scanner"  [config]="config" (event)="onEvent($event, action)"></ngx-scanner-qrcode>
 
     


    </div>
  </div>

 

  <div *ngIf="pendingFotaTask == true">
    <div class="col-md-12 waitingDiv">
      {{'mss.applyVoltageToTheUnit' | translate}} {{'mss.waitingOnData' | translate}} <br>
      {{'mss.waitingTime' | translate}} {{pendingFotaTaskTime}} {{'mss.minutes' | translate}} <br>
    </div>
  </div>

  <div *ngIf="pendingDataTask == true">
    <div class="col-md-12 waitingDiv">
      {{'mss.insertLicenseAndStartVehicle' | translate}} {{'mss.waitingOnData' | translate}} <br>
      {{'mss.waitingTime' | translate}} {{pendingDataTaskTime}} {{'mss.minutes' | translate}}<br><br>
      {{'mss.canbusAlarmNoDataConfiguration' | translate}}
      <button class="btn" type="button" (click)="step = 3; pendingDataTask = false; pendingFotaTask = false;"> {{'mss.back' | translate}}</button>
    </div>
  </div>

  <div class="col-md-6">
    <div *ngIf="mqttActive && btnTap == 'frontVehicles' && imeiRead">
      <h3>{{'mss.status' | translate}} </h3>
      {{'mss.contact' | translate}} <span *ngIf="selectedVehicleBoxStatus.timestamp != 0" style="color:green">{{'mss.okParenthesis' | translate}}{{getTimeFromUnix(selectedVehicleBoxStatus.timestamp)}} {{'mss.minutesAgo' | translate}}</span><span *ngIf="selectedVehicleBoxStatus.timestamp == 0" style="color:orange">{{'mss.awaiting' | translate}} </span> <br>
      {{'mss.tachograph' | translate}} <span *ngIf="selectedVehicleBoxStatus.driverCardStatus" style="color:green">{{'mss.ok' | translate}} </span><span *ngIf="!selectedVehicleBoxStatus.driverCardStatus && selectedVehicleBoxStatus.timestamp != 0" style="color:red">{{'mss.notOk' | translate}} </span><span
        *ngIf="!selectedVehicleBoxStatus.driverCardStatus && selectedVehicleBoxStatus.timestamp == 0" style="color:orange">{{'mss.awaiting' | translate}} </span> <br>
        {{'mss.fuel' | translate}} <span *ngIf="selectedVehicleBoxStatus.fuelDataStatus" style="color:green">{{'mss.ok' | translate}} </span><span *ngIf="!selectedVehicleBoxStatus.fuelDataStatus && selectedVehicleBoxStatus.timestamp != 0" style="color:red">{{'mss.notOk' | translate}} </span><span
        *ngIf="!selectedVehicleBoxStatus.fuelDataStatus && selectedVehicleBoxStatus.timestamp == 0" style="color:orange">{{'mss.awaiting' | translate}} </span> <br>
    </div>

    <div *ngIf="mqttActive && btnTap == 'backVehicles' && imeiRead">
      <h3>{{'mss.status' | translate}} </h3>
      {{'mss.contact' | translate}} <span *ngIf="selectedVehicleBoxStatus.timestamp != 0" style="color:green">{{'mss.okParenthesis' | translate}}{{getTimeFromUnix(selectedVehicleBoxStatus.timestamp)}} {{'mss.minutesAgo' | translate}}</span><span *ngIf="selectedVehicleBoxStatus.timestamp == 0" style="color:orange">{{'mss.awaiting' | translate}} </span> <br>
    </div>
  </div>
</div>