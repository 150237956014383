import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../environments/environment";
import {Observable} from "rxjs";
import {AddressModel} from "../address-widget/shared/address.model";

const url = environment.baseUrl + '/V1'

@Injectable({
  providedIn: 'root'
})
export class GeocodeService {

  constructor(private http: HttpClient) { }

  geocodeAddress(street: string, zipcode: string, city: string, state: string, country: string): Observable<{latitude: number, longitude: number}>{
    return this.http.get<any>(`${url}/Geocode/Search`, {params: {
      street, zipcode, city, state, country
      }});
  }

  reverseGeocodeAddress(latitude: number, longitude: number): Observable<AddressModel>
  {
    return this.http.get<AddressModel>(`${url}/Geocode/Reverse-Search/${latitude}/${longitude}`);
  }

}
