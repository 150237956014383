import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { MainGroup, SubGroup } from "./grouping.models";

const url = environment.baseUrl + '/v1/Group'

@Injectable({
  providedIn: 'root'
})

export class GroupingService {
  constructor(private http: HttpClient) { }

  getGroupsList(): Observable<MainGroup[]> {
    return this.http.get<MainGroup[]>(url)
  }


  saveMainGroup(request: any): Observable<MainGroup> {
    return this.http.put<MainGroup>(url, {
      "id": request.id,
      "name": request.name,
      "description": request.description,
      "tags": request.tags
    })
  }


  saveSubGroup(request: any): Observable<SubGroup> {
    return this.http.put<SubGroup>(url + "/SubGroup", {
      "masterGroupId": request.masterGroupId,
      "resourceType": request.resourceType,
      "name": request.name
    })
  }


  saveSubGroupMembers(request: any): Observable<SubGroup> {
    return this.http.post<SubGroup>(url + "/SubGroup/" + request.id + "/Members", {
      memberIds: request.memberIds,
      resourceType: request.resourceType
    })
  }


  deleteMainGroup(request: any): Observable<any> {
    return this.http.delete<any>(`${url}/${request}`)
  }


  deleteSubgroup(request: any): Observable<any> {
    return this.http.delete<any>(`${url}/SubGroup/${request}`)
  }

  deleteSubgroupMember(request: any): Observable<any> {
    return this.http.delete<any>(`${url}/SubGroup/${request.id}/Members`, {
      body: {
        memberIds: request.memberIds,
        resourceType: request.resourceType
      }
    })
  }

  // createMainGroup(request: any): Promise<MainGroup> {
  //   return new Promise<MainGroup>((resolve, reject) => {
  //     resolve({
  //       "id": undefined,
  //       "name": request.name,
  //       "description": request.description,
  //       "tags": undefined
  //     })

  //     reject({
  //       status: 500,
  //       message: "❌ Error creating subgroup"
  //     })
  //   })
  // }


  // saveMainGroup(request: any): Promise<MainGroup> {
  //   return new Promise((resolve, reject) => {
  //     resolve({
  //       "id": request.id,
  //       "name": request.name,
  //       "description": request.description,
  //       "tags": request.tags,
  //     })

  //     reject({
  //       status: 500,
  //       message: "❌ Error saving subgroup"
  //     })
  //   })
  // }


  // createSubgroup(request: any): Promise<SubGroup> {
  //   return new Promise((resolve, reject) => {
  //     resolve({
  //       "id": undefined,
  //       "masterGroupId": request.masterGroupId,
  //       "resourceType": request.resourceType,
  //       "name": request.name
  //     })

  //     reject({
  //       status: 500,
  //       message: "❌ Error saving subgroup"
  //     })
  //   })
  // }


  // saveSubgroup(request: any): Promise<SubGroup> {
  //   return new Promise((resolve, reject) => {
  //     resolve({
  //       "id": request.id,
  //       "masterGroupId": request.masterGroupId,
  //       "resourceType": request.resourceType,
  //       "name": request.name
  //     })

  //     reject({
  //       status: 500,
  //       message: "❌ Error saving subgroup"
  //     })
  //   })
  // }


  // deleteMainGroup(request: any): Promise<any> {
  //   return new Promise((resolve, reject) => {
  //     resolve({
  //       "id": request
  //     })

  //     reject({
  //       status: 500,
  //       message: "❌ Error deleting subgroup"
  //     })
  //   })
  // }


  // deleteSubgroup(request: any): Promise<any> {
  //   return new Promise((resolve, reject) => {
  //     resolve({
  //       "id": request.id
  //     })

  //     reject({
  //       status: 500,
  //       message: "❌ Error deleting subgroup"
  //     })
  //   })
  // }
}
