import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {CreateCustomerModel, CustomerModel, UpdateCustomerModel} from "./customer.model";
import {environment} from "../../../environments/environment";
import {AddressModel} from "../../shared/address-widget/shared/address.model";
import {ContactModel} from "../../shared/contact-widget/shared/contact.model";
import {NoteModel} from "../../shared/note-widget/shared/note.model";
import { DataExchangeModel } from 'src/app/shared/fileuploader/shared/data-exchange.model';

const url = environment.baseUrl + '/V1/Customers'

@Injectable({
  providedIn: 'root'
})
export class CustomerService {

  constructor(private http: HttpClient) { }

  getAll(): Observable<CustomerModel[]> {
    return this.http.get<CustomerModel[]>(`${url}/All`);
  }

  getSingle(id: number): Observable<CustomerModel>{
    return this.http.get<CustomerModel>(`${url}/${id}`);
  }

  create(model: CreateCustomerModel): Observable<CustomerModel>{
    return this.http.post<CustomerModel>(url, model);
  }

  update(customerId: number, model: UpdateCustomerModel): Observable<CustomerModel>{
    return this.http.put<CustomerModel>(`${url}/${customerId}`, model);
  }

  delete(id: number): Observable<any>{
    return this.http.delete(`${url}/${id}`);
  }

  deleteAddress(customerId: number, addressId: number): Observable<CustomerModel>{
    return this.http.delete<CustomerModel>(`${url}/${customerId}/Address/${addressId}`);
  }

  createAddress(customerId: number, model: AddressModel): Observable<CustomerModel>{
    return this.http.post<CustomerModel>(`${url}/${customerId}/Address`, model);
  }

  updateAddress(customerId: number, model: AddressModel): Observable<CustomerModel>{
    return this.http.put<CustomerModel>(`${url}/${customerId}/Address`, model);
  }

  deleteContact(customerId: number, contactId: number): Observable<CustomerModel>{
    return this.http.delete<CustomerModel>(`${url}/${customerId}/Contact/${contactId}`);
  }

  createContact(customerId: number, model: ContactModel): Observable<CustomerModel>{
     return this.http.post<CustomerModel>(`${url}/${customerId}/Contact`, model);
  }

  getNotes(customerId: number): Observable<CustomerModel>{
    return this.http.get<CustomerModel>(`${url}/${customerId}/Notes`);
  }

  deleteNote(customerId: number, noteId: number): Observable<CustomerModel>{
    return this.http.delete<CustomerModel>(`${url}/${customerId}/Note/${noteId}`);
  }

  createNote(customerId: number, model: NoteModel): Observable<CustomerModel> {
    return this.http.post<CustomerModel>(`${url}/${customerId}/Note`, model);
  }

  updateContact(customerId: number, model: ContactModel)
  {
    return this.http.put<ContactModel>(`${url}/Contact/${customerId}`,model)
  }

  getImportSchemas(){
    return this.http.get<DataExchangeModel[]>(`${url}/GetDataExchangeImportSchemas`);
  }

  processImportFile(file: File, schemaid: number, customerid: number) :Observable<any> {
    const formData: FormData = new FormData();
    formData.append('file', file);
    formData.append('dataExchangeSchemaId', schemaid.toString());
    return this.http.post(`${url}/ImportDataExchange`, formData);
  }
}
