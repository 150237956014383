import {EventModel} from "../../calendar/shared/event.model";

export interface ResourceModel {
  id: string;
  title: string;
  tourHours?: string;
  timeRegistrationHours?: string;
  events: EventModel[];
  restHours?: string;
}

export enum ResourceView {
  users,
  frontVehicles,
  backVehicles,
  equipments
}
