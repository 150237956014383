<div class="search-input--component">
  <form class="w-full">
    <div class="mb-3 d-flex flex-row">

      <div class="col w-full position-relative">
        <input
          class="form-control py-2"
          type="text"
          [formControl]="filter"
          [placeholder]="'grouping.misc.searchPlaceholder' | translate"
        />
        @if (this.internalFilterKey.length >= 1) {
          <span
            class="badge rounded-pill text-bg-default position-absolute left-0"
            style="bottom: -20px"
          >
            {{ this.displayNameTranslation(this.internalFilterKey[0].displayName) | translate }}

            <fa-icon [icon]="faXmark" (click)="this.badgeClick()"></fa-icon>
          </span>
        }
        <app-combobox
          [multiselect]="false"
          [options]="this.columnConfig"
          (selectedOption)="updateFilterColumn($event)"
          class="position-absolute top-0"
          style="right: 20px"
        />
      </div>
    </div>
  </form>
</div>
